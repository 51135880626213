import React, { Component } from 'react';
import Main from './Main';
import Header from './Header';
import Footer from "./Footer";
import { UserContextProvider } from "./context/UserContext";
import { getUser } from "./graphql/queries";
import { createAuditEntry } from "./graphql/mutations";
import { Auth, API, graphqlOperation } from 'aws-amplify';
import { withRouter } from "react-router-dom";
import OverlaySpinner from "./components/spinner/OverlaySpinner";
import Constants from "utils/Constants";
import { clearLoginTimestamp, getLogoutArgs } from "utils/GTMHelper"
import TagManager from 'react-gtm-module'
import Utils from "./utils/Utils";

class App extends Component {
    constructor(props) {
        super(props)
        this.updateProviders = this.updateProviders.bind(this)
        this.updateUser = this.updateUser.bind(this)
        this.logout = this.logout.bind(this)
        this.isAuthenticatedUser = this.isAuthenticatedUser.bind(this)
        this.reloadLoggedInUser = this.reloadLoggedInUser.bind(this)
        this.userHasRole = this.userHasRole.bind(this)
        this.canManageUsers = this.canManageUsers.bind(this)
        this.getRequestsByStatus = this.getRequestsByStatus.bind(this)
        this.resetActivityTime = this.resetActivityTime.bind(this)
        this.refreshUser = this.refreshUser.bind(this)
        this.getRequestsByStatusSync = this.getRequestsByStatusSync.bind(this)

        this.lastActivity = this.currentSecondsSinceEpoch()

        this.state = this.initialState
        this.state.loading = true
    }

    get initialState() {
        return {
            currentUser: undefined,
            cognitoUser: undefined,
            loggedOut: false,
            providers: [],
            providerLinks: [],
            toDashboard: false,
            loading: false,
            managedUsers: undefined,
            actions: {
                updateProviders: this.updateProviders,
                updateUser: this.updateUser,
                logout: this.logout,
                isAuthenticatedUser: this.isAuthenticatedUser,
                reloadLoggedInUser: this.reloadLoggedInUser,
                userHasRole: this.userHasRole,
                canManageUsers: this.canManageUsers,
                getRequestsByStatus: this.getRequestsByStatus,
                resetActivityTime: this.resetActivityTime,
                refreshUser: this.refreshUser,
                getRequestsByStatusSync: this.getRequestsByStatusSync
            }
        }
    }

    async logout() {
        try {
            await API.graphql(graphqlOperation(createAuditEntry, {
                input: {
                    action: Constants.AUDIT_LOGOUT
                }
            }));
            await Auth.signOut()
        }
        catch (error) {
            try {
                await API.graphql(graphqlOperation(createAuditEntry, {
                    input: {
                        action: Constants.AUDIT_LOGOUT,
                        success: false,
                        errorReason: error.code,
                        additionalInfo: error.message
                    }
                }));
            }
            catch (error) {
                // Swallow this error. Not much we can do here if the audit logging fails
            }
        }
        TagManager.dataLayer(getLogoutArgs())

        clearLoginTimestamp()
        this.setState(this.initialState)
    }

    checkActivityTimeout(secondsSinceEpoch) {
        const isActive = secondsSinceEpoch < this.lastActivity + Constants.ACTIVITY_TIMEOUT
        console.log("LASTACTIVE", new Date(secondsSinceEpoch * 1000), new Date(this.lastActivity * 1000), "Still active", isActive)
        return isActive
    }

    checkSessionTimeout(cognitoUser, secondsSinceEpoch) {
        let { idToken } = cognitoUser.signInUserSession

        if (idToken.payload.auth_time + Constants.SESSION_TIMEOUT > secondsSinceEpoch) {
            return true
        }
        return false
    }

    resetActivityTime() {
        this.lastActivity = this.currentSecondsSinceEpoch()
    }

    currentSecondsSinceEpoch() {
        return Math.floor((new Date()).getTime() / 1000)
    }

    async componentDidMount() {
        this.setState({ loading: true })

        await this.reloadLoggedInUser();
    }

    isAuthenticatedUser() {
        let authStatus = Constants.AUTH.NONE
        let { cognitoUser } = this.state // Should we be using this? await Auth.currentAuthenticatedUser()

        if (cognitoUser !== undefined) {

            let secondsSinceEpoch = this.currentSecondsSinceEpoch()
            let isEmailVerified = cognitoUser.attributes.email_verified;
            if (!this.checkSessionTimeout(cognitoUser, secondsSinceEpoch) || !this.checkActivityTimeout(secondsSinceEpoch)) {
                authStatus = Constants.AUTH.LOGGED_OUT
            } else if (this.state.currentUser === undefined) {
                authStatus = Constants.AUTH.NONE
            } else if (!isEmailVerified) {
                authStatus = Constants.AUTH.NOT_VERIFIED
            }
            else {
                authStatus = Constants.AUTH.VALID
                this.resetActivityTime()
            }
        }
        return authStatus
    }

    async reloadLoggedInUser() {
        try {
            const cognitoUser = await Auth.currentAuthenticatedUser({
                bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
            })
            await this.refreshUser()
            this.setState({
                cognitoUser: cognitoUser,
                loading: false
            })
        }
        catch (error) {
            console.log("No logged in user is available: ", error)
            this.setState(this.initialState)
        }
    }

    async refreshUser() {
        const response = await API.graphql(graphqlOperation(getUser))
        const details = response.data.getUser
        this.updateUser(details)
        let users = details.links.flatMap(l => l.provider.managedUsers || [])
        users = this.processPendingRequests(users)
        await this.updateManagedUsers(users)
        await this.updateProviders(details.links)
        console.log("Refreshed user", this.state)
        this.setState({ toDashboard: true })
    }

    processPendingRequests(managedUsers) {
        let otherusers = managedUsers.filter(u => {
            return u.links.filter(p => p.status !== Constants.PENDING).length > 0
        })
        let users = managedUsers.filter(u => {
            return u.links.filter(p => p.status === Constants.PENDING).length > 0
        })
        // Group by the stem
        users = Utils.groupByArray(users, u => u.links[0].provider.providerNumber.substring(0, 6) + u.username)
        // Only use the first element in the array but add the other providers into this one
        users = users.map(u => {
            u.forEach((user, i) => {
                if (i > 0) {
                    user.links.forEach(e => u[0].links.push(e))
                }
            })
            return u[0]
        })
        return users.concat(otherusers)
    }

    async getRequestsByStatus(status, forceRefresh = false) {
        let managedUsers = await this.getManagedUsers(forceRefresh)
        return managedUsers.filter(u => {
            return u.links.filter(p => p.status === status).length > 0
        })
    }

    getRequestsByStatusSync(status) {
        return this.state.managedUsers.filter(u => {
            return u.links.filter(p => p.status === status).length > 0
        })
    }

    async getManagedUsers(forceRefresh = false) {
        if (this.state.managedUsers && !forceRefresh) {
            return this.state.managedUsers
        } else {
            await this.refreshUser()
        }
    }


    async getUserDetails() {
        let response = await API.graphql(graphqlOperation(getUser));
        console.log("UD", response.data.getUser, response.error)
        return response
    }

    userHasRole(role) {
        let links = this.state.providerLinks || []
        return links.filter(p => p.status === Constants.ACCEPTED).filter(p => p.role === Constants.USER_ROLE_MAPPING[role]).length > 0
    }

    canManageUsers() {
        return this.userHasRole(Constants.USER_ROLE_DOCTOR) || this.userHasRole(Constants.USER_ROLE_PM)
    }

    updateUser(user) {
        this.setState({ currentUser: user })
    }

    updateProviders(providerLinks) {
        this.setState({
            providerLinks, providers: providerLinks.filter(p => p.status === Constants.ACCEPTED).map(p => { return p.provider }), alerts: providerLinks.filter(pl => {
                return pl.status === Constants.PENDING && pl.provider.isOwner
            })
        })
    }

    updateManagedUsers(users) {
        this.setState({ managedUsers: users })
    }

    renderMainSection() {
        if (this.state.loading) {
            return (
                <div className='pp-main with-spinner'>
                    <OverlaySpinner clearOverlay={true} />
                </div>
            )
        } else {
            return (
                <>
                    <Header />
                    <Main />
                </>
            )

        }
    }

    render() {

        return (
            <div className='no-touch'>
                <UserContextProvider value={{ ...this.state }}>
                    {this.renderMainSection()}
                    <Footer />
                </UserContextProvider>
            </div>
        );
    }
}

export default withRouter(App);
