import React, { Component } from 'react';
import classnames from "classnames";
import styles from './STEMSearchStep.module.scss';
import UserContext from "../../context/UserContext";
import CancelButtonComponent from "./CancelButtonComponent";
import STEMNoModalComponent from "./STEMNoModalComponent";
import SpinnerButtonComponent from 'components/spinnerButton/SpinnerButtonComponent'
import TooltipIcon from "../TooltipIcon";
import {getPageLoadArgs, getManageProvidersArgs} from "utils/GTMHelper"
import TagManager from "react-gtm-module";
import {withRouter} from "react-router-dom";

export class STEMSearchStep extends Component {

    constructor(props) {
        super(props);

        this.handleAHPRANumberChange = this.handleAHPRANumberChange.bind(this)
        this.handleStemNumberChange = this.handleStemNumberChange.bind(this)
        this.handleNext = this.handleNext.bind(this)
        this.handleSpecialty = this.handleSpecialty.bind(this)

        this.state = {
            inError: false,
            error: null
        }

    }

    handleAHPRANumberChange(event) {
        this.props.actions.setAHPRANumber(event.target.value)
    }

    handleStemNumberChange(event) {
        this.props.actions.setSTEMNumber(event.target.value)
    }

    handleNext() {
        this.props.actions.handleSearch()
    }

    handleSpecialty(event) {
        var specialty = event.target.options[event.target.selectedIndex];
        this.props.actions.setSpecialty(specialty.text, specialty.value)
    }

    componentDidMount() {
        const {cognitoId, role} = this.context.currentUser
        TagManager.dataLayer(getPageLoadArgs('Add Another STEM', this.props.location.pathname, cognitoId, role))

        const tagParms = {
            label: 'Start',
            sequenceId: this.props.gtmSequenceId,
            manageType: 'Add STEM'
        }

        TagManager.dataLayer(getManageProvidersArgs(tagParms))
    }

    render() {
        let {specialties, errorMessage, errorCode, inError} = this.props
        let ahpraClasses = 'input-group'
        let stemClasses = 'input-group'

        var actualErrorMessage = (<span>&nbsp;</span>);
        if (inError) {
            if (errorCode === 'InvalidStem' || errorCode === 'NameMismatch' || errorCode === 'StemLinkedToDoctor') {
                actualErrorMessage = errorMessage
                if (errorCode === 'NameMismatch') {
                    actualErrorMessage = 'Sorry, your STEM number does not match your details provided at time of registration. Please check and try again.'
                }
                stemClasses += ' input-group-alert'
            } else if (errorCode === 'AHPRAMismatch') {
                actualErrorMessage = 'Sorry, your AHPRA number does not match your STEM number. Please check and try again.'
                ahpraClasses += ' input-group-alert'
            } else {
                actualErrorMessage = "There was a technical error verifying your details. Please try again."
                stemClasses += ' input-group-alert'
            }
        }
        return (
            <div className={styles.step1Wrapper}>
                <div className="container">
                    <div className={classnames('row justify-content-between')}>
                        <div className={classnames('col-lg-8', styles.header)}>
                            <h3>Connect your provider details</h3>
                            <p>Please enter your details below so we can connect you with your provider data</p>
                        </div>
                        <div className={classnames(styles.header)}>
                            <CancelButtonComponent />
                        </div>
                    </div>
                    <div className={classnames('row', styles.contextBox)}>
                        <div className={classnames('col-lg-12', styles.title)}>
                            <h4>Provider details</h4>
                        </div>
                        <div className={classnames('col-lg-12', styles.contentWrapper)}>
                            <div className={classnames('col-lg-6', styles.content)}>
                                <div className={classnames("input-group select")}>
                                    <label htmlFor="specialties">Your specialty</label>
                                    <select id="specialties" onChange={this.handleSpecialty} >
                                        <option key={"none"} value={"none"}>Select specialty</option>
                                        {specialties.map(s => {
                                            return <option key={s.id} value={s.id}>{s.name}</option>
                                        })}
                                    </select>
                                    <span className="select-chevron"></span></div>

                                    <div className={classnames(stemClasses, styles.input)}>
                                        <label htmlFor="stemNumber">Your STEM Number <TooltipIcon showModal={true} className='icon icon-xs'><STEMNoModalComponent modalId='tooltipModal' /></TooltipIcon></label>
                                    <input id='stemNumber'
                                           type='text'
                                           className='input-search form-control'
                                           onChange={this.handleStemNumberChange}
                                    />
                                </div>
                                <div className={classnames(ahpraClasses, styles.input)}>
                                    <label htmlFor="ahpraNumber">Your AHPRA number, including the three characters that precede your individual number</label>
                                    <input id='ahpraNumber'
                                           type='text'
                                           className='input-search form-control'
                                           onChange={this.handleAHPRANumberChange}
                                    />
                                </div>
                                <div data-test-id='error' className={classnames('input-helper form-error small', styles.inputHelper)}>
                                    {actualErrorMessage}
                                </div>
                                <SpinnerButtonComponent disabled={this.props.disabled} handleClick={this.handleNext} spinning={this.props.searching} text={'Next'} className={styles.button}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default withRouter(STEMSearchStep);
STEMSearchStep.contextType = UserContext;
