import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { ReactComponent as MedibankLogo } from "svgs/logo-coloured.svg";
import PrimaryNav from './PrimaryNav';
import {withRouter} from 'react-router-dom';
import UserContext from 'context/UserContext'

class Header extends Component {

    renderHeaderNav(context) {
        if (this.props.location.pathname === '/verification' ||
            this.props.location.pathname === '/notverified') {
            return null
        } else if (this.props.location.pathname === '/login' ||
            this.props.location.pathname === '/forgotten') {
            return (
                <div>Not registered? <Link className='simple-link' to="/registration">Register now</Link></div>
            )
        } else if (this.props.location.pathname === '/registration') {
            return (
                <div>Would you like to exit? <Link className='simple-link' to="/login">Back to login</Link></div>
            )
        } else if (context.currentUser) {
            const {firstName, lastName} = context.currentUser;
            return (
                <PrimaryNav
                    firstName={firstName}
                    lastName={lastName}
                />
            )
        }
    }

    render() {
        return (
            <header>
                <UserContext.Consumer>
                    {userContext => {
                        return <nav>
                            <div className='menu-bar'>
                                <div className='navigation-wrapper'>
                                    <div className='logo-medibank'>
                                        <a href="https://www.medibank.com.au/" title="Medibank home">
                                            <MedibankLogo/>
                                        </a>
                                    </div>
                                    {this.renderHeaderNav(userContext)}
                                </div>
                            </div>
                        </nav>
                    }
                }
                </UserContext.Consumer>
            </header>
        );
    }
}

export default withRouter(Header)