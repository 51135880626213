import React, { Component } from 'react';
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'

class ConnectedUsersItemComponent extends Component {

    render() {

        const {user} = this.props

        return (
            <tr>
                <td>{user.firstName}&nbsp;{user.lastName}</td>
                <td>{user.email}</td>
                <td><Link className='simple-link-reverse' to={'/manageuser/' + user.username}>Manage</Link></td>
            </tr>
        )
    }
}

ConnectedUsersItemComponent.propTypes = {
    user: PropTypes.object.isRequired
}

export default ConnectedUsersItemComponent;