import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ConfigHelper from 'config/ConfigHelper';
import classnames from 'classnames';
import ProviderSearchContainer from "components/providerselect/ProviderSearchContainer";
import DatePeriodSearchComponent from "components/DatePeriodSearchComponent";
import EFTReferenceSearchComponent from "components/EFTReferenceSearchComponent";
import SearchCriteriaContext from "context/SearchCriteriaContext";
import styles from './SearchCriteriaComponent.module.scss'
import {getPageLoadArgs} from "utils/GTMHelper"
import TagManager from "react-gtm-module"

class SearchCriteriaComponent extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.tagSearchOption = this.tagSearchOption.bind(this)

        this.state = {
            activeTab: '1'
        };
    }

    tagSearchOption() {
        let tagDesc =  'Remittance advice - by provider'
        if (this.state.activeTab !== '1') {
            tagDesc = 'Remittance advice - by eft'
        }

        const {cognitoId, role} = this.props.currentUser
        const {pathname} = this.props
        TagManager.dataLayer(getPageLoadArgs(tagDesc, pathname, cognitoId, role))
    }

    toggle(tab) {
        const {setSearchProviders, setEftRef} = this.context.actions;

        if (this.state.activeTab !== tab) {
            (tab !== '1') ? setEftRef('') : setSearchProviders([]);


            this.setState({
                activeTab: tab
            }, () => {
                this.tagSearchOption()
            });
        }
    }

    renderSearchButton() {
        let disabled = !this.props.searchEnabled;
        let buttonContents = 'Search';
        if (this.props.isLoading) {
            buttonContents = <div className="spinner-md text-white"></div>;
            disabled = true;
        }

        return (
            <div className='col-lg-3 col-md-4 search-btn-wrapper'>
                <div className='input-group'>
                    <button
                        className='btn-lg btn btn-primary'
                        disabled={disabled}
                        onClick={this.props.executeSearch}
                        color="primary">
                        {buttonContents}
                    </button>
                </div>
            </div>
        )
    }

    componentDidMount() {
        this.tagSearchOption()
    }

    render() {
        const earliestRemittanceDate = ConfigHelper.getRemittanceSearchCutoffDate(new Date());
        return (
            <div className='pp-search-criteria-wrapper'>
                <ul className='pp-nav-tabs nav nav-tabs'>
                    <li className='nav-item'>
                        <div
                            className={classnames('nav-link', { active: this.state.activeTab === '1' })}
                            onClick={() => { this.toggle('1'); }}
                        >
                            Search by provider
                        </div>
                    </li>
                    <li className='nav-item'>
                        <div
                            className={classnames('nav-link', { active: this.state.activeTab === '2' }, styles.navLinkWide)}
                            onClick={() => { this.toggle('2'); }}
                        >
                            EFT reference number
                        </div>
                    </li>
                </ul>
                <div className='tab-content pp-tab-content'>
                    <div className={classnames('tab-pane tab-content-pane', { active: this.state.activeTab === '1' })} >
                        <div className='row search-btn-row'>
                            <div className='col-lg-9 col-md-8'>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <ProviderSearchContainer
                                            providers={this.props.providers}
                                        />
                                    </div>
                                </div>
                                <div className={classnames('row', styles.dateSearchRowStyle)}>
                                    <div className='col-lg-7'>
                                        <DatePeriodSearchComponent
                                            searchPeriodTitle={'Search period (Date of issue)'}
                                            earliestSelectableDate={earliestRemittanceDate}
                                        />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <span> You will only be able to search a remittance advice from the date you
                                            registered to use Provider Self Service. If you need information prior to
                                            that date, please contact us. Find our details in the </span>
                                        <a
                                            className="simple-link-reverse"
                                            href="https://www.medibank.com.au/contact-us/"
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            Healthcare providers section
                                        </a>
                                        .
                                    </div>
                                </div>
                            </div>
                            {this.renderSearchButton()}
                        </div>
                    </div>
                    <div className={classnames('tab-pane tab-content-pane', { active: this.state.activeTab === '2' })} >
                        <div className='row search-btn-row'>
                            <div className='col-lg-12'>
                                <div className='row'>
                                    <div className='col-lg-9 col-md-8'>
                                        <EFTReferenceSearchComponent />
                                    </div>
                                    {this.renderSearchButton()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

SearchCriteriaComponent.contextType = SearchCriteriaContext;

SearchCriteriaComponent.propTypes = {
    providers: PropTypes.instanceOf(Array).isRequired,
    searchEnabled: PropTypes.bool.isRequired,
    executeSearch: PropTypes.func.isRequired,
    currentUser: PropTypes.object.isRequired,
    pathname: PropTypes.string.isRequired
};

export default SearchCriteriaComponent;