// eslint-disable
// this is an auto generated file. This will be overwritten

export const updateUserDetails = `mutation UpdateUserDetails($input: UpdateUserDetailsInput) {
  updateUserDetails(input: $input) {
    username
    firstName
    lastName
    mobile
    email
    role
  }
}
`;

//features {
//       remittances
//       claims
//       confidentialMessages
//     }
//provider {
//       number
//       name
//       practiceName
//       address
//     }
//     approver {
//       username
//       firstName
//       lastName
//     }
export const createProviders = `mutation CreateProviders($input: CreateProvidersInput) {
  createProviders(input: $input) {
    result
  }
}
`;

export const linkProviders = `mutation LinkProviders($input: LinkProvidersInput) {
  linkProviders(input: $input) {
    result
  }
}
`;

export const processAccessRequest = `mutation ProcessAccessRequest($input: AccessRequest) {
  processAccessRequest(input: $input) {
    result
  }
}
`;

export const updateRole = `mutation UpdateRole($input: UpdateRoleInput) {
  updateRole(input: $input) {
    result
  }
}
`;

export const verifyEmailToken = `mutation UpdateRole($input: VerifyEmailTokenInput) {
  verifyEmailToken(input: $input) {
    cognitoId
    username
    result
  }
}
`;

export const sendEmailVerification = `mutation SendEmailVerification($input: SendEmailVerificationInput) {
  sendEmailVerification(input: $input) {
    result
  }
}
`;

export const createAuditEntry = `mutation CreateAuditEntry($input: CreateAuditEntryInput) {
  createAuditEntry(input: $input) {
    result
  }
}
`;

export const idCheck = `mutation IdCheck($input: IDCheckInput) {
  idCheck(input: $input) {
    idVerified
    idVerificationRef
  }
}
`;