import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ProviderMultiListComponent from './ProviderMultiListComponent';
import { ReactComponent as CloseIcon } from 'medikit/dist/base/svgs/icon-close.svg';

const headerStyles = {
    padding: '12px'
}

const h3Styles = {
    marginTop: '0px',
    paddingBottom: '20px'
}

const igStyles = {
    marginBottom: '0px'
}

const labelStyles = {
    paddingLeft: '42px',
    marginBottom: '12px'
}

class ProviderMultiSelectSearchModalContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isAllSelected: this.props.selectedProviders.length === this.props.providers.length,
            selectedProviders: this.props.selectedProviders
        };

        this.handleClose = this.handleClose.bind(this);
        this.handleDone = this.handleDone.bind(this);
        this.handleProviderSelect = this.handleProviderSelect.bind(this);
        this.handleProviderDeselect = this.handleProviderDeselect.bind(this);
        this.handleSelectAll = this.handleSelectAll.bind(this);
    }

    handleSelectAll() {
        if (this.state.isAllSelected) {
            this.setState({
                selectedProviders: [],
                isAllSelected: false
            })
        } else {
            this.setState({
                selectedProviders: this.props.providers,
                isAllSelected: true
            })
        }
    }

    handleProviderSelect(provider) {
        // Prevent provider from being added more than once to array of selected
        // providers...
        let exists = this.state.selectedProviders.find((el) => {
            return el.id === provider.id;
        });
        if (!exists) {
            // If the user has individually selected every provider, then make sure the
            // Select All checkbox gets ticked.
            let allProvidersSelected = false
            if (this.props.providers.length === (this.state.selectedProviders.length + 1)) {
                allProvidersSelected = true
            }
            this.setState({
                selectedProviders: [...this.state.selectedProviders, provider],
                isAllSelected: allProvidersSelected
            });
        }
    }

    handleProviderDeselect(provider) {
        const filteredArray = this.state.selectedProviders.filter((el) => {
            return el.id !== provider.id;
        });
        this.setState({
            selectedProviders: filteredArray,
            isAllSelected: false
        });
    }

    handleClose() {
        this.props.handleModalClose();
    }

    handleDone() {
        this.props.handleMultiProviderSelect(this.state.selectedProviders);
    }

    componentDidMount() {
        // Setting the modal-open class on the document body prevents it from
        // scrolling underneath the open modal
        document.body.classList.add('modal-open');
    }

    componentWillUnmount() {
        document.body.classList.remove('modal-open');
    }

    renderModalHeader() {
        return (
            <div className='row no-gutters'>
                <div style={headerStyles} className='col-lg-12'>
                    <h3 style={h3Styles}>Browse providers</h3>
                    <div style={igStyles} className='input-group'>
                        <label style={labelStyles} className='input-checkbox'>
                            <span className='simple-link-reverse'>Select all</span>
                            <input type='checkbox'
                                   checked={this.state.isAllSelected}
                                   onChange={this.handleSelectAll}
                                   data-test-id='selectAll'
                            />
                            <div className='input-indicator needsclick'></div>
                        </label>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <>
                <div className='modal' tabIndex="-1" role='dialog' aria-labelledby='exampleModalLabel'
                     aria-hidden='false' data-modal>
                    <div className='modal-dialog' role='document'>
                        <div className="modal-content">
                            <button onClick={this.handleClose} type='button' className='modal-dismiss' aria-label='Close' data-modal-dismiss>
                                <CloseIcon className='icon icon-stroke icon-xs' />
                            </button>
                            <div className='modal-body'>
                                {this.renderModalHeader()}

                                <ProviderMultiListComponent
                                    providers={this.props.providers}
                                    handleProviderSelect={this.handleProviderSelect}
                                    handleProviderDeselect={this.handleProviderDeselect}
                                    selectedProviders={this.state.selectedProviders}
                                />
                                <div className='btn-container'>
                                    <button onClick={this.handleDone} className='btn btn-lg btn-primary'>Done</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='modal-overlay-dismiss'
                         onClick={this.handleClose}
                         data-modal-dismiss></div>
                </div>
                <div className='modal-overlay'></div>
            </>
        )
    }
}

ProviderMultiSelectSearchModalContainer.propTypes = {
    providers: PropTypes.instanceOf(Array).isRequired,
    selectedProviders: PropTypes.instanceOf(Array).isRequired,
    handleMultiProviderSelect: PropTypes.func.isRequired,
    handleModalClose: PropTypes.func.isRequired
};

export default ProviderMultiSelectSearchModalContainer;