import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from 'medikit/dist/base/svgs/icon-close.svg';
import classnames from "classnames"
import styles from './AlertModalComponent.module.scss'

/***
 * This modal component is written purely using react and does not rely on any Medikit
 * specific modal functionality.
 * Use this modal if you need to programmatically display a modal based on some
 * condition, rather than showing the modal as a result of a click on an element.
 */

class AlertModalComponent extends Component {

    constructor(props) {
        super(props);

        this.modalRef = React.createRef();

        this.handleClose = this.handleClose.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        // Setting the modal-open class on the document body prevents it from
        // scrolling underneath the open modal
        document.body.classList.add('modal-open');
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        document.body.classList.remove('modal-open');
    }

    /***
     * Pass control to the parent component for direction when the user clicks outside
     * the modal
     * @param event
     */
    handleClickOutside(event) {
        if (this.modalRef &&
            !this.modalRef.current.contains(event.target))
        {
            this.handleClose();
        }
    }

    handleClose() {
        this.props.handleModalClose();
    }

    render() {
        const {modalTitle, modalContent, buttonText} = this.props;

        return (
            <>
                <div ref={this.modalRef} className='modal' tabIndex="-1" role='dialog' aria-labelledby='exampleModalLabel'
                     aria-hidden='false' data-modal>
                    <div className='modal-dialog' role='document'>
                        <div className={classnames("modal-content",styles.modalContent)}>
                            <button onClick={this.handleClose} type='button' className='modal-dismiss' aria-label='Close' data-modal-dismiss>
                                <CloseIcon className='icon icon-stroke icon-xs' />
                            </button>
                            <div className='modal-body'>
                                {
                                    modalTitle &&
                                    <>
                                        <h3 id="tooltipModalLabel">{modalTitle}</h3>
                                        <hr />
                                    </>
                                }
                                {modalContent}
                                <div className='btn-container'>
                                    <button onClick={this.handleClose} className='btn btn-lg btn-primary'>{buttonText}</button>
                                </div>
                                {/*
                                    The use of props.children allows the parent component to pass through additional
                                    components to the modal if necessary - see EmailDetailsEditContainer for an
                                    example of how it can be used.
                                */}
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                    <div className='modal-overlay-dismiss'
                         onClick={this.handleClose}
                         data-modal-dismiss></div>
                </div>
                <div className='modal-overlay'></div>
            </>
        )
    }
}

AlertModalComponent.propTypes = {
    handleModalClose: PropTypes.func.isRequired,
    modalTitle: PropTypes.string,
    modalContent: PropTypes.object.isRequired,
    buttonText: PropTypes.string
};

AlertModalComponent.defaultProps = {
    buttonText: 'Close'
}
export default AlertModalComponent;