import React, { Component } from 'react';
import styles from './Step1Container.module.scss';
import styles2 from './Step2Container.module.scss';
import LoginContext from "context/LoginContext";
import classnames from 'classnames';
import Utils from 'utils/Utils'
import ResendVerificationCodeLink from "../registration/ResendVerificationCodeLink";

class Step2Container extends Component {
    constructor(props) {
        super(props);

        this.smsInput = React.createRef();

        this.handleNext = this.handleNext.bind(this);
        this.handleMfaChange = this.handleMfaChange.bind(this);

        this.state = {
        }
    }

    handleNext() {
        this.context.actions.completeStep2();
    }

    handleMfaChange(event) {
        this.context.actions.setMfaCode(event.target.value)
    }

    renderConfirmButton() {
        let disabled = !this.props.nextEnabled;
        let buttonContents = 'Verify code';
        if (this.context.verifyingLogin) {
            buttonContents = <div className="spinner-md text-white"></div>;
            disabled = true;
        }

        return (
            <div className={styles.buttonDiv}>
                <div className='input-group'>
                    <button
                        onClick={this.handleNext}
                        className='btn-block btn btn-lg btn-primary'
                        disabled={disabled}
                        color="primary">
                        {buttonContents}
                    </button>
                </div>
            </div>
        )
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.smsInput.current.focus();
    }

    render() {
        const mobileNumber = this.props.cognitoUser ? 'xx' + this.props.cognitoUser.challengeParam.CODE_DELIVERY_DESTINATION.slice(-4) : 'xxxx'
        const {inError, error} = this.context;
        let classes = 'input-group';
        if (inError) {
            classes += ' input-group-alert';
        }
        var errorMessage = (<span>&nbsp;</span>);

        if (inError) {
            if (error === 'UserNotConfirmedException') {
                errorMessage = (
                    <span>
                        Sorry, the user is not verified during registration.
                        Please contact our Provider Self Service enquiry team.
                        Find our details in the&nbsp;
                        <a className='simple-link-reverse'
                           href="https://www.medibank.com.au/contact-us/"
                           rel='noopener noreferrer'
                           target='_blank'>Healthcare providers section</a>
                    </span>
                );
            } else {
                errorMessage = Utils.translateSMSErrorText(error);
            }
        }
        return (
            <div className={classnames('', styles.loginWrapper)}>
                <h4 className={classnames('', styles.title)}>Enter code</h4>
                <hr/>
                <p>
                    Please enter the 6-digit verification code sent to the number ending in {mobileNumber}.
                    This code will expire in 3 minutes.
                </p>
                <div className={classnames(classes, styles2.mfa)}>
                    <label htmlFor="mfa">SMS verification code</label>
                    <input id='mfa'
                           type='text'
                           className='input-search form-control'
                           ref={this.smsInput}
                           onChange={this.handleMfaChange}
                    />
                </div>
                <div data-test-id='error' className={classnames('input-helper form-error small', styles.inputHelper)}>
                    {errorMessage}
                </div>
                {this.renderConfirmButton()}
                <span className={styles.link}>
                    <ResendVerificationCodeLink
                        mobileNumberEnding={mobileNumber}
                        continue={this.context.actions.continueStep2}
                    />
                </span>

            </div>
        );
    }
}

Step2Container.contextType = LoginContext;


export default Step2Container;