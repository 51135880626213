import classnames from "classnames";
import styles from "./PracticeManagerInfoPanel.module.scss";
import React, {Component} from "react";
import { ReactComponent as InfoIcon } from "svgs/icon-info.svg";

class PracticeManagerInfoPanel extends Component {
    render() {
        return <div className={classnames('row', styles.infoPanel)}>
                    <div  className={classnames('col-lg-1')}>
                        <InfoIcon className={classnames('icon icon-stroke', styles.infoIcon)}/>
                    </div>
                    <div className={classnames('col-lg-11')}>
                        <h4>Practice manager has permission to manage their users</h4>
                        <p>
                            Accepting someone as your practice manager means that you are allowing them to view and make actions on your tasks on your behalf.
                            They can also authorise other users to be linked to your account, to perform these tasks for you.
                            You can edit the role of a user via the Manage users tab, and clicking Edit.
                        </p>
                        <p>
                            If you wish to remove a user's access to your account, or the user no longer works at your practice, you should contact us as soon as possible to do this for you.
                            Find our contact details in the <a
                                className='simple-link-reverse'
                                href="https://www.medibank.com.au/contact-us/"
                                rel='noopener noreferrer'
                                target='_blank'
                            >
                                Healthcare providers section
                            </a>
                        </p>
                    </div>
            </div>
    }
}

export default PracticeManagerInfoPanel