import React, { Component } from 'react';
import styles from './DashboardTile.module.scss'
import classnames from 'classnames';
import {Link} from 'react-router-dom';


class DashboardTile extends Component {

    renderContent() {
        if (this.props.icon) {
            return <div className={styles.tile}>
                <div className={classnames('', styles.iconWrapper)}>{this.props.icon}</div>
                <p className={styles.title}>{this.props.title}</p>
            </div>
        } else {
            return <div className={styles.hiddenTile}>
                <div className={classnames('', styles.iconWrapper)}></div>
                <p className={styles.title}>&nbsp;</p>
            </div>
        }
    }

    renderLink() {
        if (this.props.link) {
            return <Link to={this.props.link} className={styles.link}>
                {this.renderContent()}
            </Link>
        } else {
            return this.renderContent()
        }
    }

    render() {
        return (
            <div className={classnames('col-lg-4', styles.tileWrapper)}>
                {this.renderLink()}
            </div>
        );
    }
}

export default DashboardTile;