import React, { Component } from 'react';
import DetailsDisplayComponent from './DetailsDisplayComponent'
import PropTypes from 'prop-types'
import EmailDetailsEditContainer from "./EmailDetailsEditContainer";
import {getGTMSequenceId} from "utils/GTMHelper"
import TagManager from "react-gtm-module";
import {getYourDetailsArgs} from "utils/GTMHelper"

class EmailDetailsContainer extends Component {

    constructor(props) {
        super(props)

        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.handleSaveSuccess = this.handleSaveSuccess.bind(this);

        this.state = {
            isInEditMode: false,
            emailUpdated: false,
            gtmSequenceId: ''
        }
    }

    handleEditClick() {
        this.setState({
            isInEditMode: true,
            emailUpdated: false
        })
    }

    handleCancelClick() {
        this.setState({
            isInEditMode: false,
            emailUpdated: false
        })
    }

    handleSaveSuccess() {
        const tagParms = {
            label: 'Form Complete',
            sequenceId: this.state.gtmSequenceId,
            detailsType: 'Email address'
        }

        TagManager.dataLayer(getYourDetailsArgs(tagParms))

        this.setState({
            isInEditMode: false,
            emailUpdated: true
        })
    }

    componentDidMount() {
        this.setState({
            gtmSequenceId: getGTMSequenceId()
        })
    }

    renderEditComponent() {

        return (
            <EmailDetailsEditContainer
                handleCancelClick={this.handleCancelClick}
                handleSaveSuccess={this.handleSaveSuccess}
            />
        )
    }

    renderDisplayComponent() {
        const {email} = this.props

        return (
            <DetailsDisplayComponent
                title={'Email address'}
                fieldValue={email}
                buttonText={'Edit'}
                onButtonClick={this.handleEditClick}
                showUpdateAlert={this.state.emailUpdated}
                gtmSequenceId={this.state.gtmSequenceId}
            />
        )
    }

    render() {

        if (this.state.isInEditMode) {
            return (
                this.renderEditComponent()
            )
        } else {
            return (
                this.renderDisplayComponent()
            )
        }

    }
}

EmailDetailsContainer.propTypes = {
    email: PropTypes.string.isRequired
}

export default EmailDetailsContainer;