import React from 'react';
import styles from './TermsAndConditionsContainer.module.scss';
import RegistrationContext from "context/RegistrationContext";
import AlertComponent from '../AlertComponent';
import { getTermsAndConditionsHtml } from "./TermsAndConditions";
import classNames from 'classnames';
import {getGTMSequenceId,
    getRegoStepArgs,
    TAG_LABEL_FIELD_COMPLETE,
    TAG_LABEL_STEP_START
} from "utils/GTMHelper"
import TagManager from "react-gtm-module";

const TAG_STEP_NAME = 'Terms and Conditions';
const TAG_FIELD_NAME_ACCEPT_BUTTON = 'Accept Button';

class TermsAndConditionsContainer extends React.Component {

    constructor(props) {
        super(props);

        this.onTermsScroll = this.onTermsScroll.bind(this);
        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.onAcceptTermsCheckboxToggled = this.onAcceptTermsCheckboxToggled.bind(this);

        this.state = {
            acceptTermsCheckboxChecked: false,
            hasScrolledToBottom: false,
        };
    }

    onTermsScroll(event) {
        const node = event.target;
        const buffer = 1;
        const scrollOffset = Math.floor(node.scrollHeight - node.scrollTop) - buffer;
        if (scrollOffset <= node.clientHeight) {
            this.setState({ hasScrolledToBottom: true });
        }
    }

    handleButtonClick() {
        if (!this.state.hasScrolledToBottom) { return; }

        const {gtmSequenceId} = this.context
        const tagParms = {
            label: TAG_LABEL_FIELD_COMPLETE,
            sequenceId: gtmSequenceId,
            stepName: TAG_STEP_NAME,
            stepValue: 0,
            fieldName: TAG_FIELD_NAME_ACCEPT_BUTTON,
            fieldValue: ''
        }
        TagManager.dataLayer(getRegoStepArgs(tagParms))

        this.props.handleAcceptTermsAndConditions();
    }

    onAcceptTermsCheckboxToggled() {
        this.setState({ acceptTermsCheckboxChecked: !this.state.acceptTermsCheckboxChecked });
    }

    componentDidMount() {
        const gtmSequenceId = getGTMSequenceId()
        const {actions} = this.context
        const tagParms = {
            label: TAG_LABEL_STEP_START,
            sequenceId: gtmSequenceId
        }

        TagManager.dataLayer(getRegoStepArgs(tagParms))
        actions.setGTMSequenceId(gtmSequenceId)

        window.scrollTo(0, 0);
    }

    render() {
        const buttonEnabled = (this.state.hasScrolledToBottom && this.state.acceptTermsCheckboxChecked) === true;
        const buttonClassNames = classNames({
            'btn': true,
            'btn-primary': true,
            'btn-disabled': !buttonEnabled,
        });

        const disabledClass = classNames({ [styles['disabled']]: !this.state.hasScrolledToBottom });

        const checkboxClassNames = classNames({
            'input-group': true,
            [styles['checkbox-agree']]: true,
        });

        return (
            <div className={classNames(styles.contentWrapper, 'container')}>
                <AlertComponent
                    alertInfo={true}
                    alertMessage="Please read and accept the Terms of Use."
                    className={styles.alert}
                    isLargeText={true}
                />
                <div className={classNames(styles.content)}>
                    <h3>Terms of Use</h3>
                    <div
                        className={styles.terms}
                        onScroll={this.onTermsScroll}
                    >
                        {getTermsAndConditionsHtml()}
                    </div>
                    <div className={styles['footer']}>
                        <div className={checkboxClassNames}>
                            <label className={classNames('input-checkbox', styles['checkbox-wrapper'], disabledClass)}>
                                <span>I have read and agree to the </span>
                                <span>Terms of Use and </span>
                                <a
                                    className='simple-link-reverse'
                                    href="https://www.medibank.com.au/legal-information/"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    Medibank's Privacy Policy
                                </a>
                                <span>.</span>
                                <input
                                    defaultChecked={false}
                                    disabled={!this.state.hasScrolledToBottom}
                                    onClick={this.onAcceptTermsCheckboxToggled}
                                    type='checkbox'
                                    value={this.state.acceptTermsCheckboxChecked}
                                />
                                <span className={classNames('input-indicator', 'needsclick', disabledClass)} />
                            </label>
                        </div>
                        <button
                            disabled={!buttonEnabled}
                            className={buttonClassNames}
                            onClick={this.handleButtonClick}
                        >
                            Continue to create account
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

TermsAndConditionsContainer.contextType = RegistrationContext;

export default TermsAndConditionsContainer;
