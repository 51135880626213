import React, { Component } from 'react';
import classnames from 'classnames';
import styles from './MyUsersContainer.module.scss';
import AccountNavComponent from './AccountNavComponent'
import AlertsComponent from "components/dashboard/AlertsComponent"
import ConnectedUsersContainer from 'components/account/myusers/ConnectedUsersContainer'
import UserContext from "context/UserContext";
import {getPageLoadArgs} from "utils/GTMHelper"
import TagManager from "react-gtm-module"

class MyUsersContainer extends Component {

    componentDidMount() {
        const {cognitoId, role} = this.context.currentUser
        TagManager.dataLayer(getPageLoadArgs('Manage Users',this.props.location.pathname, cognitoId, role))
    }

    render() {
        return (
            <div className={classnames('container', styles.mainWrapper)}>
                <div className='row'>
                    <div className='col-lg-3'>
                        <AccountNavComponent />
                    </div>
                    <div className='col-lg-9'>
                        <h3>Your connected users</h3>
                        <AlertsComponent extraClasses={styles.alertsContainer} />
                        <ConnectedUsersContainer />
                    </div>
                </div>
            </div>
        )
    }
}

MyUsersContainer.contextType = UserContext

export default MyUsersContainer;