import React, { Component } from 'react';
import styles from './Step2DVSInfoComponent.module.scss';
import classnames from 'classnames';

class Step2DVSInfoComponent extends Component {

    render() {
        return (
            <>
                <p className='small'>
                    The driver's licence details provided as evidence of your identity will be checked with the
                    relevant government agent via the Document Verification Service.
                </p>
                <p className={classnames('small', styles.copyPadded)}>
                    To access the Document Verification Service, your information must be provided to a gateway provider, Equifax.
                </p>
                <p className={classnames('small', styles.copyPadded)}>
                    If you do not provide your driver's licence details as requested,
                    or your driver's licence is not verified by the Document Verification Service,
                    we will not be able to verify your identity, and we may not be able to approve
                    your registration to create an account to use Provider Self Service.
                </p>
            </>
        );
    }
}

export default Step2DVSInfoComponent;