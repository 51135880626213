import React, { Component } from 'react';
import {SearchCriteriaContextProvider} from 'context/SearchCriteriaContext';
import SearchCriteriaComponent from 'components/claims/SearchCriteriaComponent';
import Utils from 'utils/Utils';
import styles from './ClaimsContainer.module.scss';
import AlertComponent from 'components/AlertComponent';
import Constants from 'utils/Constants';
import UserContext from "../../context/UserContext";
import { API, graphqlOperation } from 'aws-amplify';
import {searchClaims} from 'graphql/queries';
import OverlaySpinner from 'components/spinner/OverlaySpinner'
import PaginationLinksComponent from "components/PaginationLinksComponent"
import SearchResultsComponent from 'components/claims/SearchResultsComponent'
import ClaimsDetailContainer from "./ClaimsDetailContainer";
import queryString from 'query-string'
import {getPageLoadArgs, getClaimsSearchArgs, getGTMSequenceId} from "utils/GTMHelper"
import TagManager from "react-gtm-module"

const PATIENT_SEARCH_BY_NAME = 'SEARCH_BY_NAME';
const PATIENT_SEARCH_BY_NUMBER = 'SEARCH_BY_NUMBER';

export class ClaimsContainer extends Component {

    constructor(props) {
        super(props);

        this.setSearchProviders = this.setSearchProviders.bind(this);
        this.setFromDate = this.setFromDate.bind(this);
        this.setToDate = this.setToDate.bind(this);
        this.setSearchDuration = this.setSearchDuration.bind(this);
        this.setPatientFirstName = this.setPatientFirstName.bind(this);
        this.setPatientLastName = this.setPatientLastName.bind(this);
        this.setPatientMemberNo = this.setPatientMemberNo.bind(this);
        this.fetchClaims = this.fetchClaims.bind(this);
        this.setPatientSearchByName = this.setPatientSearchByName.bind(this);
        this.setPatientSearchByNumber = this.setPatientSearchByNumber.bind(this);
        this.onPageClick = this.onPageClick.bind(this)
        this.tagViewClaimDetails = this.tagViewClaimDetails.bind(this)

        const defaultToDate = new Date();
        const defaultFromDate = Utils.subtractDaysFromDate(defaultToDate, '30');

        this.errorRef = React.createRef();

        this.state = {
            searchFromDate: defaultFromDate,
            searchToDate: defaultToDate,
            searchDateByDuration: true,
            selectedProviders: [],
            patientSearchOption: PATIENT_SEARCH_BY_NAME,
            searchFirstName: '',
            searchLastName: '',
            searchMemberNo: '',
            isLoading: false,
            hasError: false,
            fetchedClaims: null,
            displayedClaims: null,
            claimsCount: null,
            searchResultsPage: 1,
            showSearchResults: false,
            gtmSequenceId: '',
            actions: {
                setSearchProviders: this.setSearchProviders,
                setFromDate: this.setFromDate,
                setToDate: this.setToDate,
                setSearchDuration: this.setSearchDuration,
                setPatientSearchByName: this.setPatientSearchByName,
                setPatientSearchByNumber: this.setPatientSearchByNumber,
                setPatientFirstName: this.setPatientFirstName,
                setPatientLastName: this.setPatientLastName,
                setPatientMemberNo: this.setPatientMemberNo,
                displaySingleClaim: this.displaySingleClaim,
                tagViewClaimDetails: this.tagViewClaimDetails
            }
        }
    }

    setFromDate(aDate) {
        if (aDate) {
            // Set the time to 00.00.00 (midnight).
            aDate.setHours(0, 0, 0, 0);
        }
        this.setState({
            searchFromDate: aDate,
            searchDateByDuration: false
        })
    }

    setToDate(aDate) {
        if (aDate) {
            // Set the time to 23.59.59.999 (1 millisecond before midnight).
            aDate.setHours(23, 59, 59, 999);
        }
        this.setState({
            searchToDate: aDate,
            searchDateByDuration: false
        })
    }

    setSearchProviders(providers) {
        this.setState({
            selectedProviders: providers
        });
    }

    setSearchDuration(duration) {
        const toDate = new Date();
        const fromDate = Utils.subtractDaysFromDate(toDate, duration);
        this.setState({
            searchFromDate: fromDate,
            searchToDate: toDate,
            searchDateByDuration: true
        })
    }

    setPatientSearchByName() {
        this.setState({
            patientSearchOption: PATIENT_SEARCH_BY_NAME
        })
    }

    setPatientSearchByNumber() {
        this.setState({
            patientSearchOption: PATIENT_SEARCH_BY_NUMBER
        })
    }

    setPatientFirstName(firstName) {
        this.setState({
            searchFirstName: firstName
        })
    }

    setPatientLastName(lastName) {
        this.setState({
            searchLastName: lastName
        })
    }

    setPatientMemberNo(memberNo) {
        this.setState({
            searchMemberNo: memberNo
        })
    }

    /***
     * Determine if search button can be enabled if the mandatory search criteria has been
     * selected
     */
    canSearchBeRun() {
        return (this.state.searchFromDate &&
            this.state.searchToDate &&
            this.state.selectedProviders.length > 0 &&
            this.state.searchFromDate <= this.state.searchToDate) ? true : false;
    }

    onPageClick(pageNo) {
        if (this.canSearchBeRun()) {
            const {fetchedClaims} = this.state
            const displayedClaims = this.determineDisplayedClaims(fetchedClaims, pageNo)

            const {cognitoId, role} = this.context.currentUser
            TagManager.dataLayer(getPageLoadArgs('Claim Status - pagination',
                this.props.location.pathname + '?page=' + pageNo, cognitoId, role))

            this.setState({
                searchResultsPage: parseInt(pageNo),
                displayedClaims: displayedClaims
            }, () => {
                this.scrollToTop()
            });
        }
    }

    scrollToTop() {
        window.scrollTo(0, 0)
    }

    fetchClaims() {

        const gtmSequenceId = getGTMSequenceId()

        let stateValues = {
            isLoading: true,
            hasError: false,
            fetchedClaims: null,
            searchResultsPage: 1,
            claimsCount: null,
            gtmSequenceId: gtmSequenceId
        }

        if (this.state.patientSearchOption === PATIENT_SEARCH_BY_NAME) {
            stateValues.searchMemberNo = '';
        } else {
            stateValues.searchFirstName = '';
            stateValues.searchLastName = '';
        }

        this.setState(stateValues, () => {
            this.fetchClaimsByProvider();
        });
    }

    async fetchClaimsByProvider() {
        const {searchFirstName, searchLastName, searchMemberNo} = this.state
        const fromDateUtcMs = this.state.searchFromDate.getTime();
        const toDateUtcMs = this.state.searchToDate.getTime();
        const providerNumber = this.state.selectedProviders[0].providerNumber

        try {
            const response = await API.graphql(graphqlOperation(searchClaims,
                {
                    filter: {
                        providerNo: providerNumber,
                        dateFrom: fromDateUtcMs,
                        dateTo: toDateUtcMs,
                        patient: {
                            membershipNo: searchMemberNo,
                            firstname: searchFirstName,
                            lastname: searchLastName
                        }
                    }
                }));

            this.finaliseSearch(response);
        }
        catch (error) {
            this.finaliseSearchError();
        }

    }

    /***
     * Determine which claims to display
     * @param pageNo
     */
    determineDisplayedClaims(claims, pageNo) {
        const itemsPerPage = Constants.CLAIMS_SEARCH_ITEMS_PER_PAGE;
        let displayedClaims = [];
        if (claims.length <= itemsPerPage) {
            displayedClaims = claims
        } else {
            for (let i = 0; i < claims.length; i++) {
                if ((i >= itemsPerPage*(pageNo-1)) && (i < itemsPerPage*pageNo)) {
                    displayedClaims.push(claims[i])
                }
            }
        }

        return displayedClaims
    }

    tagClaimsSearch(claimNo, claimSequenceNo) {
        const {gtmSequenceId, claimsCount, selectedProviders, searchDateByDuration,
            searchFromDate, searchToDate, patientSearchOption,
            searchFirstName, searchLastName, searchMemberNo} = this.state
        const {username} = this.context.currentUser

        const gtmResultsCount = claimsCount
        const gtmSearchByValue = selectedProviders[0].providerNumber

        const fromDate = Utils.formatDate(searchFromDate);
        const toDate = Utils.formatDate(searchToDate);
        const gtmPeriodType = searchDateByDuration ? 'Duration' : 'Choose Dates'
        const gtmPeriodValue = fromDate + '-' + toDate

        let patientType = null
        if (searchFirstName.length > 0 ||
            searchLastName.length > 0 ||
            searchMemberNo.length > 0)
        {
            patientType = patientSearchOption
        }

        const tagParms = {
            sequenceId: gtmSequenceId,
            searchByValue: gtmSearchByValue,
            periodType: gtmPeriodType,
            periodValue: gtmPeriodValue,
            patientType: patientType,
            resultsCount: gtmResultsCount,
            username: username,
            claimNo: claimNo,
            claimSequenceNo: claimSequenceNo
        }

        const tagData = getClaimsSearchArgs(tagParms)
        TagManager.dataLayer(tagData)
    }

    tagViewClaimDetails(claimNo, claimSequenceNo) {
        this.tagClaimsSearch(claimNo, claimSequenceNo)
    }

    finaliseSearch(response) {
        // Sort the result such that the newest claims are first.
        const fetchedClaims = response.data.searchClaim.claims.sort((claimA, claimB) => {
            return claimB.dateLodged - claimA.dateLodged;
        });

        const displayedClaims = this.determineDisplayedClaims(fetchedClaims, 1)
        this.setState({
            fetchedClaims: fetchedClaims,
            displayedClaims: displayedClaims,
            claimsCount: fetchedClaims.length,
            isLoading: false,
            showSearchResults: true
        }, () => {
            this.tagClaimsSearch()
        });
    }

    finaliseSearchError() {
        this.setState({
            fetchedClaims: null,
            displayedClaims: null,
            claimsCount: 0,
            isLoading: false,
            hasError: true
        }, () => {
            Utils.scrollAlertIntoView(this.errorRef);
        });
    }

    renderSearchResultsCount() {
        if (this.state.showSearchResults) {
            const {claimsCount, searchResultsPage} = this.state;
            const itemsPerPage = Constants.CLAIMS_SEARCH_ITEMS_PER_PAGE;
            const rowCountMsg = Utils.determineRowCountText(itemsPerPage, searchResultsPage, claimsCount)
            return (
                <div className='row'>
                    <div className='col-sm-12'>
                        {rowCountMsg}
                    </div>
                </div>
            )
        }
    }

    renderOverlaySpinner() {
        if (this.state.isLoading) {
            return (
                <OverlaySpinner />
            );
        }
    }

    renderSearchResults() {
        if (this.state.showSearchResults) {
            return (
                <div className={styles.bottomHalf}>
                    <div className={styles.resultsWrapper}>
                        {this.renderOverlaySpinner()}
                        <div className='container'>
                            <SearchResultsComponent
                                claims={this.state.displayedClaims}
                            />
                        </div>
                    </div>
                    <div className='container pagination-wrapper'>
                        {this.renderPagination()}
                    </div>
                </div>
            )
        }
    }

    renderPagination() {
        if (this.state.claimsCount) {
            return (
                <PaginationLinksComponent
                    totalItems={this.state.claimsCount}
                    itemsPerPage={Constants.CLAIMS_SEARCH_ITEMS_PER_PAGE}
                    currentPageNo={this.state.searchResultsPage}
                    onPageClick={this.onPageClick}
                />
            )
        }
    }

    render() {
        const {params} = this.props.match;

        // When the path contains a claim number, render the claim details page.
        // Otherwise, show the claims search
        if (params.claimno) {
            const values = queryString.parse(this.props.location.search);
            return (
                <SearchCriteriaContextProvider
                    value={{...this.state, currentUser: this.context.currentUser}} >
                    <ClaimsDetailContainer
                        closeClaimDetails={this.closeClaimDetails}
                        claimNo={params.claimno}
                        membershipNo={values['mNo']}
                        partnerId={values['pId']}
                        currentUser={this.context.currentUser}
                        pathname={this.props.location.pathname}
                    />
                </SearchCriteriaContextProvider>
            )
        } else {
            let {providers} = this.context;
            const searchEnabled = this.canSearchBeRun();
            return (
                <div className={styles.claimsWrapper}>
                    <SearchCriteriaContextProvider
                        value={{...this.state, currentUser: this.context.currentUser}} >
                        <div>
                            <div className="container">
                                {this.state.hasError &&
                                <AlertComponent
                                    forwardedRef={this.errorRef}
                                    alertTitle={Constants.GENERIC_TECHNICAL_ERROR_TITLE}
                                    alertMessage={Constants.GENERIC_TECHNICAL_ERROR_MSG}
                                />
                                }

                                <h2>Claim status</h2>
                                <SearchCriteriaComponent
                                    providers={providers}
                                    searchEnabled={searchEnabled}
                                    isLoading={this.state.isLoading}
                                    executeSearch={this.fetchClaims}
                                    currentUser={this.context.currentUser}
                                    pathname={this.props.location.pathname}
                                />
                            </div>
                        </div>
                        <div className={styles.middle}>
                            <div className="container">
                                {this.renderSearchResultsCount()}
                            </div>
                        </div>
                        {this.renderSearchResults()}
                    </SearchCriteriaContextProvider>
                </div>

            )
        }
    }
}

ClaimsContainer.contextType = UserContext;
export default ClaimsContainer;