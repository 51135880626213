import React, { Component } from 'react';
import styles from './TokenExpiredComponent.module.scss';
import { ReactComponent as ExpiredIcon } from "svgs/icon-expired.svg"
import classnames from 'classnames'
import PropTypes from 'prop-types'

class TokenExpiredComponent extends Component {

    render() {
        const {handleEmailResend, isResendingEmail} = this.props
        let disabled = false
        let buttonContents = 'Resend email';
        if (isResendingEmail) {
            buttonContents = <div className="spinner-md text-white"></div>;
            disabled = true;
        }
        return (
            <div className={styles.contentWrapper}>
                <ExpiredIcon className={classnames('icon icon-stroke', styles.infoIcon)}/>
                <h2>Sorry, your link has expired</h2>
                <div className={styles.infoMessage}>
                    <p>
                        If you are trying to activate your Provider Self Service account, please click the resend email button,<br/>
                        and check your email inbox. It may take up to 5 minutes for you to receive this email.<br/>
                        Please also check your junk and spam folder if you haven't received an email from us.
                    </p>
                </div>
                <button
                    onClick={handleEmailResend}
                    disabled={disabled}
                    className={classnames('btn btn-primary btn-lg',styles.button)} >
                    {buttonContents}
                </button>
            </div>
        );
    }
}

TokenExpiredComponent.propTypes = {
    handleEmailResend: PropTypes.func.isRequired,
    isResendingEmail: PropTypes.bool.isRequired
}

export default TokenExpiredComponent;