import React, { Component } from 'react';
import SearchCriteriaContext from 'context/SearchCriteriaContext';
import Utils from 'utils/Utils'

const buttonGroupStyles = {
    maxWidth: '320px'
}

class PatientSearchComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedOption: 'patientname'
        }

        this.togglePatientOptions = this.togglePatientOptions.bind(this);
        this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
        this.handleLastNameChange = this.handleLastNameChange.bind(this);
        this.handleMemberNoChange = this.handleMemberNoChange.bind(this);
    }

    togglePatientOptions(event) {
        const {setPatientSearchByName, setPatientSearchByNumber} = this.context.actions;

        if (event.target.value === 'patientname') {
            setPatientSearchByName();
        } else {
            setPatientSearchByNumber();
        }

        this.setState({
            selectedOption: event.target.value
        });
    }

    handleFirstNameChange(event) {
        // Prevent entry of certain characters
        const value = event.target.value;
        if (value.length === 0 || !Utils.containsInvalidNameCharacter(value)) {
            this.context.actions.setPatientFirstName(event.target.value);
        }
    }

    handleLastNameChange(event) {
        // Prevent entry of certain characters
        const value = event.target.value;
        if (value.length === 0 || !Utils.containsInvalidNameCharacter(value)) {
            this.context.actions.setPatientLastName(event.target.value);
        }
    }

    handleMemberNoChange(event) {
        // Prevent entry of certain characters
        const value = event.target.value;
        if (value.length === 0 || Utils.containsOnlyLettersAndDigits(value)) {
            this.context.actions.setPatientMemberNo(event.target.value);
        }
    }

    renderSelectedNameOption() {
        const {searchFirstName, searchLastName, searchMemberNo} = this.context;

        if (this.state.selectedOption === 'patientname') {
            return (
                <div className='input-group-row'>
                    <div className='input-group input-group-small'>
                        <input onChange={this.handleFirstNameChange}
                               placeholder='First name'
                               id='memberFirstName'
                               type='text'
                               value={searchFirstName}
                               className='input-search form-control' />
                    </div>
                    <div className='input-group input-group-small'>
                        <input onChange={this.handleLastNameChange}
                               placeholder='Last name'
                               id='memberLastName'
                               type='text'
                               value={searchLastName}
                               className='input-search form-control' />
                    </div>
                </div>
            )
        } else {
            return (
                <div className='input-group-row'>
                    <div className='input-group'>
                        <input onChange={this.handleMemberNoChange}
                               placeholder='Medibank membership number'
                               id='memberNumber'
                               type='text'
                               value={searchMemberNo}
                               className='input-search form-control' />
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <div>
                <div className='m-b-1'>
                    <span>Patient (optional)</span>
                </div>
                <div>
                    <div style={buttonGroupStyles} className="btn-group btn-group-options btn-group-options-highlight-2 m-b-2">
                        <label className="btn btn-group-radio" htmlFor="patientname">
                            <input type="radio" name="patient"
                                   id="patientname"
                                   value="patientname"
                                   onChange={this.togglePatientOptions}
                                   checked={(this.state.selectedOption==='patientname')} />
                            <span className="input-label-wrapper">Patient name</span>
                        </label>
                        <label className="btn btn-group-radio" htmlFor="patientnumber">
                            <input type="radio" name="patient"
                                   id="patientnumber"
                                   value="patientnumber"
                                   onChange={this.togglePatientOptions}
                                   checked={(this.state.selectedOption==='patientnumber')} />
                            <span className="input-label-wrapper">Membership number</span>
                        </label>
                    </div>
                </div>
                <div>
                    {this.renderSelectedNameOption()}
                </div>
            </div>
        )
    }
}

PatientSearchComponent.contextType = SearchCriteriaContext;

export default PatientSearchComponent;