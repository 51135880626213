import React, { Component } from 'react';
import PropTypes from 'prop-types'
import styles from "./PasswordDetailsEditContainer.module.scss";
import classnames from "classnames";
import PasswordInputComponent from 'components/PasswordInputComponent'
import PasswordConfirmContainer from 'components/PasswordConfirmContainer'
import SpinnerButtonComponent from 'components/spinnerButton/SpinnerButtonComponent'
import {Auth} from 'aws-amplify'

class PasswordDetailsEditContainer extends Component {

    constructor(props) {
        super(props)

        this.handleOldPasswordChange = this.handleOldPasswordChange.bind(this)
        this.handleOldPasswordBlur = this.handleOldPasswordBlur.bind(this)
        this.isOldPasswordValid = this.isOldPasswordValid.bind(this)
        this.setNewPassword = this.setNewPassword.bind(this)
        this.handlePasswordSave = this.handlePasswordSave.bind(this)

        this.state = {
            oldPwdValue: '',
            oldPwdError: false,
            newPwdValue: '',
            saveInProgress: false,
            saveError: ''
        }
    }

    handleOldPasswordChange(oldPassword) {
        this.setState({
            oldPwdValue: oldPassword,
            oldPwdError: !this.isOldPasswordValid(oldPassword)
        })
    }

    handleOldPasswordBlur() {
        const {oldPwdValue} = this.state

        this.setState({
            oldPwdError: !this.isOldPasswordValid(oldPwdValue)
        })
    }

    async handlePasswordSave() {
        this.setState({
            saveInProgress: true,
            saveError: false
        })

        const {oldPwdValue, newPwdValue} = this.state
        const {handleSaveSuccess} = this.props

        try {

            const user = await Auth.currentAuthenticatedUser();
            await Auth.changePassword(user, oldPwdValue, newPwdValue);

            handleSaveSuccess();
        }
        catch (error) {
            this.setState({
                saveInProgress: false,
                saveError: error
            })
        }
    }

    isOldPasswordValid(oldPassword) {
        return oldPassword.length > 0;
    }

    setNewPassword(newPassword) {
        this.setState({
            newPwdValue: newPassword
        })
    }

    renderOldPasswordRow() {
        const {oldPassword, oldPwdError, saveInProgress} = this.state;
        return (
            <div>
                <PasswordInputComponent
                    label='Old password'
                    fieldId='old-pwd'
                    inputValue={oldPassword}
                    onChange={this.handleOldPasswordChange}
                    onBlur={this.handleOldPasswordBlur}
                    inError={oldPwdError}
                    disabled={saveInProgress}
                    errorText='You must enter your old password.'
                />
            </div>
        )
    }

    renderConfirmPasswordRow() {
        const {saveInProgress} = this.state;

        return (
            <div className={styles.confirmPwdRow}>
                <PasswordConfirmContainer
                    label='New password'
                    confirmLabel='Confirm new password'
                    withExplanation={false}
                    setUserPassword={this.setNewPassword}
                    disabled={saveInProgress}
                />
            </div>
        )
    }

    renderButtonRow() {
        const {handleCancelClick} = this.props
        const {oldPwdValue, newPwdValue, saveInProgress} = this.state
        const enableConfirmBtn = (oldPwdValue && newPwdValue);

        return (
            <div className={classnames('row', styles.buttonRow)}>
                <div className='col-lg-12'>
                    <SpinnerButtonComponent
                        handleClick={this.handlePasswordSave}
                        disabled={!enableConfirmBtn}
                        text='Save'
                        className='btn-lg'
                        spinning={saveInProgress}
                    />

                    {/*
                        Note, the use of onMouseDown rather than onClick.
                        The onClick event was not firing if the onBlur event
                        for the Old Password field resulted in an error message
                        being displayed (this is because the error message caused
                        the Cancel button to move which in turn meant the mouse up
                        was occuring outside the button, and hence no onClick
                        was registering)
                    */}
                    <button onMouseDown={handleCancelClick}
                            className='btn btn-lg btn-dark-ghost'
                            disabled={saveInProgress}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        )
    }

    renderErrorMessage() {
        const {saveError} = this.state

        if (saveError) {
            return (
                <div data-test-id='errorMessage' className={styles.errorRow}>
                    <span>Your current password is incorrect, please try again.</span><br/>
                </div>
            )
        }
    }

    render() {
        return (
            <div className={styles.detailBlock}>
                <div className={styles.detailHeader}>
                    <h4>Password</h4>
                </div>
                <div className='row'>
                    <div className='col-lg-7'>
                        <div className={styles.detailLeftOldPwd}>
                            {this.renderOldPasswordRow()}
                        </div>
                    </div>
                    <div className={classnames('col-lg-5')}>
                        <div className={styles.detailRight}>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-7'>
                        <div className={styles.detailLeft}>
                            {this.renderConfirmPasswordRow()}
                            {this.renderButtonRow()}
                            {this.renderErrorMessage()}
                        </div>
                    </div>
                    <div className={classnames('col-lg-5')}>
                        <div className={styles.detailRight}>
                            <span>
                            Your password should be between 7 and 40 characters and contain at least
                            1 number and 1 lowercase character
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );

    }
}

PasswordDetailsEditContainer.propTypes = {
    handleCancelClick: PropTypes.func.isRequired,
    handleSaveSuccess: PropTypes.func.isRequired
}

export default PasswordDetailsEditContainer;