import React, {Component} from 'react';
import classnames from 'classnames';
import UserContext from "context/UserContext";
import styles from './MyRequestsContainer.module.scss';
import AccountNavComponent from './AccountNavComponent'
import Constants from "utils/Constants";
import {Link, Redirect} from "react-router-dom";
import {ReactComponent as CloseIcon} from "svgs/icon-close.svg";
import PracticeManagerInfoPanel from 'components/account/myusers/PracticeManagerInfoPanel'
import {API, graphqlOperation} from 'aws-amplify';
import {processAccessRequest} from 'graphql/mutations';
import WhatAreRolesModal from "components/providerSetup/WhatAreRolesModal";
import OverlaySpinner from "components/spinner/OverlaySpinner";
import TooltipIcon from "components/TooltipIcon";
import {getPageLoadArgs} from "utils/GTMHelper"
import TagManager from "react-gtm-module"
import Utils from 'utils/Utils'


class MyRequestsContainer extends Component {
    constructor() {
        super()

        this.closeDeclinedAlert = this.closeDeclinedAlert.bind(this)
        this.handleAccept = this.handleAccept.bind(this)
        this.handleDecline = this.handleDecline.bind(this)
        this.toggle = this.toggle.bind(this)

        this.debug = false

        this.state = {
            showDeclined: false,
            showAccepted: false,
            toManageUsers: false,
            popoverOpen: false,
            processing: -1,
            processedUser: undefined,
        }
    }

    toggle() {
        this.setState({
            popoverOpen: !this.state.popoverOpen
        });
    }

    renderInfoPanel() {
        if (this.state.showDeclined || this.state.showAccepted) return <></>
        return <PracticeManagerInfoPanel/>

    }

    async updateAccessRequest(requesterUsername, status, providerNumbers) {
        let input = {
            requesterUsername,
            providerNumbers,
            status
        }
        if (this.debug) {
            console.log("REQUEST INPUT", input)
            const sleep = m => new Promise(r => setTimeout(r, m))
            await sleep(3000)
            return true
        }
        try {
            const response = await API.graphql(graphqlOperation(processAccessRequest, {input}));
            return response.data.processAccessRequest.result
        } catch (error) {
            console.log("Error updating roles", error)
        }

    }

    handleAccept(event) {
        this.handleUpdate(event, Constants.ACCEPTED)
    }

    handleDecline(event) {
        this.handleUpdate(event, Constants.DECLINED)
    }

    handleUpdate(event, status) {
        //Do nothing if we are already processing something
        if (this.state.processing > -1) return
        let index = parseInt(event.target.getAttribute('value'), 10)
        this.setState({processing: index}, async () => {
            let pendingRequests = this.getPendingRequests()
            let user = pendingRequests[index]
            let providerNumbers = user.links.filter(p => p.status === Constants.PENDING).map(p => {
                return {number: p.provider.providerNumber}
            })
            let success = await this.updateAccessRequest(user.username, status, providerNumbers)
            if (success) {
                let showAccepted = status === Constants.ACCEPTED;
                let showDeclined = status === Constants.DECLINED;
                this.setState({showDeclined, showAccepted, processing: -1, user})
                // this.context.actions.getManagedUsers(true)
                this.context.actions.refreshUser()
            } else {
                //BUGBUG Show some sort of error
                this.setState({processing: -1})
            }
        })
    }

    closeDeclinedAlert() {
        let pendingRequests = this.getPendingRequests()
        if (pendingRequests.length === 0) {
            this.setState({toManageUsers: true})
        } else {
            this.setState({showDeclined: false})
        }
    }

    componentDidMount() {
        const {cognitoId, role} = this.context.currentUser
        TagManager.dataLayer(getPageLoadArgs('Your Pending User Requests', this.props.location.pathname, cognitoId, role))
    }

    renderDeclinedPanel(user) {
        if (!this.state.showDeclined) return
        return <div className={'row'}>
            <div className={classnames('col-lg-12')}>
                <div className={classnames('', styles.declinedPanel)}>
                    <h4>{user.firstName} {user.lastName} has been declined</h4>
                    <CloseIcon onClick={this.closeDeclinedAlert}
                               className={classnames('icon icon-stroke', styles.closeIcon)}/>
                </div>
            </div>
        </div>

    }

    renderAcceptedPanel(user) {
        if (!this.state.showAccepted) return
        return <div className={classnames('row')}>
            <div className={classnames('col-lg-12')}>
                <div className={classnames('', styles.acceptedPanel)}>
                    <h4>{user.firstName} {user.lastName} has been added</h4>
                    <Link className='simple-link-reverse' to={"/manageuser/" + user.username}>Manage user
                        access</Link>
                </div>
            </div>
        </div>

    }


    renderActionButtons(index) {
        if (this.state.processing === index) {
            return <div className={classnames('col-lg-4')}><OverlaySpinner/></div>
        } else {
            return <div className={classnames('col-lg-4', styles.buttonDiv)}>
                <span style={{display: this.state.processing > -1 ? 'none' : 'flex'}} className="simple-link"
                      value={index} onClick={this.handleDecline}>Decline</span>
                <button value={index} className={classnames('btn btn-primary')} disabled={this.state.processing > -1}
                        onClick={this.handleAccept}>Accept
                </button>
            </div>
        }
    }


    renderPendingRequests(users) {
        if (users.length === 0) {
            return <></>
        }
        return <>
            {users.map((u, index) => {
                return (
                    <React.Fragment key={u.username}>
                        <div className={classnames('row', styles.contentWrapper)}>
                            <div className={classnames('col-lg-8', styles.providerName)}>
                                <h4>{u.firstName} {u.lastName}</h4>
                            </div>
                            {this.renderActionButtons(index)}
                            <div className={classnames('col-lg-4')}>
                                <h5>Email</h5>
                                <p>{u.email}</p>
                            </div>
                        </div>
                        <div>
                            <table
                                className={classnames('table table-striped table-responsive', styles.resultsWrapper)}>
                                <thead>
                                <tr>
                                    <th className={styles.th1}>Provider number</th>
                                    <th className={styles.th2}>Address/Addresses</th>
                                    <th className={styles.th3}>
                                        Role
                                        <span className='pointer'>
                                            <TooltipIcon showModal={true}>
                                                <WhatAreRolesModal/>
                                            </TooltipIcon>
                                        </span>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {u.links.filter(p => p.status === Constants.PENDING).map((p) => {
                                    const providerName = Utils.buildProviderName(p.provider)

                                    return (
                                        <tr key={p.provider.providerNumber}>
                                            <td>{p.provider.providerNumber}</td>
                                            <td>
                                                <b>{providerName}</b><br/>
                                                {!p.provider.practiceName.toLowerCase().includes(providerName.toLowerCase()) &&
                                                    <>
                                                        {p.provider.practiceName}<br/>
                                                    </>
                                                }
                                                {p.provider.address}
                                            </td>
                                            <td>{Constants.USER_ROLE_REVERSE_MAPPING[p.role]}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </React.Fragment>
                )
            })}
        </>
    }

    getPendingRequests() {
        return this.context.actions.getRequestsByStatusSync(Constants.PENDING)
    }

    render() {
        if (this.state.toManageUsers) {
            return <Redirect to={"/myusers"}/>
        }
        let pendingRequests = this.getPendingRequests()
        let {user} = this.state

        return <div className={classnames('container', styles.mainWrapper)}>
            <div className='row'>
                <div className='col-lg-3'>
                    <AccountNavComponent/>
                </div>
                <div className='col-lg-9'>
                    <h3>Your pending user requests</h3>
                    {this.renderInfoPanel()}
                    {this.renderAcceptedPanel(user)}
                    {this.renderDeclinedPanel(user)}
                    {this.renderPendingRequests(pendingRequests)}
                </div>
            </div>
        </div>
    }
}

MyRequestsContainer.contextType = UserContext;

export default MyRequestsContainer;