import React, { Component } from 'react';
import DetailsDisplayComponent from './DetailsDisplayComponent'
import MobileDetailsEditContainer from './MobileDetailsEditContainer'
import UserContext from "context/UserContext";
import Utils from 'utils/Utils'
import {getGTMSequenceId} from "utils/GTMHelper"
import TagManager from "react-gtm-module"
import {getYourDetailsArgs} from "utils/GTMHelper"

class MobileDetailsContainer extends Component {

    constructor(props) {
        super(props)

        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.handleConfirmClick = this.handleConfirmClick.bind(this);

        this.state = {
            isInEditMode: false,
            mobileUpdated: false,
            gtmSequenceId: ''
        }
    }

    handleEditClick() {
        this.setState({
            isInEditMode: true,
            mobileUpdated: false
        })
    }

    handleCancelClick() {
        this.setState({
            isInEditMode: false,
            mobileUpdated: false
        })
    }

    handleConfirmClick() {
        const tagParms = {
            label: 'Form Complete',
            sequenceId: this.state.gtmSequenceId,
            detailsType: 'Mobile phone number'
        }

        TagManager.dataLayer(getYourDetailsArgs(tagParms))

        this.setState({
            isInEditMode: false,
            mobileUpdated: true
        })
    }

    componentDidMount() {
        this.setState({
            gtmSequenceId: getGTMSequenceId()
        })
    }

    renderEditComponent() {
        return (
            <MobileDetailsEditContainer
                handleCancelClick={this.handleCancelClick}
                handleConfirmClick={this.handleConfirmClick}
            />
        )
    }

    renderDisplayComponent(mobile) {
        return (
            <DetailsDisplayComponent
                title={'Mobile phone number'}
                fieldValue={mobile}
                buttonText={'Edit'}
                onButtonClick={this.handleEditClick}
                showUpdateAlert={this.state.mobileUpdated}
                gtmSequenceId={this.state.gtmSequenceId}
            />
        )
    }

    render() {
        const {mobile} = this.context.currentUser;
        const formattedMobile = Utils.stripCountryCode(mobile);

        if (this.state.isInEditMode) {
            return (
                this.renderEditComponent()
            )
        } else {
            return (
                this.renderDisplayComponent(formattedMobile)
            )
        }

    }
}

MobileDetailsContainer.contextType = UserContext;

export default MobileDetailsContainer;