import React, { Component } from 'react';
import SearchCriteriaContext from "context/SearchCriteriaContext";
import Utils from 'utils/Utils';
import TooltipIcon from "./TooltipIcon";

const labelStyles = {
    paddingRight: '10px'
}

class EFTReferenceSearchComponent extends Component {

    constructor(props) {
        super(props);

        this.handleOnChange = this.handleOnChange.bind(this);

        this.state = {
        };
    }

    handleOnChange(event) {
        const value = event.target.value;
        // if value is not blank, then test the regex
        if ((value === '' || Utils.containsOnlyDigits(value)) &&
            value.length <= 12) {
            this.context.actions.setEftRef(event.target.value);
        }
    }

    render() {
        const { searchEftRef } = this.context;

        let inputValue = ''
        if (searchEftRef) {
            inputValue = searchEftRef;
        }
        return (
            <div>
                <div className='input-group'>
                    <label htmlFor="providerSelect">
                        <span style={labelStyles}>EFT reference number</span>
                        <TooltipIcon className='icon icon-xs'>
                            <span>
                                Electronic funds transfer (EFT), also known as payment reference number (PRN),
                                is the number on the client's payment slip, directly above the bar code.
                            </span>
                        </TooltipIcon>
                    </label>
                    <input value={inputValue}
                        onChange={this.handleOnChange}
                        type='text'
                        className='form-control'
                        placeholder='Enter EFT reference number'
                        name="eftref"
                        id="eftRef" />
                </div>
                <div>
                    <span> You will only be able to search a remittance advice from the date you
                        registered to use Provider Self Service. If you need information prior to
                        that date, please contact us. Find our details in the </span>
                    <a
                        className="simple-link-reverse"
                        href="https://www.medibank.com.au/contact-us/"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        Healthcare providers section
                    </a>
                    .
                </div>
            </div>
        )
    }
}

EFTReferenceSearchComponent.contextType = SearchCriteriaContext;

export default EFTReferenceSearchComponent;