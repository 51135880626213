import React, { Component } from 'react';
import Utils from 'utils/Utils';
import styles from './ClaimsDetailContainer.module.scss'
import OverlaySpinner from 'components/spinner/OverlaySpinner'
import AlertComponent from 'components/AlertComponent'
import Constants from 'utils/Constants'
import classnames from 'classnames'
import DetailHeaderComponent from 'components/claims/DetailHeaderComponent'
import DetailLineItemsHeaderComponent from 'components/claims/DetailLineItemsHeaderComponent'
import PaginationLinksComponent from "components/PaginationLinksComponent"
import DetailLineItemsResultsComponent from 'components/claims/DetailLineItemsResultsComponent'
import PropTypes from 'prop-types'
import {Redirect} from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify'
import {getClaim} from 'graphql/queries'
import {getPageLoadArgs} from "utils/GTMHelper"
import TagManager from "react-gtm-module"

export class ClaimsDetailContainer extends Component {

    constructor(props) {
        super(props)

        this.handleScroll = this.handleScroll.bind(this)
        this.onPageClick = this.onPageClick.bind(this)
        this.closeClaimDetails = this.closeClaimDetails.bind(this)

        this.errorRef = React.createRef();

        this.claimLinesHeaderRef = React.createRef();
        this.claimLinesDataRef = React.createRef();
        this.ticking = false;

        this.state = {
            claim: null,
            isLoading: true,
            hasError: false,
            claimLinesPage: 1,
            displayedClaimLines: null,
            stickClaimsLinesHeader: false,
            returnToSearchScreen: false
        }
    }

    determineDisplayedClaimLines(claim, pageNo) {
        const itemsPerPage = Constants.CLAIM_LINES_ITEMS_PER_PAGE;
        let displayedLines = [];
        if (claim.items.length <= itemsPerPage) {
            displayedLines = claim.items
        } else {
            for (let i = 0; i < claim.items.length; i++) {
                if ((i >= itemsPerPage*(pageNo-1)) && (i < itemsPerPage*pageNo)) {
                    displayedLines.push(claim.items[i])
                }
            }
        }

        return displayedLines
    }

    onPageClick(pageNo) {
        const {claim} = this.state
        const displayedClaimLines = this.determineDisplayedClaimLines(claim, pageNo)

        const {cognitoId, role} = this.context.currentUser
        TagManager.dataLayer(getPageLoadArgs('Claim Details - pagination',
            this.props.pathname + '?page=' + pageNo, cognitoId, role))

        this.setState({
            claimLinesPage: parseInt(pageNo),
            displayedClaimLines: displayedClaimLines
        }, () => {
            this.scrollToTop()
        });
    }

    scrollToTop() {
        window.scrollTo(0, 0)
    }

    async componentDidMount() {
        const {cognitoId, role} = this.props.currentUser

        TagManager.dataLayer(getPageLoadArgs('Claim Details',this.props.pathname, cognitoId, role))

        this.scrollToTop()
        window.addEventListener('scroll', this.handleScroll);

        const {claimNo, membershipNo, partnerId} = this.props

        try {
            const response = await API.graphql(graphqlOperation(getClaim, {
                input: {
                    claimNo: claimNo,
                    membershipNo: membershipNo,
                    businessPartnerId: partnerId
                }
            }));

            this.finaliseSearch(response);
        }
        catch (error) {
            console.log(error)
            this.finaliseSearchError();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    /***
     * The purpose of this function is to manage the table header for the claims lines.
     * This table header needs to be sticky when the user scrolls down.
     * Essentially, what this function does is...
     * - Check when the table header gets to the top of the window when scrolling up.
     *   When it does, set the stickClaimsLinesHeader flag on the component state. This will
     *   initiate a re-render of the table header allowing the styles to be modified on both
     *   the table header and the table data to get the header to stick.
     * - Check when the table data gets to a certain point when scrolling down. When it does,
     *   the state.stickClaimsLinesHeader flag is reset allowing the styles on the table header
     *   and table data to be adjusted to unstick the header.
     * - NB! the use of the 'ticking' flag and window.requestAnimationFrame() is for performance
     *   reasons and event throttling as per https://developer.mozilla.org/en-US/docs/Web/Events/scroll
     */
    handleScroll() {
        if (!this.ticking) {
            window.requestAnimationFrame(() => {

                const tableHeader = this.claimLinesHeaderRef.current;
                if (tableHeader) {
                    const tableHeaderTop = this.claimLinesHeaderRef.current.getBoundingClientRect().top
                    const tableHeaderHeight = this.claimLinesHeaderRef.current.getBoundingClientRect().height
                    const tableDataTop = this.claimLinesDataRef.current.getBoundingClientRect().top
                    const isHeaderAlreadySticky = this.state.stickClaimsLinesHeader
                    if (tableHeaderTop <= 0 && !isHeaderAlreadySticky) {
                        this.setState({
                            stickClaimsLinesHeader: true
                        })
                    } else if (tableDataTop > tableHeaderHeight && isHeaderAlreadySticky) {
                        this.setState({
                            stickClaimsLinesHeader: false
                        })
                    }
                }

                this.ticking = false;
            });

            this.ticking = true;
        }
    }


    finaliseSearch(response) {
        const claim = response.data.getClaim;
        const displayedClaimLines = this.determineDisplayedClaimLines(claim, 1)
        this.setState({
            claim: claim,
            displayedClaimLines: displayedClaimLines,
            isLoading: false
        });
    }

    finaliseSearchError() {
        this.setState({
            claim: null,
            isLoading: false,
            hasError: true
        }, () => {
            Utils.scrollAlertIntoView(this.errorRef);
        });
    }

    closeClaimDetails() {
        this.setState({
            returnToSearchScreen: true
        })
    }

    renderTitleSection() {
        const {claim} = this.state;

        return (
            <>
                <div className={classnames('row')} >
                    <div className={classnames('col-lg-6')}>
                        <h2>Claim number: {claim.claimNo}</h2>
                    </div>
                    <div className={classnames('col-lg-6 text-right')}>
                        <button onClick={this.closeClaimDetails} data-test-id='closeClaim' className={classnames("btn btn-dark-ghost", styles.detailsBtn)}>Done</button>
                    </div>
                </div>
                <div className={classnames('row')} >
                    <div className={classnames('col-lg-12')}>
                        <span>Date lodged: {Utils.formatEpochToDate(claim.dateLodged, "")}</span>
                    </div>
                </div>
            </>
        )
    }

    renderDetailSection() {
        const {claim} = this.state

        return (
            <DetailHeaderComponent claim={claim}/>
        )

    }

    renderClaimLineCount() {
        const {claim, claimLinesPage} = this.state;
        const lineCount = claim.items.length
        const itemsPerPage = Constants.CLAIM_LINES_ITEMS_PER_PAGE;
        const rowCountMsg = Utils.determineRowCountText(itemsPerPage, claimLinesPage, lineCount)
        return (
            <div className={classnames('row', styles.lineCount)}>
                <div className='col-lg-12'>
                    {rowCountMsg}
                </div>
            </div>
        )
    }

    renderLineItemsHeader() {
        let headerStyles = styles.lineItemsHeaderWrapper
        if (this.state.stickClaimsLinesHeader) {
            headerStyles = styles.lineItemsHeaderWrapperStuck
        }

        return (
            <div className={headerStyles}>
                <div ref={this.claimLinesHeaderRef} className='container'>
                    <div className={classnames('row', styles.lineItemsHeader)} >
                        <div className='col-lg-12'>
                            <DetailLineItemsHeaderComponent />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderLineItems() {
        const {displayedClaimLines, claim} = this.state
        let lineItemsStyles = styles.bottomHalf
        if (this.state.stickClaimsLinesHeader) {
            lineItemsStyles = styles.bottomHalfStuck
        }

        return (
            <div className={lineItemsStyles}>
                <div className='container'>
                    <DetailLineItemsResultsComponent
                        claimLines={displayedClaimLines}
                        forwardedRef={this.claimLinesDataRef}
                        claim={claim}
                    />
                </div>
                <div className='container pagination-wrapper'>
                    {this.renderPagination()}
                </div>
            </div>
        )
    }

    renderPagination() {
        const {claim} = this.state

        return (
            <PaginationLinksComponent
                totalItems={claim.items.length}
                itemsPerPage={Constants.CLAIM_LINES_ITEMS_PER_PAGE}
                currentPageNo={this.state.claimLinesPage}
                onPageClick={this.onPageClick}
            />
        )
    }

    render() {
        if (this.state.returnToSearchScreen) {
            return (
                <Redirect to='/claims' />
            )
        } else if (this.state.isLoading) {
            return (
                <OverlaySpinner />
            );
        } else if (this.state.hasError) {
            return (
                <div className={styles.claimsWrapper}>
                    <div>
                        <div className="container">
                            <AlertComponent
                                forwardedRef={this.errorRef}
                                alertTitle={Constants.GENERIC_TECHNICAL_ERROR_TITLE}
                                alertMessage={Constants.GENERIC_TECHNICAL_ERROR_MSG}
                            />
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className={styles.claimsWrapper}>
                    <div>
                        <div className="container">
                            {this.renderTitleSection()}
                            {this.renderDetailSection()}
                            {this.renderClaimLineCount()}
                        </div>
                    </div>
                    {this.renderLineItemsHeader()}
                    {this.renderLineItems()}
                </div>
            );

        }

    }
}

ClaimsDetailContainer.propTypes = {
    claimNo: PropTypes.string.isRequired,
    membershipNo: PropTypes.string.isRequired,
    partnerId: PropTypes.string.isRequired,
    currentUser: PropTypes.object.isRequired,
    pathname: PropTypes.string.isRequired
}

export default ClaimsDetailContainer;