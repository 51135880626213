import moment from 'moment';

const emailRe = /^(?!.{101})(?!.*\.\.)[\w-+]+(\.[\w-+]+)*@\d*[a-zA-Z\d][a-zA-Z\d-]*(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,6})$/
const Utils = {

    groupByArray(xs, key) {
        return xs.reduce(function (rv, x) {
            let v = key instanceof Function ? key(x) : x[key];
            let el = rv.find((r) => r && r.key === v);
            if (el) {
                el.values.push(x);
            } else {
                rv.push({key: v, values: [x]});
            }
            return rv;
        }, []).map(obj => obj.values);
    },

    /***
     * Returns a new date that is duration days before the date provided.
     * @param date
     * @param duration
     * @returns {Date}
     */
    subtractDaysFromDate(date, duration) {
        let newDate = moment(date);
        newDate.subtract(duration, 'days');
        return newDate.toDate();
    },

    /***
     * Returns a new date that is duration days after the date provided.
     * @param date
     * @param duration
     * @returns {Date}
     */
    addDaysToDate(date, duration) {
        let newDate = moment(date);
        newDate.add(duration, 'days');
        return newDate.toDate();
    },

    /***
     * Returns a DD/MM/YYYY string representation of the date provided
     * @param date
     * @returns {string}
     */
    formatDate(date) {
        const dateFormat = 'DD/MM/YYYY';
        return moment(date).format(dateFormat);
    },

    /**
     * Returns a DD/MM/YYYY string representation of the passed time in milliseconds since
     * the epoch.
     * @param {number} epoch The time in milliseconds since the Unix epoch.
     */
    formatEpochToDate(epoch, fallback) {
        // Return the fallback value, if the epoch is null or undefined, so long as
        // the fallback value is not null.
        if ((typeof epoch === 'undefined' || isNaN(epoch) || epoch === null) && fallback !== null) {
            return fallback;
        }

        const dateFormat = 'DD/MM/YY';
        const date = new Date(0);
        date.setUTCMilliseconds(epoch);
        return moment(date).format(dateFormat);
    },

    /***
     * Will attempt to scroll the component referenced by alertRef
     * into view
     * @param alertRef
     */
    scrollAlertIntoView(alertRef) {
        if (alertRef &&
            alertRef.current &&
            alertRef.current.scrollIntoView)
        {
            alertRef.current.scrollIntoView({behavior: 'smooth'})
        }
    },

    /***
     * Remove any spaces from the string provided
     * @param aString
     */
    stripSpaces(aString) {
        return aString.replace(/\s/g, '');
    },

    /***
     * Returns true if the string provided contains a numeric digit
     * @param aString
     */
    containsDigit(aString) {
        const reDigit = /\d/;
        return reDigit.test(aString);
    },

    /***
     * Returns true if the string provided contains a space
     * @param aString
     */
    containsSpace(aString) {
        const reSpace = /\s/;
        return reSpace.test(aString);
    },

    /***
     * Returns true if the string provided contains a lowercase character
     * @param aString
     */
    containsLowercase(aString) {
        const reLower = /[a-z]/;
        return reLower.test(aString);
    },

    /***
     * Returns true if the string provided contains only numeric digits
     * @param aString
     */
    containsOnlyDigits(aString) {
        // Regex enforces entry of numbers only
        const re = /^[0-9\b]+$/;
        return re.test(aString);
    },

    /***
     * Returns true if the string provided contains only numeric digits
     * or alphabetic characters
     * @param aString
     */
    containsOnlyLettersAndDigits(aString) {
        const re = /^[a-zA-Z0-9]+$/;
        return re.test(aString);
    },

    /***
     * Returns true if the string provided contains certain special characters
     * that are not to be entered in a name field. The list of disallowed
     * characters is...
     * - back slash
     * - forward slash
     * - question mark
     * - less than sign
     * - greater than sign
     * - double quote
     * - open bracket
     * - close bracket
     * - open curly brace
     * - close curly brace
     * - open square bracket
     * - close square bracket
     * - comma
     * - colon
     * - semi colon
     * @param aString
     */
    containsInvalidNameCharacter(aString) {
        const reSpace = /(\\|\/|\?|<|>|"|\(|\)|\{|\}|\[|\]|,|:|;)/;
        return reSpace.test(aString);
    },

    /***
     * Drops the leading zero from the phone number provided and add the
     * Australian country code of +61
     * @param phoneNumber
     */
    internationalisePhoneNumber(phoneNumber) {
        return '+61' + phoneNumber.substring(1);
    },

    /***
     * Strips the country code from the phone number and adds in the leading
     * zero.
     * @param phoneNumber
     */
    stripCountryCode(phoneNumber) {
        if (phoneNumber.startsWith('+61')) {
            return '0' +
                phoneNumber.substr(3,3) + ' ' +
                phoneNumber.substr(6,3) + ' ' +
                phoneNumber.substr(9,3);
        } else {
            return phoneNumber
        }
    },

    /***
     * Takes the phone number provided and formats it with spaces.
     * Assumes the number passed in is of the format +61XXXXXXXXX
     * @param phoneNumber
     */
    formatMobilePhoneNumber(phoneNumber) {
        if (phoneNumber.length === 12) {
            return phoneNumber.substr(0,3) + ' ' +
                phoneNumber.substr(3,3) + ' ' +
                phoneNumber.substr(6,3) + ' ' +
                phoneNumber.substr(9,3);
        } else {
            return phoneNumber;
        }
    },


    /**
     * Validate that an email address matches the standard Medibank
     * regular expression for emails
     *
     * @param email
     * @returns {boolean}
     */
    validateEmailAddress(email) {
        return emailRe.test(email)
    },

    /***
     * Performs some basic validation on the mobile no provided.
     * - must be 10 characters long
     * - must start with '04'
     * @param mobileNo
     * @returns {string}
     */
    isValidMobileNo(mobileNo) {
        let errorMsg = ''
        if (mobileNo.length === 0 || mobileNo.length < 10 || !mobileNo.startsWith('04')) {
            errorMsg = "You must enter a valid Australian mobile number. The number should be 10 digits long, and start with '04'"
        }

        return errorMsg
    },

    /***
     * Returns a user-friendly error message based on the error text provided
     * @param errorText
     */
    translateSMSErrorText(errorText) {
        let errorMessage = "Not able to validate the verification code. Please try again or request a new code.";

        if (errorText === 'CodeMismatchException') {
            errorMessage = "Sorry, the verification code you have entered is not correct. Please click the 'Resend' option to resend your code and try again."
        } else if (errorText === 'ExpiredCodeException') {
            errorMessage = "Your code is no longer valid. Please click the 'Resend' option to resend your code and try again."
        }

        return errorMessage;
    },

    /***
     * Takes the claim status provided and translates it into a more human-understandable format
     * @param status
     */
    translateClaimStatus(status) {
        let newStatus = status;
        if (status.toLowerCase() === 'p') {
            newStatus = 'Awaiting assessment'
        } else if (status.toLowerCase() === 'm') {
            newStatus = 'Partially assessed'
        } else {
            newStatus = 'Assessed'
        }

        return newStatus;
    },

    /***
     * Takes the claim line status provided and translates it into a more human-understandable format
     * @param status
     */
    translateClaimLineStatus(status) {
        let newStatus;

        switch(status.toLowerCase()) {
            case 'audit required':
            case 'being captured':
            case 'being edited':
            case 'correction insert':
            case 'being corrected':
            case 'audit after edit':
            case 'ready to assess':
            case 'ready to edit':
            case 'ready for auto edit':
            case 'on hold':
            case 'ready for validation':
            case 'processing':
                newStatus = 'Awaiting assessment'
                break;
            case 'assessed':
            case 'counter entry':
            case 'error':
            case 'finished editing':
            case 're assessed':
            case 're assess':
            case 'oshc migrate':
            case 'paid':
            case 'rejected':
                newStatus = 'Assessed'
                break;
            default:
                newStatus = 'Awaiting assessment'
        }

        return newStatus;
    },

    /***
     * This function is used by a number of the search screens to determine the
     * text to show when listing search results. The text is generally of the
     * form "Showing 1-20 of 100 results"
     * @param itemsPerPage
     * @param currentPageNo
     * @param itemCount
     * @returns {string}
     */
    determineRowCountText(itemsPerPage, currentPageNo, itemCount) {

        let countMsg = '';
        if (itemCount !== null) {
            let showingHi = itemsPerPage*currentPageNo;
            let showingLo = itemsPerPage*(currentPageNo-1) + 1;
            if (showingHi > itemCount) {
                showingHi = itemCount;
            }

            const showingCount = itemCount.toLocaleString('en');

            if (itemCount > 0) {
                countMsg = 'Showing ' +showingLo+ '-' +showingHi+ ' of ' +showingCount+ ' results';
            } else {
                countMsg = 'Showing 0 results';
            }
        }
        return countMsg;
    },

    /***
     * Returns a version of the string provided where the first letter is upper case
     * and the rest is lowercase
     * @param name
     * @returns {*|string}
     */
    capitaliseName(name) {
        let newName = name
        if (newName && newName.length > 0) {
            newName = name.toLowerCase()
            newName = newName.substring(0,1).toUpperCase() + newName.substring(1)
        }
        return newName
    },

    /***
     * Returns a single provider name made up of the first name and last name of the
     * provider object provided
     * @param provider
     */
    buildProviderName(provider) {
        return provider.firstname + ' ' + provider.lastname
    },

    /***
     * Returns true if the REACT_APP_PP_ENV variable indicates we are running in a development
     * environment
     */
    isDevelopmentEnvironment() {
        const env = process.env.REACT_APP_PP_ENV || '';
        return (env === '' || env.toLowerCase().includes('dev') || env.toLowerCase().includes('sit'))
    }

}

export default Utils;
