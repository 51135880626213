import classnames from "classnames";
import React, { Component } from 'react';
import styles from './SpinnerButtonComponent.module.scss';
import PropTypes from 'prop-types';


class SpinnerButtonComponent extends Component {

    render() {
        let disabled = this.props.disabled;
        let buttonContents = this.props.text;
        if (this.props.spinning) {
            buttonContents = <div className="spinner-md text-white"></div>;
            disabled = true;
        }

        let classes = this.props.isPrimary ? 'btn btn-primary' : 'btn btn-dark-ghost'

        return (
            <button
                onClick={this.props.handleClick}
                className={classnames(classes, styles.button, this.props.className)}
                disabled={disabled}
                color="primary">
                {buttonContents}
            </button>
        )
    }
}

SpinnerButtonComponent.propTypes = {
    disabled: PropTypes.bool,
    isPrimary: PropTypes.bool,
    text: PropTypes.string.isRequired,
    spinning: PropTypes.bool.isRequired,
    handleClick: PropTypes.func.isRequired,
    className: PropTypes.string
}

SpinnerButtonComponent.defaultProps = {
    disabled: false,
    spinning: false,
    isPrimary: true
}

export default SpinnerButtonComponent