import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ProviderSearchContainer from 'components/providerselect/ProviderSearchContainer';
import DatePeriodSearchComponent from 'components/DatePeriodSearchComponent';
import PatientSearchComponent from 'components/PatientSearchComponent';
import styles from './SearchCriteriaComponent.module.scss';
import ConfigHelper from 'config/ConfigHelper'
import TagManager from "react-gtm-module";
import {getPageLoadArgs} from "utils/GTMHelper"

class SearchCriteriaComponent extends Component {

    componentDidMount() {
        const {currentUser, pathname} = this.props
        TagManager.dataLayer(getPageLoadArgs('Claim Status',
            pathname, currentUser.cognitoId, currentUser.role))
    }

    determineFromDateCutoff() {
        let cutoff = new Date();
        cutoff.setFullYear(cutoff.getFullYear()-2);
        return cutoff;
    }

    renderSearchButton() {
        let disabled = !this.props.searchEnabled;
        let buttonContents = 'Search';
        if (this.props.isLoading) {
            buttonContents = <div className="spinner-md text-white"></div>;
            disabled = true;
        }

        return (
            <div className='col-lg-3 col-md-4 search-btn-wrapper'>
                <div className='input-group'>
                    <button
                        className='btn-lg btn btn-primary'
                        disabled={disabled}
                        onClick={this.props.executeSearch}
                        color="primary">
                        {buttonContents}
                    </button>
                </div>
            </div>
        )
    }

    render() {
        return (

            <div className={styles.searchCriteriaWrapper}>
                <div className={styles.searchCriteriaInner}>
                    <div className='row search-btn-row'>
                        <div className='col-lg-9 col-md-8'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <ProviderSearchContainer
                                        providers={this.props.providers}
                                        showMultiSelectOption={false}
                                    />
                                </div>
                            </div>
                            <div className={classnames('row', styles.dateSearchRow)}>
                                <div className='col-lg-6'>
                                    <DatePeriodSearchComponent
                                        earliestSelectableDate={this.determineFromDateCutoff()}
                                        restrictedDateRangePeriod={ConfigHelper.getClaimSearchMaxPeriod()}
                                        searchPeriodTitle='Search period (Date of service)'
                                    />
                                </div>
                                <div className='col-lg-6'>
                                    <PatientSearchComponent />
                                </div>
                            </div>
                            <div>
                                <p className={styles.helpText}>You are able to search a maximum period of 90 days within the past 2 years.</p>
                            </div>
                        </div>
                        {this.renderSearchButton()}
                    </div>
                </div>
            </div>
        )
    }
}

SearchCriteriaComponent.propTypes = {
    providers: PropTypes.instanceOf(Array).isRequired,
    searchEnabled: PropTypes.bool.isRequired,
    executeSearch: PropTypes.func.isRequired,
    currentUser: PropTypes.object.isRequired,
    pathname: PropTypes.string.isRequired
};

export default SearchCriteriaComponent;