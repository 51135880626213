import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './PaginationLinksComponent.module.scss';
import classnames from 'classnames';

class PaginationLinksComponent extends Component {

    constructor(props) {
        super(props);
        this.handlePageClick = this.handlePageClick.bind(this);
    }

    handlePageClick(event) {
        const pageNo = event.target.dataset.pageNo;
        this.props.onPageClick(pageNo);
    }

    renderPrevious(pageCount) {
        const {currentPageNo} = this.props;
        let prevPageNo = currentPageNo - 1,
            disabled = prevPageNo < 1;

        return (
            <button onClick={this.handlePageClick}
                    className={classnames('btn btn-dark-ghost', styles.navButton)}
                    disabled={disabled}
                    data-page-no={prevPageNo}
                    data-test-id='previous'
            >
                Previous
            </button>
        )

    }

    renderNext(pageCount) {
        const {currentPageNo} = this.props;
        let nextPageNo = currentPageNo + 1,
            disabled = (nextPageNo > pageCount);

        return (
            <button onClick={this.handlePageClick}
                    className={classnames('btn btn-dark-ghost', styles.navButton)}
                    disabled={disabled}
                    data-page-no={nextPageNo}
                    data-test-id='next'
            >
                Next
            </button>
        );
    }

    renderPaginationLink(page, isCurrentPage) {
        const ariaLabel = "Goto page " + page;
        let classes = "pagination-link simple-link-reverse";
        if (isCurrentPage) {
            classes = classnames(classes, styles.isCurrent);
        }

        return (
            <li key={page}>
                <span onClick={this.handlePageClick}
                      className={classes} aria-label={ariaLabel}
                      data-page-no={page}
                      data-test-id='page'
                >
                    {page}
                </span>
            </li>
        );
    }

    renderEllipsis(page) {
        return (
            <li key={page}>
                <span className="pagination-ellipsis">&hellip;</span>
            </li>
        );
    }

    renderPageButtons(pageCount) {
        return (
            <ul className={styles.paginationList}>
                {this.buildPageLinks(pageCount)}
            </ul>
        );
    }

    determinePageCount() {
        const {totalItems, itemsPerPage} = this.props;
        const remainder = totalItems % itemsPerPage;
        let pageCount = Math.floor(totalItems/itemsPerPage);
        if (remainder !== 0) {
            pageCount += 1;
        }

        return pageCount;
    }

    buildPageLinks(pageCount) {
        const {currentPageNo} = this.props;
        let pageLinks = [];
        // Always setup page link to first page
        pageLinks.push(this.renderPaginationLink('1',currentPageNo===1));

        // If there are more than seven pages, we will include some ellipsis
        // in the pagination links. Otherwise, a link for every page will
        // be shown.
        if (pageCount > 7) {
            if (currentPageNo < 4) {
                for (let i=2; i<=4; i++) {
                    pageLinks.push(this.renderPaginationLink(i,i===currentPageNo));
                }
                pageLinks.push(this.renderEllipsis(5));
            } else if (currentPageNo > (pageCount - 3)) {
                pageLinks.push(this.renderEllipsis(pageCount - 4));
                for (let i=(pageCount - 3); i<pageCount; i++) {
                    pageLinks.push(this.renderPaginationLink(i,i===currentPageNo));
                }
            } else {
                pageLinks.push(this.renderEllipsis(currentPageNo - 2));
                for (let i=(currentPageNo - 1); i<=(currentPageNo + 1); i++) {
                    pageLinks.push(this.renderPaginationLink(i,i===currentPageNo));
                }
                pageLinks.push(this.renderEllipsis(currentPageNo + 2));
            }

        } else {
            for (let i=2; i<pageCount; i++) {
                pageLinks.push(this.renderPaginationLink(i,i===currentPageNo));
            }
        }
        // Always setup page link to last page
        pageLinks.push(this.renderPaginationLink(pageCount,currentPageNo===pageCount));
        return pageLinks;
    }

    render() {
        if (this.props.totalItems <= this.props.itemsPerPage) {
            return null;
        } else {
            const pageCount = this.determinePageCount();
            return (
                <nav className={styles.pagination} aria-label="pagination" data-test-id="pagination">
                    {this.renderPrevious(pageCount)}
                    <div className={styles.linksWrapper}>
                        {this.renderPageButtons(pageCount)}
                    </div>
                    {this.renderNext(pageCount)}
                </nav>
            );
        }
    }
}

PaginationLinksComponent.propTypes = {
    totalItems: PropTypes.number.isRequired,
    itemsPerPage: PropTypes.number.isRequired,
    currentPageNo: PropTypes.number.isRequired,
    onPageClick: PropTypes.func.isRequired
};

export default PaginationLinksComponent;