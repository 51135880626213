import MedikitModalComponent from "components/modal/MedikitModalComponent";
import React, {Component} from "react";


class WhatAreRolesModal extends Component {
    render() {
        const modalContent =
            <>
                <h5>Doctor</h5>
                <div>
                    You should register as a doctor if you are the medical practitioner registered under
                    the provider number. Doctors will be required to set up their own profiles using a
                    provider number, STEM number and AHPRA number.<br/><br/>
                </div>
                <h5>Practice Manager</h5>
                <div>You should register as a practice manager if you are someone who manages administration
                    work for a doctor. You will be able to approve or reject requests from other users to
                    access a doctor's account, so that they can perform tasks on the doctor's behalf.
                    You can also manage one or more provider locations on behalf of a doctor.<br/><br/>
                </div>
                <h5>Other</h5>
                <div>
                    You should register as an 'Other' user if you are employed at a doctor's practice location.
                    You will be able to perform tasks on behalf of a doctor, but you will not be able to
                    approve or reject requests from other users to link to a doctor's account.
                </div>
            </>;

        return <MedikitModalComponent modalId='tooltipModal'
                               modalTitle='What are roles?'
                               modalContent={modalContent} />;
    }
}

export default WhatAreRolesModal