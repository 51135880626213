import React, { Component } from 'react';
import styles from './DetailsDisplayComponent.module.scss';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import AlertComponent from 'components/AlertComponent'
import UserContext from "context/UserContext"
import Constants from "utils/Constants"
import {Redirect} from "react-router-dom"
import TagManager from "react-gtm-module"
import {getYourDetailsArgs} from "utils/GTMHelper"


class DetailsDisplayComponent extends Component {

    constructor(props) {
        super(props)

        this.state = {
            alertIsOpen: this.props.showUpdateAlert,
            redirectToLogin: false
        }

        this.handleAlertClose = this.handleAlertClose.bind(this)
        this.handleButtonClick = this.handleButtonClick.bind(this)
    }

    handleAlertClose() {
        this.setState({
            alertIsOpen: false
        })
    }

    handleButtonClick() {
        const authStatus = this.context.actions.isAuthenticatedUser()
        if (authStatus !== Constants.AUTH.VALID) {
            this.setState({
                redirectToLogin: true
            })
        } else {
            const tagParms = {
                label: 'Start',
                sequenceId: this.props.gtmSequenceId,
                detailsType: this.props.title
            }

            TagManager.dataLayer(getYourDetailsArgs(tagParms))

            this.props.onButtonClick()
        }
    }

    renderAlertComponent() {
        if (this.props.showUpdateAlert && this.state.alertIsOpen) {
            const {title} = this.props
            const alertMessage = 'Your ' + title.toLowerCase() +  ' has been successfully changed'

            return (
                <div className={styles.alertWrapper}>
                    <AlertComponent
                        alertSuccess={true}
                        alertMessage={alertMessage}
                        isLargeText={true}
                        handleAlertClose={this.handleAlertClose}
                    />
                </div>
            )
        }
    }

    render() {
        if (this.state.redirectToLogin) {
            return <Redirect to='/login' />
        } else {
            const {title, fieldValue, buttonText} = this.props
            return (
                <div className={styles.detailWrapper}>
                    {this.renderAlertComponent()}
                    <div className={styles.detailBlock}>
                        <h4>{title}</h4>
                        <div className={styles.fieldRow}>
                            <span>{fieldValue}</span>
                        </div>
                        <div>
                            <button onClick={this.handleButtonClick} className={classnames('btn btn-lg btn-dark-ghost', styles.button)} >
                                {buttonText}
                            </button>
                        </div>
                    </div>
                </div>
            );
        }

    }
}

DetailsDisplayComponent.contextType = UserContext;

DetailsDisplayComponent.propTypes = {
    title: PropTypes.string.isRequired,
    fieldValue: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    onButtonClick: PropTypes.func.isRequired,
    showUpdateAlert: PropTypes.bool,
    gtmSequenceId: PropTypes.string.isRequired
}

DetailsDisplayComponent.defaultProps = {
    showUpdateAlert: false
}

export default DetailsDisplayComponent;