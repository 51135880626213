import React, { Component } from 'react';
import styles from './MobileDetailsSMSCodeComponent.module.scss';
import classnames from 'classnames';
import PropTypes from 'prop-types'
import Utils from 'utils/Utils'

class MobileDetailsSMSCodeComponent extends Component {

    constructor(props) {
        super(props)

        this.handleSMSCodeInput = this.handleSMSCodeInput.bind(this);
    }

    handleSMSCodeInput(event) {
        this.props.handleSMSCodeInput(event.target.value)
    }

    constructErrorComponent(errorCode) {
        if (errorCode === 'UserNotConfirmedException') {
            return (
                <span>
                    Sorry, your new mobile phone number could not be verified.
                    Please contact our Provider Self Service enquiry team.
                    Find our details in the&nbsp;
                    <a className='simple-link-reverse'
                       href="https://www.medibank.com.au/contact-us/"
                       rel='noopener noreferrer'
                       target='_blank'>Healthcare providers section</a>
                </span>
            );
        } else {
            return Utils.translateSMSErrorText(errorCode);
        }
    }

    render() {
        const {smsCodeValue, verificationError, disabled} = this.props
        let inputGroupClasses = 'input-group';
        let errorComponent = (<span></span>)
        if (verificationError.length > 0) {
            inputGroupClasses += ' input-group-alert';
            errorComponent = this.constructErrorComponent(verificationError)
        }

        return (
            <div className={classnames('row',styles.verifyRow)}>
                <div className='col-lg-12'>
                    <div className={inputGroupClasses}>
                        <span className='label'>Verification code</span>
                        <input onChange={this.handleSMSCodeInput}
                               id="verifyCode"
                               type={'text'}
                               value={smsCodeValue}
                               disabled={disabled}
                               className='input-search form-control' />
                    </div>
                </div>
                {verificationError.length > 0 &&
                <div className='col-lg-12'>
                    <div data-test-id='error' className={classnames('input-helper form-error small', styles.inputHelper)}>
                        {errorComponent}
                    </div>
                </div>
                }
            </div>
        );
    }
}

MobileDetailsSMSCodeComponent.propTypes = {
    handleSMSCodeInput: PropTypes.func.isRequired,
    smsCodeValue: PropTypes.string.isRequired,
    verificationError: PropTypes.string.isRequired,
    disabled: PropTypes.bool
}

MobileDetailsSMSCodeComponent.defaultProps = {
    disabled: false
}

export default MobileDetailsSMSCodeComponent;