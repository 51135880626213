import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SearchCriteriaContext from "context/SearchCriteriaContext";
import SearchResultsItemComponent from './SearchResultsItemComponent';
import { API, graphqlOperation } from 'aws-amplify';
import {getRemittance} from 'graphql/queries';
import Constants from 'utils/Constants';

class SearchResultsItemContainer extends Component {

    constructor(props) {
        super(props);

        this.handleDocumentDownload = this.handleDocumentDownload.bind(this);
    }

    handleDocumentDownload() {
        const {searchResultsPage, actions} = this.context
        const {remittance, sequenceNo} = this.props;

        // The sequenceNo just represents where this results item comes
        // in respect to the current page of results. For tagging, this
        // number needs to be adjusted to cater for it's position
        // in the full list of results for all pages.
        let actualSeqNo = ((searchResultsPage - 1 ) * Constants.REMITTANCE_SEARCH_ITEMS_PER_PAGE) + sequenceNo

        actions.tagDocumentDownload(remittance.eft, actualSeqNo)
        return this.fetchDocument();
    }

    async fetchDocument() {
        const {remittance} = this.props;
        const handleError = this.context.actions.handleError;

        try {
            const response = await API.graphql(graphqlOperation(getRemittance,
                {
                    remittanceId: remittance.id
                }));

            if (response.data.getRemittance.url) {
                const link = document.createElement('a');
                link.href = response.data.getRemittance.url;
                link.setAttribute('target', '_blank');
                document.body.appendChild(link);
                link.click();
            } else {
                handleError();
            }
        }
        catch (error) {
            handleError();
        }

    }


    render() {
        const {remittance} = this.props;

        return (
            <SearchResultsItemComponent
                remittance={remittance}
                handleDocumentDownload={this.handleDocumentDownload} />
        )
    }
}

SearchResultsItemContainer.propTypes = {
    remittance: PropTypes.instanceOf(Object).isRequired
};

SearchResultsItemContainer.contextType = SearchCriteriaContext;

export default SearchResultsItemContainer;