import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomisedDayPickerInputComponent from "./CustomisedDayPickerInputComponent";
import classnames from 'classnames';
import styles from './DateRangePickerComponent.module.scss';
import Utils from 'utils/Utils';

class DateRangePickerComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showFromPicker: false,
            showToPicker: false,
            hasRangeError: false,
            restrictedFromDate: null,
            restrictedToDate: null
        }

        // Setup refs to the wrapper around the date pickers so they can
        // be closed if the user clicks outside the picker
        this.fromPickerRef = React.createRef();
        this.toPickerRef = React.createRef();

        this.handleFromInputClick = this.handleFromInputClick.bind(this);
        this.handleToInputClick = this.handleToInputClick.bind(this);
        this.handleFromDateChange = this.handleFromDateChange.bind(this);
        this.handleToDateChange = this.handleToDateChange.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.state.showFromPicker) {
            if (this.fromPickerRef &&
                !this.fromPickerRef.current.contains(event.target))
            {
                this.setState({
                    showFromPicker: false
                })
            }
        } else if (this.state.showToPicker) {
            if (this.toPickerRef &&
                !this.toPickerRef.current.contains(event.target))
            {
                this.setState({
                    showToPicker: false
                })
            }
        }
    }


    handleFromInputClick() {
        this.setState({
            showFromPicker: true,
            showToPicker: false
        })
    }

    handleToInputClick() {
        this.setState({
            showFromPicker: false,
            showToPicker: true
        })
    }

    handleToDateChange(day, modifiers = {}) {
        // Shortcut if user has clicked on a disabled day
        if (modifiers.disabled) {
            return;
        }

        const {restrictedDateRangePeriod} = this.props;
        const rangeError = (this.props.selectedFromDate &&
            this.props.selectedFromDate > day);

        let restrictedDate = null;
        if (restrictedDateRangePeriod) {
            const date = Utils.subtractDaysFromDate(day, restrictedDateRangePeriod);
            restrictedDate = date;
        }

        this.setState({
            showToPicker: false,
            hasRangeError: rangeError,
            restrictedFromDate: restrictedDate
        });

        this.props.onToDateSelect(day);
    }

    handleFromDateChange(day, modifiers = {}) {
        // Shortcut if user has clicked on a disabled day
        if (modifiers.disabled) {
            return;
        }

        const {restrictedDateRangePeriod} = this.props;
        const rangeError = (this.props.selectedToDate &&
            this.props.selectedToDate < day);

        let restrictedDate = null;
        if (restrictedDateRangePeriod) {
            const date = Utils.addDaysToDate(day, restrictedDateRangePeriod);
            restrictedDate = date;
        }

        this.setState({
            showFromPicker: false,
            hasRangeError: rangeError,
            restrictedToDate: restrictedDate
        });

        this.props.onFromDateSelect(day);
    }

    render() {
        const {earliestSelectableDate,
            latestSelectableDate,
            initialFromMonth,
            initialToMonth,
            selectedFromDate,
            selectedToDate,
            restrictedDateRangePeriod} = this.props;

        const currentFromMonth = selectedFromDate ? selectedFromDate : initialFromMonth;
        const currentToMonth = selectedToDate ? selectedToDate : initialToMonth;

        // When a From Date value is set, disable any dates
        // prior to the From Date in the To Date picker
        let toDateDisableBefore = earliestSelectableDate;
        if (selectedFromDate > toDateDisableBefore) {
            toDateDisableBefore = selectedFromDate;
        }

        // Restrict from / to dates selectable if restricted range applies
        const {restrictedToDate, restrictedFromDate} = this.state;
        let toDateDisableAfter = latestSelectableDate;
        let fromDateDisableBefore = earliestSelectableDate;
        if (restrictedDateRangePeriod) {
            if (restrictedToDate &&
                restrictedToDate < toDateDisableAfter)
            {
                toDateDisableAfter = restrictedToDate;
            }

            if (restrictedFromDate &&
                restrictedFromDate > fromDateDisableBefore)
            {
                fromDateDisableBefore = restrictedFromDate;
            }
        }

        // Note the use of the "key" prop within CustomisedDayPickerInputComponent.
        // This is used to ensure a new component instance is created when the
        // selectedFromDate or selectedToDate changes. This is due to the
        // CustomisedDayPickerInputComponent having to manage internal state for
        // the year / month controls
        return (
            <>
            <div className='input-group-row'>
                <div ref={this.fromPickerRef}
                     className={classnames('input-group select input-group-small', styles.rangeWrapper)}>
                    <CustomisedDayPickerInputComponent
                        handleDateChange={this.handleFromDateChange}
                        currentMonth={currentFromMonth}
                        minDate={earliestSelectableDate}
                        maxDate={latestSelectableDate}
                        disableBeforeDate={fromDateDisableBefore}
                        disableAfterDate={latestSelectableDate}
                        placeHolder={'Date from'}
                        handleInputClick={this.handleFromInputClick}
                        showPicker={this.state.showFromPicker}
                        selectedDate={selectedFromDate}
                        key={currentFromMonth}
                        inError={this.state.hasRangeError}
                    />
                </div>
                <div ref={this.toPickerRef}
                     className={classnames('input-group select input-group-small', styles.rangeWrapper)}>
                    <CustomisedDayPickerInputComponent
                        handleDateChange={this.handleToDateChange}
                        currentMonth={currentToMonth}
                        minDate={earliestSelectableDate}
                        maxDate={latestSelectableDate}
                        disableBeforeDate={toDateDisableBefore}
                        disableAfterDate={toDateDisableAfter}
                        placeHolder={'Date to'}
                        handleInputClick={this.handleToInputClick}
                        showPicker={this.state.showToPicker}
                        selectedDate={selectedToDate}
                        key={currentToMonth}
                        inError={this.state.hasRangeError}
                    />
                </div>
            </div>
            {this.state.hasRangeError &&
                <small className="input-helper feedback form-error">
                    Sorry, the "Date from" must be earlier than the "Date to". Please try again.
                </small>
            }
            </>
        )
    }
}

DateRangePickerComponent.propTypes = {
    // The current From Date value. This is used to restrict
    // dates in the To Date picker (the To Date picker will
    // disable any dates prior to the current From Date)
    fromDate: PropTypes.instanceOf(Date),
    // The initial From month shown in the date picker
    initialFromMonth: PropTypes.instanceOf(Date).isRequired,
    // The initial To month shown in the date picker
    initialToMonth: PropTypes.instanceOf(Date).isRequired,
    // The earliest selectable date (ie no dates before this date
    // will be shown in the date picker)
    earliestSelectableDate: PropTypes.instanceOf(Date).isRequired,
    // The latest selectable date (ie no dates after this date
    // will be shown in the date picker)
    latestSelectableDate: PropTypes.instanceOf(Date).isRequired,
    // Function to call when From Date selected
    onFromDateSelect: PropTypes.func.isRequired,
    // Function to call when From Date selected
    onToDateSelect: PropTypes.func.isRequired,
    // Represents a period in days to which the range of dates selectable
    // needs to be restricted. For example, if set to 90, then when a From
    // Date is chosen, the To Date will be restricted to being no more
    // than 90 days after the From Date
    restrictedDateRangePeriod: PropTypes.number
};

export default DateRangePickerComponent;