import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ProviderListItemComponent from './ProviderListItemComponent';

class ProviderMultiListComponent extends Component {

    renderProviders() {
        const {providers} = this.props;

        let renderedArray = providers.map((provider) => {
            let isSelected = this.props.selectedProviders.findIndex((selectedProvider) => {
                return selectedProvider.id === provider.id;
            });

            return (
                <div key={provider.id} className='col-lg-6 pp-listed-provider-wrapper'>
                    <ProviderListItemComponent
                        handleProviderSelect={this.props.handleProviderSelect}
                        handleProviderDeselect={this.props.handleProviderDeselect}
                        showCheckbox={true}
                        provider={provider}
                        isChecked={isSelected !== -1}
                    />
                </div>
            )
        });
        if (renderedArray.length % 2 !== 0) {
            renderedArray.push(
                <div key={'-1'} className='col-6 pp-listed-provider-wrapper d-none d-lg-block'>
                </div>
            )
        }
        return renderedArray;
    }

    render() {
        return (
            <div className='relative'>
                <div className='pp-provider-multilist-wrapper row no-gutters'>
                    {this.renderProviders()}
                </div>
            </div>
        )
    }
}

ProviderMultiListComponent.propTypes = {
    providers: PropTypes.instanceOf(Array).isRequired,
    selectedProviders: PropTypes.instanceOf(Array).isRequired,
    handleProviderSelect: PropTypes.func.isRequired,
    handleProviderDeselect: PropTypes.func.isRequired
};

export default ProviderMultiListComponent;