import React, { Component } from 'react';
import Constants from 'utils/Constants';
import STEMSearchStep from 'components/providerSetup/STEMSearchStep'
import AddProviderStep from 'components/providerSetup/AddProviderStep'
import UserContext from "context/UserContext";
import {getSpecialities, getProviderDetails} from "graphql/queries";
import {createProviders} from "graphql/mutations"
import {API, graphqlOperation} from 'aws-amplify';
import STEMSearchResultsStep from 'components/providerSetup/STEMSearchResultsStep'
import {Redirect} from "react-router-dom";
import AddProviderSearchResultsStep from "components/providerSetup/AddProviderSearchResultsStep";
import {getGTMSequenceId, getManageProvidersArgs} from "utils/GTMHelper";
import TagManager from "react-gtm-module";


class ProviderSetupContainer extends Component {

    constructor(props) {
        super(props);

        this.setAHPRANumber = this.setAHPRANumber.bind(this)
        this.setSTEMNumber = this.setSTEMNumber.bind(this)
        this.getSpecialtyList = this.getSpecialtyList.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
        this.setSpecialty = this.setSpecialty.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleNextStep = this.handleNextStep.bind(this)
        this.clearErrors = this.clearErrors.bind(this)

        this.state = {
            specialties: [],
            disabled: true,
            providerLocations: [],
            providersAndRoles: [],
            doctorEmail: undefined,
            ahpraNumber: undefined,
            stemNumber: undefined,
            inError: false,
            errorCode: undefined,
            errorMessage: undefined,
            step: 1,
            provider: undefined,
            specialty: "none",
            specialtyId: undefined,
            searching: false,
            submitting: false,
            gtmSequenceId: getGTMSequenceId(),
            actions: {
                getSpecialtyList: this.getSpecialtyList,
                setAHPRANumber: this.setAHPRANumber,
                setSTEMNumber: this.setSTEMNumber,
                handleSearch: this.handleSearch,
                setSpecialty: this.setSpecialty,
                handleNextStep: this.handleNextStep,
                clearErrors: this.clearErrors
            }
        }
        this.getSpecialtyList()
    }

    async getSpecialtyList() {
        try {
            const specialties = await API.graphql(graphqlOperation(getSpecialities))
            this.setState({specialties: specialties.data.getSpecialities})
        }
        catch (error) {

        }
    }

    setSpecialty(specialty, specialtyId) {
        this.setState({
            specialty,
            specialtyId
        }, () => {
            this.validate()
        })
    }

    setAHPRANumber(ahpraNumber) {
        this.setState({
            ahpraNumber: ahpraNumber
        }, () => {
            this.validate()
        })
    }

    setSTEMNumber(stemNumber) {
        this.setState({
            stemNumber: stemNumber
        }, () => {
            this.validate()
        })
    }

    clearErrors() {
        this.setState({inError: false, errorCode: undefined, errorMessage: undefined})
    }

    validate() {
        let valid = this.state.ahpraNumber && this.state.stemNumber && this.state.specialty !== 'none'

        this.setState({
            disabled: !valid
        })
    }

    async handleSubmit() {
        this.setState({submitting: true}, async () => {
            try {
                await API.graphql(graphqlOperation(createProviders, {
                        input: {
                            stem: this.state.stemNumber,
                            ahpra: this.state.ahpraNumber,
                            specialityId: this.state.specialtyId
                        }
                    }
                ));

                await this.context.actions.refreshUser()

                const tagParms = {
                    label: 'Form Complete',
                    sequenceId: this.state.gtmSequenceId
                }

                TagManager.dataLayer(getManageProvidersArgs(tagParms))

                this.setState({toDashboard: true, submitting: false});
            }
            catch(error) {
                this.setState({inError: true, submitting: false, errorCode: error.errors[0].errorType, errorMessage: error.errors[0].message})
            }
        })

    }

    handleSearch() {
        this.setState({searching: true}, async () => {
            try {
                let results = await API.graphql(graphqlOperation(getProviderDetails, {
                    input: {
                        stem: this.state.stemNumber,
                        ahpra: this.state.ahpraNumber
                    }
                }));

                let provider = results.data.getProviderDetails
                provider.specialty = this.state.specialty

                this.tagSearchStep()

                this.setState({step: 2, provider, searching: false})
            }
            catch (error) {
                this.tagSearchStep(error.errors[0].message)

                this.setState({inError: true, searching: false, errorCode: error.errors[0].errorType, errorMessage: error.errors[0].message})
            }
        })
    }

    tagSearchStep(errorMessage) {
        const tagParms = {
            label: 'Step Complete',
            stepName: 'Search for Providers',
            stepValue: this.state.step,
            sequenceId: this.state.gtmSequenceId
        }

        if (errorMessage) {
            tagParms.errorName = 'No Providers Found'
            tagParms.errorDetails = errorMessage
            tagParms.label = 'Form Error'
        }
        TagManager.dataLayer(getManageProvidersArgs(tagParms))
    }

    handleNextStep(locations) {
        let allLinked = locations.filter(loc => {
            return !loc.linkedToUser
        }).length === 0

        if (locations.length === 0) {
            let code = 'NoDataFound'
            let message = 'Sorry we cannot find any matching records in our system'
            this.tagSearchStep('No matching records found')
            this.setState({inError: true, searching: false, errorCode: code, errorMessage: message})
        } else if (allLinked) {
            let code = 'AllProvidersLinked'
            this.tagSearchStep('All providers already linked')
            this.setState({inError: true, searching: false, errorCode: code})
        } else {
            this.tagSearchStep()
            this.setState({step: this.state.step + 1, providerLocations: locations.filter(l => !l.linkedToUser)})
        }
    }

    shouldShowSTEMSearch() {
        let {currentUser, providers} = this.context

        const { match: { params } } = this.props
        let {role} = currentUser
        return (params.pageToShow === 'calc' && role === Constants.USER_ROLE_MAPPING[Constants.USER_ROLE_DOCTOR] && providers.length === 0)
            || params.pageToShow === 'stem'
    }

    render() {
        if (this.state.toDashboard) {
            return <Redirect to={"/"} />
        }

        if (this.shouldShowSTEMSearch()) {
            if (this.state.step === 1) {
                return <STEMSearchStep specialties={this.state.specialties}
                                       disabled={this.state.disabled}
                                       actions={this.state.actions}
                                       errorCode={this.state.errorCode}
                                       errorMessage={this.state.errorMessage}
                                       inError={this.state.inError}
                                       searching={this.state.searching}
                                       gtmSequenceId={this.state.gtmSequenceId}
                />
            } else if (this.state.step === 2 && this.state.provider) {
                let {provider} = this.state;
                return <STEMSearchResultsStep provider={provider} handleSubmit={this.handleSubmit} submitting={this.state.submitting}/>
            }
        } else {
            if (this.state.step === 1) {
                return <AddProviderStep actions={this.state.actions}
                                        errorCode={this.state.errorCode}
                                        errorMessage={this.state.errorMessage}
                                        inError={this.state.inError}
                                        searching={this.state.searching}
                                        clearErrors={this.clearErrors}
                                        gtmSequenceId={this.state.gtmSequenceId}
                />
            } else {
                return <AddProviderSearchResultsStep
                    providerLocations={this.state.providerLocations}
                    gtmSequenceId={this.state.gtmSequenceId}
                />
            }
        }
    }
}

ProviderSetupContainer.contextType = UserContext;

export default ProviderSetupContainer;