import React, { Component } from 'react';
import styles from './RegistrationContainer.module.scss';
import {RegistrationContextProvider} from 'context/RegistrationContext';
import TermsAndConditionsContainer from 'components/registration/TermsAndConditionsContainer';
import Step1Container from 'components/registration/Step1Container';
import Step2Container from 'components/registration/Step2Container';
import Step3Container from 'components/registration/Step3Container';
import Step4Container from 'components/registration/Step4Container';
import Step5Container from 'components/registration/Step5Container';
import StepsHeaderComponent from 'components/registration/StepsHeaderComponent';
import {Auth, API, graphqlOperation} from 'aws-amplify';
import Constants from 'utils/Constants';
import Utils from 'utils/Utils';
import {sendEmailVerification, createAuditEntry} from "graphql/mutations";
import AlertComponent from "components/AlertComponent";
import classnames from 'classnames'
import TagManager from "react-gtm-module";
import {getPageLoadArgs, getRegoStepArgs, TAG_LABEL_STEP_COMPLETE} from "utils/GTMHelper"

const TAG_STEP_NAME_TERMS_CONDITIONS = 'Terms & Conditions';
const TAG_STEP_NAME_ROLE = 'Select role';
const TAG_STEP_NAME_ID_CHECK = 'ID Verification';
const TAG_STEP_NAME_CREATE_ACCOUNT = 'Create Account';
const TAG_STEP_NAME_VERIFY_MOBILE = 'Verify Mobile Number';
const TAG_STEP_NAME_VERIFY_EMAIL = 'Verify Email';

class RegistrationContainer extends Component {

    constructor(props) {
        super(props);

        this.initialiseState = this.initialiseState.bind(this);
        this.setSelectedRole = this.setSelectedRole.bind(this);
        this.setUserFirstName = this.setUserFirstName.bind(this);
        this.setUserLastName = this.setUserLastName.bind(this);
        this.setUserDOB = this.setUserDOB.bind(this);
        this.setIdVerificationRef = this.setIdVerificationRef.bind(this);
        this.setDriversLicence = this.setDriversLicence.bind(this);
        this.setDriversLicenceState = this.setDriversLicenceState.bind(this);
        this.setDriversCardNumber = this.setDriversCardNumber.bind(this);
        this.restartRegistration = this.restartRegistration.bind(this);
        this.completeStep2 = this.completeStep2.bind(this);
        this.setUserPassword = this.setUserPassword.bind(this);
        this.setUserMobile = this.setUserMobile.bind(this);
        this.setUsername = this.setUsername.bind(this);
        this.setUserEmail = this.setUserEmail.bind(this);
        this.completeStep3 = this.completeStep3.bind(this);
        this.setVerificationCode = this.setVerificationCode.bind(this);
        this.completeStep4 = this.completeStep4.bind(this);
        this.continueStep4 = this.continueStep4.bind(this);
        this.completeStep5 = this.completeStep5.bind(this);
        this.continueStep5 = this.continueStep5.bind(this);
        this.handleError = this.handleError.bind(this);
        this.handleAcceptTermsAndConditions = this.handleAcceptTermsAndConditions.bind(this);
        this.setGTMSequenceId = this.setGTMSequenceId.bind(this)

        this.errorRef = React.createRef();

        const stateValues = this.initialiseState()
        const stateActions = {
            actions: {
                setGTMSequenceId: this.setGTMSequenceId,
                setSelectedRole: this.setSelectedRole,
                    acceptedTermsAndConditions: this.acceptedTermsAndConditions,
                    setUserFirstName: this.setUserFirstName,
                    setUserLastName: this.setUserLastName,
                    setUserDOB: this.setUserDOB,
                    setIdVerificationRef: this.setIdVerificationRef,
                    restartRegistration: this.restartRegistration,
                    setDriversLicence: this.setDriversLicence,
                    setDriversLicenceState: this.setDriversLicenceState,
                    setDriversCardNumber: this.setDriversCardNumber,
                    completeStep2: this.completeStep2,
                    setUserPassword: this.setUserPassword,
                    setUserMobile: this.setUserMobile,
                    setUsername: this.setUsername,
                    setUserEmail: this.setUserEmail,
                    completeStep3: this.completeStep3,
                    setVerificationCode: this.setVerificationCode,
                    completeStep4: this.completeStep4,
                    continueStep4: this.continueStep4,
                    completeStep5: this.completeStep5,
                    continueStep5: this.continueStep5
            }
        }
        this.state = {...stateValues, ...stateActions}
    }

    initialiseState() {
        return {
            gtmSequenceId: '',
            stepNo: 1,
            selectedRole: '',
            userFirstName: '',
            userLastName: '',
            dateOfBirth: '',
            idVerificationRef: null,
            driversLicenceNo: '',
            driversLicenceState: 'ACT',
            driversCardNumber: '',
            username: '',
            userPassword: '',
            userMobile: '',
            userEmail: '',
            verificationCode: '',
            creatingAccount: false,
            confirmingAccount: false,
            cognitoUser: null,
            hasError: false,
            error: null,
            hasVerificationCodeError: false,
            acceptedTermsAndConditions: false,
            cognitoId: null,
            displayMobileUniquenessError: false,
        }
    }

    setGTMSequenceId(seqId) {
        this.setState({
            gtmSequenceId: seqId
        })
    }

    setUserDOB(dateInput) {
        this.setState({
            dateOfBirth: dateInput
        })
    }

    setIdVerificationRef(idVerificationRef) {
        this.setState({
            idVerificationRef: idVerificationRef
        })
    }

    setUserFirstName(firstName) {
        this.setState({
            userFirstName: firstName
        })
    }

    setUserLastName(lastName) {
        this.setState({
            userLastName: lastName
        })
    }

    setSelectedRole(role) {
        const {stepNo, gtmSequenceId} = this.state
        const tagParms = {
            label: TAG_LABEL_STEP_COMPLETE,
            sequenceId: gtmSequenceId,
            stepName: TAG_STEP_NAME_ROLE,
            stepValue: stepNo
        }
        TagManager.dataLayer(getRegoStepArgs(tagParms))

        this.setState({
            stepNo: 2,
            selectedRole: role
        })
    }

    setDriversLicence(driversLicence) {
        this.setState({
            driversLicenceNo: driversLicence
        })
    }

    setDriversLicenceState(state) {
        this.setState({
            driversLicenceState: state
        })
    }
    
    setDriversCardNumber(driversCardNumber) {
        this.setState({
            driversCardNumber
        })
    }

    setUsername(username) {
        this.setState({
            username: username
        })
    }

    setUserPassword(password) {
        this.setState({
            userPassword: password
        })
    }

    setUserMobile(mobile) {
        this.setState({
            userMobile: mobile
        })
    }

    setUserEmail(email) {
        this.setState({
            userEmail: email
        })
    }

    setVerificationCode(code) {
        this.setState({
            verificationCode: code
        })
    }

    restartRegistration() {
        const gtmSequenceId = this.state.gtmSequenceId
        const initialState = this.initialiseState()
        initialState.acceptedTermsAndConditions = true
        initialState.gtmSequenceId = gtmSequenceId

        this.setState(initialState);
    }

    canStep2Proceed() {
        let goodToGo = false;

        if (this.state.selectedRole.length > 0 &&
            this.state.userFirstName.length > 0 &&
            this.state.userLastName.length > 0 &&
            this.state.dateOfBirth.length > 0 &&
            this.state.driversLicenceNo.length > 0 &&
            this.state.driversCardNumber.length > 0)
        {
            goodToGo = true;
        }

        return goodToGo;
    }

    canStep3Proceed() {
        let goodToGo = false;
        if (this.state.username.length > 0 &&
            this.state.userMobile.length > 0 &&
            this.state.userPassword.length > 0 &&
            this.state.userEmail.length > 0)
        {
            goodToGo = true;
        }

        return goodToGo;
    }

    canStep4Proceed() {
        return (this.state.verificationCode.length > 0 );
    }

    completeStep2() {
        const {stepNo, gtmSequenceId} = this.state
        const tagParms = {
            label: TAG_LABEL_STEP_COMPLETE,
            sequenceId: gtmSequenceId,
            stepName: TAG_STEP_NAME_ID_CHECK,
            stepValue: stepNo
        }
        TagManager.dataLayer(getRegoStepArgs(tagParms))

        this.setState({
            stepNo: 3
        })
    }

    completeStep3() {
        this.setState({
            creatingAccount: true
        }, async () => {
            await this.createAccount();
        });
    }

    completeStep4() {
        this.setState({
            confirmingAccount: true
        }, async () => {
            await this.confirmAccount();
        })
    }

    continueStep4() {
        this.setState({
            confirmingAccount: false
        }, () => {
            this.resendCode();
        })
    }

    completeStep5() {
        const {stepNo, gtmSequenceId, cognitoId, selectedRole} = this.state
        const tagParms = {
            label: TAG_LABEL_STEP_COMPLETE,
            sequenceId: gtmSequenceId,
            stepName: TAG_STEP_NAME_VERIFY_EMAIL,
            stepValue: stepNo,
            cognitoId: cognitoId,
            usertype: selectedRole
        }
        TagManager.dataLayer(getRegoStepArgs(tagParms))

        this.props.history.push({
            pathname: '/login',
            state: {
                from: 'Registration'
            }
        });
    }

    async continueStep5() {

        this.setState({
            hasError: false
        })

        try {
            let input = {
                username: this.state.username
            }

            await API.graphql(graphqlOperation(sendEmailVerification, {
                    input
                }
            ));
        }
        catch(error) {
            this.handleError(error)
        }
    }

    resendCode() {
        this.setState({
            hasError: false
        })

        const {cognitoUser} = this.state;
        cognitoUser.resendConfirmationCode((err, result) => {
            if (err) {
                this.handleError(err)
            }
        });
    }

    async confirmAccount() {
        this.setState({
            hasError: false
        })
        const {username, verificationCode} = this.state;
        try {
            await Auth.confirmSignUp(username, verificationCode, {
                // Optional. Force user confirmation irrespective of existing alias. By default set to True.
                forceAliasCreation: true
            });

            this.setState({
                confirmingAccount: false,
                hasError: false,
                error: null,
                stepNo: 5
            }, () => {
                const {stepNo, gtmSequenceId, cognitoId, selectedRole} = this.state
                const tagParms = {
                    label: TAG_LABEL_STEP_COMPLETE,
                    sequenceId: gtmSequenceId,
                    stepName: TAG_STEP_NAME_VERIFY_MOBILE,
                    stepValue: (stepNo - 1),
                    cognitoId: cognitoId,
                    usertype: selectedRole
                }
                TagManager.dataLayer(getRegoStepArgs(tagParms))
            });
        }
        catch (error) {
            console.log(error)
            try {
                await API.graphql(graphqlOperation(createAuditEntry,
                    {input: {
                            action: Constants.AUDIT_MOBILE_VERIFICATION,
                            success: false,
                            errorReason: error.code,
                            additionalInfo: error.message
                        }}));
            }
            catch(error) {
                // Swallow this error. Not much we can do here if the audit logging fails
            }


            // Note, handleError not called here as the error is passed through to the
            // verification component to render the error message, rather than using
            // the alert component
            this.setState({
                confirmingAccount: false,
                hasVerificationCodeError: true,
                error: error.code
            })
        }
    }

    async createAccount() {
        this.setState({
            hasError: false
        })
        const {selectedRole, username, userPassword, userEmail, userMobile,
            userFirstName, userLastName, dateOfBirth, idVerificationRef} = this.state;

        // Sorry about the hard-coding. It seems that when creating a user that is not a
        // doctor, the back-end is expecting a role of OTHER. However, when linking a user
        // to a provider, and the user role of Other is chosen, then the link is created
        // with a role of STAFF. We can't use the USER_ROLE_MAPPING in this conflicting
        // scenario.
        let roleValue = Constants.USER_ROLE_MAPPING[selectedRole]
        if (roleValue !== 'DOCTOR') {
            roleValue = 'OTHER'
        }

        try {
            const response = await Auth.signUp(
                {
                    'username': username,
                    'password': userPassword,
                    'attributes': {
                        'email': userEmail,
                        'phone_number': Utils.internationalisePhoneNumber(userMobile),
                    },
                    'validationData': [
                        {
                            'Name': 'first_name',
                            'Value': userFirstName
                        },
                        {
                            'Name': 'last_name',
                            'Value': userLastName
                        },
                        {
                            'Name': 'birthdate',
                            'Value': dateOfBirth
                        },
                        {
                            'Name': 'role',
                            'Value': roleValue
                        },
                        {
                            'Name': 'id_type',
                            'Value': 'DRIVERS_LICENSE'
                        },
                        {
                            'Name': 'id_verified',
                            'Value': 'true'
                        },
                        {
                            'Name': 'id_verification_ref',
                            'Value': idVerificationRef
                        }
                    ]
                });

            this.setState({
                creatingAccount: false,
                cognitoUser: response.user,
                stepNo: 4,
                cognitoId: response.userSub
            }, () => {
                const {stepNo, gtmSequenceId, cognitoId, selectedRole} = this.state
                const tagParms = {
                    label: TAG_LABEL_STEP_COMPLETE,
                    sequenceId: gtmSequenceId,
                    stepName: TAG_STEP_NAME_CREATE_ACCOUNT,
                    stepValue: (stepNo - 1),
                    cognitoId: cognitoId,
                    usertype: selectedRole
                }
                TagManager.dataLayer(getRegoStepArgs(tagParms))
            });
        }
        catch (error) {
            this.setState({
                creatingAccount: false
            });
            this.handleError(error)
        }
    }

    handleAcceptTermsAndConditions() {
        const {gtmSequenceId} = this.state
        const tagParms = {
            label: TAG_LABEL_STEP_COMPLETE,
            sequenceId: gtmSequenceId,
            stepName: TAG_STEP_NAME_TERMS_CONDITIONS,
            stepValue: 0
        }
        TagManager.dataLayer(getRegoStepArgs(tagParms))

        this.setState({acceptedTermsAndConditions: true});
    }

    handleError(error) {
        if (error && error.message && error.message.includes("NonUniqueMobileError")) {
            this.setState({displayMobileUniquenessError: true});
            return;
        }

        this.setState({
            displayMobileUniquenessError: false,
            hasError: true,
        }, () => {
            Utils.scrollAlertIntoView(this.errorRef)
        });
    }

    componentDidMount() {
        TagManager.dataLayer(getPageLoadArgs('Registration',this.props.location.pathname))
    }

    renderRegistrationSteps() {
        return (
            <div className={styles.stepsWrapper}>
                <div className='container'>
                    <StepsHeaderComponent
                        stepNo={this.state.stepNo}
                    />
                </div>
            </div>
        )
    }

    renderRegoStep() {
        let comp = null;
        let nextEnabled = false;
        switch(this.state.stepNo) {
            case 1:
                comp = <Step1Container />;
                break;

            case 2:
                nextEnabled = this.canStep2Proceed();
                comp = <Step2Container nextEnabled={nextEnabled} />;
                break;

            case 3:
                nextEnabled = this.canStep3Proceed();
                comp = <Step3Container nextEnabled={nextEnabled} />;
                break;

            case 4:
                nextEnabled = this.canStep4Proceed();
                comp = <Step4Container nextEnabled={nextEnabled} />;
                break;

            case 5:
                comp = <Step5Container email={this.state.userEmail}/>;
                break;

            default:
                comp = null;
        }

        return comp;
    }

    renderAlertComponent() {
        return (
            <div className={classnames('container',styles.alertWrapper)}>
                <AlertComponent
                    forwardedRef={this.errorRef}
                    alertTitle={Constants.GENERIC_TECHNICAL_ERROR_TITLE}
                    alertMessage={Constants.GENERIC_TECHNICAL_ERROR_MSG}
                />
            </div>
        )
    }

    renderRegistration = () => {
        if (this.state.acceptedTermsAndConditions) {
            return (
                <React.Fragment>
                    {this.renderRegistrationSteps()}
                    {this.state.hasError && this.renderAlertComponent()}
                    {this.renderRegoStep()}
                </React.Fragment>
            );
        } else {
            return (
                <TermsAndConditionsContainer
                    handleAcceptTermsAndConditions={this.handleAcceptTermsAndConditions}
                />
            );
        }
    }

    render() {
        return (
            <RegistrationContextProvider value={{...this.state}} >
                {this.renderRegistration()}
            </RegistrationContextProvider>
        );
    }
}

export default RegistrationContainer;