import React, { Component } from 'react';
import PasswordInputComponent from 'components/PasswordInputComponent';
import PropTypes from 'prop-types';
import Utils from 'utils/Utils';

const PWD_ERROR_FORMAT = 'Passwords should be 7-40 characters and contain at least 1 lower case letter and 1 number.';
const PWD_ERROR_MATCH = 'Your passwords do not match, please try again.';

class PasswordConfirmContainer extends Component {

    constructor(props) {
        super(props);

        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.onConfirmChange = this.onConfirmChange.bind(this);
        this.handlePwdBlur = this.handlePwdBlur.bind(this);
        this.handleConfirmPwdBlur = this.handleConfirmPwdBlur.bind(this);

        this.state = {
            password: '',
            confirmedPassword: '',
            pwdError: false,
            confirmPwdError: false,
            blurred: {
                password: false,
                confirmedPassword: false
            }
        }
    }

    isPasswordValid(inputValue) {

        let validPwd = inputValue.length > 6 &&
            Utils.containsDigit(inputValue) &&
            Utils.containsLowercase(inputValue);

        return validPwd;
    }

    isConfirmPasswordValid(inputValue) {
        const {password} = this.state;

        let validPwd = this.isPasswordValid(inputValue);

        // Also check if the entered confirm password matches the
        // entered password
        validPwd = validPwd && (inputValue === password);

        return validPwd;

    }

    handlePwdBlur() {
        const {password} = this.state;

        this.setState({
            pwdError: !this.isPasswordValid(password),
            blurred: {
                ...this.state.blurred,
                password: true
            }
        })
    }

    handleConfirmPwdBlur() {
        const {confirmedPassword} = this.state;

        let validPwd = this.isConfirmPasswordValid(confirmedPassword);

        this.setState({
            confirmPwdError: !validPwd,
            blurred: {
                ...this.state.blurred,
                confirmedPassword: true
            }
        })
    }

    onPasswordChange(inputValue) {
        // Prevent entry of spaces
        const trimmedInput = Utils.stripSpaces(inputValue);
        const {blurred} = this.state;

        // Early exit - prevent no more than 40 characters from being entered
        if (trimmedInput.length > 40) {
            return;
        }

        const inputInError = !this.isPasswordValid(trimmedInput);
        // If the user has already visited this field, then its ok to
        // show the error as the field input changes
        const showError = blurred.password && inputInError;

        // If there is anything wrong with the password input, then the userPassword
        // stored in the Registration context must be cleared
        if (inputInError) {
            this.props.setUserPassword('');
        }

        this.setState({
            password: trimmedInput,
            pwdError: showError
        }, () => {
            // If the password changes, then initiate a check on the confirm password
            this.onConfirmChange(this.state.confirmedPassword);
        })
    }

    onConfirmChange(inputValue) {
        // Prevent entry of spaces
        const trimmedInput = Utils.stripSpaces(inputValue);
        const {blurred} = this.state;

        // Early exit - prevent no more than 40 characters from being entered
        if (trimmedInput.length > 40) {
            return;
        }

        const inputInError = !this.isConfirmPasswordValid(trimmedInput);
        // If the user has already visited this field, then its ok to
        // show the error as the field input changes
        const showError = blurred.confirmedPassword && inputInError;

        // If there is anything wrong with the confirm password input, then the userPassword
        // stored in the Registration context must be cleared
        if (inputInError) {
            this.props.setUserPassword('');
        } else {
            // The confirm password has a valid format and matches the entered password, so
            // set the userPassword in the Registration context
            this.props.setUserPassword(trimmedInput);
        }

        this.setState({
            confirmedPassword: trimmedInput,
            confirmPwdError: showError
        })
    }

    render() {
        const {password, confirmedPassword, pwdError, confirmPwdError} = this.state;
        let {disabled, label, confirmLabel, withExplanation} = this.props;

        // Override the withExplanation if the password entered is in error.
        if (pwdError) {
            withExplanation = false;
        }

        return (
            <>
                <PasswordInputComponent
                    label={label}
                    fieldId={'password'}
                    inputValue={password}
                    onChange={this.onPasswordChange}
                    onBlur={this.handlePwdBlur}
                    inError={pwdError}
                    disabled={disabled}
                    errorText={PWD_ERROR_FORMAT}
                    withExplanation={withExplanation}
                />
                <PasswordInputComponent
                    label={confirmLabel}
                    fieldId={'confirm'}
                    inputValue={confirmedPassword}
                    onChange={this.onConfirmChange}
                    onBlur={this.handleConfirmPwdBlur}
                    inError={confirmPwdError}
                    disabled={disabled}
                    errorText={PWD_ERROR_MATCH}
                />
            </>
        )
    }
}

PasswordConfirmContainer.propTypes = {
    setUserPassword: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    confirmLabel: PropTypes.string,
    withExplanation: PropTypes.bool
}

PasswordConfirmContainer.defaultProps = {
    disabled: false,
    withExplanation: false,
    label: 'Password',
    confirmLabel: 'Confirm password'
}

export default PasswordConfirmContainer;