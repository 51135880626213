import React, { Component } from 'react';
import DetailLineItemsResultsListComponent from './DetailLineItemsResultsListComponent'

class DetailLineItemsResultsComponent extends Component {

    render() {
        const {claimLines, claim} = this.props;

        if (claimLines &&
            claimLines.length > 0)
        {
            return <DetailLineItemsResultsListComponent
                forwardedRef={this.props.forwardedRef}
                claimLines={claimLines}
                claim={claim}
            />
        } else {
            let msg = 'No results found.';
            if (!claimLines) {
                msg = '';
            }
            return (
                <div className='text-center'>{msg}</div>
            )
        }
    }
}

export default DetailLineItemsResultsComponent;