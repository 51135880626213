import React, { Component } from 'react';
import MedikitModalComponent from 'components/modal/MedikitModalComponent';
import PropTypes from 'prop-types';

class STEMNoModalComponent extends Component {

    render() {
        const modalContent =
            <>
                <p>A STEM Number is the unique identifier for a Medicare provider and is up to 6 numeric characters in length.
                    Your Provider Numbers for each of your practice locations are created by adding a single digit character, followed by a letter, to your STEM Number.</p>
            </>;

        return <MedikitModalComponent modalId={this.props.modalId}
                               modalTitle='What is a STEM Number?'
                               modalContent={modalContent} />;
    }
}

STEMNoModalComponent.propTypes = {
    modalId: PropTypes.string.isRequired
};

export default STEMNoModalComponent;