import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './DetailLineItemsResultsListComponent.module.scss'
import classnames from 'classnames'

import DetailLineItemsItemComponent from './DetailLineItemsItemComponent';

class DetailLineItemsResultsListComponent extends Component {

    renderLineItems() {
        const {claim} = this.props
        return this.props.claimLines.map((claimLine) => {
            return <DetailLineItemsItemComponent
                key={claimLine.itemId}
                claimLine={claimLine}
                claim={claim}
            />
        });
    }

    render() {
        return (
            <table ref={this.props.forwardedRef} className={classnames('table table-striped',styles.linesTable)}>
                <tbody>
                {this.renderLineItems()}
                </tbody>
            </table>
        )
    }
}

DetailLineItemsResultsListComponent.propTypes = {
    claimLines: PropTypes.instanceOf(Array).isRequired,
    claim: PropTypes.object.isRequired
};

export default DetailLineItemsResultsListComponent;