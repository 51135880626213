import React, { Component } from 'react';
import PropTypes from 'prop-types'
import styles from "./EmailDetailsEditContainer.module.scss";
import classnames from "classnames"
import SpinnerButtonComponent from 'components/spinnerButton/SpinnerButtonComponent'
import {Auth, API, graphqlOperation} from 'aws-amplify'
import {updateUserDetails} from "graphql/mutations"
import UserContext from "context/UserContext"
import AlertModalComponent from "components/modal/AlertModalComponent"
import {sendEmailVerification} from "graphql/mutations"
import EmailComponent from 'components/email/EmailComponent'

class EmailDetailsEditContainer extends Component {

    constructor(props) {
        super(props)

        this.handleEmailInput = this.handleEmailInput.bind(this);
        this.handleEmailSave = this.handleEmailSave.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this)
        this.handleEmailResend = this.handleEmailResend.bind(this)

        this.state = {
            emailValue: '',
            emailIsValid: false,
            updateInProgress: false,
            saveError: false,
            resendRequested: false,
            resendFailed: false,
            showAlertModal: false
        }
    }

    async handleModalClose() {

        // Reload the logged in user to ensure the status of the email_verified
        // attribute for the user is up to date
        try {
            await this.context.actions.reloadLoggedInUser()
            this.props.handleSaveSuccess();
        }
        catch (error) {
            console.log(error)
        }

    }

    async handleEmailResend() {
        this.setState({
            resendRequested: true,
            resendFailed: false
        })

        try {
            const {currentUser} = this.context
            let input = {
                username: currentUser.username
            }

            await API.graphql(graphqlOperation(sendEmailVerification, {
                    input
                }
            ));
        }
        catch (error) {
            this.setState({
                resendFailed: true
            })
        }
    }

    async handleEmailSave() {
        this.setState({
            updateInProgress: true,
            saveError: false
        })

        try {

            let user = await Auth.currentAuthenticatedUser();

            const newEmail = this.state.emailValue

            await Auth.updateUserAttributes(user, {
                'email': newEmail
            });

            let input = {
                email: newEmail
            }

            const response = await API.graphql(graphqlOperation(updateUserDetails, {
                    input
                }
            ));

            this.handleUpdateSuccess(response.data.updateUserDetails);
        }
        catch (error) {
            this.setState({
                updateInProgress: false,
                saveError: true
            })
        }
    }

    handleUpdateSuccess(user) {
        this.context.actions.updateUser(user)

        this.setState({
            updateInProgress: false,
            showAlertModal: true
        })
    }

    handleEmailInput(inputValue, isInError) {
        this.setState({
            emailValue: inputValue,
            emailIsValid: !isInError
        })
    }

    renderInputField() {
        const {updateInProgress} = this.state;

        return <EmailComponent
            onEmailInput={this.handleEmailInput}
            disabled={updateInProgress}
            oldEmailAddress={this.context.currentUser.email}
        />

    }

    renderButtonRow() {
        const {handleCancelClick} = this.props
        const {emailIsValid, updateInProgress} = this.state

        return (
            <div className={classnames('row', styles.buttonRow)}>
                <div className='col-lg-12'>
                    <SpinnerButtonComponent
                        handleClick={this.handleEmailSave}
                        disabled={!emailIsValid}
                        text='Save'
                        className='btn-lg'
                        spinning={updateInProgress}
                    />

                    <button onClick={handleCancelClick}
                            className='btn btn-lg btn-dark-ghost'
                            disabled={updateInProgress}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        )
    }

    renderInfoMessageRow() {
        const {email, username} = this.context.currentUser
        if (email === username) {
            return (
                <div>
                    Please note that your username ({username}) will not change
                    by updating your email address.
                </div>
            )
        }
    }

    renderErrorMessage() {
        const {saveError} = this.state

        if (saveError) {
            return (
                <div data-test-id='errorMessage' className={styles.errorRow}>
                    <span>There was a technical problem encountered whilst trying to update your email address.</span><br/>
                    <span>{saveError.message}</span>
                </div>
            )
        }
    }

    getModalResendLink() {
        const {resendRequested, resendFailed} = this.state;
        let resendLink =
            <>
                <span>Didn't get it?</span>
                <span data-test-id='resendLink' onClick={this.handleEmailResend} className='simple-link-reverse'>Resend email</span>
            </>

        if (resendFailed) {
            resendLink = <span className={styles.resendError} data-test-id='resentError'>There was a problem sending your verification email. Please try again later.</span>
        } else if (resendRequested) {
            resendLink = <span data-test-id='resentMessage'>A new verification email has been sent.</span>
        }
        return (
            <div className={styles.resendWrapper}>
                {resendLink}
            </div>
        )
    }

    getModalContent() {
        const {emailValue} = this.state
        return (
            <div className={styles.modalContent}>
                <p>To verify your new email address, we have sent an email to <strong>{emailValue}</strong>.</p>
                <p>Please click the link in the email to complete the update to your email address. It may take up to 5 minutes for you to receive your email.
                    If you haven't received it yet, please check your junk and spam folder. This link will only be valid for the next 24 hours.</p>
                <p>Please note, if you registered to use your email address as your username to log in, your username won’t be changed to this new email address.</p>
            </div>
        );
    }

    renderAlertModal() {

        if (this.state.showAlertModal) {

            return (
                <AlertModalComponent
                    handleModalClose={this.handleModalClose}
                    buttonText={'Continue to Provider Self Service'}
                    modalTitle={'Confirm your new email'}
                    modalContent={this.getModalContent()} >
                    {this.getModalResendLink()}
                </AlertModalComponent>
            )
        }
    }

    render() {
        return (
            <div className={styles.detailBlock}>
                <div className={styles.detailHeader}>
                    <h4>Email</h4>
                </div>
                <div className='row'>
                    <div className='col-lg-7'>
                        <div className={styles.detailLeft}>
                            {this.renderInputField()}
                            {this.renderInfoMessageRow()}
                            {this.renderButtonRow()}
                            {this.renderErrorMessage()}
                        </div>
                    </div>
                    <div className={classnames('col-lg-5')}>
                        <div className={styles.detailRight}>
                            <span>
                            You will use this email to receive notifications or updates from Provider
                            Self Service<br/><br/>
                            We will send you an email with a verification link to confirm your new email address.
                            </span>
                        </div>
                    </div>
                </div>
                {this.renderAlertModal()}
            </div>
        );
    }
}

EmailDetailsEditContainer.contextType = UserContext;

EmailDetailsEditContainer.propTypes = {
    handleCancelClick: PropTypes.func.isRequired,
    handleSaveSuccess: PropTypes.func.isRequired
}

export default EmailDetailsEditContainer;