import React, { Component } from 'react';
import styles from "./DetailLineItemsHeaderComponent.module.scss"

class DetailLineItemsHeaderComponent extends Component {

    render() {
        return (
            <table className={styles.tableHeader}>
                <tbody>
                <tr>
                    <td className={styles.datesTopCell} colSpan='3' >Date of</td>
                    <td className={styles.providerTopCell}>Service provider</td>
                    <td className={styles.chequeCell} rowSpan='2'>EFT/Cheque<br/>number</td>
                    <td className={styles.serviceCell} rowSpan='2'>Service<br/>charge</td>
                    <td className={styles.mbsItemNoCell} rowSpan='2'>MBS<br/>item #</td>
                    <td className={styles.mbsFeeCell} rowSpan='2'>MBS<br/>fee</td>
                    <td className={styles.itemCell} rowSpan='2'>Medicare<br/>payment</td>
                    <td className={styles.mplCell} rowSpan='2'>MPL benefit<br/>amount</td>
                    <td className={styles.benefitCell} rowSpan='2'>Total item<br/>benefit</td>
                    <td className={styles.statusCell} rowSpan='2'>Status</td>
                </tr>
                <tr>
                    <td className={styles.datesBottomCell}>Service</td>
                    <td className={styles.datesBottomCell}>Payment</td>
                    <td className={styles.datesAssessCell}>Assessment</td>
                    <td className={styles.providerBottomCell}>Name and number</td>
                </tr>
                </tbody>
            </table>
        )
    }
}

export default DetailLineItemsHeaderComponent;