import React, { Component } from 'react';
import RegistrationContext from "context/RegistrationContext";
import VerifyEmailComponent from 'components/verification/VerifyEmailComponent'
import PropTypes from 'prop-types'
import TagManager from "react-gtm-module"
import {getRegoStepArgs, TAG_LABEL_FORM_COMPLETE} from "utils/GTMHelper"

const TAG_STEP_NAME = 'Email verification needed';
const TAG_FIELD_NAME_VERIFY_BUTTON = 'Continute to Login Button';
const TAG_FIELD_NAME_RESEND_EMAIL = 'Resend Email Link';

class Step5Container extends Component {
    constructor(props) {
        super(props);

        this.handleNext = this.handleNext.bind(this);
        this.handleResend = this.handleResend.bind(this);
    }

    handleNext() {
        this.tagFieldChange(TAG_FIELD_NAME_VERIFY_BUTTON, '')

        const {actions} = this.context;
        actions.completeStep5();
    }

    handleResend() {
        this.tagFieldChange(TAG_FIELD_NAME_RESEND_EMAIL, '')

        const {actions} = this.context;
        actions.continueStep5();
    }

    tagFieldChange(fieldName, fieldValue) {
        const {stepNo, gtmSequenceId} = this.context
        const tagParms = {
            label: TAG_LABEL_FORM_COMPLETE,
            sequenceId: gtmSequenceId,
            stepName: TAG_STEP_NAME,
            stepValue: stepNo,
            fieldName: fieldName,
            fieldValue: fieldValue
        }
        TagManager.dataLayer(getRegoStepArgs(tagParms))
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <VerifyEmailComponent
                email={this.props.email}
                nextButtonText='Continue to log in'
                handleNext={this.handleNext}
                handleResendEmail={this.handleResend}
            />
        );
    }
}

Step5Container.contextType = RegistrationContext;

Step5Container.propTypes = {
    email: PropTypes.string.isRequired
}

export default Step5Container;