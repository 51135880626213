import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import styles from './AlertsComponent.module.scss'
import classnames from 'classnames';
import UserContext from "context/UserContext";
import Constants from "utils/Constants";

class AlertsComponent extends Component {
    constructor() {
        super()

        this.state = {
            pendingRequests: []
        }
    }

    async componentDidMount() {
        let {canManageUsers, getRequestsByStatus} = this.context.actions
        if (canManageUsers()) {
            let pendingRequests = await getRequestsByStatus(Constants.PENDING)
            this.setState({pendingRequests})
        }
    }

    render() {
        let {pendingRequests} = this.state
        let plural = pendingRequests.length > 1 ? 's' : ''
        if (pendingRequests.length > 0) {
            return <div className={classnames('row', this.props.extraClasses)}>
                <div className={classnames('col-lg-12', styles.alerts)}>
                    <div className={'row'}>
                        <h4 className={'col-lg-10'}>You have {pendingRequests.length} pending user request{plural}</h4>
                        <p className={classnames('col-lg-2', styles.navLink)}><Link className='simple-link-reverse' to='/myrequests'>View
                            request{plural}</Link></p>
                    </div>
                </div>
            </div>
        }

        return <div></div>
    }
}

AlertsComponent.contextType = UserContext;

export default AlertsComponent;