import React, { Component } from 'react';
import {Redirect} from "react-router-dom";
import styles from './CancelButtonComponent.module.scss';

class CancelButtonComponent extends Component {

    constructor(props) {
        super(props);

        this.handleCancel = this.handleCancel.bind(this);

        this.state = {
            toDashboard: false
        }
    }

    handleCancel() {
        this.setState({
            toDashboard: true
        });
    }

    render() {
        if (this.state.toDashboard) {
            return <Redirect to={"/"} />
        }

        return (
            <button onClick={this.handleCancel} className={styles.cancelButton}>Cancel process</button>
        );
    }
}

export default CancelButtonComponent;