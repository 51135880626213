import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as SearchIcon } from "medikit/dist/base/svgs/icon-search.svg";
import { ReactComponent as CloseIcon } from "medikit/dist/base/svgs/icon-close.svg";
import './ProviderSearchContainer.scss';
import ProviderListComponent from "./ProviderListComponent";
import ProviderMultiSelectSearchModalContainer from './ProviderMultiSelectSearchModalContainer';
import SearchCriteriaContext from "context/SearchCriteriaContext";
import Utils from 'utils/Utils'

class ProviderSearchContainer extends Component {

    constructor(props){
        super(props);

        this.state = {
            filterString: '',
            showSingleSelectList: false,
            showMultiSelectModal: false
        }

        this.inputRef = React.createRef();

        this.handleInputKeyPress = this.handleInputKeyPress.bind(this);
        this.handleSingleProviderSelect = this.handleSingleProviderSelect.bind(this);
        this.handleClearSelection = this.handleClearSelection.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.handleMultiProviderSelect = this.handleMultiProviderSelect.bind(this);
        this.handleMultiProviderClose = this.handleMultiProviderClose.bind(this);
    }

    toggleModal() {
        this.setState({
            showMultiSelectModal: !this.state.showMultiSelectModal
        })
    }

    /***
     * Simulate an on blur effect on the Provider Input field.
     * If the user clicks outside the list of displayed providers,
     * then the list will be closed
     */
    handleOnBlur() {
        this.setState({
            showSingleSelectList: false
        });
    }

    /***
     * When a string is entered in the Provider Number input field, that string
     * is used to filter the list of providers shown.
     * @param event
     */
    handleInputKeyPress(event) {
        let filterValue = event.target.value;
        this.setState({
            filterString: filterValue,
            showSingleSelectList: true
        });
    }

    /***
     * Clear the Provider Number input field when the "X" button is clicked
     * @param event
     */
    handleClearSelection(event) {
        this.context.actions.setSearchProviders([]);
        this.setState({
            filterString: '',
            showSingleSelectList: false
        });
    }

    /***
     * When a Provider is selected from the Provider List, show this provider in the
     * input field and hide the list
     * @param provider
     */
    handleSingleProviderSelect(provider) {
        this.context.actions.setSearchProviders([provider]);
        this.setState({
            showSingleSelectList: false
        });
    }

    handleMultiProviderSelect(providers) {
        this.context.actions.setSearchProviders(providers);
        this.handleMultiProviderClose();
    }

    handleMultiProviderClose() {
        this.setState({
            showMultiSelectModal: false
        });
    }

    /***
     * Renders either a list of providers or a single provider that has been
     * selected from the list
     * @returns {*}
     */
    renderProviders() {
        const {filterString, showSingleSelectList} = this.state;
        const {providers} = this.props;
        const {selectedProviders} = this.context;

        if (showSingleSelectList) {
            return (
                <ProviderListComponent
                    filterString={filterString}
                    providers={providers}
                    handleProviderSelect={this.handleSingleProviderSelect}
                    handleOnBlur={this.handleOnBlur}
                    inputRef={this.inputRef}
                />
            )
        } else if (selectedProviders.length === 1) {
            const providerName = Utils.buildProviderName(selectedProviders[0])
            return (
                <>
                    <CloseIcon className='icon icon-stroke icon-xs clear-btn'
                               onClick={this.handleClearSelection}
                    />
                    <div className='selected-provider-wrapper'>
                        <span>{selectedProviders[0].providerNumber} - <b>{providerName}</b></span>
                    </div>
                </>
            )
        } else if (selectedProviders.length > 1) {
            return (
                <>
                    <div className='selected-multi-provider-wrapper'>
                        <CloseIcon className='icon icon-stroke icon-xs clear-btn'
                                   onClick={this.handleClearSelection}
                        />
                        <span>Multiple providers selected</span>
                    </div>
                </>
            )
        }
    }

    renderMultiSelectModal() {
        const {providers} = this.props;
        const {selectedProviders} = this.context;
        if (this.state.showMultiSelectModal) {
            return <ProviderMultiSelectSearchModalContainer
                providers={providers}
                selectedProviders={selectedProviders}
                handleMultiProviderSelect={this.handleMultiProviderSelect}
                handleModalClose={this.handleMultiProviderClose}
            />
        }
    }

    renderMultiSelectOption() {
        if (this.props.showMultiSelectOption) {
            // Don't provide option to select multiple providers if the user only has access
            // to one provider
            if (this.props.providers.length <= 1) {
                return null
            } else {
                return (
                    <>
                        <div className='simple-link'
                             onClick={this.toggleModal}>
                            Select multiple providers
                        </div>
                        {this.renderMultiSelectModal()}
                    </>
                )
            }
        }
    }

    render() {
        const {filterString} = this.state;
        const {selectedProviders} = this.context;

        let inputValue = filterString;
        let inputDisabled = false;
        if (selectedProviders.length > 0) {
            inputValue = ' ';
            inputDisabled = true;
        }

        return (
            <div className='pp-provider-select'>
                <div className='input-group select input-with-icon-left'>
                    <span className='label' >Provider</span>
                    <input onChange={this.handleInputKeyPress}
                           onFocus={this.handleInputKeyPress}
                           ref={this.inputRef}
                           placeholder='Search by name or provider number'
                           id="providerSelect"
                           disabled={inputDisabled}
                           type={'text'}
                           value={inputValue}
                           className='input-search form-control' />
                    <SearchIcon className='icon icon-stroke' />
                    {this.renderProviders()}
                </div>
                {this.renderMultiSelectOption()}
            </div>
        )
    }
}

ProviderSearchContainer.contextType = SearchCriteriaContext;

ProviderSearchContainer.propTypes = {
    providers: PropTypes.instanceOf(Array).isRequired,
    showMultiSelectOption: PropTypes.bool
};

ProviderSearchContainer.defaultProps = {
    showMultiSelectOption: true
}

export default ProviderSearchContainer;