import React, { Component } from 'react';
import classnames from "classnames";
import styles from './MedikitModalComponent.module.scss';
import PropTypes from 'prop-types';

/***
 * This component relies heavily on the Modal js component that is part of Medikit.
 * Use this component if you are building functionality like a popup tooltip.
 * See AddProviderSearchResultsStep for an example of how this component can be
 * used.
 */
class MedikitModalComponent extends Component {

    render() {
        const {modalId, modalTitle, modalContent} = this.props;

        return (
            <div className={classnames("modal", styles.content)} id={modalId} tabIndex="-1" role="dialog"
                 aria-labelledby="tooltipModal" aria-hidden="true" data-modal="">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <button type="button" className="modal-dismiss" aria-label="Close" data-modal-dismiss="">
                            <svg className="icon icon-stroke" xmlns="http://www.w3.org/2000/svg" width="60"
                                 height="60" viewBox="0 0 60 60">
                                <path className="currentColor" vectorEffect="non-scaling-stroke"
                                      d="M47.5 12.9l-35 35M12.5 12.9l35 35"></path>
                            </svg>
                        </button>
                        <div className="modal-body">
                            <h3 id="tooltipModalLabel">{modalTitle}</h3>
                            {modalContent}
                            <div className="btn-container">
                                <button data-modal-dismiss="" className={classnames("btn btn-primary", styles.modalClose)}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-overlay-dismiss" data-modal-dismiss=""></div>
            </div>

        );
    }
}

MedikitModalComponent.propTypes = {
    modalId: PropTypes.string.isRequired,
    modalTitle: PropTypes.string.isRequired,
    modalContent: PropTypes.object.isRequired
};

export default MedikitModalComponent;