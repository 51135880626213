import React, { Component } from 'react';
import classnames from "classnames";
import UserContext from "../../context/UserContext";
import styles from './STEMSearchResultsStep.module.scss';
import {Redirect} from "react-router-dom";
import SpinnerButtonComponent from 'components/spinnerButton/SpinnerButtonComponent'
import CancelButtonComponent from "./CancelButtonComponent";
import WhatAreRolesModal from "components/providerSetup/WhatAreRolesModal";
import TooltipIcon from "components/TooltipIcon";

class STEMSearchResultsStep extends Component {

    constructor(props) {
        super(props);

        this.handleCancel = this.handleCancel.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)

        this.state = {
            toDashboard: false
        }
    }

    handleCancel() {
        this.setState({toDashboard: true})
    }

    async handleSubmit() {
       this.props.handleSubmit()
    }

    render() {
        let {provider} = this.props
        if (!provider.locations) provider.locations = []
        if (this.state.toDashboard) {
            return <Redirect to={"/"} />
        }

        return (
            <div className={styles.resultsWrapper}>
                <div className="container">
                    <div className={classnames('row justify-content-between')}>
                        <div className={classnames('col-lg-10', styles.header)}>
                            <h3>Review your provider details</h3>
                            <p>
                                Please take a moment to review the details below and check that these are correct.
                                Not seeing the right details?<br/>
                                Please check your STEM and AHPRA number are correct.
                            </p>
                        </div>
                        <div className={classnames('col-lg-2', styles.header, styles.cancel)}>
                            <CancelButtonComponent/>
                        </div>
                    </div>
                    <div className={classnames('row', styles.contentWrapper)}>
                        <div className={classnames('col-lg-12', styles.contentBox)}>
                            <div className={classnames('row')}>
                                <div className={classnames('col-lg-6', styles.providerName)}>
                                    <p>Provider name</p>
                                    <h4>{provider.firstname} {provider.lastname}</h4>
                                </div>
                            </div>
                            <div className={'row'}>
                                <div className={'col-lg-3'}>
                                    <h5>Specialty</h5>
                                    <p>{provider.specialty}</p>
                                </div>
                                <div className={'col-lg-2'}>
                                    <h5>STEM Number</h5>
                                    <p>{provider.stem}</p>
                                </div>
                                <div className={'col-lg-2'}>
                                    <h5>AHPRA Number</h5>
                                    <p>{provider.ahpra}</p>
                                </div>
                            </div>
                        </div>
                        <div className={classnames('col-lg-12', styles.tableHeader)}>
                            <div className={classnames('row')}>
                                <div className={'col-lg-2'}>
                                    <span>Provider number</span>
                                </div>
                                <div className={'col-lg-6'}>
                                    <span>Address/Addresses</span>
                                </div>
                                <div className={'col-lg-4'}>
                                    <span>
                                        Your role
                                        <span className='pointer'>
                                            <TooltipIcon  showModal={true}>
                                                <WhatAreRolesModal/>
                                            </TooltipIcon>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        {provider.locations.map(location => {
                            return (
                                <div key={location.providerNumber} className={classnames('col-lg-12', styles.contentBox, styles.locationsList)}>
                                    <div>
                                        <div  className={classnames('row', styles.locationWrapper)}>
                                            <div className={'col-lg-2'}>
                                                <p>{location.providerNumber}</p>
                                            </div>
                                            <div className={'col-lg-6'}>
                                                <p><b>{location.practiceName}</b><br/>{location.address}</p>
                                            </div>
                                            <div className={'col-lg-4'}>
                                                <p>Doctor (Admin)</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                        <SpinnerButtonComponent className={styles.button} disabled={this.props.disabled} handleClick={this.handleSubmit} spinning={this.props.submitting} text={'Add all'}/>
                    </div>
                </div>
            </div>
        );
    }
}

STEMSearchResultsStep.contextType = UserContext;

export default STEMSearchResultsStep;