import React, { Component } from 'react';
import classnames from "classnames";
import UserContext from "../../context/UserContext";
import styles from './AddProviderSearchResultsStep.module.scss';
import { Redirect } from "react-router-dom";
import SpinnerButtonComponent from 'components/spinnerButton/SpinnerButtonComponent'
import CancelButtonComponent from "./CancelButtonComponent";
import { linkProviders } from "../../graphql/mutations"
import { API, graphqlOperation } from 'aws-amplify'
import Constants from "utils/Constants";
import WhatAreRolesModal from "./WhatAreRolesModal";
import TooltipIcon from 'components/TooltipIcon'
import AlertComponent from 'components/AlertComponent'
import TagManager from "react-gtm-module";
import { getManageProvidersArgs } from "utils/GTMHelper";

class AddProviderSearchResultsStep extends Component {

    constructor(props) {
        super(props);

        this.handleCancel = this.handleCancel.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleRoleChange = this.handleRoleChange.bind(this)
        this.handleChecked = this.handleChecked.bind(this)

        this.state = {
            disabled: true,
            submitting: false,
            toManageProviders: false
        }
    }

    validate() {
        let { providerLocations } = this.props
        let valid = true
        for (let index in providerLocations) {
            let loc = providerLocations[index]
            if (loc.newLink === undefined) {
                //Set a default state
                loc.newLink = true
            }
            if (loc.newLink && loc.role === undefined) {
                valid = false
                break
            }
        }
        if (providerLocations.filter(loc => loc.newLink).length < 1) {
            valid = false
        }

        this.setState({
            disabled: !valid
        });
    }

    handleCancel() {
        this.setState({ toDashboard: true })
    }

    async handleSubmit() {
        let providersAndRoles = this.props.providerLocations.map(loc => {
            return { role: loc.role, providerNumber: loc.providerNumber }
        })
        let input = {
            links: providersAndRoles
        }
        this.handleSubmitProviderNumber(input)
    }

    async handleSubmitProviderNumber(input) {
        this.setState({ submitting: true }, async () => {
            try {
                let success = await API.graphql(graphqlOperation(linkProviders, { input: input }));
                if (success.data.linkProviders.result) {
                    const tagParms = {
                        label: 'Form Complete',
                        sequenceId: this.props.gtmSequenceId
                    }

                    TagManager.dataLayer(getManageProvidersArgs(tagParms))

                    await this.context.actions.refreshUser()
                    this.setState({ toManageProviders: true, submitting: false });
                }
            }
            catch (error) {
                console.log(error)
                this.setState({ inError: true, submitting: false, errorCode: error.errors[0].errorType, errorMessage: error.errors[0].message })
            }
        })
    }

    isProviderCurrentlyRegisteredForPortal() {
        return !(this.props.providerLocations &&
            this.props.providerLocations.length > 0 &&
            !this.props.providerLocations[0].foundInPortal);
    }

    renderAlertComponent() {
        if (!this.isProviderCurrentlyRegisteredForPortal()) {
            const alertMessage = 'Your access request cannot be completed until the doctor is registered with Provider Self Service. You need to ask the doctor to register with Provider Self Service at https://providers.medibank.com.au/registration.'
            const alertTitle = 'This provider is not registered on Provider Self Service'
            return (
                <div className='row'>
                    <div className={styles.alertWrapper}>
                        <AlertComponent
                            alertInfo={true}
                            alertTitle={alertTitle}
                            alertMessage={alertMessage}
                        />
                    </div>
                </div>
            )
        }
    }

    renderDoctorRegsitrationMessage() {
        if (!this.isProviderCurrentlyRegisteredForPortal()) {
            return (
                <React.Fragment>
                    <p><b>If you are a practice manager, download and fill out the <a href="/Provider-Self-Service-Authorisation-Form.pdf" download="Provider-Self-Service-Authorisation-Form.pdf">editable PDF form</a> for the doctor you wish to register.</b></p>
                    <p>Please click the 'Submit' button, below, to submit your request. Your request will be processed when the doctor registers with Provider Self Service, and accepts or rejects your request.</p>
                </React.Fragment>
            )
        } else {
            return undefined;
        }
    }

    renderFormFooter() {
        return (
            <div className={'col-lg-12  no-gutters'}>
                <div className={'row'}>
                </div>
                <div className={classnames('row', styles.bottomPadding)}>
                    <div className="col-lg-12">
                        {this.renderDoctorRegsitrationMessage()}
                        <SpinnerButtonComponent
                            className={styles.button}
                            disabled={this.state.disabled}
                            handleClick={this.handleSubmit}
                            spinning={this.state.submitting}
                            text={'Submit'}
                        />
                    </div>
                    <div data-test-id='error' className={classnames('input-helper form-error small')}>
                        {this.state.errorMessage ? this.state.errorMessage : <span>&nbsp;</span>}
                    </div>
                </div>
            </div>
        );
    }

    handleRoleChange(event) {
        let { providerLocations } = this.props
        let select = event.target.options[event.target.selectedIndex]
        let location = providerLocations[event.target.value]

        if (event.target.selectedIndex > 0) {
            let role = select.text
            location.role = Constants.USER_ROLE_MAPPING[role]
        } else {
            location.role = undefined
        }
        this.validate()
    }

    handleChecked(event) {
        let { providerLocations } = this.props
        let checked = event.target.checked
        let location = providerLocations[event.target.value]
        location.newLink = checked
        this.validate()
    }

    render() {
        let { providerLocations } = this.props
        if (this.state.toDashboard) {
            return <Redirect to={"/"} />
        } else if (this.state.toManageProviders) {
            return <Redirect to={"/myproviders"} />
        }

        let providerName = providerLocations && providerLocations.length > 0 ? providerLocations[0].firstname + ' ' + providerLocations[0].lastname : ''
        return (
            <div>
                <div className="container">
                    <div className={classnames('row justify-content-between')}>
                        <div className={classnames('col-lg-8', styles.header)}>
                            <h3>Request access to other provider data</h3>
                            <p>Select the providers that you would like access to.</p>
                        </div>
                        <div className={classnames(styles.header)}>
                            <CancelButtonComponent />
                        </div>
                    </div>
                    {this.renderAlertComponent()}
                    <div className={classnames('row')}>
                        <div className={classnames('col-lg-12', styles.contentWrapper)} >
                            <div className={classnames(styles.contentBox)}>
                                <div className={styles.providerName}>
                                    <p>Provider name</p>
                                    <h4>{providerName}</h4>
                                </div>
                            </div>
                            <div className='container'>
                                <div className={classnames('row', styles.contentBox, styles.listHeader)}>
                                    <div className={'col-lg-2'}>
                                        Provider number
                                    </div>
                                    <div className={'col-lg-6'}>
                                        Address/Addresses
                                    </div>
                                    <div className={'col-lg-4'}>
                                        <span>
                                            Your role
                                            <span className='pointer'>
                                                <TooltipIcon showModal={true}>
                                                    <WhatAreRolesModal />
                                                </TooltipIcon>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className={classnames('row no-gutters')}>
                                    <hr className={classnames(styles.hr)} />
                                </div>
                                {providerLocations.map((location, index) => {
                                    return <div key={location.providerNumber}>
                                        <div className={classnames('row', styles.contentBox)}>
                                            <div className={'col-lg-2'}>
                                                <div className="input-group">
                                                    <p><label className="input-checkbox">
                                                        {location.providerNumber}
                                                        <input type="checkbox" defaultChecked={true} value={index} onChange={this.handleChecked} />
                                                        <span className="input-indicator needsclick"></span>
                                                    </label>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className={'col-lg-6'}>
                                                <b>{location.practiceName}</b><br />{location.address}
                                            </div>
                                            <div className={'col-lg-4'}>
                                                <div className="input-group select">
                                                    <select id="roleSelect" onChange={this.handleRoleChange}>
                                                        <option value={index}>Select role</option>
                                                        <option value={index}>Other</option>
                                                    </select>
                                                    <span className="select-chevron"></span></div>
                                            </div>
                                        </div>
                                        <div className={classnames('row no-gutters')}>
                                            <hr className={styles.hr} />
                                        </div>
                                    </div>

                                })}
                                <div className={classnames('row', styles.contentBox)}>
                                    {this.renderFormFooter()}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

AddProviderSearchResultsStep.contextType = UserContext;

export default AddProviderSearchResultsStep;