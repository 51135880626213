import React, { Component } from 'react';
import SearchResultsListComponent from './SearchResultsListComponent';
import classnames from 'classnames';
import styles from './SearchResultsComponent.module.scss';

class SearchResultsComponent extends Component {

    renderRemittanceRows() {
        const {remittances} = this.props;
        if (remittances &&
            remittances.length > 0)
        {
            return <SearchResultsListComponent remittances={remittances}/>
        } else {
            let msg = 'Sorry, no matching records have been found.';
            if (!remittances) {
                msg = '';
            }
            return (
                <tr>
                    <td className={styles.emptyRowStyles} colSpan="5" >
                        <div className='text-center'>{msg}</div>
                    </td>
                </tr>
            )
        }
    }

    render() {
        return (
            <div className='row'>
                <div className='col-lg-12'>
                    <table className={classnames('table table-striped table-responsive lifted-table', styles.resultsWrapper)}>
                        <thead>
                        <tr>
                            <th className={styles.th1}>Date of issue</th>
                            <th className={styles.th2}>Provider number</th>
                            <th className={styles.th3}>Provider details</th>
                            <th className={styles.th4}>EFT ref number</th>
                            <th className={styles.th5}>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.renderRemittanceRows()}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default SearchResultsComponent;