import React, { Component } from 'react';
import styles from './PersonalDetailsComponent.module.scss';
import PropTypes from 'prop-types';

class PersonalDetailsComponent extends Component {

    render() {
        const {firstName, lastName, username} = this.props

        return (
            <div className={styles.detailBlock}>
                <h4>Personal Details</h4>
                <div className={styles.detailRow}>
                    <div className={styles.detailItem}>
                        <div><h5>Name</h5></div>
                        <div>{firstName}&nbsp;{lastName}</div>
                    </div>
                    <div className={styles.detailItem}>
                        <div><h5>Username</h5></div>
                        <div>{username}</div>
                    </div>
                </div>
            </div>
        );
    }
}

PersonalDetailsComponent.propTypes = {
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired

}

export default PersonalDetailsComponent;