import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SearchResultsItemContainer from './SearchResultsItemContainer';

class SearchResultsListComponent extends Component {

    render() {

        let i = 0
        return this.props.remittances.map((remittance) => {
            i += 1
            return <SearchResultsItemContainer
                key={remittance.id}
                remittance={remittance}
                sequenceNo={i}
            />
        });
    }
}

SearchResultsListComponent.propTypes = {
    remittances: PropTypes.instanceOf(Array).isRequired
};

export default SearchResultsListComponent;