import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './SearchResultsItemComponent.module.scss'
import classnames from 'classnames'
import { ReactComponent as DateIcon } from "medikit/dist/base/svgs/icon-calendar.svg"
import { ReactComponent as HourglassIcon } from "medikit/dist/base/svgs/icon-buy-early.svg"
import Utils from 'utils/Utils'

class SearchResultsItemComponent extends Component {

    renderTopRow() {
        const {claim} = this.props;
        return  (
            <div className={classnames('row',styles.topRow)}>
                <div className={classnames('col-lg-3', styles.left)}>
                    <span className={classnames('font-feature', styles.lightBold)}>Claim number&nbsp;</span>
                    <span className={classnames('font-feature')}>{claim.claimNo}</span>
                </div>
                <div className={classnames('col-lg-3 offset-lg-6', styles.right)}>
                    <DateIcon className={classnames('icon icon-stroke', styles.dateIcon)}/>
                    <span className={classnames('font-feature', styles.lightBold)}>Date lodged&nbsp;</span>
                    <span className={classnames('font-feature')}>{Utils.formatEpochToDate(claim.dateLodged, "")}</span>
                </div>
            </div>
        )
    }

    renderPatientTitleRow() {
        return  (
            <div className={classnames('row',styles.titleRow)}>
                <div className={classnames('col-lg-3')}>
                    <b>Patient name</b>
                </div>
                <div className={classnames('col-lg-3')}>
                    <b>Payee provider</b>
                </div>
                <div className={classnames('col-lg-3')}>
                    <b>Total charge amount</b>
                </div>
                <div className={classnames('col-lg-3')}>
                    <b>Status</b>
                </div>
            </div>
        )
    }

    renderMainDetailRow() {
        const {claim} = this.props;
        const firstname = Utils.capitaliseName(claim.patient.firstname)
        const lastname = Utils.capitaliseName(claim.patient.lastname)
        return  (
            <div className={classnames('row',styles.detailRow)}>
                <div className={classnames('col-lg-3')}>
                    <span className={classnames('font-feature', styles.lightBold)}>{firstname} {lastname}</span>
                </div>
                <div className={classnames('col-lg-3')}>
                    <span className={classnames('font-feature', styles.lightBold)}>{claim.provider.name}</span>
                </div>
                <div className={classnames('col-lg-3')}>
                    <span className={classnames('font-feature', styles.lightBold)}>${claim.chargeAmount}</span>
                </div>
                <div className={classnames('col-lg-3')}>
                    {this.renderClaimStatus()}
                </div>
            </div>
        )
    }

    renderClaimStatus() {
        const {claim, handleViewDetails} = this.props;
        let statusComp = null
        const translatedStatus = Utils.translateClaimStatus(claim.status)

        if (claim.status.toLowerCase() === 'p') {
            statusComp =
                <>
                    <HourglassIcon className={classnames('icon icon-stroke', styles.pendingIcon)}/>
                    <span className={classnames('font-feature', styles.statusPending)}>{translatedStatus}</span>
                </>

        } else if (claim.status.toLowerCase() === 'm') {
            statusComp =
                <>
                    <span data-test-id='statusLink' onClick={handleViewDetails} className={classnames('font-feature', styles.statusLink)}>{translatedStatus}</span>
                </>
        } else {
            statusComp =
                <>
                    <span data-test-id='statusLink' onClick={handleViewDetails} className={classnames('font-feature', styles.statusLink)}>{translatedStatus}</span>
                </>
        }
        return statusComp;
    }

    renderSubDetailRow() {
        const {claim} = this.props;
        return  (
            <div className={classnames('row',styles.subDetailRow)}>
                <div className={classnames('col-lg-3')}>
                    <span className={styles.lightGrey}>(Membership:&nbsp;{claim.patient.membershipNo})</span>
                </div>
                <div className={classnames('col-lg-3')}>
                    <span className={styles.lightGrey}>(Provider number:&nbsp;{claim.provider.number})</span>
                </div>
            </div>
        )
    }

    renderInvoiceTitleRow() {
        const {claim} = this.props;
        if (claim.referenceNo) {
            return  (
                <div className={classnames('row',styles.titleRow)}>
                    <div className={classnames('col-lg-3')}>
                        <b>Invoice/Account ref number</b>
                    </div>
                </div>
            )
        }
    }

    renderInvoiceDetailRow() {
        const {claim} = this.props;
        if (claim.referenceNo) {
            return  (
                <div className={classnames('row',styles.eclipseRow)}>
                    <div className={classnames('col-lg-3')}>
                        <span className={classnames('font-feature', styles.lightBold)}>{claim.referenceNo}</span>
                    </div>
                </div>
            )
        }
    }

    renderBenefitRow() {
        const {claim, handleViewDetails} = this.props;
        if (claim.status.toLowerCase() === 'p') {
            return (
                <div className={classnames('row',styles.benefitRow)}>
                    <div className={classnames('col-lg-3 offset-lg-6')}>
                        <span className={classnames('font-feature',styles.benefitTitle)}>Total benefit amount</span>
                    </div>
                    <div className={classnames('font-feature col-lg-3',styles.benefitTitle)}>
                        <span>TBA</span>
                    </div>
                </div>
            )
        } else {
            return  (
                <div className={classnames('row',styles.benefitRow)}>
                    <div className={classnames('col-lg-2 offset-lg-6', styles.left)}>
                        <span className={classnames('font-feature',styles.benefitTitle)}>Total benefit amount</span>
                    </div>
                    <div className={classnames('col-lg-4', styles.right)}>
                        <span className={styles.dollarSign}>$</span>
                        <span className={classnames('font-feature', styles.largeNumbers)}>{claim.benefitAmount}</span>
                        <button onClick={handleViewDetails} data-test-id='seeDetails' className={classnames("btn btn-dark-ghost", styles.detailsBtn)}>See details</button>
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <>
                {this.renderTopRow()}
                {this.renderPatientTitleRow()}
                {this.renderMainDetailRow()}
                {this.renderSubDetailRow()}
                {this.renderInvoiceTitleRow()}
                {this.renderInvoiceDetailRow()}
                {this.renderBenefitRow()}
            </>
        )
    }
}

SearchResultsItemComponent.propTypes = {
    claim: PropTypes.instanceOf(Object).isRequired,
    handleViewDetails: PropTypes.func.isRequired
};

export default SearchResultsItemComponent;