import React, { Component } from 'react';
import styles from './StepsHeaderComponent.module.scss';
import classnames from 'classnames';
import PropTypes from 'prop-types';

class StepsHeaderComponent extends Component {

    render() {
        const {stepNo} = this.props;
        return (
            <div className={styles.stepsContainer} >
                <div className={classnames(styles.step)} >
                    <span className={classnames('progress-path-count' , styles.stepCount)}>1</span>
                    <span className={styles.stepText}>Select role</span>
                </div>
                <div className={styles.separator}><hr/></div>
                <div className={classnames(styles.step, {[styles.disabled] : (stepNo < 2)})}>
                    <span className={classnames('progress-path-count' , styles.stepCount)}>2</span>
                    <span className={styles.stepText}>ID verification</span>
                </div>
                <div className={styles.separator}><hr/></div>
                <div className={classnames(styles.step, {[styles.disabled] : (stepNo < 3)})}>
                    <span className={classnames('progress-path-count' , styles.stepCount)}>3</span>
                    <span className={styles.stepText}>Create account</span>
                </div>
                <div className={styles.separator}><hr/></div>
                <div className={classnames(styles.step, {[styles.disabled] : (stepNo < 4)})}>
                    <span className={classnames('progress-path-count' , styles.stepCount)}>4</span>
                    <span className={styles.stepText}>Verify mobile</span>
                </div>
                <div className={styles.separator}><hr/></div>
                <div className={classnames(styles.step, {[styles.disabled] : (stepNo < 5)})}>
                    <span className={classnames('progress-path-count' , styles.stepCount)}>5</span>
                    <span className={styles.stepText}>Verify email</span>
                </div>
            </div>
        );
    }
}

StepsHeaderComponent.propTypes = {
    stepNo: PropTypes.number
}

StepsHeaderComponent.default = {
    stepNo: 1
}

export default StepsHeaderComponent;