import React, {Component} from 'react';
import classnames from 'classnames';
import {Popover, PopoverBody} from 'reactstrap'
import {Modal} from "medikit";


class DialogModalComponent extends Component {
    constructor(props) {
        super(props)
        this.toggle = this.toggle.bind(this)

        this.state = {
            popoverOpen: false,
            modalCreated: false,
        }
    }

    componentDidMount() {
        try {
            if (!this.state.modalCreated && this.props.showModal) {
                new Modal(this.refs.DrRegisterModal);
                this.setState({modalCreated: true});
            }
        } catch (e) {
            console.error(e);
        }
    }

    toggle() {
        if (!this.props.showModal) {
            this.setState({
                popoverOpen: !this.state.popoverOpen
            });
        }
    }


    renderPopover() {
        if (!this.props.showModal) {
            return <Popover placement="top"
                            isOpen={this.state.popoverOpen}
                            target="addDoctor"
                            hideArrow={false}
            >
                <PopoverBody>
                    {this.props.children}
                </PopoverBody>
            </Popover>
        } else {
            return <>{this.props.children}</>
        }
    }

    render() {
        return <>
            <button
                className={classnames('mx-auto btn btn-primary')}
                color="primary"
                ref='DrRegisterModal'
                data-modal-open=""
                data-modal-target="#DrRegisterModal"
                id='addDoctor'
            >
                    {this.props.buttonText}
            </button>
            
            {this.renderPopover()}
        </>
    }
}

DialogModalComponent.defaultProps = {
    showModal: false,
    buttonText : 'Add a Doctor'
};

export default DialogModalComponent


