import React, { Component } from 'react';
import {SearchCriteriaContextProvider} from 'context/SearchCriteriaContext';
import SearchCriteriaComponent from 'components/remittance/SearchCriteriaComponent';
import SearchResultsComponent from "components/remittance/SearchResultsComponent";
import SearchResultsSorterComponent from 'components/remittance/SearchResultsSorterComponent';
import OverlaySpinner from 'components/spinner/OverlaySpinner';
import { API, graphqlOperation } from 'aws-amplify';
import {searchRemittances} from 'graphql/queries';
import styles from './RemittancesContainer.module.scss';
import Constants from 'utils/Constants';
import PaginationLinksComponent from "components/PaginationLinksComponent";
import AlertComponent from "components/AlertComponent";
import Utils from 'utils/Utils';
import UserContext from "context/UserContext";
import {getPageLoadArgs, getRemittanceSearchArgs, getGTMSequenceId} from "utils/GTMHelper"
import TagManager from "react-gtm-module"

const SEARCH_BY_PROVIDER = 'SEARCH_BY_PROVIDER';
const SEARCH_BY_EFT = 'SEARCH_BY_EFT';

export class RemittancesContainer extends Component {

    constructor(props) {
        super(props);

        this.setSearchProviders = this.setSearchProviders.bind(this);
        this.setFromDate = this.setFromDate.bind(this);
        this.setToDate = this.setToDate.bind(this);
        this.setEftRef = this.setEftRef.bind(this);
        this.setSearchDuration = this.setSearchDuration.bind(this);
        this.setSortOrder = this.setSortOrder.bind(this);
        this.fetchRemittances = this.fetchRemittances.bind(this);
        this.onPageClick = this.onPageClick.bind(this);
        this.handleError = this.handleError.bind(this);
        this.tagDocumentDownload = this.tagDocumentDownload.bind(this)

        const defaultToDate = new Date();
        const defaultFromDate = Utils.subtractDaysFromDate(defaultToDate, '30');

        this.errorRef = React.createRef();

        this.state = {
            searchOption: SEARCH_BY_PROVIDER,
            searchDateByDuration: true,
            searchFromDate: defaultFromDate,
            searchToDate: defaultToDate,
            searchEftRef: null,
            selectedProviders: [],
            sortOrder: 'd-d',
            isLoading: false,
            hasError: false,
            fetchedRemittances: null,
            remittanceCount: null,
            searchResultsPage: 1,
            // The first time this search screen is shown, before any search has been run,
            // the search results table header should not be shown. Once a search has been
            // run, then the header can be shown and left in place for any additional
            // searches
            showSearchResults: false,
            gtmSequenceId: '',
            actions: {
                setSearchProviders: this.setSearchProviders,
                setFromDate: this.setFromDate,
                setToDate: this.setToDate,
                setEftRef: this.setEftRef,
                setSearchDuration: this.setSearchDuration,
                handleError: this.handleError,
                tagDocumentDownload: this.tagDocumentDownload
            }
        }
    }

    setFromDate(aDate) {
        this.setState({
            searchFromDate: aDate,
            searchDateByDuration: false
        })
    }

    setToDate(aDate) {
        this.setState({
            searchToDate: aDate,
            searchDateByDuration: false
        })
    }

    setSearchProviders(providers) {
        this.setState({
            selectedProviders: providers,
            searchOption: SEARCH_BY_PROVIDER
        });
    }

    setEftRef(eftRef) {
        this.setState({
            searchEftRef: eftRef,
            searchOption: SEARCH_BY_EFT
        })
    }

    setSortOrder(option) {
        this.setState({
            sortOrder: option,
            searchResultsPage: 1
        }, () => {
            if (this.canSearchBeRun()) {
                this.fetchRemittances(false);
            }
        });
    }

    setSearchDuration(duration) {
        const toDate = new Date();
        const fromDate = Utils.subtractDaysFromDate(toDate, duration);
        this.setState({
            searchFromDate: fromDate,
            searchToDate: toDate,
            searchDateByDuration: true
        })
    }

    onPageClick(pageNo) {
        if (this.canSearchBeRun()) {
            const {cognitoId, role} = this.context.currentUser
            TagManager.dataLayer(getPageLoadArgs('Remittance advice - pagination',
                this.props.location.pathname + '?page=' + pageNo, cognitoId, role))

            this.setState({
                searchResultsPage: parseInt(pageNo)
            }, () => {
                this.fetchRemittances(false);
            });
        }
    }

    handleError() {
        this.setState({
            hasError: true
        }, () => {
            Utils.scrollAlertIntoView(this.errorRef)
        });
    }

    /***
     * Determine if search button can be enabled if the mandatory search criteria has been
     * selected
     */
    canSearchBeRun() {
        let goodToGo = false;

        if (this.state.searchOption === SEARCH_BY_PROVIDER &&
            this.state.searchFromDate &&
            this.state.searchToDate &&
            this.state.selectedProviders.length > 0 &&
            this.state.searchFromDate <= this.state.searchToDate)
        {
            goodToGo = true;
        } else if (this.state.searchOption === SEARCH_BY_EFT &&
            this.state.searchEftRef &&
            this.state.searchEftRef.length > 0)
        {
            goodToGo = true;
        }
        return goodToGo;

    }

    tagRemittanceSearch(documentId, documentSequenceNo) {
        const {gtmSequenceId, searchOption, searchEftRef, remittanceCount,
            selectedProviders, searchFromDate, searchToDate, searchDateByDuration} = this.state
        const {username} = this.context.currentUser

        let gtmSearchByValue
        let gtmSearchByType = 'Provider'
        let gtmPeriodType = null
        let gtmPeriodValue = null
        const gtmResultsCount = remittanceCount

        if (searchOption === SEARCH_BY_PROVIDER) {
            if (selectedProviders.length > 1) {
                gtmSearchByValue = 'Multiple Providers'
            } else {
                gtmSearchByValue = selectedProviders[0].providerNumber
            }
            const fromDate = Utils.formatDate(searchFromDate);
            const toDate = Utils.formatDate(searchToDate);

            gtmPeriodType = searchDateByDuration ? 'Duration' : 'Choose Dates'
            gtmPeriodValue = fromDate + '-' + toDate
        } else {
            gtmSearchByType = 'EFT'
            gtmSearchByValue = searchEftRef
        }

        const tagData = getRemittanceSearchArgs(
            gtmSequenceId, gtmSearchByType, gtmSearchByValue,
            gtmPeriodType, gtmPeriodValue, gtmResultsCount,
            username, documentId, documentSequenceNo
        )
        TagManager.dataLayer(tagData)
    }

    tagDocumentDownload(documentId, documentSequenceNo) {
        this.tagRemittanceSearch(documentId, documentSequenceNo)
    }

    finaliseSearch(response) {

        this.setState({
            fetchedRemittances: response.data.searchRemittances.remittances,
            remittanceCount: response.data.searchRemittances.size,
            isLoading: false,
            showSearchResults: true
        }, () => {
            this.tagRemittanceSearch()
        });
    }

    finaliseSearchError() {
        this.setState({
            fetchedRemittances: null,
            remittanceCount: 0,
            isLoading: false,
            hasError: true
        }, () => {
            Utils.scrollAlertIntoView(this.errorRef);
        });
    }

    /***
     * Fetch Remittances
     * @param clearPastSearch When a new search is started, the results from
     * any previous search should be cleared. If, however, the new search is
     * as a result of a sort order change or a page change, then the previous
     * search results are left in place and replaced when the new search
     * completes.
     */
    fetchRemittances(clearPastSearch=true) {
        let fetchedRemittances = this.state.fetchedRemittances;
        let currentPageNo = this.state.searchResultsPage;
        let gtmSequenceId = this.state.gtmSequenceId

        if (clearPastSearch) {
            fetchedRemittances = null;
            currentPageNo = 1;
            gtmSequenceId = getGTMSequenceId()
        }

        this.setState({
            isLoading: true,
            hasError: false,
            fetchedRemittances: fetchedRemittances,
            searchResultsPage: currentPageNo,
            remittanceCount: null,
            gtmSequenceId: gtmSequenceId
        }, () => {
            if (this.state.searchOption === SEARCH_BY_PROVIDER) {
                this.fetchRemittancesByProvider();
            } else {
                this.fetchRemittancesByEFT();
            }
        });
    }

    async fetchRemittancesByEFT() {
        const eftRef = this.state.searchEftRef;

        try {
            const response = await API.graphql(graphqlOperation(searchRemittances,
                {
                    filter: {
                        userId: this.context.currentUser.id,
                        providerIds: [],
                        dateFrom: null,
                        dateTo: null,
                        eft: eftRef,
                        allProviders: false
                    }
                }));

            this.finaliseSearch(response);
        }
        catch (error) {
            this.finaliseSearchError();
        }

    }

    determineSortOrder() {
        let sortOrder = {
            criteria: null,
            order: null
        };
        this.state.sortOrder.startsWith('n') ? sortOrder.criteria = 'PROVIDER_NAME' : sortOrder.criteria = 'DATE';
        this.state.sortOrder.endsWith('a') ? sortOrder.order = 'ASC' : sortOrder.order = 'DESC';

        return sortOrder;
    }

    async fetchRemittancesByProvider() {
        const itemsPerPage = Constants.REMITTANCE_SEARCH_ITEMS_PER_PAGE;
        const fromDate = Utils.formatDate(this.state.searchFromDate);
        const toDate = Utils.formatDate(this.state.searchToDate);
        let providerIds = null;
        let allProviders = false;
        if (this.state.selectedProviders.length === this.context.providers.length) {
            allProviders = true;
        } else {
            providerIds = this.state.selectedProviders.map((provider) => {
                return provider.providerNumber;
            });
        }
        const sortOrder = this.determineSortOrder();

        try {
            const response = await API.graphql(graphqlOperation(searchRemittances,
                {
                    filter: {
                        userId: this.context.currentUser.id,
                        providerIds: providerIds,
                        dateFrom: fromDate,
                        dateTo: toDate,
                        eft: null,
                        allProviders: allProviders
                    },
                    pagination: {
                        pageNum: this.state.searchResultsPage, limit: itemsPerPage
                    },
                    sortOrder: sortOrder
                }));

            this.finaliseSearch(response);
        }
        catch (error) {
            this.finaliseSearchError();
        }

    }

    renderSorterComponent() {
        if (this.state.searchOption === SEARCH_BY_PROVIDER &&
            this.state.showSearchResults) {
            const includeNameSort = ((this.state.selectedProviders.length !== 1))
            return (
                <SearchResultsSorterComponent
                    remittanceCount={this.state.remittanceCount}
                    pageNo={this.state.searchResultsPage}
                    includeNameSort={includeNameSort}
                    handleSorting={this.setSortOrder}
                />
            )
        }
    }

    renderOverlaySpinner() {
        if (this.state.isLoading) {
            return (
                <OverlaySpinner />
            );
        }
    }

    renderSearchResults() {
        if (this.state.showSearchResults) {
            return (
                <div className={styles.bottomHalf}>
                    <div className={styles.resultsWrapper}>
                        {this.renderOverlaySpinner()}
                        <div className='container'>
                            <SearchResultsComponent
                                remittances={this.state.fetchedRemittances}
                            />
                        </div>
                    </div>
                    <div className='container pagination-wrapper'>
                        {this.renderPagination()}
                    </div>
                </div>
            )
        }
    }

    renderPagination() {
        if (this.state.remittanceCount) {
            return (
                <PaginationLinksComponent
                    totalItems={this.state.remittanceCount}
                    itemsPerPage={Constants.REMITTANCE_SEARCH_ITEMS_PER_PAGE}
                    currentPageNo={this.state.searchResultsPage}
                    onPageClick={this.onPageClick}
                />
            )
        }
    }

    render() {
        let {providers} = this.context
        const searchEnabled = this.canSearchBeRun();

        return (
            <div className={styles.remittancesWrapper}>

            <SearchCriteriaContextProvider
                value={{...this.state, currentUser: this.context.currentUser}} >
                <div>
                    <div className="container">
                        {this.state.hasError &&
                            <AlertComponent
                                forwardedRef={this.errorRef}
                                alertTitle={Constants.GENERIC_TECHNICAL_ERROR_TITLE}
                                alertMessage={Constants.GENERIC_TECHNICAL_ERROR_MSG}
                            />
                        }

                        <h2>Remittance advice</h2>

                        <SearchCriteriaComponent
                            providers={providers}
                            searchEnabled={searchEnabled}
                            isLoading={this.state.isLoading}
                            executeSearch={this.fetchRemittances}
                            currentUser={this.context.currentUser}
                            pathname={this.props.location.pathname}
                        />
                    </div>
                </div>
                <div className={styles.middle}>
                    <div className="container">
                        {this.renderSorterComponent()}
                    </div>
                </div>
                {this.renderSearchResults()}
            </SearchCriteriaContextProvider>
            </div>

        );
    }
}
RemittancesContainer.contextType = UserContext;

export default RemittancesContainer