import React, { Component } from 'react';
import DetailsDisplayComponent from './DetailsDisplayComponent'
import PasswordDetailsEditContainer from './PasswordDetailsEditContainer'
import {getGTMSequenceId} from "utils/GTMHelper"
import TagManager from "react-gtm-module"
import {getYourDetailsArgs} from "utils/GTMHelper"

class PasswordDetailsContainer extends Component {

    constructor(props) {
        super(props)

        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.handleSaveSuccess = this.handleSaveSuccess.bind(this);

        this.state = {
            isInEditMode: false,
            passwordUpdated: false,
            gtmSequenceId: ''
        }
    }

    handleEditClick() {
        this.setState({
            isInEditMode: true,
            passwordUpdated: false
        })
    }

    handleCancelClick() {
        this.setState({
            isInEditMode: false,
            passwordUpdated: false
        })
    }

    handleSaveSuccess() {
        const tagParms = {
            label: 'Form Complete',
            sequenceId: this.state.gtmSequenceId,
            detailsType: 'Password'
        }

        TagManager.dataLayer(getYourDetailsArgs(tagParms))

        this.setState({
            isInEditMode: false,
            passwordUpdated: true
        })
    }

    componentDidMount() {
        this.setState({
            gtmSequenceId: getGTMSequenceId()
        })
    }

    renderEditComponent() {
        return (
            <PasswordDetailsEditContainer
                handleCancelClick={this.handleCancelClick}
                handleSaveSuccess={this.handleSaveSuccess}
            />
        )
    }

    renderDisplayComponent() {
        const password = '********'

        return (
            <DetailsDisplayComponent
                title={'Password'}
                fieldValue={password}
                buttonText={'Edit'}
                onButtonClick={this.handleEditClick}
                showUpdateAlert={this.state.passwordUpdated}
                gtmSequenceId={this.state.gtmSequenceId}
            />
        )
    }

    render() {

        if (this.state.isInEditMode) {
            return (
                this.renderEditComponent()
            )
        } else {
            return (
                this.renderDisplayComponent()
            )
        }

    }
}

export default PasswordDetailsContainer;