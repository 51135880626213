// Import core-js modules for Internet Explorer support
import 'core-js/es6/array';
import 'core-js/es6/promise';
import 'core-js/es6/object';
import 'core-js/es7/object';
import 'core-js/es6/function';
import 'core-js/es6/string';
import "core-js/fn/array/flat-map"

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports-env';
import 'react-app-polyfill/ie11';
import TagManager from 'react-gtm-module'

import 'medikit/dist/base/styles/bundle.css';
import 'styles/styles.scss';

import App from './App';
import * as serviceWorker from './serviceWorker';

const env = process.env.REACT_APP_PP_ENV || '';
/***
 * Setup Google Tag Manager
 */
let gtmPreview = process.env.REACT_APP_GTM_PREVIEW_PROD
let gtmAuth = process.env.REACT_APP_GTM_AUTH_PROD
if (env === '' || env.toLowerCase().includes('dev') || env.toLowerCase().includes('sit')) {
    gtmPreview = process.env.REACT_APP_GTM_PREVIEW_DEV
    gtmAuth = process.env.REACT_APP_GTM_AUTH_DEV
}

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
    auth: gtmAuth,
    preview: gtmPreview
}

TagManager.initialize(tagManagerArgs)



let config = {}
if (env !== '') {
    config = awsconfig[env]
} else {
    config = awsconfig['default']
}
config.aws_appsync_authenticationType = 'AWS_IAM';
Amplify.configure(config);
// To see IAM credentials information
Auth.currentCredentials()
  .then(d => console.log('Credentials: ', d))
  .catch(e => console.log('Credentials Error: ', e))



const app = (
    <BrowserRouter>
        <App />
    </BrowserRouter>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
