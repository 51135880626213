import React, { Component } from 'react';
import styles from './UsernameStep2Container.module.scss';
import ForgottenContext from "context/ForgottenContext";
import classnames from 'classnames';
import DateInputComponent from 'components/datepickers/DateInputComponent'
import MobileNoInputContainer from "components/mobileno/MobileNoInputContainer";
import PropTypes from 'prop-types'
import {getPageLoadArgs} from "utils/GTMHelper"
import TagManager from "react-gtm-module"
import { withRouter } from 'react-router-dom'

export class UsernameStep2Container extends Component {
    constructor(props) {
        super(props);

        this.handleNext = this.handleNext.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.handleFirstNameChange = this.handleFirstNameChange.bind(this)
        this.handleLastNameChange = this.handleLastNameChange.bind(this)
    }

    handleNext() {
        const {actions} = this.context;
        actions.completeForgotUsernameStep2();
    }

    handleBack() {
        const {actions} = this.context;
        actions.backToStep1();
    }

    handleFirstNameChange(event) {
        const inputValue = event.target.value;
        // Only allow entry of a maximum of 40 digits
        if (inputValue.length > 40)
        {
            return;
        }

        this.context.actions.setFirstName(inputValue);
    }

    handleLastNameChange(event) {
        const inputValue = event.target.value;
        // Only allow entry of a maximum of 40 digits
        if (inputValue.length > 40)
        {
            return;
        }
        this.context.actions.setLastName(inputValue);
    }

    componentDidMount() {
        TagManager.dataLayer(getPageLoadArgs('Forgot Username',this.props.location.pathname+'/username'))

        window.scrollTo(0, 0);
    }

    renderButtons() {
        let {nextEnabled, processingRequest} = this.props;
        let nextButtonContents = 'Next';
        let backButtonContents = 'Back';
        if (processingRequest) {
            nextButtonContents = <div className="spinner-md text-white"></div>;
        }
        return (
            <div className="row">
                 <div className="col-xs-6 text-left">
                    <button
                        data-test-id="backBtn"
                        onClick={this.handleBack}
                        className={classnames('btn btn-lg btn-dark-ghost', styles.button)}
                        color="dark-ghost"
                        disabled={processingRequest}
                    >
                        {backButtonContents}
                    </button>
                </div>
                 <div className="col-xs-6 text-right">
                    <button
                        data-test-id="nextBtn"
                        onClick={this.handleNext}
                        className={classnames('btn btn-lg btn-primary', styles.button2)}
                        disabled={!nextEnabled || processingRequest}
                        color="primary">
                        {nextButtonContents}
                    </button>
                </div>
            </div>
        )
    }

    renderFirstNameRow() {
        const {firstName} = this.context;
        const {processingRequest} = this.props;

        return (
            <div className='input-group-row'>
                <div className='input-group'>
                    <label htmlFor="firstname">First name</label>
                    <input id='firstname'
                           value={firstName}
                           type='text'
                           className='input-search form-control'
                           disabled={processingRequest}
                           onChange={this.handleFirstNameChange}
                    />
                </div>
            </div>
        )
    }

    renderLastNameRow() {
        const {lastName} = this.context;
        const {processingRequest} = this.props;

        return (
            <div className='input-group-row'>
                <div className='input-group'>
                    <label htmlFor="lastname">Last name</label>
                    <input id='lastname'
                           value={lastName}
                           type='text'
                           className='input-search form-control'
                           disabled={processingRequest}
                           onChange={this.handleLastNameChange}
                    />
                </div>
            </div>
        )
    }

    renderDOBRow() {
        const {actions} = this.context;
        const {processingRequest} = this.props;
        return (
            <div className='input-group-row'>
                <div className='input-group'>
                    <label htmlFor="dateOfBirth">Date of birth</label>
                    <DateInputComponent
                        onDateInput={actions.setDOB}
                        dobRestriction={true}
                        earliestDate={'01/01/1900'}
                        disabled={processingRequest}
                    />
                </div>
            </div>
        )
    }

    renderMobileNoRow() {
        const {actions} = this.context
        const {processingRequest} = this.props

        return (
            <MobileNoInputContainer
                onMobileInput={actions.setMobileNo}
                labelText={'Registered mobile number'}
                disabled={processingRequest}
            />
        )
    }

    render() {
        return (
            <>
                <div className={styles.titleSection}>
                    <h4>Forgot your username?</h4>
                    <p>Please enter your details below.</p>
                </div>
                {this.renderFirstNameRow()}
                {this.renderLastNameRow()}
                {this.renderDOBRow()}
                {this.renderMobileNoRow()}
                {this.renderButtons()}
            </>
        );
    }
}

UsernameStep2Container.propTypes = {
    nextEnabled: PropTypes.bool.isRequired,
    processingRequest: PropTypes.bool.isRequired
}

export default withRouter(UsernameStep2Container);
UsernameStep2Container.contextType = ForgottenContext;
