import React, { Component } from 'react';
import Utils from 'utils/Utils';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './Step3UsernameContainer.module.scss';
import { Auth } from 'aws-amplify';
import debounce from 'lodash/debounce'

const NAME_NOT_CHECKED = 'NAME_NOT_CHECKED';
const NAME_TAKEN = 'NAME_TAKEN';
const NAME_AVAILABLE = 'NAME_AVAILABLE';
const NAME_ERROR = 'NAME_ERROR';

class Step3UsernameContainer extends Component {

    constructor(props) {
        super(props);

        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);
        this.checkUsernameUniqueness = this.checkUsernameUniqueness.bind(this);
        this.inputValidationDB = debounce(this.inputValidation,500);
        this.validateUsernameInput = this.validateUsernameInput.bind(this)

        this.state = {
            username: '',
            isLoading: false,
            isInError: false,
            nameCheckStatus: NAME_NOT_CHECKED,
            isInvalidInput: false,
            invalidInputMsg: ''
        }
    }

    handleOnChange(event) {
        const inputValue = event.target.value;
        if (Utils.containsSpace(inputValue) ||
            inputValue.length > 100) {
            return;
        }

        const username = event.target.value.toLowerCase();

        this.inputValidationDB(username);

        this.setState({
            username: username,
            nameCheckStatus: NAME_NOT_CHECKED
        }, () => {
            this.props.onUsernameInput('');
        });
    }

    validateUsernameInput(inputValue) {
        let errorText = ''

        if (this.props.emailAsUsername) {
            if (!Utils.validateEmailAddress(inputValue)) {
                errorText = "Please enter a valid username in an email address format"
            }
        } else {
            if (inputValue.indexOf('@') >= 0) {
                errorText = "Username cannot contain an @"
            } else if (inputValue.indexOf('.') >= 0) {
                errorText = "Username cannot contain a ."
            } else if (inputValue.length < 5) {
                errorText = "Username cannot be less than 5 characters"
            }
        }

        return errorText
    }

    inputValidation(inputValue) {
        const errorText = this.validateUsernameInput(inputValue)
        this.setState({
            isInvalidInput: (errorText.length > 0),
            invalidInputMsg: errorText
        })
    }

    async checkUsernameUniqueness() {
        const {username} = this.state;

        try {
            const code = '000000';
            await Auth.confirmSignUp(username, code, {
                // If set to False, the API will throw an AliasExistsException error
                // if the phone number/email used already exists as an alias with a different user
                forceAliasCreation: false
            })
            // The expectation is the Auth.confirmSignup should always reject with an error code.
            // So, if we get here something has gone amiss - throw a generic error
            throw Error()
        }
        catch (error) {
            let canUseName = true
            let nameCheckStatus = NAME_AVAILABLE

            switch ( error.code ) {
                case 'UserNotFoundException':
                    canUseName = true;
                    break;
                case 'NotAuthorizedException':
                case 'AliasExistsException':
                case 'CodeMismatchException':
                case 'ExpiredCodeException':
                    canUseName = false;
                    nameCheckStatus = NAME_TAKEN
                    break;
                default:
                    canUseName = false;
                    nameCheckStatus = NAME_ERROR
            }

            this.setState({
                isLoading: false,
                nameCheckStatus: nameCheckStatus
            }, () => {
                const chosenName = canUseName ? username : '';
                this.props.onUsernameInput(chosenName);
            })
        }
    }

    /***
     * When the field loses focus, check if the username entered is available or already in
     * use.
     */
    handleOnBlur() {
        const {username} = this.state;

        const inputError = this.validateUsernameInput(username)
        if (inputError.length > 0) {
            this.setState({
                isInvalidInput: true,
                invalidInputMsg: inputError
            })
        } else {
            this.setState({
                isLoading: true,
                nameCheckStatus: NAME_NOT_CHECKED
            }, () => {
                this.checkUsernameUniqueness();
            });
        }
    }

    renderNameCheckStatus() {
        const {nameCheckStatus, isInvalidInput, invalidInputMsg} = this.state;

        let msg;
        if (isInvalidInput) {
            msg = invalidInputMsg
        } else if (nameCheckStatus === NAME_NOT_CHECKED) {
            return null;
        } else if (nameCheckStatus === NAME_TAKEN) {
            msg = 'This username is already taken, please try a different username';
        } else if (nameCheckStatus === NAME_ERROR) {
            msg = 'Sorry - we could not verify your username';
        } else {
            msg = 'This username is available to use'
        }

        return (
            <small data-test-id='message' className={classnames('input-helper feedback', styles.inputHelper)}>{msg}</small>
        )
    }

    render() {
        const {username, isLoading, nameCheckStatus, isInvalidInput} = this.state;
        const {emailAsUsername} = this.props;
        let classes = 'input-group input-with-icon-right';
        if (isInvalidInput) {
            classes += ' input-group-alert';
        } else if (nameCheckStatus === NAME_AVAILABLE) {
            classes += ' input-group-success';
        } else if (nameCheckStatus !== NAME_NOT_CHECKED) {
            classes += ' input-group-alert';
        }

        const labelText = emailAsUsername ? 'Username (Email)' : 'Username (5-20 characters)'
        return (
            <>
                <div className={classes}>
                    <label htmlFor="username">{labelText}</label>
                    <input id='username'
                           type='text'
                           value={username}
                           disabled={this.props.disabled}
                           className='input-search form-control'
                           onChange={this.handleOnChange}
                           onBlur={this.handleOnBlur}
                    />
                    {isLoading &&
                        <div className="spinner"></div>
                    }
                </div>
                {this.renderNameCheckStatus()}
            </>
        );
    }
}

Step3UsernameContainer.propTypes = {
    onUsernameInput: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    emailAsUsername: PropTypes.bool
}

Step3UsernameContainer.defaultProps = {
    disabled: true,
    emailAsUsername: false
}

export default Step3UsernameContainer;