import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SearchResultsItemComponent extends Component {

    render() {
        const {remittance, handleDocumentDownload} = this.props;

        return (
            <tr>
                <td>{remittance.dateOfIssue}</td>
                <td>{remittance.provider.number}</td>
                <td><b>{remittance.provider.name}</b><br/>{remittance.provider.address}</td>
                <td>{remittance.eft}</td>
                <td>
                    <span onClick={handleDocumentDownload} className='simple-link-reverse'>Download</span>
                </td>
            </tr>
        )
    }
}

SearchResultsItemComponent.propTypes = {
    remittance: PropTypes.instanceOf(Object).isRequired,
    handleDocumentDownload: PropTypes.func.isRequired
};

export default SearchResultsItemComponent;