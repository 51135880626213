import React, { Component } from 'react';
import styles from './Step4Container.module.scss';
import RegistrationContext from "context/RegistrationContext";
import classnames from 'classnames';
import Step4CodeContainer from './Step4CodeContainer';
import ResendVerificationCodeLink from "./ResendVerificationCodeLink";
import TagManager from "react-gtm-module";
import {getRegoStepArgs, TAG_LABEL_FORM_COMPLETE} from "utils/GTMHelper"

const TAG_STEP_NAME = 'Verify mobile number';
const TAG_FIELD_NAME_VERIFY_BUTTON = 'Verify Button';
const TAG_FIELD_NAME_RESEND_CODE = 'Resend Code Link';

class Step4Container extends Component {
    constructor(props) {
        super(props);

        this.handleNext = this.handleNext.bind(this);
        this.handleResendCode = this.handleResendCode.bind(this);
    }

    handleNext() {
        this.tagFieldChange(TAG_FIELD_NAME_VERIFY_BUTTON, '')

        const {actions} = this.context;
        actions.completeStep4();
    }

    handleResendCode() {
        this.tagFieldChange(TAG_FIELD_NAME_RESEND_CODE, '')
        this.context.actions.continueStep4()
    }

    tagFieldChange(fieldName, fieldValue) {
        const {stepNo, gtmSequenceId} = this.context
        const tagParms = {
            label: TAG_LABEL_FORM_COMPLETE,
            sequenceId: gtmSequenceId,
            stepName: TAG_STEP_NAME,
            stepValue: stepNo,
            fieldName: fieldName,
            fieldValue: fieldValue
        }
        TagManager.dataLayer(getRegoStepArgs(tagParms))
    }

    renderContentTitle() {
        return (
            <div className='row'>
                <div className={classnames('col-lg-7', styles.titleWrapper)}>
                    <h3>Verify your mobile number</h3>
                    <hr/>
                </div>
                <div className={classnames('col-lg-5', styles.copyWrapper)}>
                </div>
            </div>
        )
    }

    truncateMobileNumber() {
        const {userMobile} = this.context;
        return 'xx' + userMobile.slice(-4)
    }

    renderVerificationCodeRow() {
        const {confirmingAccount, actions, error, hasVerificationCodeError} = this.context;
        const userMobile = this.truncateMobileNumber()

        return (
            <div className='row align-items-stretch'>
                <div className={classnames('col-lg-7', styles.fieldsWrapper)}>
                    <div className='row'>
                        <div className='col-lg-9'>
                            <Step4CodeContainer
                                onCodeInput={actions.setVerificationCode}
                                disabled={confirmingAccount}
                                error={error}
                                invalid={hasVerificationCodeError}
                            />
                        </div>
                    </div>
                </div>
                <div className={classnames('col-lg-5', styles.copyWrapper)}>
                    <div>
                        Thank you for registering! A verification code has been sent to the number ending in {userMobile}.<br/>
                        <p>
                        If you have made an error with your mobile number, please contact our Provider Self Service enquiry team. Find our details in the <a
                            className='simple-link-reverse'
                            href="https://www.medibank.com.au/contact-us/"
                            rel='noopener noreferrer'
                            target='_blank'
                        >
                            Healthcare providers section
                        </a>.
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    renderConfirmButton() {
        let disabled = !this.props.nextEnabled;
        let buttonContents = 'Verify';
        if (this.context.confirmingAccount) {
            buttonContents = <div className="spinner-md text-white"></div>;
            disabled = true;
        }

        return (
            <div>
                <div className='input-group'>
                    <button
                        onClick={this.handleNext}
                        className='btn-lg btn btn-primary'
                        disabled={disabled}
                        color="primary">
                        {buttonContents}
                    </button>
                </div>
            </div>
        )
    }

    renderConfirmButtonRow() {
        const userMobile = this.truncateMobileNumber()
        return (
            <div className='row'>
                <div className={classnames('col-lg-7', styles.buttonWrapper)}>
                    {this.renderConfirmButton()}
                    <ResendVerificationCodeLink
                        continue={this.handleResendCode}
                        mobileNumberEnding={userMobile}
                    />
                </div>
                <div className={classnames('col-lg-5', styles.copyWrapper)}>
                </div>
            </div>
        )
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <>
                <div className={classnames('container', styles.contentWrapper)}>
                    {this.renderContentTitle()}
                    {this.renderVerificationCodeRow()}
                    {this.renderConfirmButtonRow()}
                </div>
            </>
        );
    }
}

Step4Container.contextType = RegistrationContext;

export default Step4Container;