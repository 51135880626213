import React, { Component } from 'react';
import styles from './PwdStep3Container.module.scss';
import ForgottenContext from "context/ForgottenContext";
import classnames from 'classnames';
import PasswordConfirmContainer from 'components/PasswordConfirmContainer';

class PwdStep3Container extends Component {
    constructor(props) {
        super(props);

        this.handleNext = this.handleNext.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.handleResend = this.handleResend.bind(this);
        this.showResend = this.showResend.bind(this);
        this.handleCodeChange = this.handleCodeChange.bind(this);

        this.state = {
            resent: false
        }
    }

    handleNext(event) {
        event.preventDefault();
        const {actions} = this.context;
        actions.completePwdStep3();
    }


    handleBack() {
        const {actions} = this.context;
        actions.backToStep2();
    }    

    handleCodeChange(event) {
        this.context.actions.setVerificationCode(event.target.value)
    }

    showResend() {
        this.setState({resent: false});
    }
   

    handleResend(event) {
        event.preventDefault();
        this.setState({resent: true});
        const {actions} = this.context;
        actions.continuePwdStep3();
        setTimeout(this.showResend, 10000);
    }

    renderPasswordRow() {
        const {resettingPassword, actions} = this.context;
        return (
            <div className={classnames('input-group', styles.password)}>
                <PasswordConfirmContainer
                    setUserPassword={actions.setPassword}
                    disabled={resettingPassword}
                    label='New password'
                    confirmLabel='Confirm new password'
                    withExplanation={true}
                />
            </div>
        )
    }

    renderButtons() {
        let disabled = !this.props.nextEnabled;
        let nextButtonContents = 'Confirm';
        let backButtonContents = 'Back';
        if (this.context.resettingPassword) {
            nextButtonContents = <div className="spinner-md text-white"></div>;
            disabled = true;
        }

        return (
            <div className="row">
                <div className="col-xs-6 text-left">
                    <button
                        data-test-id="backBtn"
                        onClick={this.handleBack}
                        className={classnames('btn btn-lg btn-dark-ghost', styles.button)}
                        color="dark-ghost">
                        {backButtonContents}
                    </button>
                </div>
                 <div className="col-xs-6 text-right">
                        <button
                            data-test-id="nextBtn"
                            onClick={this.handleNext}
                            className={classnames('btn btn-lg btn-primary', styles.button2)}
                            disabled={disabled}
                            color="primary">
                            {nextButtonContents}
                        </button>
                    </div>
                </div>
        )
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    renderErrorMessage() {
        const {hasError, errorMessage} = this.context;

        if (hasError) {
            return (
                <div data-test-id='error' className={classnames('form-error small', styles.inputHelper)}>
                    {errorMessage}
                </div>
            )
        } else {
            return (
                <span>&nbsp;</span>
            )
        }
    }

    render() {
        const {mobileEnding, hasError} = this.context;

        var codeClasses = 'input-group';

        if (hasError) {
            codeClasses += ' input-group-alert';
        }

        var resendMessage = (
            <span className={styles.link}>
              Didn't get it? <a data-test-id="resendLink" href="true" onClick={this.handleResend}>Resend code</a>
            </span>
        );
        if (this.state.resent) {
            resendMessage = (<div data-test-id="resentMsg" className={classnames(styles.link)}>A new verification code has been sent.</div>)
        }
        return (
            <>
                <h4>Reset a new password</h4>
                <p>
                    To reset your password, please enter the 6-digit verification code sent to the mobile number ending
                    in xx{mobileEnding.substr(mobileEnding.length - 2)}.<br/>
                    This code will expire after 3 minutes.
                </p>
                <div className={classnames(codeClasses, styles.code)}>
                    <label htmlFor="code">Verification code</label>
                    <input id='code'
                           type='text'
                           className='input-search form-control'
                           onChange={this.handleCodeChange}
                    />
                </div>
                {this.renderErrorMessage()}
                {this.renderPasswordRow()}
                {this.renderButtons()}
                {resendMessage}
            </>
        );
    }
}

PwdStep3Container.contextType = ForgottenContext;

export default PwdStep3Container;