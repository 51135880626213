import React, { Component } from 'react';
import classnames from "classnames";
import styles from './RegisterDoctorModalComponent.module.scss';
import PropTypes from 'prop-types';

class RegisterDoctorModalComponent extends Component {

    render() {
        const {modalId, modalTitle} = this.props;

        const modalContent =
        <>
            <hr/>
            <div className={'m-t-4'}>
                <strong><p>Step 1 - Download the editable PDF form and fill out one form for each doctor that needs to be registered.</p></strong>
                <a className={classnames('mx-auto btn-lg btn btn-primary m-t-2', styles.downloadFormBtn)}
                 href="/Provider-Self-Service-Authorisation-Form.pdf" download="Provider-Self-Service-Authorisation-Form.pdf">Download form</a>
            </div>
            <div className={'m-t-4'}>
                <p><strong>Step 2 -  Email the documents below to </strong>
                    <p className={classnames('', styles.selfServiceMailId)}>ProviderSelfService@medibank.com.au</p>
                </p>
                <ul>
                    <li>Filled out PDF form(s)</li>
                    <li>Scanned copy of the doctor’s drivers licence</li>
                    <li>Proof of name change (if applicable)</li>
                </ul>
                <p className={classnames('', styles.downloadFormNote)}>NOTE: If more doctors need to be added at a later date, you can add them from Manage provider screen with the same process.</p>
            </div>
            <div className={'m-t-4'}>
                <strong><p>Step 3 - The manual registration process may take up to 5 business days, but we will contact you once completed.</p></strong>
                <p className={'m-t-4 m-b-3'}>If you have anymore questions, please contact Medibank HPAT team at 1300 130 460 or  <p className={classnames('', styles.selfServiceMailId)}>ProviderSelfService@medibank.com.au</p></p>
            </div>
        </>;


        return (
            <div className={classnames("modal", styles.content)} id={modalId} tabIndex="-1" role="dialog"
                 aria-labelledby="DrRegisterModal" aria-hidden="true" data-modal="">
                <div className={classnames("modal-dialog", styles.DrRegisterModal)} role="document">
                    <div className="modal-content">
                        <button type="button" className={classnames("modal-dismiss", styles.modalCloseBtn)} aria-label="Close" data-modal-dismiss="">
                            <svg className="icon icon-stroke" xmlns="http://www.w3.org/2000/svg" width="60"
                                 height="60" viewBox="0 0 60 60">
                                <path className="currentColor" vectorEffect="non-scaling-stroke"
                                      d="M47.5 12.9l-35 35M12.5 12.9l35 35"></path>
                            </svg>
                        </button>
                        <div className="modal-body">
                            <h3 id="DrRegisterModalLabel">{modalTitle}</h3>
                            {modalContent}
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

RegisterDoctorModalComponent.propTypes = {
    modalId: PropTypes.string.isRequired,
    modalTitle: PropTypes.string.isRequired
};

export default RegisterDoctorModalComponent;