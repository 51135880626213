import React, { Component } from 'react';
import styles from './VerifyEmailComponent.module.scss';
import classnames from 'classnames'
import PropTypes from 'prop-types'
import UserContext from 'context/UserContext'
import ResendVerificationCodeLink from "components/registration/ResendVerificationCodeLink";
import { ReactComponent as CloseIcon } from 'medikit/dist/base/svgs/icon-close.svg'

class VerifyEmailComponent extends Component {

    constructor(props) {
        super(props)
        this.handleHeaderMessageClose = this.handleHeaderMessageClose.bind(this)
        this.handleResend = this.handleResend.bind(this)

        this.state = {
            showHeaderMessage: false
        }
    }

    handleResend() {
        this.setState({
            showHeaderMessage: true
        })
        this.props.handleResendEmail()
    }

    handleHeaderMessageClose() {
        this.setState({
            showHeaderMessage: false
        })
    }

    renderHeaderSection() {
        if (this.state.showHeaderMessage) {
            return (
                <div data-test-id='infoMessage' className={styles.headerWrapper}>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-10'>
                                <h4>We've sent an email to {this.props.email}</h4>
                            </div>
                            <div className={classnames('col-lg-2',styles.closeButtonCell)} >
                                <CloseIcon data-test-id='closeBtn' onClick={this.handleHeaderMessageClose} className={classnames('icon icon-stroke icon-xs', styles.closeButton)} />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    renderContentTitle() {
        return (
            <div className='row'>
                <div className={classnames('col-lg-7', styles.titleWrapper)}>
                    <h3>Email verification needed</h3>
                    <hr/>
                </div>
                <div className={classnames('col-lg-5', styles.copyWrapper)}>
                </div>
            </div>
        )
    }

    renderInfoRow() {
        return (
            <>
                <div className='row'>
                    <div className={classnames('col-lg-7', styles.infoWrapper)}>
                        <p>
                            To complete your registration and activate your account, we need to verify
                            your email address. Please click the link in the email we've sent to your inbox.
                            This link will only be valid for the next 24 hours.
                        </p>
                        <p>
                            Please follow the instructions contained in the verification email.
                            Once your email is verified, click the button below to continue to log in,
                            and access the Provider Self Service Dashboard.
                        </p>
                    </div>
                    <div className={classnames('col-lg-5', styles.infoCopyWrapper)}>
                        <span>
                            It may take up to 5 minutes for you to receive your email.
                            If you haven't received your verification email yet,
                            please check your junk and spam folder.
                        </span>
                        <p>
                            If you have made an error with your email address, please contact our Provider Self Service enquiry team.
                        </p>
                        <p>
                            Find our details in the&nbsp;
                            <a className='simple-link-reverse'
                               href="https://www.medibank.com.au/contact-us/"
                               rel='noopener noreferrer'
                               target='_blank'>Healthcare providers section</a>
                        </p>
                    </div>
                </div>
            </>
        )
    }

    renderButtonRow() {
        let disabled = false
        let buttonContents = this.props.nextButtonText

        if (this.props.checkingVerification) {
            buttonContents = <div className="spinner-md text-white"></div>;
            disabled = true;
        }
        return (
            <div className='row'>
                <div className={classnames('col-lg-7', styles.buttonWrapper)}>
                    <div>
                        <div className='input-group'>
                            <button
                                onClick={this.props.handleNext}
                                className={classnames('btn-lg btn btn-primary', styles.nextButton)}
                                disabled={disabled}
                                color="primary">
                                {buttonContents}
                            </button>
                        </div>
                    </div>
                    <ResendVerificationCodeLink
                        sendViaEmail={true}
                        continue={this.handleResend}/>
                </div>
                <div className={classnames('col-lg-5', styles.copyWrapper)}>
                </div>
            </div>
        )
    }

    render() {
        return (
            <>
                {this.renderHeaderSection()}
                <div className={classnames('container', styles.contentWrapper)}>
                    {this.renderContentTitle()}
                    {this.renderInfoRow()}
                    {this.renderButtonRow()}
                </div>
            </>
        )
    }
}

VerifyEmailComponent.contextType = UserContext;

VerifyEmailComponent.propTypes = {
    email: PropTypes.string.isRequired,
    handleNext: PropTypes.func.isRequired,
    handleResendEmail: PropTypes.func.isRequired,
    checkingVerification: PropTypes.bool,
    nextButtonText: PropTypes.string
}

VerifyEmailComponent.defaultProps = {
    nextButtonText: 'I already verified my email',
    checkingVerification: false
}

export default VerifyEmailComponent;