import React, { Component } from 'react';
import styles from './Step1Container.module.scss';
import ForgottenContext from 'context/ForgottenContext';
import classnames from 'classnames';

class Step1Container extends Component {

    constructor(props) {
        super(props);

        this.handleForgottenUsername = this.handleForgottenUsername.bind(this);
        this.handleForgottenPassword = this.handleForgottenPassword.bind(this);
    }

    handleForgottenUsername() {
        const {actions} = this.context;
        actions.completeStep1(false);
    }

    handleForgottenPassword() {
        const {actions} = this.context;
        actions.completeStep1(true);
    }

    onBlur(event) {
        //Do nothing
    }


    componentDidMount() {
        window.scrollTo(0, 0);
    }

    renderUsernameButton() {
        let buttonContents = 'Forgot my username';
        return (
            <div>
                <button
                    data-test-id='username'
                    onClick={this.handleForgottenUsername}
                    className={classnames('btn btn-primary btn-block', styles.button)}
                    color="primary">
                    {buttonContents}
                </button>
            </div>
        )
    }

    renderPasswordButton() {
        let buttonContents = 'Forgot my password';
        return (
            <div>
                <button
                    data-test-id='password'
                    onClick={this.handleForgottenPassword}
                    className={classnames('btn btn-primary btn-block', styles.button)}
                    color="primary">
                    {buttonContents}
                </button>
            </div>
        )
    }


    render() {
        return (
            <>
                <h4>What did you forget?</h4>
                {this.renderUsernameButton()}
                {this.renderPasswordButton()}
            </>
        );
    }
}

Step1Container.contextType = ForgottenContext;

export default Step1Container;