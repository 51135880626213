import React, { Component } from 'react';
import styles from './ConnectedUsersContainer.module.scss'
import classnames from "classnames";
import UserContext from "context/UserContext";
import ConnectedUsersListComponent from "./ConnectedUsersListComponent";
import Constants from "utils/Constants";
import Utils from "../../../utils/Utils";

class ConnectedUsersContainer extends Component {

    render() {
        let managedUsers = this.context.managedUsers
        let users = managedUsers.filter(u => (u.links.filter(p => p.status === Constants.ACCEPTED).length > 0))
        users = Utils.groupByArray(users, a => a.username).map(i => i[0])

        return (
            <div className='row'>
                <div className='col-lg-12'>
                    <table className={classnames('table table-striped table-responsive', styles.resultsWrapper)}>
                        <thead>
                        <tr>
                            <th className={styles.th1}>Name</th>
                            <th className={styles.th2}>Email</th>
                            <th className={styles.th3}>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                            <ConnectedUsersListComponent connectedUsers={users}/>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

ConnectedUsersContainer.contextType = UserContext;

export default ConnectedUsersContainer;