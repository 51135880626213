import React, {Component} from "react";
import styles from 'components/spinner/OverlaySpinner.module.scss';
import classnames from "classnames";
import PropTypes from 'prop-types'

class OverlaySpinner extends Component {

    render() {
        const {clearOverlay} = this.props
        let overlayStyle = styles.overlay;
        let spinnerStyle = 'spinner spinner-lg text-muted'
        if (clearOverlay) {
            overlayStyle = styles.clearOverlay;
            spinnerStyle = classnames(spinnerStyle, styles.darkerSpinner)
        }

        return (
            <div data-test-id='overlaySpinner' className={overlayStyle}>
                <div className={classnames('container', styles.centerSpinner)}>
                    <div className={spinnerStyle} ></div>
                </div>
            </div>
        )
    }
}

OverlaySpinner.propTypes = {
    clearOverlay: PropTypes.bool
}

OverlaySpinner.defaultProps = {
    clearOverlay: false
}

export default OverlaySpinner;

