import React, { Component } from 'react';
import styles from './PortalInfoSidebarComponent.module.scss'
import PropTypes from 'prop-types'

class PortalInfoSidebarComponent extends Component {

    renderInfoCopy() {
        let copy = <>
            <h4>Welcome to Provider Self Service</h4>
            <ul>
                <li>Access remittance advices</li>
                <li>Monitor claims status</li>
            </ul>
        </>

        if (this.props.showMobileMessage) {
            copy = <>
                <div className={styles.mobileCopy}>
                    <span>
                    If you have made an error with your mobile number, please contact our Provider Self Service enquiry team.<br/><br/>
                    Find our details in the&nbsp;
                        <a className='simple-link-reverse'
                           href="https://www.medibank.com.au/contact-us/"
                           rel='noopener noreferrer'
                           target='_blank'>Healthcare providers section</a>
                </span>
                </div>
            </>
        }

        return copy
    }

    render() {
        return (
            <div className={styles.sidebarWrapper} >
                <div className={styles.copyWrapper}>
                    {this.renderInfoCopy()}
                </div>
                <div className={styles.image}>
                    <img src="/images/bg_providerportal_login.jpg" alt="Login"/>
                </div>
            </div>
        )
    }
}

PortalInfoSidebarComponent.contextType = PortalInfoSidebarComponent;

PortalInfoSidebarComponent.propTypes = {
    showMobileMessage: PropTypes.bool
}

PortalInfoSidebarComponent.defaultProps = {
    showMobileMessage: false
}

export default PortalInfoSidebarComponent;