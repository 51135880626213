const Constants = {
    REMITTANCE_SEARCH_ITEMS_PER_PAGE: 20,
    CLAIMS_SEARCH_ITEMS_PER_PAGE: 20,
    CLAIM_LINES_ITEMS_PER_PAGE: 20,
    GENERIC_TECHNICAL_ERROR_TITLE: 'Error!',
    GENERIC_TECHNICAL_ERROR_MSG: 'Sorry we are experiencing technical issues. Please try again later',

    USER_ROLE_DOCTOR: 'Doctor',
    USER_ROLE_PM: 'Practice manager',
    USER_ROLE_STAFF: 'Other',

    USER_ROLE_HELPTEXT: {
        'Doctor': 'I have a STEM number and AHPRA number.',
        'Practice manager': 'I manage one or more providers on behalf of a Doctor.',
        'Other': 'I am not a doctor. I work as a practice manager or provider employee.'
    },

    USER_ROLE_MAPPING: {
        'Doctor': 'DOCTOR',
        'Practice manager': 'PRACTICE_MANAGER',
        'Other': 'STAFF'
    },

    USER_ROLE_REVERSE_MAPPING: {
        'DOCTOR': 'Doctor',
        'PRACTICE_MANAGER': 'Practice manager',
        'STAFF': 'Other'
    },

    AUDIT_LOGOUT: 'LOGOUT',
    AUDIT_AUTHENTICATION: 'AUTHENTICATION',
    AUDIT_MOBILE_VERIFICATION: 'MOBILE_VERIFICATION',

    ACCEPTED: 'ACCEPTED',
    DECLINED: 'DECLINED',
    PENDING: 'PENDING',

    SESSION_TIMEOUT: 8 * 60 * 60, //8 Hours
    ACTIVITY_TIMEOUT: 20 * 60,//20 mins

    AUTH: {
        NONE: 0,
        LOGGED_OUT: 1,
        VALID: 2,
        NOT_VERIFIED: 3
    },
};

export default Constants;