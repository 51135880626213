import React, { Component } from 'react';
import { Auth, API, graphqlOperation } from 'aws-amplify'
import MobileDetailsEditNumberComponent from './MobileDetailsEditNumberComponent'
import PropTypes from 'prop-types'
import Utils from 'utils/Utils'
import debounce from 'lodash/debounce'
import styles from "./MobileDetailsEditContainer.module.scss";
import classnames from "classnames";
import MobileDetailsSMSCodeComponent from "./MobileDetailsSMSCodeComponent";
import SpinnerButtonComponent from 'components/spinnerButton/SpinnerButtonComponent'
import { updateUserDetails } from "graphql/mutations";
import { isMobileRegistered } from "graphql/queries";
import UserContext from "context/UserContext"
import AlertModalComponent from "components/modal/AlertModalComponent";

class MobileDetailsEditContainer extends Component {

    constructor(props) {
        super(props)

        this._resendTimer = null;

        this.handleMobileNoInput = this.handleMobileNoInput.bind(this);
        this.handleSMSCodeInput = this.handleSMSCodeInput.bind(this);
        this.mobileValidationDB = debounce(this.mobileNoValidation, 500);
        this.handleSendSMS = this.handleSendSMS.bind(this);
        this.showResend = this.showResend.bind(this);
        this.handleSMSCodeVerification = this.handleSMSCodeVerification.bind(this);
        this.handleUpdateSuccess = this.handleUpdateSuccess.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this)
        this.handleModalClose = this.handleModalClose.bind(this)

        this.state = {
            mobileNoValue: '',
            mobileNoIsValid: false,
            mobileNoErrorMsg: '',
            mobileNoHasChanged: false,
            updateInProgress: false,
            smsRequested: false,
            showResendSMS: false,
            smsCodeValue: '',
            enableSMSEntry: false,
            enableConfirmBtn: false,
            verifyingSMSCode: false,
            verificationError: '',
            showAlertModal: false,
            mobileNoUpdateFailed: false
        }
    }

    handleModalClose() {
        this.setState({
            showAlertModal: false
        })
    }

    async handleMobileNoInput(mobileNo) {

        // Only allow entry of numbers and a maximum of 10 digits
        if (mobileNo.length > 10 ||
            (mobileNo.length > 0 && !Utils.containsOnlyDigits(mobileNo))) {
            return;
        }

        let mobileIsInUse = false;
        if (mobileNo.length === 10) {
            if (!Utils.isDevelopmentEnvironment()) {
                const newNo = Utils.internationalisePhoneNumber(mobileNo);
                const isNewNumberResponse = await API.graphql(graphqlOperation(isMobileRegistered, {
                    mobile: newNo
                }));

                mobileIsInUse = (
                    isNewNumberResponse
                    && isNewNumberResponse.data
                    && isNewNumberResponse.data.isMobileRegistered
                    && isNewNumberResponse.data.isMobileRegistered.exists === true
                );
            }
        }

        this.setState({
            mobileIsInUse,
            mobileNoValue: mobileNo,
            smsRequested: false,
            showResendSMS: false,
            smsCodeValue: '',
            verificationError: '',
            enableConfirmBtn: false,
            enableSMSEntry: false
        })

        this.mobileValidationDB(mobileNo);
    }

    handleSMSCodeInput(smsCode) {
        // Only allow entry of numbers and a maximum of 6 digits
        if (smsCode.length > 6 ||
            (smsCode.length > 0 && !Utils.containsOnlyDigits(smsCode))) {
            return;
        }

        const enableConfirm = (smsCode.length === 6) && this.state.mobileNoIsValid;
        this.setState({
            smsCodeValue: smsCode,
            enableConfirmBtn: enableConfirm,
            verificationError: ''
        })
    }

    mobileNoValidation(mobileNo) {
        const errorMsg = Utils.isValidMobileNo(mobileNo)

        if (errorMsg.length > 0) {
            this.setState({
                mobileNoIsValid: false,
                mobileNoErrorMsg: errorMsg
            })
        } else {
            this.setState({
                mobileNoIsValid: true,
                mobileNoErrorMsg: ''
            })
        }
    }

    async handleSendSMS() {
        this.setState({
            smsRequested: true,
            smsCodeValue: '',
            verificationError: '',
            updateInProgress: true,
            mobileNoUpdateFailed: false,
            enableSMSEntry: false
        })

        this._resendTimer = setTimeout(this.showResend, 1000);

        try {

            let user = await Auth.currentAuthenticatedUser();
            const newNo = Utils.internationalisePhoneNumber(this.state.mobileNoValue)

            if (this.state.showResendSMS) {
                await Auth.verifyUserAttribute(user, 'phone_number')
            } else {
                await Auth.updateUserAttributes(user, {
                    'phone_number': newNo
                });
            }

            let input = {
                mobile: newNo
            }

            const response = await API.graphql(graphqlOperation(updateUserDetails, {
                input
            }
            ));

            this.handleUpdateSuccess(response.data.updateUserDetails);
        }
        catch (error) {
            let message;
            message = 'There was a technical problem encountered whilst trying to update your mobile number.';

            this.setState({
                mobileNoUpdateFailed: true,
                updateInProgress: false,
                mobileNoErrorMsg: message,
            })
        }
    }

    handleUpdateSuccess(user) {
        this.context.actions.updateUser(user)

        this.setState({
            mobileNoHasChanged: true,
            updateInProgress: false,
            enableSMSEntry: true
        })
    }

    async handleSMSCodeVerification() {
        this.setState({
            verifyingSMSCode: true,
            verificationError: ''
        })

        try {
            await Auth.verifyCurrentUserAttributeSubmit('phone_number', this.state.smsCodeValue);

            this.props.handleConfirmClick();
        }
        catch (error) {
            this.setState({
                verifyingSMSCode: false,
                verificationError: error.code
            })
        }
    }

    handleCancelClick() {
        if (this.state.mobileNoHasChanged) {
            this.setState({
                showAlertModal: true
            })
        } else {
            const { handleCancelClick } = this.props
            handleCancelClick();
        }

    }

    showResend() {
        const { mobileNoUpdateFailed } = this.state
        this.setState({
            showResendSMS: !mobileNoUpdateFailed,
            smsRequested: false
        })
    }

    /***
     * When the component unmounts, specifically clear the timer that may be
     * running to re-enable Resend SMS button.
     */
    componentWillUnmount() {
        if (this._resendTimer) {
            clearTimeout(this._resendTimer);
            this._resendTimer = null;
        }
    }


    renderMobileComponent() {
        const { mobileNoValue, mobileNoIsValid, mobileNoErrorMsg, smsRequested, showResendSMS, mobileIsInUse } = this.state
        return (
            <MobileDetailsEditNumberComponent
                mobileIsInUse={mobileIsInUse}
                mobileNoValue={mobileNoValue}
                handleCancelClick={this.handleCancelClick}
                handleMobileNoInput={this.handleMobileNoInput}
                mobileNoIsValid={mobileNoIsValid}
                mobileNoErrorMsg={mobileNoErrorMsg}
                handleSendSMS={this.handleSendSMS}
                smsRequested={smsRequested}
                showResendSMS={showResendSMS}
            />
        )
    }

    renderVerificationComponent() {
        const { smsCodeValue, verificationError, enableSMSEntry } = this.state;
        return (
            <MobileDetailsSMSCodeComponent
                handleSMSCodeInput={this.handleSMSCodeInput}
                smsCodeValue={smsCodeValue}
                verificationError={verificationError}
                disabled={!enableSMSEntry}
            />
        )
    }

    renderButtonComponent() {
        const { enableConfirmBtn, verifyingSMSCode, updateInProgress } = this.state

        return (
            <div className={classnames('row', styles.buttonRow)}>
                <div className='col-lg-12'>
                    <SpinnerButtonComponent
                        handleClick={this.handleSMSCodeVerification}
                        disabled={!enableConfirmBtn}
                        text='Save'
                        className='btn-lg'
                        spinning={verifyingSMSCode}
                    />

                    <button
                        onClick={this.handleCancelClick}
                        className='btn btn-lg btn-dark-ghost'
                        disabled={updateInProgress}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        )
    }

    renderAlertModal() {
        if (this.state.showAlertModal) {
            const mobileSuffix = 'xx' + this.state.mobileNoValue.slice(-4)
            const modalContent =
                <>
                    <p>Warning! You recently changed your mobile phone number, but have not entered the verification code yet.</p>
                    <p>To complete the mobile verification process, please enter the verification code sent to your new mobile number ending in {mobileSuffix}.</p>
                </>;

            return (
                <AlertModalComponent
                    handleModalClose={this.handleModalClose}
                    modalTitle={'Warning!'}
                    modalContent={modalContent}
                />
            )
        }
    }

    renderInfoText() {
        let comp =
            <span>
                Please enter your new mobile number, and click update. Your new number will be used for all Provider Self Service communications, and a verification code will be sent to this number each time you log in, so please check that it is correct. We will send you a 6-digit verification code, shortly.
            </span>

        if (this.state.enableSMSEntry) {
            comp =
                <span>
                    Please enter the verification code sent to the mobile number {this.state.mobileNoValue}<br />
                    This code will be valid for 3 minutes.
            </span>
        }

        return comp
    }

    render() {
        return (
            <div className={styles.detailBlock}>
                <div className={styles.detailHeader}>
                    <h4>Mobile number</h4>
                </div>
                <div className='row'>
                    <div className='col-lg-7'>
                        <div className={styles.detailLeft}>
                            {this.renderMobileComponent()}
                            {this.renderVerificationComponent()}
                            {this.renderButtonComponent()}
                        </div>
                    </div>
                    <div className={classnames('col-lg-5')}>
                        <div className={styles.detailRight}>
                            {this.renderInfoText()}
                        </div>
                    </div>
                </div>
                {this.renderAlertModal()}
            </div>
        );

    }
}

MobileDetailsEditContainer.propTypes = {
    handleCancelClick: PropTypes.func.isRequired,
    handleConfirmClick: PropTypes.func.isRequired
}

MobileDetailsEditContainer.contextType = UserContext;

export default MobileDetailsEditContainer;