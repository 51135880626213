import React, { Component } from 'react';
import styles from './SelectRoleComponent.module.scss';
import classnames from 'classnames';
import PropTypes from 'prop-types';

class SelectRoleComponent extends Component {
    constructor(props) {
        super(props);

        this.handleSelect = this.handleSelect.bind(this);
    }

    handleSelect() {
        this.props.handleRoleSelect(this.props.selectedRole);
    }

    render() {
        const {selectedRole, helpText, icon} = this.props;
        let {title} = this.props
        if (!title) title = selectedRole
        return (
            <>
                <div className={styles.header} >
                    <div className={styles.iconWrapper}>{icon}</div>
                    <div className={styles.title}>
                        {title}
                    </div>
                </div>
                <div className={styles.body} >
                    {helpText}
                </div>
                <div className={styles.footer} >
                    <button onClick={this.handleSelect} className={classnames('btn-lg btn btn-dark')} >
                        Select
                    </button>
                </div>
            </>
        );
    }
}

SelectRoleComponent.propTypes = {
    selectedRole: PropTypes.string,
    helpText: PropTypes.string,
    icon: PropTypes.element,
    handleRoleSelect: PropTypes.func.isRequired
}

export default SelectRoleComponent;