import React, { Component } from 'react';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { LoginContextProvider } from 'context/LoginContext';
import Step1Container from 'components/login/Step1Container';
import Step2Container from 'components/login/Step2Container';
import UserContext from "context/UserContext";
import { Redirect } from 'react-router-dom'
import classnames from "classnames";
import styles from "./LoginContainer.module.scss";
import Utils from 'utils/Utils';
import Constants from "utils/Constants";
import PortalInfoSidebarComponent from "components/PortalInfoSidebarComponent";
import { createAuditEntry } from "graphql/mutations";
import TagManager from 'react-gtm-module'
import {
    getPageLoadArgs, getGTMSequenceId, getLoginStepArgs, setLoginTimestamp, TAG_LABEL_STEP_COMPLETE,
    TAG_LABEL_FORM_ERROR, TAG_LABEL_FORM_COMPLETE
} from "utils/GTMHelper"
import ScheduledOutageComponent from "components/login/ScheduledOutageComponent";

const TAG_STEP_NAME_USERNAME = 'Enter username';
const TAG_STEP_NAME_VERIFICATION = 'Enter sms code';

class LoginContainer extends Component {
    constructor(props) {
        super(props);

        this.setUsername = this.setUsername.bind(this);
        this.setPassword = this.setPassword.bind(this);
        this.setMfaCode = this.setMfaCode.bind(this);
        this.completeStep1 = this.completeStep1.bind(this);
        this.completeStep2 = this.completeStep2.bind(this);
        this.continueStep2 = this.continueStep2.bind(this);
        this.hideMessage = this.hideMessage.bind(this);

        const gtmSequenceId = getGTMSequenceId()

        this.state = {
            toDashboard: false,
            stepNo: 1,
            username: '',
            password: '',
            mfaCode: '',
            loggingInAccount: false,
            verifyingLogin: false,
            showMessage: true,
            cognitoUser: null,
            inError: false,
            error: null,
            loggedOut: true,
            gtmSequenceId: gtmSequenceId,
            actions: {
                setUsername: this.setUsername,
                setPassword: this.setPassword,
                setMfaCode: this.setMfaCode,
                completeStep1: this.completeStep1,
                completeStep2: this.completeStep2,
                continueStep2: this.continueStep2
            }
        }

    }

    continueStep2() {
        this.setState({
            verifyingLogin: false
        }, () => {
            this.loginAccount();
        })
    }

    setUsername(username) {
        this.setState({
            username: username,
            inError: false
        })
    }

    setPassword(password) {
        this.setState({
            password: password,
            inError: false
        })
    }

    setMfaCode(mfaCode) {
        this.setState({
            mfaCode: mfaCode,
            inError: false
        })
    }

    completeStep1() {
        this.loginAccount();
    }

    tagFieldError(stepName, errorName, errorDetails) {
        const { stepNo, gtmSequenceId } = this.state
        const tagParms = {
            label: TAG_LABEL_FORM_ERROR,
            sequenceId: gtmSequenceId,
            stepName: stepName,
            stepValue: stepNo,
            errorName: errorName,
            errorDetails: errorDetails
        }

        TagManager.dataLayer(getLoginStepArgs(tagParms))
    }

    async loginAccount() {
        const { username, password } = this.state;

        this.setState({
            loggingInAccount: true,
            inError: false
        })

        try {
            const response = await Auth.signIn(username, password);

            this.setState({
                loggingInAccount: false,
                cognitoUser: response,
                stepNo: 2,
                inError: false
            }, () => {
                const { stepNo, gtmSequenceId } = this.state
                const tagParms = {
                    label: TAG_LABEL_STEP_COMPLETE,
                    sequenceId: gtmSequenceId,
                    stepName: TAG_STEP_NAME_USERNAME,
                    stepValue: (stepNo - 1)
                }
                TagManager.dataLayer(getLoginStepArgs(tagParms))
            });

            this.context.actions.resetActivityTime()

        }
        catch (error) {
            // Special treatment when the user fails login numerous times.
            if (error.message === 'User is disabled') {
                error.code = 'LoginAttemptsExceededException'
            }

            try {
                await API.graphql(graphqlOperation(createAuditEntry, {
                    input: {
                        action: Constants.AUDIT_AUTHENTICATION,
                        success: false,
                        errorReason: error.code,
                        additionalInfo: error.message
                    }
                }));
            }
            catch (error) {
                // Swallow this error. Not much we can do here if the audit logging fails
            }


            this.tagFieldError(
                TAG_STEP_NAME_USERNAME,
                'Username / Password',
                error.message + ':' + error.code
            )

            this.setState({
                loggingInAccount: false,
                verifyingLogin: false,
                inError: true,
                error: error.code
            })
        }
    }

    async mfaLogin() {
        const { cognitoUser, mfaCode } = this.state;
        try {
            this.setState({
                verifyingLogin: true,
                inError: false
            })

            const response = await Auth.confirmSignIn(cognitoUser, mfaCode, 'SMS_MFA');
            await this.context.actions.reloadLoggedInUser()

            const { currentUser } = this.context;
            const { stepNo, gtmSequenceId } = this.state
            const tagParms = {
                label: TAG_LABEL_FORM_COMPLETE,
                sequenceId: gtmSequenceId,
                stepName: TAG_STEP_NAME_VERIFICATION,
                stepValue: stepNo,
                cognitoId: currentUser.cognitoId,
                usertype: currentUser.role
            }
            TagManager.dataLayer(getLoginStepArgs(tagParms))

            setLoginTimestamp()

            this.setState({
                verifyingLogin: false,
                cognitoUser: response,
                inError: false,
                toDashboard: true
            })

        } catch (error) {
            this.tagFieldError(
                TAG_STEP_NAME_VERIFICATION,
                'SMS Verification Code',
                'Verification of SMS code failed: ' + error.code
            )

            try {
                await API.graphql(graphqlOperation(createAuditEntry,
                    {
                        input: {
                            action: Constants.AUDIT_AUTHENTICATION,
                            success: false,
                            errorReason: error.code,
                            additionalInfo: error.message
                        }
                    }));
            }
            catch (error) {
                // Swallow this error. Not much we can do here if the audit logging fails
            }

            this.setState({
                verifyingLogin: false,
                inError: true,
                error: error.code
            })
        }
    }

    completeStep2() {
        this.mfaLogin()
    }

    renderLoginStep() {
        let comp = null;
        let nextEnabled = false;
        switch (this.state.stepNo) {
            case 1:
                nextEnabled = this.canStep1Proceed();
                comp = <Step1Container nextEnabled={nextEnabled} />;
                break;

            case 2:
                nextEnabled = this.canStep2Proceed();
                comp = <Step2Container nextEnabled={nextEnabled} cognitoUser={this.state.cognitoUser} />;
                break;
            default:
                comp = null;
        }
        return comp;
    }

    canStep2Proceed() {
        return (this.state.mfaCode.length === 6 && Utils.containsOnlyDigits(this.state.mfaCode));
    }

    canStep1Proceed() {
        return (this.state.username.length > 0 && this.state.password.length > 0);
    }

    hideMessage() {
        this.setState({
            showMessage: false
        });
    }

    async componentDidMount() {
        TagManager.dataLayer(getPageLoadArgs('Login', this.props.location.pathname))

        let authStatus = this.context.actions.isAuthenticatedUser()
        if (authStatus === Constants.AUTH.LOGGED_OUT) {
            await this.context.actions.logout()
            this.setState({ loggedOut: true, toDashboard: false })
        } else if (authStatus === Constants.AUTH.VALID || authStatus === Constants.AUTH.NOT_VERIFIED) {
            this.setState({ toDashboard: true, loggedOut: false })
        } else {
            this.setState({ toDashboard: false, loggedOut: false })
        }
    }

    renderCloseMessageButton() {
        return (
            <button className="alert-dismiss" aria-label="Close" data-alert-dismiss="" onClick={this.hideMessage}>
                <span className="sr-only">Dismiss message</span>
                <svg className="icon icon-stroke icon-xs" xmlns="http://www.w3.org/2000/svg" width="60" height="60"
                    viewBox="0 0 60 60">
                    <path className="currentColor" vectorEffect="non-scaling-stroke" d="M47.5 12.9l-35 35M12.5 12.9l35 35" />
                </svg>
            </button>
        )
    }

    renderInfoMessage() {
        var messageFrom = null;
        if (this.state.showMessage) {
            if (this.props.location && this.props.location.state) {
                if (this.props.location.state.from === 'Registration') {
                    messageFrom = (
                        <>
                            <div className={classnames('message-body')}>
                                Your account has been activated successfully.
                            </div>
                            {this.renderCloseMessageButton()}
                        </>
                    );
                } else if (this.props.location.state.from === 'ForgottenPassword') {
                    messageFrom = (
                        <>
                            <div className={classnames('message-body')}>
                                <h4>Your password has been reset</h4>
                                <div>You can now login using your new password.</div>
                            </div>
                            {this.renderCloseMessageButton()}
                        </>
                    );
                } else if (this.props.location.state.from === 'ForgottenUsername') {
                    messageFrom = (
                        <>
                            <div className={classnames('message-body')}>
                                <h4>Username has been sent</h4>
                                <div>We've sent your username to your registered email address.</div>
                            </div>
                            {this.renderCloseMessageButton()}
                        </>
                    );
                }
            } else if (this.state.loggedOut && this.state.stepNo === 1) {
                messageFrom = (
                    <>
                        <div className={classnames('message-body')}>
                            <h4>You were logged out due to inactivity or your session has expired</h4>
                            <div>Please login again to continue working.</div>
                        </div>
                        {this.renderCloseMessageButton()}
                    </>
                );
            }
        }

        if (messageFrom) {
            return (
                <div className='bg-neutral-1'>
                    <div className='container'>
                        <div className={classnames('message', styles.messageWrapper)} role="alert" data-alert="">
                            {messageFrom}
                        </div>
                    </div>
                </div>
            )
        }
    }

    render() {
        if (this.state.toDashboard === true) {
            return <Redirect to='/' />
        }

        return (
            <LoginContextProvider value={{ ...this.state }} >
                {this.renderInfoMessage()}
                <div className={classnames('container', styles.contentWrapper)}>
                    <ScheduledOutageComponent />
                    <div className={classnames('row')}>
                        <div className={classnames('col-lg-5 no-gutters', styles.colPadding)}>
                            {this.renderLoginStep()}
                        </div>
                        <div className={classnames('col-lg-7 no-gutters', styles.sidebarWrapper)}>
                            <PortalInfoSidebarComponent
                                showMobileMessage={(this.state.stepNo > 1)}
                            />
                        </div>
                    </div>
                </div>
            </LoginContextProvider>
        )
    }
}

LoginContainer.contextType = UserContext;

export default LoginContainer;