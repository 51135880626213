import React, { Component } from 'react';
import SearchResultsListComponent from './SearchResultsListComponent'
import styles from './SearchResultsComponent.module.scss'

class SearchResultsComponent extends Component {

    render() {
        const {claims} = this.props;
        if (claims &&
            claims.length > 0)
        {
            return <SearchResultsListComponent claims={claims}/>
        } else {
            let msg = 'Sorry, no matching records have been found.';
            if (!claims) {
                msg = '';
            }
            return (
                <div className={styles.emptyRowStyles}><span>{msg}</span></div>
            )
        }
    }
}

export default SearchResultsComponent;