import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SearchResultsItemContainer from './SearchResultsItemContainer';

class SearchResultsListComponent extends Component {

    render() {

        let i = 0
        return this.props.claims.map((claim) => {
            i += 1
            return <SearchResultsItemContainer
                key={claim.claimNo}
                claim={claim}
                sequenceNo={i}
            />
        });
    }
}

SearchResultsListComponent.propTypes = {
    claims: PropTypes.instanceOf(Array).isRequired
};

export default SearchResultsListComponent;