import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EmailComponent from "components/email/EmailComponent";

class Step3EmailContainer extends Component {

    constructor(props) {
        super(props);

        this.handleEmailInput = this.handleEmailInput.bind(this);
    }

    handleEmailInput(inputValue, isInError) {
        const {onEmailInput} = this.props;

        if (isInError) {
            onEmailInput('')
        } else {
            onEmailInput(inputValue)
        }
    }

    render() {
        const {disabled} = this.props;

        return <EmailComponent onEmailInput={this.handleEmailInput} disabled={disabled} />
    }
}

Step3EmailContainer.propTypes = {
    onEmailInput: PropTypes.func.isRequired,
    disabled: PropTypes.bool
}

Step3EmailContainer.defaultProps = {
    disabled: false
}


export default Step3EmailContainer;