import React, { Component } from 'react';
import { ReactComponent as MedibankLogo } from "svgs/logo-black-white.svg";
import moment from 'moment'
import styles from "./Footer.module.scss";

class Footer extends Component {
    render() {
        const year = moment().format('YYYY');
        return (
            <footer className='footer-wrapper'>
                <div className="footer-legals">
                    <div className="container">
                        <p className={styles["footer-text"]}>
                            <a href="/">
                                <MedibankLogo/>
                            </a>Copyright © {year} Medibank Private Limited. All rights reserved. ABN 47 080 890 259.
                        </p>
                        <div className={styles.policies}>
                            <a
                                className='simple-link-reverse'
                                href="https://www.medibank.com.au/legal-information/"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                Privacy Policy
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;