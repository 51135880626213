import React, {Component} from 'react';
import classnames from 'classnames';
import UserContext from "context/UserContext";
import styles from './MyProvidersContainer.module.scss';
import AccountNavComponent from './AccountNavComponent'
import Constants from "utils/Constants";
import {Redirect} from "react-router-dom";
import TooltipIcon from 'components/TooltipIcon'
import WhatAreRolesModal from "components/providerSetup/WhatAreRolesModal";
import {getPageLoadArgs} from "utils/GTMHelper"
import TagManager from "react-gtm-module";

class MyProvidersContainer extends Component {

    constructor(props) {
        super(props);
        this.handleAddStem = this.handleAddStem.bind(this)
        this.handleAddProvider = this.handleAddProvider.bind(this)

        this.state = {
            toAddProvider: false,
            toAddStem: false
        }
    }

    handleAddProvider() {
        this.setState({toAddProvider: true})
    }

    handleAddStem() {
        this.setState({toAddStem: true})
    }

    componentDidMount() {
        const {cognitoId, role} = this.context.currentUser
        TagManager.dataLayer(getPageLoadArgs('Manage Providers', this.props.location.pathname, cognitoId, role))
    }

    renderSTEMDetails(providers) {
        if (providers.length > 0) {
            return (<>
                {providers.filter(p=>p.isOwner).map((provider) => {
                    return (

                        <div key={provider.stem} className={classnames('row', styles.stemBox)}>
                            <div className={classnames('col-lg-12', styles.contentWrapper)}>
                                <div className={classnames('col-lg-12')}>
                                    <div className={styles.providerName}>
                                        <p>Provider name</p>
                                        <h4>{provider.firstname} {provider.lastname}</h4>
                                    </div>
                                </div>
                                <div className={classnames('', styles.detailsBox)}>
                                    <div className={'col-lg-3'}>
                                        <h5>STEM Number</h5>
                                    </div>
                                    <div className={'col-lg-3'}>
                                        <h5>AHPRA Number</h5>
                                    </div>
                                    <div className={'col-lg-6'}>
                                        <h5>Specialty</h5>
                                    </div>
                                </div>
                                <div className={classnames('', styles.detailsBox)}>
                                    <div className={'col-lg-3'}>
                                        <p>{provider.stem}</p>
                                    </div>
                                    <div className={'col-lg-3'}>
                                        <p>{provider.ahpra}</p>
                                    </div>
                                    <div className={'col-lg-6'}>
                                        <p>{provider.speciality}</p>
                                    </div>
                                </div>
                                <div key={provider.stem} className={classnames('row')}>
                                    <div className='col-lg-12'>
                                        <table className={classnames('table table-striped table-responsive', styles.providerTable)}>
                                            <thead>
                                            <tr>
                                                <th className={styles.th1}>Provider number</th>
                                                <th className={styles.th2}>Address/Addresses</th>
                                                <th className={styles.th3}>
                                                    Your role
                                                    <span className='pointer'>
                                                        <TooltipIcon showModal={true}>
                                                            <WhatAreRolesModal/>
                                                        </TooltipIcon>
                                                    </span>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {provider.locations.map((location) => {
                                                return (
                                                    <tr key={location.providerNumber}>
                                                        <td>
                                                            {location.providerNumber}
                                                        </td>
                                                        <td>
                                                            <b>{location.practiceName}</b><br/>
                                                            {location.address}
                                                        </td>
                                                        <td>
                                                            Doctor (Admin)
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </>)
        } else {
            return (
                <div className={classnames('row', styles.noConnectedWrapper)}>
                    You don't have any connected STEM Numbers
                </div>
            )
        }
    }

    renderConnectedProviders(providers) {
        if (providers.length > 0) {
            return <>
                {providers.map((provider) => {
                    return (
                        <div key={provider.stem} className={classnames('row',styles.stemBox)}>
                            <div className={classnames('col-lg-12', styles.contentWrapper)}>
                                {this.renderAwaiting(provider)}
                                {this.renderDeclined(provider)}
                                {this.renderAccepted(provider)}
                                <div className={classnames('col-lg-12')}>
                                    <div className={classnames('', styles.providerName)}>
                                        <p>Provider name</p>
                                        <h4>{provider.firstname} {provider.lastname}</h4>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <table className={classnames('table table-striped table-responsive', styles.providerTable)}>
                                            <thead>
                                            <tr>
                                                <th className={styles.th1}>Provider number</th>
                                                <th className={styles.th2}>Address/Addresses</th>
                                                <th className={styles.th3}>
                                                    Your role
                                                    <span className='pointer'>
                                                        <TooltipIcon showModal={true}>
                                                            <WhatAreRolesModal/>
                                                        </TooltipIcon>
                                                    </span>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {provider.locations.map((location) => {
                                                return (
                                                    <tr key={location.providerNumber}>
                                                        <td>
                                                            {location.providerNumber}
                                                        </td>
                                                        <td>
                                                            <b>{location.practiceName}</b><br/>
                                                            {location.address}
                                                        </td>
                                                        <td>
                                                            {location.role}
                                                        </td>
                                                    </tr>
                                                    )
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </>
        } else {
            return (
                <div className={classnames('row', styles.noConnectedWrapper)}>
                    You don't have any connected providers
                </div>
            )
        }
    }

    renderAwaiting(provider) {
        if (provider.locations && provider.locations.length > 0 && provider.locations[0].status !== Constants.PENDING) {
            return <></>
        }
        return (
            <div className={classnames(styles.awaiting,styles.groupHeader)}>
                <h4>Awaiting response</h4>
            </div>
        )
    }

    renderDeclined(provider) {
        if (provider.locations && provider.locations.length > 0 && provider.locations[0].status !== Constants.DECLINED) {
            return <></>
        }
        return (
            <div className={classnames(styles.declined,styles.groupHeader)}>
                <h4>Request declined</h4>
            </div>
        )
    }

    renderAccepted(provider) {
        if (provider.locations && provider.locations.length > 0 && provider.locations[0].status !== Constants.ACCEPTED) {
            return <></>
        }
        return (
            <div className={classnames(styles.accepted,styles.groupHeader)}>
                <h4>Request accepted</h4>
            </div>
        )
    }

    processProviders(providerLinks) {
        let providers = {}
        providerLinks.forEach(link => {
            let key = link.provider.stem + link.status
            let { provider } = link
            if (!providers[key]) {
                providers[key] = {isOwner: provider.isOwner, stem: provider.stem, ahpra: provider.ahpra[0], number: provider.providerNumber, speciality: provider.speciality.name, firstname: provider.firstname, lastname: provider.lastname, locations: []}
            }
            providers[key].locations.push({providerNumber: provider.providerNumber, role: Constants.USER_ROLE_REVERSE_MAPPING[link.role], status: link.status, address: provider.address, practiceName: provider.practiceName})
        })
        return Object.keys(providers).map(key => {
            return providers[key]
        })
    }

    render() {
        if (this.state.toAddProvider) {
            return <Redirect to={"/providersetup/provider"}/>
        } else if (this.state.toAddStem) {
            return <Redirect to={"/providersetup/stem"}/>
        }

        let providers = this.processProviders(this.context.providerLinks)
        let connectedProviders = providers.filter(p => {
            return !p.isOwner
        })

        // Setup correct button text depending on whether user has their own provider numbers linked
        // and / or has other providers connected
        const hasConnectedSTEM = (providers.length > 0 && providers.length > connectedProviders.length)
        let stemBtnText = (hasConnectedSTEM) ? 'Add another STEM' : 'Add a STEM'
        const hasConnectedProviders = (connectedProviders.length > 0)
        const providersBtnText = (hasConnectedProviders) ? 'Add another provider' : 'Add a provider'

        let stemSection = ''
        if (Constants.USER_ROLE_REVERSE_MAPPING[this.context.currentUser.role] === Constants.USER_ROLE_DOCTOR) {
            stemSection = (
            <>
                <div className={'row'}>
                    <div className={classnames('col-lg-4', styles.stemDiv)}>
                        <h3>Your STEM Details</h3>
                    </div>
                    <div className={classnames('col-lg-8', styles.buttonDiv)}>
                        <button className={classnames('btn btn-primary', styles.addBtn)} onClick={this.handleAddStem}>{stemBtnText}</button>
                    </div>
                </div>
                {this.renderSTEMDetails(providers)}
            </>)
        }

        let connectedProvidersSection = (
            <>
                <div className={classnames('row', styles.sectionHeader)}>
                    <div className={classnames('col-lg-6', styles.stemDiv)}>
                        <h3>Other provider details</h3>
                    </div>
                    <div className={classnames('col-lg-6', styles.buttonDiv)}>
                    <button className={classnames('btn btn-primary', styles.addBtn)} onClick={this.handleAddProvider}>{providersBtnText}</button>
                    </div>
                </div>
                {this.renderConnectedProviders(connectedProviders)}
            </>)

        return (
            <div className={classnames('container', styles.mainWrapper)}>
                <div className='row'>
                    <div className='col-lg-3'>
                        <AccountNavComponent/>
                    </div>
                    <div className='col-lg-9'>
                        {stemSection}
                        {connectedProvidersSection}
                    </div>
                </div>
            </div>
        )
    }
}

MyProvidersContainer.contextType = UserContext;

export default MyProvidersContainer;