import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import styles from './DateInputComponent.module.scss';
import classnames from 'classnames';

const ERROR_TEXT_FORMAT = 'Please enter a correct date in the format DD/MM/YYYY. e.g 01/02/1981';
const ERROR_TEXT_DOB = 'Date entered cannot be in the future.';

class DateInputComponent extends Component {

    constructor(props) {
        super(props);

        this.handleOnChange = this.handleOnChange.bind(this);
        this.state = {
            inError: false,
            errorText: '',
            inputValue: ''
        }
    }

    getCaretPosition (ctrl) {
        // IE < 9 Support
        if (document.selection) {
            ctrl.focus();
            var range = document.selection.createRange();
            var rangelen = range.text.length;
            range.moveStart ('character', -ctrl.value.length);
            var start = range.text.length - rangelen;
            return {'start': start, 'end': start + rangelen };
        }
        // IE >=9 and other browsers
        else if (ctrl.selectionStart || ctrl.selectionStart === '0') {
            return {'start': ctrl.selectionStart, 'end': ctrl.selectionEnd };
        } else {
            return {'start': 0, 'end': 0};
        }
    }


    setCaretPosition(ctrl, start, end) {
        // IE >= 9 and other browsers
        if(ctrl.setSelectionRange)
        {
            ctrl.focus();
            ctrl.setSelectionRange(start, end);
        }
        // IE < 9
        else if (ctrl.createTextRange) {
            var range = ctrl.createTextRange();
            range.collapse(true);
            range.moveEnd('character', end);
            range.moveStart('character', start);
            range.select();
        }
    }


    handleOnChange(event) {
        let incrementCaret = 0
        let ctrl = event.target
        const {onDateInput, dobRestriction} = this.props;
        const origValue = event.target.value;
        let caretPosition = this.getCaretPosition(ctrl)

        if (origValue.length < 1) {
            this.setState({
                inputValue: '',
                inError: false
            });
        } else {
            // Date entered must only contain numbers and slashes.
            const reNumber = /^[0-9/]+$/;
            let errorText = ERROR_TEXT_FORMAT;
            let validInput = reNumber.test(origValue);
            if (!validInput) {
                return;
            }

            // Shortcut if only slashes entered with no numbers
            let numbers = origValue.split('/');
            if (numbers.length < 1) {
                return;
            }

            // Munge numbers together - truncate digits if more than 8
            let newValue = numbers.join('').substring(0,8);

            // Reinsert slashes into appropriate locations...
            if (newValue.length > 2 ||
                (newValue.length === 2 && origValue.length > 2)) {
                newValue = newValue.substring(0,2) + '/' + newValue.substring(2);
                if (caretPosition.start > 2) incrementCaret = 1
            }
            if (newValue.length > 5 ||
                (newValue.length === 5 && origValue.length > 5)) {
                newValue = newValue.substring(0,5) + '/' + newValue.substring(5);
                if (caretPosition.start > 5) incrementCaret = 1
            }

            if ((newValue.length - origValue.length) === 0) {
                incrementCaret = 0
            }

            validInput = true;
            if (newValue.length === 10) {
                let m = moment(newValue, "DD/MM/YYYY");
                validInput =  m.isValid();

                let earliestDate = moment(this.props.earliestDate ? this.props.earliestDate : '01/01/1920', "DD/MM/YYYY");

                if (validInput) {
                    if (dobRestriction) {
                        if (m.isAfter(moment())) {
                            onDateInput('');
                            validInput = false;
                            errorText = ERROR_TEXT_DOB;
                        } else if (earliestDate.isAfter(m)) {
                            onDateInput('');
                            validInput = false;
                            errorText = ERROR_TEXT_FORMAT;
                        }
                        else {
                            onDateInput(newValue);
                        }
                    } else {
                        onDateInput(newValue);
                    }
                } else {
                    onDateInput('');
                    validInput = false;
                    errorText = ERROR_TEXT_FORMAT;
                }
            } else {
                onDateInput('');
                validInput = false;
                errorText = ERROR_TEXT_FORMAT;
            }

            this.setState({
                inputValue: newValue,
                inError: !validInput,
                errorText: errorText
            }, () => {

                    this.setCaretPosition(ctrl, caretPosition.start + incrementCaret, caretPosition.end + incrementCaret)
            });

        }
    }

    renderDateError() {
        const {inError, errorText} = this.state;
        if (inError) {
            return (
                <div data-test-id='error' className={classnames('input-helper form-error small', styles.dateError)}>
                    {errorText}
                </div>
            )
        }
    }
    render() {
        const {inputValue, inError} = this.state;
        const {disabled} = this.props;

        return (
            <>
                <input value={inputValue}
                       onChange={this.handleOnChange}
                       type='text'
                       className={classnames('input-search form-control', {error: inError} )}
                       placeholder='DD/MM/YYYY'
                       disabled={disabled}
                />
                {this.renderDateError()}
            </>
        )
    }
}

DateInputComponent.propTypes = {
    onDateInput: PropTypes.func.isRequired,
    dobRestriction: PropTypes.bool,
    disabled: PropTypes.bool
}

DateInputComponent.defaultProps = {
    dobRestriction: false,
    disabled: false
};

export default DateInputComponent;
