import React, { Component } from 'react';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import {ForgottenContextProvider} from 'context/ForgottenContext';
import Step1Container from 'components/forgotten/Step1Container';
import PwdStep2Container from 'components/forgotten/PwdStep2Container';
import PwdStep3Container from 'components/forgotten/PwdStep3Container';
import OverlaySpinner from 'components/spinner/OverlaySpinner';
import UsernameStep2Container from 'components/forgotten/UsernameStep2Container'
import styles from './ForgottenContainer.module.scss'
import classnames from "classnames";
import {Link} from "react-router-dom";
import PortalInfoSidebarComponent from "../../components/PortalInfoSidebarComponent";
import AlertComponent from 'components/AlertComponent'
import Constants from 'utils/Constants'
import {findUser} from 'graphql/queries'
import {getPageLoadArgs, getGTMSequenceId, getForgottenArgs, TAG_LABEL_FORM_ERROR,
    TAG_LABEL_STEP_COMPLETE, TAG_LABEL_STEP_START, TAG_LABEL_FORM_COMPLETE} from "utils/GTMHelper"
import TagManager from "react-gtm-module";

const TAG_STEP_NAME_USERNAME_OR_PASSWORD = 'Choose Username or Password';
const TAG_STEP_NAME_USERNAME = 'Forgot username';
const TAG_STEP_NAME_FORGOT_PASSWORD = 'Forgot password';
const TAG_STEP_NAME_RESET_PASSWORD = 'Reset a new password';

class ForgottenContainer extends Component {
    constructor(props) {
        super(props);

        this.setUsername = this.setUsername.bind(this);
        this.setPassword = this.setPassword.bind(this);
        this.setVerificationCode = this.setVerificationCode.bind(this);
        this.completeStep1 = this.completeStep1.bind(this);
        this.backToStep1 = this.backToStep1.bind(this);
        this.backToStep2 = this.backToStep2.bind(this);
        this.completePwdStep2 = this.completePwdStep2.bind(this);
        this.completePwdStep3 = this.completePwdStep3.bind(this);
        this.continuePwdStep3 = this.continuePwdStep3.bind(this);
        this.setFirstName = this.setFirstName.bind(this)
        this.setLastName = this.setLastName.bind(this)
        this.setDOB = this.setDOB.bind(this)
        this.setMobileNo = this.setMobileNo.bind(this)
        this.completeForgotUsernameStep2 = this.completeForgotUsernameStep2.bind(this)

        this.state = {
            stepNo: 1,
            username: '',
            mobileEnding: '',
            password: '',
            firstName: '',
            lastName: '',
            dateOfBirth: '',
            mobileNo: '',
            verificationCode: '',
            verifyingUsername: false,
            resettingPassword: false,
            sendingForgottenUsername: false,
            isPassword: true,
            hasError: false,
            hasGenericError: false,
            errorMessage: null,
            showRememberedLink: true,
            gtmSequenceId: getGTMSequenceId(),
            actions: {
                setUsername: this.setUsername,
                setPassword: this.setPassword,
                setFirstName: this.setFirstName,
                setLastName: this.setLastName,
                setDOB: this.setDOB,
                setMobileNo: this.setMobileNo,
                setVerificationCode: this.setVerificationCode,
                completeStep1: this.completeStep1,
                completePwdStep2: this.completePwdStep2,
                completePwdStep3: this.completePwdStep3,
                continuePwdStep3: this.continuePwdStep3,
                completeForgotUsernameStep2: this.completeForgotUsernameStep2,
                backToStep1: this.backToStep1,
                backToStep2: this.backToStep2
            }
        }
    }

    backToStep1() {
        this.setState({
            stepNo: 1,
            username: '',
            firstName: '',
            lastName: '',
            dateOfBirth: '',
            mobileNo: '',
            hasGenericError: false
        })
    }

    backToStep2() {
        this.setState({
            stepNo: 2,
            password: '',
            verificationCode: '',
            showRememberedLink: true,
            hasGenericError: false
        })
    }    

    setUsername(username) {
        this.setState({
            username: username
        })
    }

    setFirstName(firstName) {
        this.setState({
            firstName: firstName
        })
    }

    setLastName(lastName) {
        this.setState({
            lastName: lastName
        })
    }

    setDOB(DOB) {
        this.setState({
            dateOfBirth: DOB
        })
    }

    setMobileNo(mobileNo) {
        this.setState({
            mobileNo: mobileNo
        })
    }

    setPassword(password) {
        this.setState({
            password: password
        })
    }

    setVerificationCode(verificationCode) {
        this.setState({
            verificationCode: verificationCode
        })
    }

    completePwdStep2() {
        this.forgotPasswordRequest();
    }

    continuePwdStep3() {
        this.forgotPasswordRequest();
    }

    completePwdStep3() {
        this.setState({
            resettingPassword: true,
        }, () => {
            this.resetPassword();
        })
    }

    async completeForgotUsernameStep2() {
        this.setState({
            sendingForgottenUsername: true
        })

        try {
            const {firstName, lastName, dateOfBirth, mobileNo} = this.state

            await API.graphql(graphqlOperation(findUser,
                {
                    filter: {
                        firstname: firstName,
                        lastname: lastName,
                        mobile: mobileNo,
                        dob: dateOfBirth
                    }
                }));

            this.tagFormEvent(TAG_LABEL_FORM_COMPLETE, TAG_STEP_NAME_USERNAME)

            this.setState({
                stepNo: 3,
                sendingForgottenUsername: false,
                hasGenericError: false
            });

            this.props.history.push({
                pathname: '/login',
                state: {
                    from: 'ForgottenUsername'
                }
            });

        } catch(error) {
            this.setState({
                stepNo: 2,
                sendingForgottenUsername: false,
                hasGenericError: true
            })
        }
    }

    /***
     * Step 1 of the "Forgotten Something" process is simply determining if the user forgot
     * their password or username.
     * @param isPassword Indicates if the user is going down the Forgotten Password path, or the
     * Forgotten Username path. True indicates Forgotten Password
     */
    completeStep1(isPassword) {
        this.tagFormEvent(TAG_LABEL_STEP_COMPLETE, TAG_STEP_NAME_USERNAME_OR_PASSWORD)

        this.setState({
            stepNo: 2,
            isPassword: isPassword,
            hasError: false
        })
    }    

    async forgotPasswordRequest() {
        const {username} = this.state;

        this.setState({
            verifyingUsername: true
        })

        try {
            const {CodeDeliveryDetails} = await Auth.forgotPassword(username);

            this.tagFormEvent(TAG_LABEL_STEP_COMPLETE, TAG_STEP_NAME_FORGOT_PASSWORD)

            this.setState({
                stepNo: 3,
                hasError: false,
                showRememberedLink: false,
                mobileEnding: CodeDeliveryDetails.Destination,
                verifyingUsername: false
            });
        } catch (error) {
            const errorCode = error.code
            let genericError = true
            let errorMessage = null

            if ((['UserNotFoundException', 'InvalidParameterException'].indexOf(errorCode) > -1)) {
                genericError = false;
                errorMessage = "Incorrect username. Please try again.";
            } else if (errorCode === 'LimitExceededException') {
                genericError = false;
                errorMessage = 'Attempt limit exceeded. Please try after some time.';
            }

            this.tagFormEvent(TAG_LABEL_FORM_ERROR, TAG_STEP_NAME_USERNAME, errorCode, errorMessage)

            this.setState({
                stepNo: 2,
                hasError: !genericError,
                hasGenericError: genericError,
                errorMessage: errorMessage,
                verifyingUsername: false
            })
        }        
    }

    async resetPassword() {
        const {username, password, verificationCode} = this.state;
        try {
            await Auth.forgotPasswordSubmit(username, verificationCode, password);

            this.tagFormEvent(TAG_LABEL_FORM_COMPLETE, TAG_STEP_NAME_RESET_PASSWORD)

            this.setState({
                resettingPassword: false,
                hasError: false,
                stepNo: 0
            });

            this.props.history.push({
                pathname: '/login',
                state: {
                    from: 'ForgottenPassword'
                }
            });

        } catch (error) {
            const errorCode = error.code
            let genericError = true
            let errorMessage = null

            if (errorCode === 'CodeMismatchException') {
                genericError = false;
                errorMessage = "Incorrect verification code. Please try again."
            } else if  (errorCode === 'ExpiredCodeException') {
                genericError = false;
                errorMessage = 'Your code has expired. Codes are only valid for 1 hour. Please request a new code and try again.';
            }

            this.setState({
                stepNo: 3,
                resettingPassword: false,
                hasError: !genericError,
                hasGenericError: genericError,
                errorMessage: errorMessage
            });
        }          
    }

    renderForgottenStep() {
        let comp = null;
        let nextEnabled = false;
        switch(this.state.stepNo) {
            case 0:
                comp = <OverlaySpinner />;
                break;
            case 1:
                comp = <Step1Container />;
                break;
            case 2:
                if (this.state.isPassword) {
                    nextEnabled = this.canPwdStep2Proceed();
                    comp = <PwdStep2Container
                        nextEnabled={nextEnabled}
                        verifyingUsername={this.state.verifyingUsername}
                    />;
                } else {
                    nextEnabled = this.canUsernameStep2Proceed();
                    comp = <UsernameStep2Container
                        nextEnabled={nextEnabled}
                        processingRequest={this.state.sendingForgottenUsername}
                    />;
                }
                break;

            case 3:
                if (this.state.isPassword) {
                    nextEnabled = this.canPwdStep3Proceed();
                    comp = <PwdStep3Container nextEnabled={nextEnabled}
                    />;
                }
                break;
            default:
                comp = null;
        }
        return comp;
    }

    canPwdStep2Proceed() {
        return (this.state.username.length > 0 );
    }

    canPwdStep3Proceed() {
        return (this.state.verificationCode.length > 0 && this.state.password.length > 0);
    }

    canUsernameStep2Proceed() {
        return (
            this.state.firstName.length > 0 &&
            this.state.lastName.length > 0 &&
            this.state.dateOfBirth.length > 0 &&
            this.state.mobileNo.length > 0
        );
    }

    renderRememberedLink() {
        if (this.state.showRememberedLink) {
            return (
                <Link className={classnames('simple-link-reverse', styles.link)} to={"/login"}>I've remembered my log in details</Link>
            )
        }
    }

    renderErrorAlert() {
        if (this.state.hasGenericError) {
            return (
                <AlertComponent
                    forwardedRef={this.errorRef}
                    alertTitle={Constants.GENERIC_TECHNICAL_ERROR_TITLE}
                    alertMessage={Constants.GENERIC_TECHNICAL_ERROR_MSG}
                />
            )
        }
    }

    tagFormEvent(label, stepName, errorName, errorDetails) {
        const tagParms = {
            label: label,
            sequenceId: this.state.gtmSequenceId,
            stepName: stepName,
            stepValue: this.state.stepNo,
            errorName: errorName,
            errorDetails: errorDetails
        }
        TagManager.dataLayer(getForgottenArgs(tagParms))
    }

    componentDidMount() {
        TagManager.dataLayer(getPageLoadArgs('Forgot Username/Password',this.props.location.pathname))

        this.tagFormEvent(TAG_LABEL_STEP_START, TAG_STEP_NAME_USERNAME_OR_PASSWORD)
    }

    render() {
        return (
            <ForgottenContextProvider value={{...this.state}} >
                <div className={classnames('container', styles.contentWrapper)}>
                    {this.renderErrorAlert()}
                    <div className={classnames('row no-gutters')}>
                        <div className={classnames('col-lg-5', styles.colPadding)}>
                            <div className={classnames('', styles.forgottenWrapper)}>
                                {this.renderForgottenStep()}
                                {this.renderRememberedLink()}
                            </div>
                        </div>
                        <div className={classnames('col-lg-7', styles.sidebarWrapper)}>
                            <PortalInfoSidebarComponent/>
                        </div>
                    </div>
                </div>
            </ForgottenContextProvider>
            )
    }
}


export default ForgottenContainer;