import React, { Component } from 'react';
import styles from './ScheduledOutageComponent.module.scss';
import classnames from 'classnames'
import { ReactComponent as InfoIcon } from "svgs/icon-24-7.svg"

class ScheduledOutageComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            outageMessage: []
        }
    }

    async componentDidMount() {
        try {
            const response = await fetch('outage/scheduled-outage-message.txt');
            if (response.ok) {
                const messageContent = await response.text()
                if (messageContent && messageContent.length > 0) {
                    const splitMessage = messageContent.split(/\r|\n/)
                    const messageLines = splitMessage.filter((line) => {
                        return !line.startsWith('#') && line.trim().length > 0
                    })
                    this.setState({
                        outageMessage: messageLines
                    })
                }
            }
        }
        catch(error) {
            // Do nothing
        }
    }

    render() {

        if (this.state.outageMessage.length > 0) {
            return (
                <div className='row'>
                    <div className={classnames('col-lg-12', styles.column)} >
                        <div className={classnames(styles.alertWrapper)}>
                            <div className={classnames('alert bg-neutral-1', styles.alertColor)}
                                 role="alert" data-alert=""
                            >
                                <div className={classnames('alert-icon', styles.alertIconColor)}>
                                    <InfoIcon />
                                </div>

                                <div data-test-id='outageMessage' className="alert-body">
                                    <div className={styles.alertTitle}>Upcoming Scheduled Maintenance</div>
                                    {this.state.outageMessage.map((line, i) => (
                                        <p key={i}>{line}</p>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return null
        }
    }
}

export default ScheduledOutageComponent;