import React, { Component } from 'react';
import WelcomeComponent from 'components/dashboard/WelcomeComponent';
import DashboardComponent from 'components/dashboard/DashboardComponent';
import classnames from 'classnames';
import UserContext from "context/UserContext";
import {getPageLoadArgs} from "utils/GTMHelper"
import TagManager from "react-gtm-module";

class HomeContainer extends Component {

    currentUserHasProviders() {
        return this.context.currentUser && this.context.providerLinks && this.context.providerLinks.length > 0
    }

    componentDidMount() {
        const {cognitoId, role} = this.context.currentUser
        TagManager.dataLayer(getPageLoadArgs('Dashboard', this.props.location.pathname, cognitoId, role))
    }

    renderDashboard() {
        if (this.currentUserHasProviders()) {
            return <DashboardComponent />
        } else {
            return <WelcomeComponent/>
        }
    }

    render() {
        return (
            <div className={classnames("container")}>
                {this.renderDashboard()}
            </div>
        );
    }
}

HomeContainer.contextType = UserContext;

export default HomeContainer;