import React, { Component } from 'react';
import classnames from "classnames";
import Utils from 'utils/Utils';
import PropTypes from 'prop-types';
import styles from './Step4CodeContainer.module.scss';
import TagManager from "react-gtm-module"
import {getRegoStepArgs, TAG_LABEL_FORM_ERROR} from "utils/GTMHelper"
import RegistrationContext from "context/RegistrationContext"

const TAG_STEP_NAME = 'Verify mobile number';

class Step4CodeContainer extends Component {

    constructor(props) {
        super(props);

        this.smsInput = React.createRef();

        this.handleOnChange = this.handleOnChange.bind(this);

        this.state = {
            verificationCode: ''
        }
    }

    tagFieldError(errorName, errorDetails) {
        const {stepNo, gtmSequenceId} = this.context
        const tagParms = {
            label: TAG_LABEL_FORM_ERROR,
            sequenceId: gtmSequenceId,
            stepName: TAG_STEP_NAME,
            stepValue: stepNo,
            errorName: errorName,
            errorDetails: errorDetails
        }

        TagManager.dataLayer(getRegoStepArgs(tagParms))
    }

    handleOnChange(event) {
        const inputValue = event.target.value;
        const {onCodeInput} = this.props;

        // Only allow entry of numbers
        if (inputValue.length > 0 && !Utils.containsOnlyDigits(inputValue))
        {
            return;
        }

        this.setState({
            verificationCode: inputValue
        })

        onCodeInput(inputValue);
    }

    componentDidMount() {
        this.smsInput.current.focus();
    }

    render() {
        const {verificationCode} = this.state;
        const {disabled, invalid, error} = this.props;
        let classes = 'input-group';
        if (invalid) {
            classes += ' input-group-alert';
        }
        var errorMessage = (<span>&nbsp;</span>);
        if (invalid) {
            if (error === 'CodeMismatchException') {
                errorMessage = "Your verification code is not valid. Need a new code? Click resend code to try again."
            } else {
                errorMessage = "Not able to validate the verification code. Please try again."
            }

            this.tagFieldError('SMS Verification Code',errorMessage)
        }
        return (
            <>
                <div className={classes}>
                    <label htmlFor="code">Please enter the 6-digit verification code we have sent to your mobile number.</label>
                    <input id='code'
                           type='text'
                           value={verificationCode}
                           className='input-search form-control'
                           onChange={this.handleOnChange}
                           disabled={disabled}
                           ref={this.smsInput}
                    />
                </div>
                <div data-test-id='error' className={classnames('input-helper form-error small', styles.inputHelper)}>
                    {errorMessage}
                </div>
            </>
        );
    }
}

Step4CodeContainer.contextType = RegistrationContext;

Step4CodeContainer.propTypes = {
    onCodeInput: PropTypes.func.isRequired,
    disabled: PropTypes.bool
}

Step4CodeContainer.defaultProps = {
    disabled: false,
    error: null,
    invalid: false
}


export default Step4CodeContainer;