import React, { Component } from 'react';
import styles from './TokenVerifiedComponent.module.scss';
import { ReactComponent as VerifiedIcon } from "svgs/icon-verified.svg"
import classnames from 'classnames';
import PropTypes from 'prop-types'
import TagManager from "react-gtm-module"
import {getFormEventArgs} from "utils/GTMHelper"

class TokenVerifiedComponent extends Component {

    componentDidMount() {
        let tagParms = {
            formName: 'Registration',
            sequenceId: this.props.gtmSequenceId,
            label: 'Confirm Email Complete',
            cognitoId: this.props.cognitoId,
            userType: 'unknown'
        }

        TagManager.dataLayer(getFormEventArgs(tagParms))
    }

    render() {
        const {handleContinue, isContinuing} = this.props

        let disabled = false
        let buttonContents = 'Continue to Provider Self Service';
        if (isContinuing) {
            buttonContents = <div className="spinner-md text-white"></div>;
            disabled = true;
        }

        return (
            <div className={styles.contentWrapper}>
                <VerifiedIcon className={classnames('icon icon-stroke', styles.infoIcon)}/>
                <h2>Thank you</h2>
                <div className={styles.infoMessage}>
                    <p>
                        Your email address has been verified.<br/>
                        You can either close this window or continue to Provider Self Service
                    </p>
                </div>
                <button
                    onClick={handleContinue}
                    className={classnames('btn btn-primary btn-lg',styles.button)}
                    disabled={disabled}
                >
                    {buttonContents}
                </button>
            </div>
        );
    }
}

TokenVerifiedComponent.propTypes = {
    handleContinue: PropTypes.func.isRequired,
    isContinuing: PropTypes.bool.isRequired,
    gtmSequenceId: PropTypes.string.isRequired,
    cognitoId: PropTypes.string.isRequired
}


export default TokenVerifiedComponent;