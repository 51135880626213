import React, {Component} from "react";
import PropTypes from 'prop-types';
import moment from 'moment';
import DayPicker from "react-day-picker/DayPicker";
import YearMonthForm from './YearMonthFormComponent';
import CalendarIcon from "medikit/dist/base/svgs/icon-calendar.svg";
import './react-day-picker-customised.css';
import classnames from "classnames";

const dateInputStyle = {
    backgroundImage: `url(${CalendarIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '97.5% 50%',
    backgroundSize: '16px 16px'
};

class CustomisedDayPickerInputComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentMonth: this.props.currentMonth
        }

        this.handleYearMonthChange = this.handleYearMonthChange.bind(this);
    }

    /***
     * When the year or month is changed using the YearMonthFormComponent
     * apply this change to the calendar component so it shows days for
     * the current month / year.
     * @param month
     */
    handleYearMonthChange(month) {
        this.setState({
            currentMonth: month
        });
    }

    renderPicker() {
        if (this.props.showPicker) {
            return (
                <DayPicker
                    onDayClick={this.props.handleDateChange}
                    selectedDays={[this.props.selectedDate]}
                    showOutsideDays={true}
                    month={this.state.currentMonth}
                    fromMonth={this.props.minDate}
                    toMonth={this.props.maxDate}
                    disabledDays={[{
                        before: this.props.disableBeforeDate,
                        after: this.props.disableAfterDate
                    }]}
                    captionElement={({ date, localeUtils }) => (
                        <YearMonthForm
                            date={date}
                            localeUtils={localeUtils}
                            onChange={this.handleYearMonthChange}
                            minDate={this.props.minDate}
                            maxDate={this.props.maxDate}
                        />
                    )}
                />
            )
        } else {
            return null;
        }
    }
    render() {
        const {selectedDate, inError} = this.props;
        let inputValue = '';
        if (selectedDate) {
            inputValue = moment(selectedDate).format('DD/MM/YYYY');
        }

        return (
            <div>
                <input placeholder={this.props.placeHolder} type="text"
                       className={classnames('input-search form-control', {error: inError} )}
                       style={dateInputStyle}
                       readOnly
                       onClick={this.props.handleInputClick}
                       value={inputValue}
                />
                {this.renderPicker()}
            </div>
        )
    }

}

CustomisedDayPickerInputComponent.propTypes = {
    handleDateChange: PropTypes.func.isRequired,
    currentMonth: PropTypes.instanceOf(Date),
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
    disableBeforeDate: PropTypes.instanceOf(Date),
    disableAfterDate: PropTypes.instanceOf(Date),
    handleInputClick: PropTypes.func.isRequired,
    showPicker: PropTypes.bool,
    selectedDate: PropTypes.instanceOf(Date),
    inError: PropTypes.bool,
};

CustomisedDayPickerInputComponent.defaultProps = {
    placeHolder: 'DD/MM/YYYY',
    showPicker: false,
    inError: false
};

export default CustomisedDayPickerInputComponent;