// eslint-disable
// this is an auto generated file. This will be overwritten


export const getSpecialities = `query GetSpecialities {
  getSpecialities {
    id
    name
  }
}
`;

export const searchRemittances = `query SearchRemittances($filter: RemittanceFilter, $pagination: Pagination, $sortOrder: SortOrder) {
  searchRemittances(filter: $filter, pagination: $pagination, sortOrder: $sortOrder) {
    size
    remittances {
      id
      dateOfIssue
      eft
      documentNumber
      provider {
        id
        number
        name
        address
      }
    }
  }
}
`;

export const getRemittance = `query GetRemittance($remittanceId: ID!) {
  getRemittance(remittanceId: $remittanceId) {
    authorized
    url
  }
}
`;

export const getUser = `query GetUser {
  getUser {
    cognitoId
    username
    firstName
    lastName
    mobile
    email
    role
    dob
    links {
      role
      status
      providerNumber
      approver {
        username
        firstName
        lastName
      }
      provider {
        id
        practiceName
        address
        isOwner
        providerNumber
        firstname
        lastname
        ahpra
        stem
        speciality {
            name
        }
        managedUsers {
          username
          firstName
          lastName
          mobile
          email
          role
          dob
          links {
            role
            provider {
                firstname
                lastname
                practiceName
                providerNumber
                address
            }
            status
           }
        }
      }
    }
  }
}
`;

export const getProviderDetails = `query GetProviderDetails($input: GetProviderDetailsInput) {
  getProviderDetails(input: $input) {
    firstname
    lastname
    stem
    ahpra
    locations {
      providerNumber
      address
      practiceName
      foundInPortal
    }
  }
}
`;

export const lookupProvider = `query LookupProvider($input: GetProviderLocationsInput) {
  lookupProvider(input: $input) {
    providerNumber
    firstname
    lastname
    address
    practiceName
    foundInPortal
    linkedToUser
  }
}
`;


export const findUser = `query FindUser($filter: UserFilter) {
  findUser(filter: $filter) {
    result
  }
}
`;

export const getClaim = `query GetClaim($input: GetClaimInput) {
  getClaim(input: $input) {
    claimNo
    patient {
      firstname
      lastname
      membershipNo
      businessPartnerId
    }
    dateLodged
    chargeAmount
    status
    assessedDate
    benefitAmount
    transactionId
    referenceNo
    provider {
      name
      number
      address
    }
    items {
      itemId
      serviceDate
      paymentDate
      assessmentDate
      eftNumber
      serviceCharge
      mbsItemNo
      mbsFee
      medicarePayment
      mplBenefitAmount
      totalItemAmount
      status
      serviceProvider {
        number
        name
      }
      reasons {
        code
        description
      }
    }
  }
}
`;

export const searchClaims = `query SearchClaims($filter: SearchClaimFilter) {
  searchClaim(filter: $filter) {
    size
    claims {
      claimNo
      patient {
        firstname
        lastname
        membershipNo
        businessPartnerId
      }
      dateLodged
      chargeAmount
      status
      assessedDate
      benefitAmount
      transactionId
      referenceNo
      provider {
        name
        number
      }
    }
  }
}
`;


export const isMobileRegistered = `query isMobileRegistered($mobile: String) {
  isMobileRegistered(mobile: $mobile) {
    exists
  }
}
`;