import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './DetailLineItemsItemComponent.module.scss'
import Utils from 'utils/Utils'
import classnames from 'classnames'
import { ReactComponent as ChevronIcon } from 'medikit/dist/base/svgs/icon-chevron-down.svg';
import SearchCriteriaContext from "context/SearchCriteriaContext";
import TagManager from "react-gtm-module";
import {getClaimsSearchArgs} from "utils/GTMHelper"

class DetailLineItemsItemComponent extends Component {

    constructor(props) {
        super(props)

        this.toggleReasonCodes = this.toggleReasonCodes.bind(this)

        this.state = {
            isReasonCodesVisible: false
        }
    }

    tagViewReasonCodes() {
        const {gtmSequenceId, currentUser} = this.context
        const {claim} = this.props

        const tagParms = {
            sequenceId: gtmSequenceId,
            searchByValue: claim.provider.number,
            username: currentUser.username,
            claimNo: claim.claimNo
        }

        const tagData = getClaimsSearchArgs(tagParms)
        tagData.dataLayer.userId = currentUser.username
        tagData.dataLayer.label = 'Reason Codes Clicked'

        TagManager.dataLayer(tagData)
    }

    toggleReasonCodes() {
        if (!this.state.isReasonCodesVisible) {
            this.tagViewReasonCodes()
        }

        this.setState({
            isReasonCodesVisible: !this.state.isReasonCodesVisible
        })
    }

    hasReasonCodes() {
        const {claimLine} = this.props
        return claimLine.reasons && claimLine.reasons.length > 0
    }

    renderStatusCell() {
        const cellInlineStyle = {borderLeft: '1px solid #d8dbe3', paddingRight: '5px'}
        const {claimLine} = this.props
        const newStatus = Utils.translateClaimLineStatus(claimLine.status)
        const isAssessed = (newStatus === 'Assessed')
        const hasReasonCodes = this.hasReasonCodes()
        const {isReasonCodesVisible} = this.state
        const chevronWrapperStyles = isAssessed ? classnames(styles.statusRight) : {}
        const chevronStyles = isReasonCodesVisible ? classnames(styles.statusChevronOpen) : classnames(styles.statusChevron)


        if (hasReasonCodes) {
            return (
                <td className={classnames(styles.statusCell)} style={cellInlineStyle}>
                    <div className={classnames(styles.statusWrapper)}>
                        <div>
                            <span data-test-id='statusLink' onClick={this.toggleReasonCodes} className={classnames('simple-link-reverse', styles.statusLink)}>{newStatus}</span>
                        </div>
                        <div className={chevronWrapperStyles}>
                            <span data-test-id='statusChevron' onClick={this.toggleReasonCodes} className={chevronStyles}><ChevronIcon /></span>
                        </div>
                    </div>
                </td>
            )
        } else {
            return (
                <td className={styles.statusCell} style={cellInlineStyle}>{newStatus}</td>
            )
        }
    }

    renderLineItem() {
        const {claimLine} = this.props
        const fallbackDate = "";

        return (

            <tr>

                <td className={styles.datesBottomCell}>{Utils.formatEpochToDate(claimLine.serviceDate, fallbackDate)}</td>
                <td className={styles.datesBottomCell}>{Utils.formatEpochToDate(claimLine.paymentDate, fallbackDate)}</td>
                <td className={styles.datesAssessCell}>{Utils.formatEpochToDate(claimLine.assessmentDate, fallbackDate)}</td>
                <td className={styles.providerTopCell} style={{borderLeft: '1px solid #d8dbe3', borderRight: '1px solid #d8dbe3'}}>
                    <div className={styles.providerName}><b>{claimLine.serviceProvider.name}</b></div>
                    <div>{claimLine.serviceProvider.number}</div>
                </td>
                <td className={styles.chequeCell}>{claimLine.eftNumber}</td>
                <td className={styles.serviceCell}>{claimLine.serviceCharge}</td>
                <td className={styles.mbsItemNoCell}>{claimLine.mbsItemNo}</td>
                <td className={styles.mbsFeeCell}>{claimLine.mbsFee}</td>
                <td>{claimLine.medicarePayment}</td>
                <td className={styles.mplCell}>{claimLine.mplBenefitAmount}</td>
                <td className={styles.benefitCell}>{claimLine.totalItemAmount}</td>
                {this.renderStatusCell()}
            </tr>
        )
    }

    renderReasonCodes() {
        if (this.state.isReasonCodesVisible) {
            const {claimLine} = this.props
            const newStatus = Utils.translateClaimLineStatus(claimLine.status)
            const reasonCodesComp = claimLine.reasons.map((reasonCode) => {
                return (
                    <tr data-test-id='reasonCode' key={reasonCode.code} style={{backgroundColor: '#e6e9f0'}}>
                        <td colSpan='3'></td>
                        <td><b>{reasonCode.code}</b></td>
                        <td colSpan='8'>{reasonCode.description}</td>
                    </tr>
                )
            });

            return (
                <>
                    <tr data-test-id='reasonCodeHdr' style={{backgroundColor: '#e6e9f0'}}>
                        <td colSpan='3'>Status: <b>{newStatus}</b></td>
                        <td>Reason code:</td>
                        <td colSpan='8'><b>Reason description:</b></td>
                    </tr>
                    {reasonCodesComp}
                </>
            )
        }
    }

    render() {
        return (
            <>
                {this.renderLineItem()}
                {this.hasReasonCodes() && this.renderReasonCodes()}
            </>
        )
    }
}

DetailLineItemsItemComponent.propTypes = {
    claimLine: PropTypes.instanceOf(Object).isRequired,
    claim: PropTypes.object.isRequired
};

DetailLineItemsItemComponent.contextType = SearchCriteriaContext;
export default DetailLineItemsItemComponent;
