import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './EmailComponent.module.scss';
import Utils from 'utils/Utils'
import debounce from 'lodash/debounce'

class EmailComponent extends Component {

    constructor(props) {
        super(props);

        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);
        this.inputValidationDB = debounce(this.inputValidation,500);

        this.state = {
            email: this.props.oldEmailAddress,
            inError: false,
            isEmailUnchanged: false
        }
    }

    inputValidation(inputValue) {
        let isValid = Utils.validateEmailAddress(inputValue)
        const {oldEmailAddress} = this.props;

        // If supplied, compare the old email address to the email address entered.
        // They need to be different.
        if (oldEmailAddress !== '') {
            let isEmailUnchanged = false;
            if (oldEmailAddress === inputValue) {
                isValid = false;
                isEmailUnchanged = true
            }
            this.setState({
                isEmailUnchanged: isEmailUnchanged
            })
        }

        const {onEmailInput} = this.props;
        onEmailInput(inputValue, !isValid);

        this.setState({
            inError: !isValid
        })
    }

    handleOnChange(event) {
        const inputValue = event.target.value;
        if (Utils.containsSpace(inputValue) ||
            inputValue.length > 100) {
            return;
        }

        this.inputValidationDB(inputValue);

        this.setState({
            email: inputValue
        })
    }

    handleOnBlur() {
        const {email} = this.state;
        this.inputValidation(email)
    }

    render() {
        const {email, inError, isEmailUnchanged} = this.state;
        const {disabled, showLabel, placeholder} = this.props;
        let errorMessage = 'Please enter a valid email address in the following format: 123@domain.x'
        let classes = 'input-group';
        if (inError) {
            classes += ' input-group-alert';
            if (isEmailUnchanged) {
                errorMessage = 'The email address you have entered is the same as your current email address.'
            }
        }

        return (
            <>
                <div className={classes}>
                    {showLabel &&
                        <label htmlFor="email">Email</label>
                    }
                    <input id='email'
                           type='text'
                           value={email}
                           className='input-search form-control'
                           onChange={this.handleOnChange}
                           disabled={disabled}
                           onBlur={this.handleOnBlur}
                           placeholder={placeholder}
                    />
                </div>
                {inError &&
                <div data-test-id='error' className={classnames('input-helper form-error small', styles.inputHelper)}>
                    {errorMessage}
                </div>
                }
            </>
        );
    }
}

EmailComponent.propTypes = {
    onEmailInput: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    oldEmailAddress: PropTypes.string,
    showLabel: PropTypes.bool,
    placeholder: PropTypes.string
}

EmailComponent.defaultProps = {
    disabled: false,
    oldEmailAddress: '',
    showLabel: true,
    placeholder: ''
}

export default EmailComponent;