import React, { Component } from 'react';
import './datepickers/react-day-picker-customised.css';
import DateRangePickerComponent from "./datepickers/DateRangePickerComponent";
import SearchCriteriaContext from "context/SearchCriteriaContext";
import PropTypes from 'prop-types';

const buttonGroupStyles = {
    maxWidth: '320px'
}

class DatePeriodSearchComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedOption: 'duration'
        }

        this.toggleDateOptions = this.toggleDateOptions.bind(this);
        this.handleDurationChange = this.handleDurationChange.bind(this);
    }

    toggleDateOptions(event) {
        const option = event.target.value;
        if (option === 'duration') {
            this.context.actions.setSearchDuration('30');
        } else {
            this.context.actions.setFromDate(null);
            this.context.actions.setToDate(null);
        }

        this.setState({
            selectedOption: event.target.value
        });
    }

    handleDurationChange(event) {
        this.context.actions.setSearchDuration(event.target.value);
    }

    renderSelectedPeriodOption() {
        if (this.state.selectedOption === 'duration') {
            return (
                <div className='input-group select'>
                    <select onChange={this.handleDurationChange} className='form-control' type="select" name="select" id="duration">
                        <option value='30'>Last 30 days</option>
                        <option value='60'>Last 60 days</option>
                        <option value='90'>Last 90 days</option>
                    </select>
                    <span className='select-chevron'></span>
                </div>
            )
        } else {
            const {earliestSelectableDate, restrictedDateRangePeriod} = this.props;
            const latestSelectableDate = new Date();
            const {searchFromDate, searchToDate, actions} = this.context;
            return (
                <DateRangePickerComponent
                    earliestSelectableDate={earliestSelectableDate}
                    latestSelectableDate={latestSelectableDate}
                    initialFromMonth={latestSelectableDate}
                    initialToMonth={latestSelectableDate}
                    onFromDateSelect={actions.setFromDate}
                    onToDateSelect={actions.setToDate}
                    selectedFromDate={searchFromDate}
                    selectedToDate={searchToDate}
                    restrictedDateRangePeriod={restrictedDateRangePeriod}
                />
            )
        }
    }

    render() {
        return (
            <div>
                <div className='m-b-1'>
                    <span>{this.props.searchPeriodTitle}</span>
                </div>
                <div>
                    <div style={buttonGroupStyles} className="btn-group btn-group-options btn-group-options-highlight-2 m-b-2">
                        <label className="btn btn-group-radio" htmlFor="option1a">
                            <input type="radio" name="options"
                                   id="option1a"
                                   value="duration"
                                   onChange={this.toggleDateOptions}
                                   checked={(this.state.selectedOption==='duration')} />
                                <span className="input-label-wrapper">Period</span>
                        </label>
                        <label className="btn btn-group-radio" htmlFor="option2a">
                            <input type="radio" name="options"
                                   id="option2a"
                                   value="choose dates"
                                   onChange={this.toggleDateOptions}
                                   checked={(this.state.selectedOption==='choose dates')} />
                            <span className="input-label-wrapper">Choose dates</span>
                        </label>
                    </div>
                </div>

                <div>
                    {this.renderSelectedPeriodOption()}
                </div>
            </div>
        )
    }
}

DatePeriodSearchComponent.contextType = SearchCriteriaContext;

DatePeriodSearchComponent.propTypes = {
    // Represents a period in days to which the range of dates selectable
    // needs to be restricted. For example, if set to 90, then when a From
    // Date is chosen, the To Date will be restricted to being no more
    // than 90 days after the From Date
    restrictedDateRangePeriod: PropTypes.number,
    // Represents the earliest date that can be chosen in the picker. Any
    // days prior to this day will not be able to be selected
    earliestSelectableDate: PropTypes.instanceOf(Date).isRequired,
    searchPeriodTitle: PropTypes.string
};

DatePeriodSearchComponent.defaultProps = {
    searchPeriodTitle: 'Search period'
}

export default DatePeriodSearchComponent;