const awsmobile_prod01 = {
    "aws_project_region": "ap-southeast-2",
    "aws_appsync_graphqlEndpoint": "https://qwsn3luadrfktbibloyu6trthy.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_content_delivery_bucket": "providerportal-prod01" ,
    "aws_content_delivery_bucket_region": "ap-southeast-2",
    "aws_content_delivery_url": "https://d1qtdt9sdle9he.cloudfront.net",
    "aws_cognito_identity_pool_id": "ap-southeast-2:013dc0af-342b-425c-a148-8ec76b063b20",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_uAX5cyf02",
    "aws_user_pools_web_client_id": "5e41k98ln77j4m96c4kfc4rfkd"
};

const config = {
        "prod01": awsmobile_prod01
}

export default config;
