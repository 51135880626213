import React, {Component} from 'react'
import classnames from "classnames";
import styles from "./ManageUserContainer.module.scss";
import AccountNavComponent from "../account/AccountNavComponent";
import UserContext from "context/UserContext";
import Constants from "../../utils/Constants";
import PracticeManagerInfoPanel from "../../components/account/myusers/PracticeManagerInfoPanel";
import TooltipIcon from 'components/TooltipIcon'
import { API, graphqlOperation } from 'aws-amplify';
import {updateRole} from 'graphql/mutations';
import SpinnerButtonComponent from "../../components/spinnerButton/SpinnerButtonComponent";
import WhatAreRolesModal from "../../components/providerSetup/WhatAreRolesModal";
import AlertComponent from 'components/AlertComponent'
import {getPageLoadArgs, getManageUserArgs, getGTMSequenceId} from "utils/GTMHelper"
import TagManager from "react-gtm-module"
import Utils from 'utils/Utils'

class ManageUserContainer extends Component {
    constructor() {
        super()

        this.handleCancel = this.handleCancel.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.handleEdit = this.handleEdit.bind(this)
        this.handleRoleChange = this.handleRoleChange.bind(this)
        this.handleAlertClose = this.handleAlertClose.bind(this)

        this.state = {
            editMode: false,
            user: undefined,
            disabled: true,
            spinning: false,
            showSaveSuccess: false,
            alertIsOpen: false,
            gtmSequenceId: '',
            // An array simply used for tagging purposes. It stores the original roles
            // for the user being updated and uses these original values to actually
            // determine if roles have been changed.
            originalRoles:[]
        }
    }

    componentDidMount() {
        const {cognitoId, role} = this.context.currentUser
        TagManager.dataLayer(getPageLoadArgs('Manager User',this.props.location.pathname, cognitoId, role))

        this.setState({user: this.getUserCopy()})
    }

    renderInfoPanel() {
        if (this.state.editMode) {
            return <PracticeManagerInfoPanel/>
        }
    }

    renderSaveSuccessAlert() {
        if (this.state.showSaveSuccess && this.state.alertIsOpen) {
            const alertMessage = 'Your change has been saved.'

            return (
                <div>
                    <AlertComponent
                        alertSuccess={true}
                        alertMessage={alertMessage}
                        isLargeText={true}
                        handleAlertClose={this.handleAlertClose}
                    />
                </div>
            )
        }
    }

    handleAlertClose() {
        this.setState({
            alertIsOpen: false
        })
    }

    handleRoleChange(event) {
        let user = this.state.user
        let index = event.target.getAttribute('index')
        let p = this.getFilteredProviders(user)[index]
        p.role = event.target.value
        this.setState({user, disabled: false})
    }

    renderRole(p, index) {
        if (this.state.editMode) {
            return <div className={classnames("input-group select", styles.select)}>
                <select index={index} value={p.role} onChange={this.handleRoleChange}>
                    <option value={Constants.USER_ROLE_MAPPING[Constants.USER_ROLE_PM]}>{Constants.USER_ROLE_PM}</option>
                    <option value={Constants.USER_ROLE_MAPPING[Constants.USER_ROLE_STAFF]}>{Constants.USER_ROLE_STAFF}</option>
                </select>
                <span className={classnames("select-chevron", styles.selectChevron)}></span>
            </div>
        } else {
            return <>{Constants.USER_ROLE_REVERSE_MAPPING[p.role]}</>
        }
    }

    renderButtons() {
        if (this.state.editMode) {
            return <div>
                <span data-test-id='cancel-btn' onClick={this.handleCancel} className={'simple-link'}>Cancel</span>
                <SpinnerButtonComponent data-test-id='save-btn' spinning={this.state.spinning} handleClick={this.handleSave} className={'btn btn-primary'} text='Save' disabled={this.state.disabled}/>
            </div>
        } else {
            return <button data-test-id='edit-btn' onClick={this.handleEdit} className={'btn btn-dark-ghost'}>Edit</button>
        }
    }

    handleSave() {
        this.setState({spinning: true}, async () => {
            let success = await this.updateRole()
            if (success) {
                this.setState({
                    editMode: false,
                    disabled: true,
                    spinning: false,
                    showSaveSuccess: true,
                    alertIsOpen: true})
                // this.context.actions.getManagedUsers(true)
                this.context.actions.refreshUser()
            } else {
                //Handle error?
                this.setState({editMode: false, disabled: true, user: this.getUserCopy(), spinning: false})
            }
        })
    }

    async updateRole() {
        let user = this.state.user
        let originalRoles = this.state.originalRoles
        let updateCount = 0
        let links = user.links.map(p => {
            const providerOriginalRole =
                originalRoles.filter(item => item.providerNumber === p.provider.providerNumber)

            // For tagging purposes, only count an update if the role has actually changed
            if (providerOriginalRole.length > 0 &&
                providerOriginalRole[0].originalRole !== p.role) {
                updateCount++
            }

            return {providerNumber: p.provider.providerNumber, role: p.role}
        })
        try {
            const response = await API.graphql(graphqlOperation(updateRole, {input: {username: user.username, links}}));
            this.tagFormEvent('Form Complete', updateCount)

            return response.data.updateRole.result
        }
        catch (error) {
            console.log("Error updating roles", error)
        }

    }

    tagFormEvent(label, updateCount) {
        const tagParms = {
            label: label,
            sequenceId: this.state.gtmSequenceId,
            permissionsModifiedCount: updateCount
        }

        TagManager.dataLayer(getManageUserArgs(tagParms))
    }

    handleCancel() {
        this.tagFormEvent('Form Complete - Cancel')

        this.setState({editMode: false, disabled: true, user: this.getUserCopy()})
    }

    handleEdit() {
        const gtmSequenceId = getGTMSequenceId()

        // For tagging purposes, record the original role in the state so we
        // can count how many roles have actually been changed
        const filteredProviders = this.getFilteredProviders(this.state.user)
        let originalRoles = []
        filteredProviders.forEach((provider) => {
            let p = {
                providerNumber: provider.provider.providerNumber,
                originalRole: provider.role
            }
            originalRoles.push(p)
        })

        this.setState({
                editMode: true,
                gtmSequenceId: gtmSequenceId,
                originalRoles: originalRoles
            },
            () => {
                this.tagFormEvent('Start')
            })
    }

    getUserCopy() {
        const {match: {params: {username}}} = this.props
        let users = this.context.managedUsers.filter(u => u.username === username)
        let user = undefined
        if (users.length > 0) {
            user = users[0]
        }
        user = JSON.parse(JSON.stringify(user))
        for (let i = 1; i < users.length; i++) {
            user.links = user.links.concat(users[i].links)
        }
        return user
    }

    getFilteredProviders(user) {
        return user.links
            .filter(p => p.status === Constants.ACCEPTED)
            .filter(p => p.role !== Constants.USER_ROLE_MAPPING[Constants.USER_ROLE_DOCTOR])
    }

    render() {
        let user = this.state.user
        if (!user) return <></>
        let providers = this.getFilteredProviders(user)

        return <div className={classnames('container', styles.mainWrapper)}>
            <div className='row'>
                <div className='col-lg-3'>
                    <AccountNavComponent/>
                </div>
                <div className={classnames('col-lg-9')}>
                    <div className={classnames(styles.providerHeader)}>
                        <h3>Manage user</h3>
                    </div>
                    <div className={classnames('', styles.contentBox)}>
                        <h4>{user.firstName} {user.lastName}</h4>
                        <h5>Email</h5>
                        <p>{user.email}</p>
                    </div>
                    {this.renderInfoPanel()}
                    <div>
                        <div className={classnames(styles.providerHeader)}>
                            <h3>Connected providers</h3>
                            {this.renderButtons()}
                        </div>
                        {this.renderSaveSuccessAlert()}
                        <div>
                            <table className={classnames('table table-striped table-responsive', styles.table)}>
                                <thead>
                                <tr>
                                    <th className={styles.th1}>Provider number</th>
                                    <th className={styles.th2}>Address/Addresses</th>
                                    <th className={styles.th3}>
                                        Role
                                        <span className='pointer'>
                                            <TooltipIcon showModal={true}>
                                                <WhatAreRolesModal/>
                                            </TooltipIcon>
                                        </span>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                { providers.map((p, index) => {
                                    const providerName = Utils.buildProviderName(p.provider)
                                    return (
                                        <tr key={p.provider.providerNumber}>
                                            <td>{p.provider.providerNumber}</td>
                                            <td>
                                                <b>{providerName}</b><br/>
                                                {!p.provider.practiceName.toLowerCase().includes(providerName.toLowerCase()) &&
                                                <>
                                                    {p.provider.practiceName}<br/>
                                                </>
                                                }
                                                {p.provider.address}
                                            </td>
                                            <td>{this.renderRole(p, index)}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    }
}

ManageUserContainer.contextType = UserContext;

export default ManageUserContainer