import React from "react";

const UserContext = React.createContext({
    currentUser: undefined,
    providers: [],
    updateProviders: (providers) => {},
    updateUser: (user) => {},
    managedUsers: []
});
export const UserContextProvider = UserContext.Provider;
export default UserContext;

