import React, { Component } from 'react';
import styles from './VerifyEmailContainer.module.scss';
import classnames from 'classnames';
import UserContext from 'context/UserContext'
import AlertComponent from "components/AlertComponent"
import VerifyEmailComponent from "components/verification/VerifyEmailComponent";
import Constants from "utils/Constants"
import {API, graphqlOperation} from "aws-amplify"
import {sendEmailVerification} from "graphql/mutations"
import Utils from 'utils/Utils'
import {Redirect} from "react-router-dom";
import TagManager from "react-gtm-module";
import {getPageLoadArgs} from "utils/GTMHelper"

class VerifyEmailContainer extends Component {

    constructor(props) {
        super(props);

        this.errorRef = React.createRef();
        this.handleError = this.handleError.bind(this)
        this.handleNext = this.handleNext.bind(this);
        this.handleResendEmail = this.handleResendEmail.bind(this)

        this.state = {
            hasError: false,
            checkingVerification: false,
            toDashboard: false
        }
    }

    handleError() {
        this.setState({
            hasError: true
        })
    }

    async handleNext() {
        try {
            this.setState({
                checkingVerification: true
            })
            await this.context.actions.reloadLoggedInUser()

            const authStatus = this.context.actions.isAuthenticatedUser()
            if (authStatus === Constants.AUTH.NOT_VERIFIED) {
                this.setState({
                    checkingVerification: false
                })
            } else {
                this.setState({
                    toDashboard: true
                })
            }
        }
        catch(error) {
            this.setState({
                hasError: true,
                checkingVerification: false
            }, () => {
                Utils.scrollAlertIntoView(this.errorRef)
            });
        }
    }

    async handleResendEmail() {
        this.setState({
            hasError: false
        })

        try {
            const {currentUser} = this.context
            let input = {
                username: currentUser.username
            }

            await API.graphql(graphqlOperation(sendEmailVerification, {
                    input
                }
            ));

        }
        catch(error) {
            this.setState({
                hasError: true
            }, () => {
                Utils.scrollAlertIntoView(this.errorRef)
            });
        }
    }


    componentDidMount() {
        const {cognitoId, role} = this.context.currentUser
        TagManager.dataLayer(getPageLoadArgs('Email Verification Needed',this.props.location.pathname, cognitoId, role))

        window.scrollTo(0, 0);
    }

    renderErrorMessage() {
        return (
            <div className={classnames('container', styles.errorWrapper)}>
                <AlertComponent
                    forwardedRef={this.errorRef}
                    alertTitle={Constants.GENERIC_TECHNICAL_ERROR_TITLE}
                    alertMessage={Constants.GENERIC_TECHNICAL_ERROR_MSG}
                />
            </div>
        )
    }

    render() {
        const {currentUser} = this.context

        if (this.state.toDashboard) {
            return (
                <Redirect to='/' />
            )
        } else {
            return (
                <>
                    {this.state.hasError && this.renderErrorMessage()}
                    <VerifyEmailComponent
                        email={currentUser.email}
                        handleNext={this.handleNext}
                        handleResendEmail={this.handleResendEmail}
                        checkingVerification={this.state.checkingVerification}
                    />
                </>
            )
        }

    }
}

VerifyEmailContainer.contextType = UserContext;

export default VerifyEmailContainer;