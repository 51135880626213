import moment from 'moment'


const TIMESTAMP_FORMAT = 'YYYYMMDDHHmmssSSS'

const timeSinceStart = (sequenceId) => {
    const startTime = moment(sequenceId.substring(0,17), TIMESTAMP_FORMAT)
    const now = new moment()
    const duration = now.diff(startTime, 'seconds')
    return duration
}

export const TAG_LABEL_FIELD_COMPLETE = 'Field Complete'
export const TAG_LABEL_FORM_COMPLETE = 'Form Complete'
export const TAG_LABEL_FORM_ERROR = 'Form Error'
export const TAG_LABEL_STEP_START = 'Start'
export const TAG_LABEL_STEP_COMPLETE = 'Step Complete'

export const getTimeSinceLogin = () => {
    let timeSinceLogin = 0
    const loginTimestamp = getLoginTimestamp()
    if (loginTimestamp) {
        timeSinceLogin = timeSinceStart(loginTimestamp)
    }
    return timeSinceLogin
}

export const getPageLoadArgs = (pageTitle, pagePath, cognitoId, role) => {

    return {
        dataLayer: {
            event: 'Page Load',
            pageTitle: pageTitle,
            pagePath: pagePath,
            userId: cognitoId,
            userType: role,
            timeSinceLogin: getTimeSinceLogin()
        }
    }
}

export const getFormEventArgs = (tagParms) => {

    let dataArgs = {
        dataLayer: {
            event: 'Form Event',
            formName: tagParms.formName,
            sequenceID: tagParms.sequenceId,
            label: tagParms.label,
            timeSinceStart: timeSinceStart(tagParms.sequenceId)
        }
    }

    if (tagParms.cognitoId) {
        dataArgs.dataLayer.userId = tagParms.cognitoId
        dataArgs.dataLayer.userType = tagParms.userType
    }
    return dataArgs
}

export const getRegoStepArgs = (tagParms) => {

    let dataArgs = {
        dataLayer: {
            event: 'Form Event',
            formName: 'Registration',
            sequenceID: tagParms.sequenceId,
            label: tagParms.label
        }
    }

    if (tagParms.stepName) {
        const step = {
            name: tagParms.stepName,
            value: tagParms.stepValue
        }

        dataArgs.dataLayer.step = step
        dataArgs.dataLayer.timeSinceStart = timeSinceStart(tagParms.sequenceId)
    }

    if (tagParms.fieldName) {
        const field = {
            name: tagParms.fieldName,
            value: tagParms.fieldValue
        }

        dataArgs.dataLayer.field = field
    }

    if (tagParms.errorName) {
        const error = {
            name: tagParms.errorName,
            details: tagParms.errorDetails
        }

        dataArgs.dataLayer.error = error
    }

    if (tagParms.cognitoId) {
        dataArgs.dataLayer.userid = tagParms.cognitoId
        dataArgs.dataLayer.usertype = tagParms.usertype
    }
    return dataArgs
}

export const getLoginStepArgs = (tagParms) => {

    let dataArgs = {
        dataLayer: {
            event: 'Form Event',
            formName: 'Login',
            sequenceID: tagParms.sequenceId,
            label: tagParms.label
        }
    }

    if (tagParms.stepName) {
        const step = {
            name: tagParms.stepName,
            value: tagParms.stepValue
        }

        dataArgs.dataLayer.step = step
        dataArgs.dataLayer.timeSinceStart = timeSinceStart(tagParms.sequenceId)
    }

    if (tagParms.cognitoId) {
        dataArgs.dataLayer.userId = tagParms.cognitoId
        dataArgs.dataLayer.userType = tagParms.usertype
    }

    if (tagParms.errorName) {
        const error = {
            name: tagParms.errorName,
            details: tagParms.errorDetails
        }

        dataArgs.dataLayer.error = error
    }

    return dataArgs
}

export const getGTMSequenceId = () => {

    const dts = new moment().format(TIMESTAMP_FORMAT)
    const rand = Math.random()
    const uniqueId = dts + '-' + rand.toString(36).substring(2);

    return uniqueId
}

export const getLoginTimestamp = () => {
    return sessionStorage.getItem('timeLoggedIn')
}

export const setLoginTimestamp = () => {
    sessionStorage.setItem('timeLoggedIn', new moment().format(TIMESTAMP_FORMAT))
}

export const clearLoginTimestamp = () => {
    sessionStorage.removeItem('timeLoggedIn')
}

export const getRemittanceSearchArgs = (sequenceId, searchByType, searchByValue,
    periodType, periodValue, resultsCount, username, documentId, documentSequenceNo) => {

    let dataArgs = {
        dataLayer: {
            event: 'Search Event',
            search: {
                name: 'Remittance',
                type: searchByType,
                term: searchByValue,
                periodType: periodType,
                period: periodValue
            },
            results: {
                totalResults: resultsCount,
            },
            sequenceID: sequenceId,
            label: "Search Submitted",
            userId: username
        }
    }

    if (documentId) {
        dataArgs.dataLayer.results.selected = documentId
        dataArgs.dataLayer.results.selectedNumber = documentSequenceNo
        dataArgs.dataLayer.label = 'Search Result Clicked'
    }

    return dataArgs
}

export const getClaimsSearchArgs = (tagParms) => {

    let dataArgs = {
        dataLayer: {
            event: 'Search Event',
            search: {
                name: 'Claim',
                type: 'Provider',
                term: tagParms.searchByValue,
                periodType: tagParms.periodType,
                period: tagParms.periodValue,
                patientType: tagParms.patientType,
            },
            results: {
                totalResults: tagParms.resultsCount,
            },
            sequenceID: tagParms.sequenceId,
            label: "Search Submitted",
            userId: tagParms.username
        }
    }

    if (tagParms.claimNo) {
        dataArgs.dataLayer.results.selected = tagParms.claimNo
        dataArgs.dataLayer.results.selectedNumber = tagParms.claimSequenceNo
        dataArgs.dataLayer.label = 'Search Result Clicked'
    }

    return dataArgs
}

export const getYourDetailsArgs = (tagParms) => {

    let dataArgs = {
        dataLayer: {
            event: 'Form Event',
            formName: 'Edit Details - ' + tagParms.detailsType,
            sequenceID: tagParms.sequenceId,
            label: tagParms.label
        }
    }

    if (tagParms.label.toLowerCase().includes('complete')) {
        dataArgs.dataLayer.timeSinceStart = timeSinceStart(tagParms.sequenceId)
    }

    return dataArgs
}

export const getManageProvidersArgs = (tagParms) => {

    let dataArgs = {
        dataLayer: {
            event: 'Form Event',
            formName: 'Provider details',
            sequenceID: tagParms.sequenceId,
            label: tagParms.label,
            timeSinceStart: timeSinceStart(tagParms.sequenceId)
        }
    }

    if (tagParms.manageType) {
        dataArgs.dataLayer.formName += (' - ' + tagParms.manageType)
    }

    if (tagParms.stepName) {
        const step = {
            name: tagParms.stepName,
            value: tagParms.stepValue
        }

        dataArgs.dataLayer.step = step
    }

    if (tagParms.errorName) {
        const error = {
            name: tagParms.errorName,
            details: tagParms.errorDetails
        }

        dataArgs.dataLayer.error = error
    }

    return dataArgs
}

export const getManageUserArgs = (tagParms) => {

    let dataArgs = {
        dataLayer: {
            event: 'Form Event',
            formName: 'Manager Users - Permissions',
            sequenceID: tagParms.sequenceId,
            label: tagParms.label,
            timeSinceStart: timeSinceStart(tagParms.sequenceId)
        }
    }

    if (tagParms.permissionsModifiedCount >= 0) {
        dataArgs.dataLayer.data = tagParms.permissionsModifiedCount
    }

    return dataArgs
}

export const getForgottenArgs = (tagParms) => {

    let dataArgs = {
        dataLayer: {
            event: 'Form Event',
            formName: 'Forgot Username or Password',
            sequenceID: tagParms.sequenceId,
            label: tagParms.label,
            timeSinceStart: timeSinceStart(tagParms.sequenceId)
        }
    }

    if (tagParms.stepName) {
        const step = {
            name: tagParms.stepName,
            value: tagParms.stepValue
        }

        dataArgs.dataLayer.step = step
    }

    if (tagParms.errorName) {
        const error = {
            name: tagParms.errorName,
            details: tagParms.errorDetails
        }

        dataArgs.dataLayer.error = error
    }

    return dataArgs
}

export const getLogoutArgs = (tagParms) => {

    const dataArgs = {
        dataLayer: {
            event: 'Form Event',
            formName: 'Logout',
            timeSinceLogin: getTimeSinceLogin(),
            userId: null,
            userType: null
        }
    }

    return dataArgs
}
