import React, { Component } from 'react';
import styles from './Step2Container.module.scss';
import RegistrationContext from 'context/RegistrationContext';
import Step2DVSInfoComponent from './Step2DVSInfoComponent';
import Step2FormContainer from './Step2FormContainer';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { API, graphqlOperation } from 'aws-amplify'
import { idCheck } from "graphql/mutations"
import Constants from 'utils/Constants'
import TagManager from "react-gtm-module"
import { getRegoStepArgs, TAG_LABEL_FIELD_COMPLETE, TAG_LABEL_FORM_ERROR } from "utils/GTMHelper"
import Utils from 'utils/Utils'

const TAG_STEP_NAME = 'ID Verification';

class Step2Container extends Component {

    constructor(props) {
        super(props);

        this.handleNext = this.handleNext.bind(this);
        this.handleError = this.handleError.bind(this)
        this.handleRestartRegistration = this.handleRestartRegistration.bind(this)
        this.tagFieldChange = this.tagFieldChange.bind(this)
        this.tagFieldError = this.tagFieldError.bind(this)
        this.onDvsCheckboxToggled = this.onDvsCheckboxToggled.bind(this);

        this.state = {
            isCheckingId: false,
            hasIdCheckError: false,
            hasTechError: false,
            idCheckCountsExceeded: false,
            dvsAgreement: false
        }
    }

    tagFieldChange(fieldName) {
        const { stepNo, gtmSequenceId } = this.context
        const tagParms = {
            label: TAG_LABEL_FIELD_COMPLETE,
            sequenceId: gtmSequenceId,
            stepName: TAG_STEP_NAME,
            stepValue: stepNo,
            fieldName: fieldName,
            fieldValue: ''
        }

        TagManager.dataLayer(getRegoStepArgs(tagParms))
    }

    tagFieldError(errorName, errorDetails) {
        const { stepNo, gtmSequenceId } = this.context
        const tagParms = {
            label: TAG_LABEL_FORM_ERROR,
            sequenceId: gtmSequenceId,
            stepName: TAG_STEP_NAME,
            stepValue: stepNo,
            errorName: errorName,
            errorDetails: errorDetails
        }

        TagManager.dataLayer(getRegoStepArgs(tagParms))
    }

    handleRestartRegistration() {
        const { actions } = this.context

        this.tagFieldChange('Change Role')

        actions.restartRegistration()
    }

    async handleNext() {
        const { idVerificationRef, actions } = this.context;

        this.tagFieldChange('Next Button')

        this.setState({
            hasIdCheckError: false,
            hasTechError: false,
            isCheckingId: true
        })

        try {
            const { userFirstName, userLastName, dateOfBirth, driversLicenceNo, driversLicenceState, driversCardNumber } = this.context

            let input = {
                firstname: userFirstName,
                lastname: userLastName,
                dob: dateOfBirth,
                state: driversLicenceState,
                driversLicenceNumber: driversLicenceNo,
                cardNumber: driversCardNumber,
                idVerificationRef: idVerificationRef
            }

            const response = await API.graphql(graphqlOperation(idCheck, {
                input
            }
            ));

            actions.setIdVerificationRef(response.data.idCheck.idVerificationRef);

            if (!Utils.isDevelopmentEnvironment()) {
                /* Non-development mode: enforce the ID check. */
                if (!response.data.idCheck.idVerified) {
                    this.tagFieldError(
                        'Drivers Licence',
                        'Drivers licence check failed validation. Verification Ref: ' + response.data.idCheck.idVerificationRef
                    )

                    this.setState({
                        hasIdCheckError: true,
                        isCheckingId: false
                    })
                } else {
                    actions.completeStep2();
                }
            } else {
                /* Development mode: Skip the ID verification. */
                actions.completeStep2();
            }
        }
        catch (error) {
            this.handleError(error)
        }

    }

    handleError(error) {
        this.tagFieldError(
            'Drivers Licence',
            'Could not validate drivers licence due to a technical error'
        )

        let idCheckCountsExceeded = false;
        let hasIdCheckError = this.state.hasIdCheckError;
        if (error &&  error.errors) {
            idCheckCountsExceeded = error.errors.filter((error) => {
                return error.message.includes("AttemptsExceeded");
            }).length !== 0;

            if (!hasIdCheckError) {
                hasIdCheckError = idCheckCountsExceeded;
            }
        }

        this.setState({
            hasTechError: true,
            isCheckingId: false,
            idCheckCountsExceeded,
            hasIdCheckError,
        })
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    renderTitleSection() {
        const { selectedRole } = this.context;
        let helpText = 'To create an account as a doctor, you must have a STEM number and an AHPRA number.'
        if (selectedRole !== Constants.USER_ROLE_DOCTOR) {
            helpText = 'I am not a doctor. I work as a practice manager or provider employee.'
        }
        return (
            <div className={styles.titleWrapper}>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-lg-10'>
                            <h3>{selectedRole}</h3>
                            <div>
                                {helpText}
                            </div>
                        </div>
                        <div className='col-lg-2'>
                            <button data-test-id='changeRole' onClick={this.handleRestartRegistration} className="btn btn-dark-ghost">Change role</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderErrorSection() {

        const { idVerificationRef } = this.context;
        const { hasIdCheckError, hasTechError, idCheckCountsExceeded } = this.state;
        let errorMessage
        let errorComponent

        if (hasIdCheckError) {
            if (idCheckCountsExceeded) {
                errorMessage = <>Sorry, we’re having trouble verifying your ID details. Please call us on <a className='simple-link-reverse error-message-link' href="tel:1300130460">1300 130 460</a> and quote the reference number{' '} 
                    {idVerificationRef} for your support.</>
                errorComponent = <span data-test-id='errorMsg'>{errorMessage}</span>
            } else {
                errorMessage = "Sorry, we’re having trouble verifying your ID details. Please check your details and try again."
                errorComponent = <span data-test-id='errorMsg'>{errorMessage}</span>
            }
        } else if (hasTechError) {
            errorMessage = "Sorry, we are having a technical issue verifying your ID details. Please try again later."
            errorComponent = <span data-test-id='errorMsg'>{errorMessage}</span>
        }

        if (errorMessage) {
            return (
                <div className='row'>
                    <div className={classnames('col-lg-7', styles.fieldsErrorWrapper)}>
                        {errorComponent}
                    </div>
                    <div className={classnames('col-lg-5', styles.copyErrorWrapper)}>
                    </div>
                </div>
            )
        }
    }

    renderContentSection() {
        const disabled = this.state.isCheckingId;

        return (
            <div className={styles.contentWrapper}>
                <div className={classnames('container', styles.content)}>
                    <div className='row'>
                        <div className={classnames('col-lg-7', styles.fieldsWrapper)}>
                            <Step2FormContainer
                                disabled={disabled}
                                onDvsCheckboxToggled={this.onDvsCheckboxToggled}
                            />
                        </div>
                        <div className={classnames('col-lg-5', styles.copyWrapper)}>
                            <Step2DVSInfoComponent />
                        </div>
                    </div>
                    {this.renderErrorSection()}
                    <div className='row'>
                        <div className={classnames('col-lg-7', styles.buttonWrapper)}>
                            {this.renderNextButton()}
                        </div>
                        <div className={classnames('col-lg-5', styles.copyWrapper)}>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderNextButton() {
        let disabled = !(this.props.nextEnabled && this.state.dvsCheckboxChecked);

        let buttonContents = 'Next';
        if (this.state.isCheckingId) {
            buttonContents = <div className="spinner-md text-white"></div>;
            disabled = true;
        }

        return (
            <div>
                <div className='input-group'>
                    <button
                        data-test-id='nextBtn'
                        onClick={this.handleNext}
                        className='btn-lg btn btn-primary'
                        disabled={disabled}
                        color="primary">
                        {buttonContents}
                    </button>
                </div>
            </div>
        )
    }

    render() {
        return (
            <>
                {this.renderTitleSection()}
                {this.renderContentSection()}
            </>
        );
    }

    onDvsCheckboxToggled(newValue) {
        this.setState({ dvsCheckboxChecked: newValue });
    }
}

Step2Container.contextType = RegistrationContext;

Step2Container.propTypes = {
    nextEnabled: PropTypes.bool
};

Step2Container.defaultProps = {
    nextEnabled: false
};

export default Step2Container;