import React, { Component } from 'react';
import classnames from "classnames";
import Utils from 'utils/Utils';
import PropTypes from 'prop-types';
import styles from './MobileNoInputContainer.module.scss';

class MobileNoInputContainer extends Component {

    constructor(props) {
        super(props);

        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);

        this.state = {
            mobileNo: '',
            inError: false,
            errorMsg: '',
            blurred: false
        }
    }

    isInputValid(inputValue) {
        return Utils.isValidMobileNo(inputValue).length > 0;
    }

    handleOnChange(event) {
        const inputValue = event.target.value;
        const {onMobileInput} = this.props;

        // Only allow entry of numbers and a maximum of 10 digits
        if (inputValue.length > 10 ||
            (inputValue.length > 0 && !Utils.containsOnlyDigits(inputValue)))
        {
            return;
        }

        let inputInError;
        let errorMsg = Utils.isValidMobileNo(inputValue);
        if (errorMsg.length === 0) {
            inputInError = false;
            onMobileInput(inputValue);
        } else {
            inputInError = true;
            onMobileInput('');
        }

        // If the user has already visited this field, then its ok to
        // show the error as the field input changes
        const showError = this.state.blurred && inputInError;

        this.setState({
            mobileNo: event.target.value,
            inError: showError,
            errorMsg: errorMsg
        })
    }

    /***
     * When the field loses focus, check if the mobile number entered is valid
     */
    handleOnBlur() {
        const {mobileNo} = this.state;
        const errorMsg = Utils.isValidMobileNo(mobileNo)

        this.setState({
            inError: errorMsg.length > 0,
            errorMsg: errorMsg,
            blurred: true
        })
    }

    render() {
        const {mobileNo, inError, errorMsg} = this.state;
        const {disabled, labelText} = this.props;
        let classes = 'input-group';
        if (inError) {
            classes += ' input-group-alert';
        }
        return (
            <>
                <div className={classes}>
                    <label htmlFor="mobile">{labelText}</label>
                    <input id='mobile'
                           type='text'
                           value={mobileNo}
                           className='input-search form-control'
                           onChange={this.handleOnChange}
                           disabled={disabled}
                           onBlur={this.handleOnBlur}
                    />
                </div>
                {inError &&
                <div data-test-id='error' className={classnames('input-helper form-error small', styles.inputHelper)}>
                    {errorMsg}
                </div>
                }
                {
                    this.props.isNumberAlreadyInUse &&
                    <div data-test-id='error' className={classnames('input-helper form-error small', styles.inputHelper)}>
                        This mobile number is already registered. Please enter a new number or login using your existing details.
                    </div>
                }
            </>
        );
    }
}

MobileNoInputContainer.propTypes = {
    disabled: PropTypes.bool,
    isNumberAlreadyInUse: PropTypes.bool,
    labelText: PropTypes.string,
    onMobileInput: PropTypes.func.isRequired,
}

MobileNoInputContainer.defaultProps = {
    disabled: false,
    isNumberAlreadyInUse: false,
    labelText: 'Your mobile number',
}


export default MobileNoInputContainer;