import React, { Component } from 'react';
import styles from './DashboardComponent.module.scss'
import classnames from 'classnames';
import DashboardTile from './DashboardTile'

import { ReactComponent as RemittanceIcon } from 'svgs/ic_search_remittance_advice.svg';
import { ReactComponent as ProvidersIcon } from 'svgs/ic_manage_providers.svg';
// import { ReactComponent as InboxIcon } from 'svgs/ic_inbox.svg';
import { ReactComponent as UsersIcon } from 'svgs/ic_manage_users.svg';
import { ReactComponent as ClaimsIcon } from 'svgs/ic_check_claim_status.svg';
import { ReactComponent as DetailsIcon } from 'svgs/ic_my_details.svg';
import UserContext from "context/UserContext";
import AlertsComponent from "./AlertsComponent";

class DashboardComponent extends Component {

    renderAlerts() {
        return <>
                <AlertsComponent />
            </>
    }

    renderUsersTile() {
        let {canManageUsers} = this.context.actions
        if (canManageUsers()) {
            return <DashboardTile icon={<UsersIcon/>} title='Manage users' link="/myusers"/>
        } else {
            return <DashboardTile title='' />
        }
    }

    render() {
        return (
            <div className={styles.container}>
                <div className={'row'}>
                    <div className={classnames('col-lg-6 ', styles.header)}>
                        <span><span className={styles.username}>Hi {this.context.currentUser.firstName},</span> welcome to Provider Self Service</span>
                    </div>
                </div>
                {this.renderAlerts()}
                <div className={'row'}>
                    <DashboardTile icon={<RemittanceIcon/>} title='Search remittance advice' link="/remittances"/>
                    <DashboardTile icon={<ClaimsIcon/>} title='Check claim status' link="/claims"/>
                    <DashboardTile icon={<ProvidersIcon/>} title='Manage providers' link="/myproviders"/>
                </div>
                <div className={classnames('row', styles.bottomRow)}>
                    {this.renderUsersTile()}
                    {/*<DashboardTile icon={<InboxIcon/>} title='Inbox' link="/inbox"/>*/}
                    <DashboardTile icon={<DetailsIcon/>} title='Your details' link="/mydetails"/>
                    <DashboardTile title='' />
                </div>
            </div>
        );
    }
}

DashboardComponent.contextType = UserContext;

export default DashboardComponent;