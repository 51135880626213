import React, { Component } from 'react';
import styles from './Step1Container.module.scss';
import RegistrationContext from "context/RegistrationContext";
import classnames from 'classnames';
import SelectRoleComponent from 'components/registration/SelectRoleComponent';
import { ReactComponent as StaffIcon } from 'svgs/single-neutral.svg';
import Constants from 'utils/Constants';
import TagManager from "react-gtm-module";
import {getRegoStepArgs,
    TAG_LABEL_FIELD_COMPLETE
} from "utils/GTMHelper"

const TAG_STEP_NAME = 'Select role';
const TAG_FIELD_NAME_ROLE = 'Role';

class Step1Container extends Component {

    constructor(props) {
        super(props);

        this.handleRoleSelect = this.handleRoleSelect.bind(this);
    }

    handleRoleSelect(role) {
        const {stepNo, gtmSequenceId} = this.context
        const tagParms = {
            label: TAG_LABEL_FIELD_COMPLETE,
            sequenceId: gtmSequenceId,
            stepName: TAG_STEP_NAME,
            stepValue: stepNo,
            fieldName: TAG_FIELD_NAME_ROLE,
            fieldValue: role
        }
        TagManager.dataLayer(getRegoStepArgs(tagParms))

        this.context.actions.setSelectedRole(role);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className={styles.step1Wrapper}>
                <div className="container">
                    <div className={styles.introText} >
                        <div>
                            To register with Medibank’s Provider Self Service, you’ll need a current Australian driver's licence,
                            an email address and personal Australian mobile number.
                        </div>
                        <div>
                            We'll ask you to verify your details during registration, so please make sure you have access to your email address and mobile device before you start.
                        </div>
                    </div>
                    <h3>Tell us about your role</h3>
                    <div className={classnames('row',styles.roleRow)}>
                        <div className='col-lg-4'>
                            <SelectRoleComponent
                                icon={<StaffIcon/>}
                                selectedRole='Practice manager & Other'
                                helpText={Constants.USER_ROLE_HELPTEXT[Constants.USER_ROLE_STAFF]}
                                handleRoleSelect={this.handleRoleSelect}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Step1Container.contextType = RegistrationContext;

export default Step1Container;