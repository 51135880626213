import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ProviderListItemComponent from './ProviderListItemComponent';
import Utils from 'utils/Utils'


class ProviderListComponent extends Component {

    constructor(props){
        super(props);

        this.applyNameFilter = this.applyNameFilter.bind(this);

        this.listRef = React.createRef();

        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    /***
     * If the user clicks outside the Provider List component and also outside
     * the associated Provider Input field, then the Provider List should be
     * closed
     * @param event
     */
    handleClickOutside(event) {
        if (this.listRef &&
            !this.listRef.current.contains(event.target) &&
            !this.props.inputRef.current.contains(event.target))
        {
            this.props.handleOnBlur();
        }
    }

    /***
     * Uses the string entered in the Provider Number field and applies it to each
     * provider in the Provider List to see if the string matches (ie is contained
     * in) either the Provider Number or Provider Name
     * @param provider
     * @returns {boolean}
     */
    applyNameFilter(provider) {
        const {filterString} = this.props;
        const providerName = Utils.buildProviderName(provider)
        if (filterString && filterString.length > 0) {
            return provider.practiceName.toLowerCase().includes(filterString.toLowerCase()) ||
                providerName.toLowerCase().includes(filterString.toLowerCase()) ||
                provider.providerNumber.toLowerCase().includes(filterString.toLowerCase());
        } else {
            return true;
        }

    }

    /***
     * Renders a list of only those providers that are a match for the string entered in the
     * Provider Number input field
     * @returns {*}
     */
    renderFilteredProviders() {
        const {providers} = this.props;
        let filteredList = providers.filter(this.applyNameFilter);
        if (filteredList.length > 0) {
            return (
                providers.filter(this.applyNameFilter).map((provider) => {
                    return (
                        <ProviderListItemComponent
                            key={provider.id}
                            handleProviderSelect={this.props.handleProviderSelect}
                            provider={provider} />
                    );
                })
            )
        } else {
            return (
                <div className='pp-listed-provider'>
                    <div className='l-col'>
                    </div>
                    <div className='r-col'>
                        No matching provider found.
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <div ref={this.listRef} className='relative'>
                <div className='pp-provider-list-wrapper'>
                    {this.renderFilteredProviders()}
                </div>
            </div>
        )
    }
}

ProviderListComponent.propTypes = {
    filterString: PropTypes.string.isRequired,
    providers: PropTypes.instanceOf(Array).isRequired,
    handleProviderSelect: PropTypes.func.isRequired,
    handleOnBlur: PropTypes.func.isRequired,
    inputRef: PropTypes.instanceOf(Object).isRequired
};

export default ProviderListComponent;