import React, { Component } from 'react';
import classnames from "classnames";
import styles from './AddProviderSearchComponent.module.scss';
import {Modal} from "medikit";
import STEMNoModalComponent from "./STEMNoModalComponent";
import PropTypes from 'prop-types';

const buttonGroupStyles = {
    maxWidth: '320px'
}

class AddProviderSearchComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedOption: 'stemNo',
            modalCreated: false
        }

        this.tooltipModalRef = React.createRef();

        this.toggleProviderOptions = this.toggleProviderOptions.bind(this);
        this.handleStemNoChange = this.handleStemNoChange.bind(this);
        this.handleProviderNoChange = this.handleProviderNoChange.bind(this);
    }

    handleStemNoChange(event) {
        this.props.setStemNo(event.target.value);
    }

    handleProviderNoChange(event) {
        this.props.setProviderNo(event.target.value);
    }

    toggleProviderOptions(event) {

        this.setState({
            selectedOption: event.target.value
        });

        if (event.target.value === 'stemNo') {
            this.props.setProviderNo('');
        } else {
            this.props.setStemNo('');
        }
        this.props.clearErrors()
    }

    componentDidUpdate() {
        try {
            // When the user toggles to the Provider No option from the STEM No option
            // the ref passed to the original Medikit.Modal constructor will be
            // referencing an outdated element. So, the MediKit.Modal needs to be
            // reinitialised when the user toggles back to the STEM No option
            // from the Provider No option
            if (this.tooltipModalRef.current === null && this.state.modalCreated) {
                this.setState({ modalCreated: false });
            } else if(!this.state.modalCreated && this.tooltipModalRef.current) {
                new Modal(this.tooltipModalRef.current);
                this.setState({ modalCreated: true });
            }
        } catch (e) {
            console.error(e);
        }
    }

    renderSelectedOption(inputClass) {
        if (this.state.selectedOption === 'stemNo') {
            return (
                <div className='row'>
                    <div className='col-lg-8'>
                        <div className='input-group-row'>
                            <div className={inputClass}>
                                <input onChange={this.handleStemNoChange}
                                       id='stemNoInput'
                                       type='text'
                                       value={this.props.stemNo}
                                       className='input-search form-control' />
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className={classnames('simple-link', styles.stemLink)}
                             ref={this.tooltipModalRef}
                             data-modal-open=""
                             data-modal-target="#tooltipModal" >
                            What is a STEM number?
                        </div>
                    </div>
                    <STEMNoModalComponent modalId='tooltipModal' />
                </div>
            )
        } else {
            return (
                <div className='row'>
                    <div className='col-lg-8'>
                        <div className='input-group-row'>
                            <div className={inputClass}>
                                <input onChange={this.handleProviderNoChange}
                                       id='providerNoInput'
                                       type='text'
                                       value={this.props.providerNo}
                                       className='input-search form-control' />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <div>
                <div className='m-b-1'>
                    <span>Search provider by</span>
                </div>
                <div>
                    <div style={buttonGroupStyles} className="btn-group btn-group-options btn-group-options-highlight-2 m-b-2">
                        <label className="btn btn-group-radio" htmlFor="stemNo">
                            <input type="radio" name="patient"
                                   id="stemNo"
                                   value="stemNo"
                                   onChange={this.toggleProviderOptions}
                                   checked={(this.state.selectedOption==='stemNo')} />
                            <span className="input-label-wrapper">STEM number</span>
                        </label>
                        <label className="btn btn-group-radio" htmlFor="providerNo">
                            <input type="radio" name="patient"
                                   id="providerNo"
                                   value="providerNo"
                                   onChange={this.toggleProviderOptions}
                                   checked={(this.state.selectedOption==='providerNo')} />
                            <span className="input-label-wrapper">Provider number</span>
                        </label>
                    </div>
                </div>
                <div>
                    {this.renderSelectedOption(this.props.inputClass)}
                </div>

            </div>
        )
    }
}

AddProviderSearchComponent.propTypes = {
    stemNo: PropTypes.string,
    providerNo: PropTypes.string,
    setStemNo: PropTypes.func.isRequired,
    setProviderNo: PropTypes.func.isRequired
};

export default AddProviderSearchComponent;