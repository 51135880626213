import React, { Component } from 'react';
import styles from './WelcomeComponent.module.scss'
import classnames from 'classnames';
import { ReactComponent as WelcomeIcon } from 'svgs/common-file-text-search.svg';
import DialogModalComponent from "components/modal/DialogModalComponent";
import RegisterDoctorModalComponent from "components/modal/RegisterDoctorModalComponent";


class WelcomeComponent extends Component {

    render() {
        return (
            <div className={'row'}>
                <div className={classnames('col-lg-6 offset-lg-3', styles.welcome)}>
                    <div className={classnames('', styles.iconWrapper)}><WelcomeIcon/></div>
                    <h2 className={'text-center'}>Welcome to Provider Self Service</h2>
                    <p className={classnames('text-center', styles.welcomeInfo)}>Thank you for registering! Next, request to add a doctor to your provider portal.</p>
                    <div className={classnames('m-b-2 m-t-3', styles.buttonWrapper)}>
                        <DialogModalComponent showModal={true} buttonText={'Request to add a doctor'}>
                            <RegisterDoctorModalComponent modalId='DrRegisterModal' modalTitle='How to register doctors'/>
                        </DialogModalComponent> 
                    </div>
                    <p className={classnames('', styles.registrationProcessNote)}>The manual registration process may take up to 5 business days, but we will contact you once completed.</p>
                </div>
            </div>
        );
    }
}

export default WelcomeComponent;