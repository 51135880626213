import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import UserContext from 'context/UserContext';
import HomeContainer from './pages/home/HomeContainer';
import ClaimsContainer from './pages/claims/ClaimsContainer';
import RemittancesContainer from './pages/remittances/RemittancesContainer';
import MessagesContainer from './pages/messages/MessagesContainer';
import RegistrationContainer from './pages/registration/RegistrationContainer';
import LoginContainer from './pages/login/LoginContainer';
import ForgottenContainer from './pages/forgotten/ForgottenContainer';
import ProviderSetupContainer from "./pages/providerSetup/ProviderSetupContainer";
import MyDetailsContainer from './pages/account/MyDetailsContainer'
import MyProvidersContainer from './pages/account/MyProvidersContainer'
import MyUsersContainer from './pages/account/MyUsersContainer'
import MyRequestsContainer from './pages/account/MyRequestsContainer'
import ManageUserContainer from './pages/users/ManageUserContainer'
import VerificationContainer from './pages/verification/VerificationContainer'
import VerifyEmailContainer from './pages/verification/VerifyEmailContainer'
import Constants from "./utils/Constants";
import classnames from "classnames";
import styles from "./Main.module.scss";

const NoMatch = () => (
    <div className={classnames('container', styles.contentWrapper)}>
        <div className={classnames('row')}>
            <div className='col-lg-12'>
                <h2 className={styles.bottomSpacer}>Page not found!</h2>
                <h4 className={styles.bottomSpacer}>
                    Sorry, but it looks like the page you are<br/>
                    looking for could not be found.
                </h4>
                <h5>This could be for one of the following reasons :</h5>
                <ul>
                    <li>The page has been moved</li>
                    <li>The page has been deleted</li>
                    <li>The link you followed has an incorrect Url</li>
                    <li>You mis-typed the Url</li>
                </ul>
            </div>
        </div>
    </div>
)

/**
 * Special functional component to ensure certain routes are only accessible to an
 * authenticated user
 * @param Component
 * @param authenticated
 * @param rest
 * @returns {*}
 * @constructor
 */
function AuthenticatedRoute({component: Component, authStatus, authenticated, ...rest}) {
    return (
        <Route
            {...rest}
            render={(props) => {
                if (authenticated(authStatus)) {
                    return <Component {...props} {...rest} />
                } else if (authStatus === Constants.AUTH.NOT_VERIFIED) {
                    if (props.location.pathname === '/notverified') {
                        return <Component {...props} {...rest} />
                    } else {
                        return <Redirect to='/notverified' />
                    }
                } else {
                    return <Redirect to='/login' />
                }
            }}
        />
    )
}

class Main extends Component {

    render() {
        const authStatus = this.context.actions.isAuthenticatedUser()
        const authenticated = (authStatus) => {
            return authStatus === Constants.AUTH.VALID
        }
        const canManageUsers = (authStatus) => {
            return authStatus === Constants.AUTH.VALID &&
                this.context.actions.canManageUsers()
        }

        return (
            <div className='pp-main'>
                <Switch>
                    <AuthenticatedRoute authStatus={authStatus} authenticated={authenticated}  exact path='/' component={HomeContainer}/>
                    <Route path='/login' component={LoginContainer}/>
                    <Route path='/forgotten' component={ForgottenContainer}/>
                    <Route path='/registration' component={RegistrationContainer}/>
                    <Route path='/verification' component={VerificationContainer}/>
                    <AuthenticatedRoute authStatus={authStatus} authenticated={authenticated} path='/notverified' exact component={VerifyEmailContainer}/>
                    <AuthenticatedRoute authStatus={authStatus} authenticated={authenticated} path='/claims/:claimno' component={ClaimsContainer}/>
                    <AuthenticatedRoute authStatus={authStatus} authenticated={authenticated} exact path='/claims' component={ClaimsContainer}/>
                    <AuthenticatedRoute authStatus={authStatus} authenticated={authenticated} path='/remittances' component={RemittancesContainer}/>
                    <AuthenticatedRoute authStatus={authStatus} authenticated={authenticated} path='/messages' component={MessagesContainer}/>
                    <AuthenticatedRoute authStatus={authStatus} authenticated={authenticated} path='/providersetup/:pageToShow(stem|provider|calc)' component={ProviderSetupContainer}/>
                    <AuthenticatedRoute authStatus={authStatus} authenticated={authenticated} path='/mydetails' component={MyDetailsContainer}/>
                    <AuthenticatedRoute authStatus={authStatus} authenticated={authenticated} path='/myproviders' component={MyProvidersContainer}/>
                    <AuthenticatedRoute authStatus={authStatus} authenticated={canManageUsers} path='/myusers' component={MyUsersContainer}/>
                    <AuthenticatedRoute authStatus={authStatus} authenticated={canManageUsers} path='/myrequests' component={MyRequestsContainer}/>
                    <AuthenticatedRoute authStatus={authStatus} authenticated={canManageUsers} path='/manageuser/:username' component={ManageUserContainer}/>
                    <Route component={NoMatch} />
                </Switch>
            </div>
        );
    }
}

Main.contextType = UserContext;

export default Main;