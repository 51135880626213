import React, { Component } from 'react';
import classnames from 'classnames'
import PropTypes from 'prop-types'
import styles from "./DetailHeaderComponent.module.scss"
import Utils from 'utils/Utils'

class DetailHeaderComponent extends Component {

    renderPatientTitleRow() {
        return  (
            <div className={classnames('row')}>
                <div className={classnames('col-lg-7')}>
                    <div className={classnames('row')}>
                        <div className={classnames('col-lg-3')}>
                            <b>Patient name</b>
                        </div>
                        <div className={classnames('col-lg-4')}>
                            <b>Membership number</b>
                        </div>
                        <div className={classnames('col-lg-5')}>
                            <b>Payee provider number</b>
                        </div>
                    </div>
                </div>
                <div className={classnames('col-lg-5')}>
                    <div className={classnames('row')}>
                        <div className={classnames('col-lg-4')}>
                            <b>Total benefit</b>
                        </div>
                        <div className={classnames('col-lg-4')}>
                            <b>Total charged</b>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderPatientDetailRow() {
        const {claim} = this.props
        const firstname = Utils.capitaliseName(claim.patient.firstname)
        const lastname = Utils.capitaliseName(claim.patient.lastname)

        return  (
            <div className={classnames('row', styles.detailRow)}>
                <div className={classnames('col-lg-7')}>
                    <div className={classnames('row')}>
                        <div className={classnames('col-lg-3')}>
                            <span>{firstname} {lastname}</span>
                        </div>
                        <div className={classnames('col-lg-4')}>
                            <span>{claim.patient.membershipNo}</span>
                        </div>
                        <div className={classnames('col-lg-5')}>
                            <span>{claim.provider.number}</span>
                        </div>
                    </div>
                </div>
                <div className={classnames('col-lg-5')}>
                    <div className={classnames('row')}>
                        <div className={classnames('col-lg-4')}>
                            <b>${claim.benefitAmount}</b>
                        </div>
                        <div className={classnames('col-lg-4')}>
                            <span>${claim.chargeAmount}</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderInvoiceTitleRow() {
        const {claim} = this.props;
        if (claim.transactionId || claim.referenceNo) {
            return  (
                <div className={classnames('row', styles.eclipseRow)}>
                    <div className={classnames('col-lg-7')}>
                        <div className={classnames('row')}>
                            {claim.referenceNo &&
                            <div className={classnames('col-lg-5')}>
                                <b>Invoice/Account ref number</b>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            )
        }
    }

    renderInvoiceDetailRow() {
        const {claim} = this.props;
        if (claim.transactionId || claim.referenceNo) {
            return  (
                <div className={classnames('row')}>
                    <div className={classnames('col-lg-7')}>
                        <div className={classnames('row')}>
                            {claim.referenceNo &&
                            <div className={classnames('col-lg-5')}>
                                <span>{claim.referenceNo}</span>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <div className={classnames('container', styles.sectionWrapper)}>
                {this.renderPatientTitleRow()}
                {this.renderPatientDetailRow()}
                {this.renderInvoiceTitleRow()}
                {this.renderInvoiceDetailRow()}
            </div>
        )
    }
}

DetailHeaderComponent.propTypes = {
    claim: PropTypes.object.isRequired
}

export default DetailHeaderComponent;