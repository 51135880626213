import React, {Component} from 'react';
import classnames from 'classnames';
import styles from './TooltipIcon.module.scss'
import {ReactComponent as Icon} from "svgs/icon-tooltip.svg";
import {Popover, PopoverBody} from 'reactstrap'
import {Modal} from "medikit";


class TooltipIcon extends Component {
    constructor() {
        super()
        this.toggle = this.toggle.bind(this)

        this.state = {
            popoverOpen: false,
            modalCreated: false,
        }
    }

    componentDidMount() {
        try {
            if (!this.state.modalCreated && this.props.showModal) {
                new Modal(this.refs.tooltipModal);
                this.setState({modalCreated: true});
            }
        } catch (e) {
            console.error(e);
        }
    }

    toggle() {
        if (!this.props.showModal) {
            this.setState({
                popoverOpen: !this.state.popoverOpen
            });
        }
    }


    renderPopover() {
        if (!this.props.showModal) {
            return <Popover placement="top"
                            isOpen={this.state.popoverOpen}
                            target="iconTooltip"
                            className='tooltip'
                            hideArrow={false}
            >
                <PopoverBody className='tooltip-inner'>
                    {this.props.children}
                </PopoverBody>
            </Popover>
        } else {
            return <>{this.props.children}</>
        }
    }

    render() {
        return <>
            <span className={classnames(styles.tooltipButton)}
                  ref='tooltipModal'
                  data-modal-open=""
                  data-modal-target="#tooltipModal">
                <Icon id="iconTooltip" onMouseEnter={this.toggle} onMouseLeave={this.toggle}
                      className={classnames('icon icon-xs', styles.tooltipIconClass)}/>
            </span>
            {this.renderPopover()}
        </>
    }
}

TooltipIcon.defaultProps = {
    showModal: false,
};

export default TooltipIcon


