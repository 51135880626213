import React, { Component } from 'react';
import {Link, NavLink} from 'react-router-dom';
import UserContext from "context/UserContext";
import { ReactComponent as UserIcon } from 'svgs/single-neutral.svg'
import { ReactComponent as ChevronIcon } from "medikit/dist/base/svgs/icon-chevron-down.svg";
import styles from './PrimaryNav.module.scss';

class PrimaryNav extends Component {

    constructor(props) {
        super(props)

        this.state = {
            showSubMenu: false
        }

        this.subMenuRef = React.createRef();

        this.toggleSubMenu = this.toggleSubMenu.bind(this);
        this.logout = this.logout.bind(this)
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    /***
     * If the user clicks outside the Provider List component and also outside
     * the associated Provider Input field, then the Provider List should be
     * closed
     * @param event
     */
    handleClickOutside(event) {
        if (this.subMenuRef &&
            this.subMenuRef.current &&
            !this.subMenuRef.current.contains(event.target))
        {
            this.setState({
                showSubMenu: false
            })
        }
    }

    logout(event) {
        event.preventDefault()

        this.context.actions.logout()
    }

    toggleSubMenu() {
        this.setState({
            showSubMenu: !this.state.showSubMenu
        })
    }

    renderUsersLink() {
        let {canManageUsers} = this.context.actions
        if (canManageUsers()) {
            return <li><Link to='/myusers'>Manage users</Link></li>
        } else {
            return <></>
        }
    }

    renderSubMenu() {
        if (this.state.showSubMenu) {
            return (
                <div ref={this.subMenuRef} data-test-id='subMenu' className={styles.userMenu}>
                    <ul>
                        <li><Link to='/myproviders'>Manage providers</Link></li>
                        {this.renderUsersLink()}
                        <li><Link to='/mydetails'>Your details</Link></li>
                        <li data-test-id='logout' onClick={this.logout}>Log out</li>
                    </ul>
                </div>
            )
        }
    }
    render() {
        const {firstName, lastName} = this.props;

        return (
            <div className='primary-nav'>
                <div className='navigation'>
                    <ul>
                        <li className='primary-nav-category'>
                            <NavLink className='nav-link' exact to='/'>Dashboard</NavLink>
                        </li>
                        <li className='primary-nav-category'>
                            <NavLink className='nav-link' to='/remittances'>Remittance advice</NavLink>
                        </li>
                        <li className='primary-nav-category'>
                            <NavLink className='nav-link' to='/claims'>Claim status</NavLink>
                        </li>
                        {/*<li className='primary-nav-category'>*/}
                            {/*<NavLink className='nav-link' to='/messages'>Messages</NavLink>*/}
                        {/*</li>*/}
                    </ul>
                    <div data-test-id='subMenuBtn' onClick={this.toggleSubMenu} className={styles.userWrapper}>
                        <div className={styles.iconWrapper}>
                            <UserIcon />
                        </div>
                        <span>{firstName}&nbsp;{lastName}</span>
                        <div className={styles.chevronWrapper}>
                            <ChevronIcon />
                        </div>
                        {this.renderSubMenu()}
                    </div>
                </div>
            </div>
        );
    }
}

PrimaryNav.contextType = UserContext;

export default PrimaryNav;