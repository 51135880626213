import React, {Component} from 'react';
import {Link} from 'react-router-dom'
import styles from './Step1Container.module.scss';
import LoginContext from 'context/LoginContext';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import PasswordInputContainer from '../PasswordInputComponent'

class Step1Container extends Component {

    constructor(props) {
        super(props);

        this.handleNext = this.handleNext.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this)
        this.handleUsernameChange = this.handleUsernameChange.bind(this)
    }

    handleNext() {
        this.context.actions.completeStep1();
    }

    handleUsernameChange(event) {
        this.context.actions.setUsername(event.target.value);
    }

    handlePasswordChange(value) {
        this.context.actions.setPassword(value);
    }

    onBlur(event) {
        //Do nothing
    }


    componentDidMount() {
        window.scrollTo(0, 0);
    }

    renderNextButton() {
        let disabled = !this.props.nextEnabled;
        let buttonContents = 'Log in';
        if (this.context.loggingInAccount) {
            buttonContents = <div className="spinner-md text-white"></div>;
            disabled = true;
        }

        return (

            <button
                onClick={this.handleNext}
                className={classnames('btn btn-primary btn-block', styles.button)}
                disabled={disabled}
                color="primary">
                {buttonContents}
            </button>

        )
    }


    render() {
        const {inError, error} = this.context;
        let classes = 'input-group';
        if (inError) {
            classes += ' input-group-alert';
        }
        let errorMessage = (<span>&nbsp;</span>);
        if (inError) {
            if (error === 'NotAuthorizedException' || error === 'UserNotFoundException') {
                errorMessage = "Incorrect username or password. Please try again."
            } else if (error === 'UserNotConfirmedException') {
                errorMessage = (
                    <span>
                    Sorry, the user is not verified during registration.
                    Please contact our Provider Self Service enquiry team.
                    Find our details in the&nbsp;
                        <a className='simple-link-reverse'
                           href="https://www.medibank.com.au/contact-us/"
                           rel='noopener noreferrer'
                           target='_blank'>Healthcare providers section</a>
                    </span>
                )
            } else if (error === 'LoginAttemptsExceededException') {
                errorMessage = (
                    <span>
                        Sorry, you have exceeded the maximum login attempts allowed.
                        Please wait 10 minutes before trying again.
                        Forgotten your login details? Click <Link className={styles.errorLink} to={"/forgotten"}>here</Link>
                        &nbsp;to reset your password.
                    </span>
                )
            } else {
                errorMessage = "Not able to validate the login credentials. Please try again."
            }
        }
        return (
            <div className={classnames('', styles.loginWrapper)}>
                <h4 className={classnames('', styles.title)}>Log into your Provider Self Service account</h4>
                <hr/>
                <div className={classnames(classes, styles.username)}>
                    <label htmlFor="username">Username or Email</label>
                    <input id='username'
                           type='text'
                           className='input-search form-control'
                           onChange={this.handleUsernameChange}
                    />
                </div>
                <PasswordInputContainer className={classnames(classes, styles.password)} label={'Password'}
                                        onChange={this.handlePasswordChange} onBlur={this.onBlur} inError={false}
                                        errorText={''} fieldId={'password'}/>
                <div data-test-id='error' className={classnames('input-helper form-error small', styles.inputHelper)}>
                    {errorMessage}
                </div>
                {this.renderNextButton()}
                <Link className={classnames('simple-link-reverse',styles.link)} to={"/forgotten"}>Forgot your login details?</Link>
            </div>
        );
    }
}

Step1Container.contextType = LoginContext;

Step1Container.propTypes = {
    nextEnabled: PropTypes.bool,
    actions: PropTypes.object
};

Step1Container.defaultProps = {
    nextEnabled: false
};

export default Step1Container;