import React, { Component } from 'react';
import {NavLink} from 'react-router-dom';
import styles from './AccountNavComponent.module.scss';
import classnames from "classnames";
import UserContext from "context/UserContext";

class AccountNavComponent extends Component {

    renderUsersLink() {
        const isActive = (match, location) => {
            let matched = false
            let matches = ['/myusers','/myrequests', '/manageuser/*']
            matches.forEach(s => {
                if (!matched) matched = location.pathname.match(s)
            })
            return matched
        }

        if (this.context.actions.canManageUsers()) {
            return <NavLink className={classnames('account-nav-item', styles.menuLink)} to='/myusers'
                            isActive={isActive}>Manage users</NavLink>
        } else {
            return <></>
        }
    }

    /***
     * Note: the account-nav-item class on the menu links below is defined at a global level in
     * _nav.scss. This was required so that the dynamic addition of the 'active' class that is
     * done by React Router for the NavLink component could be styled (trying to use 'active'
     * in the AccountNavComponent.module.scss doesn't work)
     * @returns {*}
     */
    render() {
        /**
         * Handle the fact that the requests page should also show as being on the users page as far as nav goes
         * so if the current location is one of the pages we are interested in then mark it as active
         *
         * @param match
         * @param location
         * @returns {boolean}
         */

        return (
            <div data-test-id='accountMenu' className={styles.accountMenu}>
                <NavLink className={classnames('account-nav-item', styles.menuLink)} to='/myproviders'>Manage providers</NavLink>
                {this.renderUsersLink()}
                <NavLink className={classnames('account-nav-item', styles.menuLink)} to='/mydetails'>Your details</NavLink>
            </div>
        )
    }
}

AccountNavComponent.contextType = UserContext;

export default AccountNavComponent;