import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './AlertComponent.module.scss';
import classnames from 'classnames'
import { ReactComponent as CrossIcon } from "medikit/dist/base/svgs/icon-cross.svg";
import { ReactComponent as InfoIcon } from "svgs/icon-info.svg"

class AlertComponent extends Component {

    renderCloseIcon() {
        const {handleAlertClose} = this.props;
        if (handleAlertClose) {
            return (
                <span data-test-id='close' className={styles.closeIcon} onClick={handleAlertClose} aria-label="Close"><span className="sr-only">Dismiss alert</span>
                    <CrossIcon />
                </span>
            )
        }
    }

    render() {
        const {forwardedRef, alertTitle, alertMessage, alertSuccess, alertInfo, isLargeText} = this.props;

        return (
            <div className={classnames(styles.alertWrapper, this.props.className)}>
                <div ref={forwardedRef}
                     className={classnames('alert bg-neutral-1', { 'alert-success': alertSuccess }, { 'alert-info': alertInfo })}
                     role="alert" data-alert=""
                >
                    {alertInfo &&
                    <div className='alert-icon alert-icon-info'>
                        <InfoIcon />
                    </div>
                    }

                    <div className="alert-body">
                        {alertTitle ? (
                            <div className={styles.alertTitle}>{alertTitle}</div>
                        ): (null)}
                        <span className={classnames({ 'h4': isLargeText })}>{alertMessage}</span>
                    </div>
                    {this.renderCloseIcon()}
                </div>
            </div>
        )
    }
}

AlertComponent.propTypes = {
    alertTitle: PropTypes.string,
    alertMessage: PropTypes.string.isRequired,
    className: PropTypes.string,
    // A react ref can be passed to this component which can allow the parent component
    // to scroll this alert component into view
    forwardedRef: PropTypes.object,
    alertSuccess: PropTypes.bool,
    alertInfo: PropTypes.bool,
    isLargeText: PropTypes.bool,
    handleAlertClose: PropTypes.func
};

AlertComponent.defaultProps = {
    alertSuccess: false,
    alertInfo: false,
    isLargeText: false
}

export default AlertComponent;