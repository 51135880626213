import {Component} from "react";
import PropTypes from 'prop-types';
import React from "react";

class YearMonthFormComponent extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);

        const months = this.props.localeUtils.getMonths();
        const lowYear = this.props.minDate.getFullYear();
        const highYear = this.props.maxDate.getFullYear();
        const years = [];

        for (let i = lowYear; i <= highYear; i += 1) {
            years.push(i);
        }

        this.state = {
            months: months,
            years: years
        };

    }

    handleChange(e) {
        const { year, month } = e.target.form;
        this.props.onChange(new Date(year.value, month.value));
    }

    render() {
        const {date} = this.props;
        return (
            <form className="DayPicker-Caption">
                <div className="select">
                    <select name="month" onChange={this.handleChange} value={date.getMonth()}>
                        {this.state.months.map((month, i) => (
                            <option key={month} value={i}>
                                {month}
                            </option>
                        ))}
                    </select>
                    <span className='select-chevron'></span>
                </div>
                <div className="select">
                    <select name="year" onChange={this.handleChange} value={date.getFullYear()}>
                        {this.state.years.map(year => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                    </select>
                    <span className='select-chevron'></span>
                </div>
            </form>
        )
    }

}

YearMonthFormComponent.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
    minDate: PropTypes.instanceOf(Date).isRequired,
    maxDate: PropTypes.instanceOf(Date).isRequired,
    localeUtils: PropTypes.instanceOf(Object).isRequired,
    onChange: PropTypes.func.isRequired
};

export default YearMonthFormComponent;