import React, { Component } from 'react';
import {verifyEmailToken} from "../../graphql/mutations";
import {API, graphqlOperation} from 'aws-amplify';
import {sendEmailVerification} from "graphql/mutations"
import queryString from 'query-string'
import TokenVerifiedComponent from 'components/verification/TokenVerifiedComponent'
import TokenExpiredComponent from 'components/verification/TokenExpiredComponent'
import UserContext from 'context/UserContext'
import {Redirect} from "react-router-dom";
import TagManager from "react-gtm-module";
import {getPageLoadArgs} from "utils/GTMHelper"
import OverlaySpinner from 'components/spinner/OverlaySpinner';
import {getGTMSequenceId} from "utils/GTMHelper";

class VerificationContainer extends Component {

    constructor(props) {
        super(props)

        this.handleContinue = this.handleContinue.bind(this)
        this.handleEmailResend = this.handleEmailResend.bind(this)

        this.state = {
            isVerifying: true,
            isVerified: false,
            isExpired: false,
            showEmailInfo: false,
            toDashboard: false,
            isReloadingUser: false,
            isResendingEmail: false,
            username: null,
            gtmSequenceId: '',
            cognitoId: ''
        }
    }

    async handleContinue() {
        this.setState({
            isReloadingUser: true
        })

        await this.context.actions.reloadLoggedInUser()

        this.setState({
            toDashboard: true
        })
    }

    async checkVerificationToken() {

        try {
            const values = queryString.parse(this.props.location.search);
            const verification = await API.graphql(graphqlOperation(verifyEmailToken, {input: {token: values.token}}));
            this.setState({
                isVerified: verification.data.verifyEmailToken.result,
                isExpired: !verification.data.verifyEmailToken.result,
                username: verification.data.verifyEmailToken.username,
                cognitoId: verification.data.verifyEmailToken.cognitoId,
                isVerifying: false
            })
        }
        catch (error) {
            this.setState({
                isExpired: true,
                isVerifying: false
            })
        }
    }

    async componentDidMount() {
        TagManager.dataLayer(getPageLoadArgs('Verifying Email Address','/verification'))

        this.setState({
            isVerified: false,
            isExpired: false,
            gtmSequenceId: getGTMSequenceId()
        })

        await this.checkVerificationToken()
    }

    async handleEmailResend() {
        this.setState({
            isResendingEmail: true
        })

        try {

            let input = {
                username: this.state.username
            }

            await API.graphql(graphqlOperation(sendEmailVerification, {
                    input
                }
            ));

            this.setState({
                isResendingEmail: false
            })
        }
        catch (error) {
            this.setState({
                isResendingEmail: false
            })
        }
    }

    render() {
        if (this.state.toDashboard) {
            return (
                <Redirect to='/' />
            )
        }

        const {isVerified, isExpired, isReloadingUser,
            isResendingEmail, isVerifying, gtmSequenceId, cognitoId} = this.state

        let comp = null

        if (isVerifying) {
            comp = <OverlaySpinner />
        }
        else if (isVerified) {
            comp = <TokenVerifiedComponent
                handleContinue={this.handleContinue}
                isContinuing={isReloadingUser}
                gtmSequenceId={gtmSequenceId}
                cognitoId={cognitoId}
            />
        } else if (isExpired) {
            comp = <TokenExpiredComponent
                        handleEmailResend={this.handleEmailResend}
                        isResendingEmail={isResendingEmail}
                    />
        }

        return comp
    }
}

VerificationContainer.contextType = UserContext;

export default VerificationContainer;