import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Utils from 'utils/Utils'

class ProviderListItemComponent extends Component {

    constructor(props){
        super(props);

        this.handleProviderSelect = this.handleProviderSelect.bind(this);
    }

    handleProviderSelect(event) {
        event.preventDefault();
        // Deselection is only required as on option when the check box is
        // being used. Otherwise, clicking on the provider always represents
        // selection of the provider
        if (this.props.showCheckbox && this.props.isChecked) {
            this.props.handleProviderDeselect(this.props.provider);
        } else {
            this.props.handleProviderSelect(this.props.provider);
        }

    }

    renderItemCheckbox() {
        if (this.props.showCheckbox) {
            return (
                <div className='input-group'>
                    <label className='input-checkbox'>
                        <input type='checkbox' readOnly checked={this.props.isChecked} />
                        <div className='input-indicator needsclick'></div>
                    </label>
                </div>
            )
        } else {
            return null;
        }
    }

    render() {
        const {provider} = this.props;
        const providerName = Utils.buildProviderName(provider)

        return (
            <div onClick={this.handleProviderSelect} className='pp-listed-provider'>

                {this.renderItemCheckbox()}

                <div className='l-col'>
                    {provider.providerNumber}
                </div>
                <div className='r-col'>
                    <b>{providerName}</b><br/>
                    {!provider.practiceName.toLowerCase().includes(providerName.toLowerCase()) &&
                        <>
                        {provider.practiceName}<br/>
                        </>
                    }
                    <span className='small'>{provider.address}</span>
                </div>
            </div>
        );
    }
}

ProviderListItemComponent.propTypes = {
    provider: PropTypes.instanceOf(Object).isRequired,
    handleProviderSelect: PropTypes.func.isRequired,
    handleProviderDeselect: PropTypes.func,
    showCheckbox: PropTypes.bool,
    isChecked: PropTypes.bool
};

ProviderListItemComponent.defaultProps = {
    showCheckbox: false,
    isChecked: false
};

export default ProviderListItemComponent;