import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Constants from 'utils/Constants';
import Utils from 'utils/Utils'

const leftSideStyles = {
    textAlign: 'left',
    paddingTop: '12px'
};

const rightSideStyles = {
    textAlign: 'right'
};

const labelStyles = {
    display: 'inline-block',
    textAlign: 'right',
    paddingTop: '12px'
}

class SearchResultsSorterComponent extends Component {

    constructor(props) {
        super(props);

        this.handleOnChange = this.handleOnChange.bind(this);
    }

    handleOnChange(event) {
        this.props.handleSorting(event.target.value);
    }


    determineRowCount() {
        const {remittanceCount, pageNo} = this.props;
        const itemsPerPage = Constants.REMITTANCE_SEARCH_ITEMS_PER_PAGE;
        const rowCountMsg = Utils.determineRowCountText(itemsPerPage, pageNo, remittanceCount)

        return rowCountMsg;
    }

    renderNameOptions() {
        if (this.props.includeNameSort) {
            return (
                <>
                    <option value='n-a'>Provider Name (A-Z)</option>
                    <option value='n-d'>Provider Name (Z-A)</option>
                </>
            )
        }
    }

    render() {

        return (
            <div className='row'>
                <div style={leftSideStyles} className='col-sm-6'>
                    {this.determineRowCount()}
                </div>
                <div className='col-sm-1 offset-sm-2' style={labelStyles}>Sort by</div>
                <div style={rightSideStyles} className='col-sm-3'>
                    <div className='input-group select'>
                        <select onChange={this.handleOnChange} className='form-control' type="select" name="select" id="sortOrder">
                            <option value='d-d'>Date (newest - oldest)</option>
                            <option value='d-a'>Date (oldest - newest)</option>
                            {this.renderNameOptions()}
                        </select>
                        <span className='select-chevron'></span>
                    </div>
                </div>
            </div>
        )
    }
}

SearchResultsSorterComponent.propTypes = {
    pageNo: PropTypes.number.isRequired,
    remittanceCount: PropTypes.number,
    includeNameSort: PropTypes.bool,
    handleSorting: PropTypes.func.isRequired
};

SearchResultsSorterComponent.defaultProps = {
    includeNameSort: true,
    remittanceCount: null
};

export default SearchResultsSorterComponent;