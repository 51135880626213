const ConfigHelper = {
    /***
     * Uses env variables to determine how far back the user is allowed to search for
     * remittances. We can only go back either a maximum number of years from today
     * (eg 7 years) or to a set, hard-coded config date (whichever is closer to today)
     * @returns {Date}
     */
    getRemittanceSearchCutoffDate(today) {
        const restrictedRemittanceDate = new Date(process.env.REACT_APP_REMITTANCE_SEARCH_MIN_FROM_DATE);
        const xYearsAgo = new Date(today.getTime());

        xYearsAgo.setFullYear(today.getFullYear() - process.env.REACT_APP_REMITTANCE_SEARCH_MAX_PERIOD_YEARS);

        if (xYearsAgo > restrictedRemittanceDate) {
            return xYearsAgo;
        } else {
            return restrictedRemittanceDate;
        }
    },

    getClaimSearchMaxPeriod() {
        return parseInt(process.env.REACT_APP_CLAIMS_RESTRICTED_DATE_RANGE_PERIOD, 10)
    }
};

export default ConfigHelper;