import React, { Component } from 'react';
import styles from './PwdStep2Container.module.scss';
import ForgottenContext from "context/ForgottenContext";
import classnames from 'classnames';
import PropTypes from 'prop-types'
import {getPageLoadArgs} from "utils/GTMHelper"
import TagManager from "react-gtm-module"
import { withRouter } from 'react-router-dom'

export class PwdStep2Container extends Component {
    constructor(props) {
        super(props);

        this.handleNext = this.handleNext.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.handleUsernameChange = this.handleUsernameChange.bind(this)
    }

    handleNext() {
        const {actions} = this.context;
        actions.completePwdStep2();
    }

    handleBack() {
        const {actions} = this.context;
        actions.backToStep1();
    }    

    handleUsernameChange(event) {
        this.context.actions.setUsername(event.target.value);
    }

    componentDidMount() {
        TagManager.dataLayer(getPageLoadArgs('Forgot Password',this.props.location.pathname+'/password'))

        window.scrollTo(0, 0);
    }

    renderButtons() {
        let disabled = !this.props.nextEnabled;
        let nextButtonContents = 'Next';
        let backButtonContents = 'Back';
        if (this.props.verifyingUsername) {
            nextButtonContents = <div className="spinner-md text-white"></div>;
            disabled = true;
        }
        return (
            <div className="row">
                 <div className="col-xs-6 text-left">
                    <button
                        data-test-id="backBtn"
                        onClick={this.handleBack}
                        className={classnames('btn btn-lg btn-dark-ghost', styles.button)}
                        color="dark-ghost">
                        {backButtonContents}
                    </button>
                </div>
                 <div className="col-xs-6 text-right">
                    <button
                        data-test-id="nextBtn"
                        onClick={this.handleNext}
                        className={classnames('btn btn-lg btn-primary', styles.button2)}
                        disabled={disabled}
                        color="primary">
                        {nextButtonContents}
                    </button>
                </div>
            </div>
        )
    }

    renderErrorMessage() {
        const {hasError, errorMessage} = this.context;

        if (hasError) {
            return (
                <div data-test-id='error' className={classnames('form-error small', styles.inputHelper)}>
                    {errorMessage}
                </div>
            )
        } else {
            return (
                <span>&nbsp;</span>
            )
        }
    }

    render() {
        const {username, hasError} = this.context;

        var usernameClasses = 'input-group';
        if (hasError) {
            usernameClasses += ' input-group-alert';
        }
        return (
            <>
                <h4>Forgot your password?</h4>
                <p>Enter the username you used to sign up and we'll send you a code to your registerd mobile number to reset your password.<br/>
                </p>
                <div className={classnames(usernameClasses, styles.username)}>
                    <label htmlFor="username">Username</label>
                    <input id='username'
                           value={username}
                           type='text'
                           className='input-search form-control'
                           onChange={this.handleUsernameChange}
                    />
                </div>
                {this.renderErrorMessage()}
                {this.renderButtons()}
            </>
        );
    }
}
PwdStep2Container.propTypes = {
    nextEnabled: PropTypes.bool.isRequired,
    verifyingUsername: PropTypes.bool
}

export default withRouter(PwdStep2Container);
PwdStep2Container.contextType = ForgottenContext;
