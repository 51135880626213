import React from 'react';
import styles from './TermsAndConditions.module.scss';

export const getTermsAndConditionsHtml = () => {
    return (
        <div className={styles["terms-and-conditions"]}>
            <h4>Provider Self Service</h4>
            <h4>Terms of Use</h4>
                <strong>
                <p>The Provider Self Service (Portal) is a facility established and maintained by Medibank Private Limited ACN 138 752 815 (MPL) and MPL's related bodies corporate (together Medibank/we/us/our) for access and use by:</p>
                <ul>
                        <li>a medical practitioner who is a recognised provider under MPL's Fund Rules and has established a Portal account (Doctor User);</li>
                        <li>a practice manager working at a practice of a Doctor User (Practice Manager User); and</li>
                        <li>a staff member working at a practice of a Doctor User (Staff Member User),</li>
                </ul>
                <p>(each such User being referred to in these Terms of Use as you/your) to track the status of private health insurance claims submitted to MPL in relation to treatment rendered by the Doctor User and to obtain remittance advices with respect to such claims.</p>
                <p>If you are a Doctor User, and a Practice Manager User and/or a Staff Member User ceases to work at your practice or you change practices, you must call Medibank immediately and request Medibank to unlink that Practice Manager User and/or Staff Member User from your Portal account. Failure to do so will mean that the Practice Manager User and/or Staff Member User will be able to continue to access information relating to your Portal account. Alternatively, in the case of departing Staff Member Users, the Practice Manager User can call Medibank and request Medibank to unlink the Staff Member User from the Doctor User's account.</p>
                <p>These Terms of Use govern your access to and use of the Portal.</p>
                <p>These Terms of Use contain important information, including disclaimers, limitations of liability and information regarding the process to verify your identity which involves the disclosure of your personal information to third parties including the Department of Home Affairs. Your registration to, and use of, the Portal constitutes acceptance of these Terms of Use.</p>
                </strong>

                <br/><h6>1. ELIGIBILITY FOR ACCESS AND LEVEL OF SERVICES</h6>

                <p>To be eligible to register to access, and to access, the Portal you must be:</p>
                <ol type={'a'}>
                        <li><span>a medical practitioner who is a recognised provider under MPL's Fund Rules;</span></li>
                        <li><span>a practice manager working at a practice of a Doctor User; or</span></li>
                        <li><span>a staff member working at a practice of a Doctor User.</span></li>
                </ol>
                <p>In order to register and establish a Portal account, all prospective users must provide your current Australian drivers licence number which will be used for identity verification purposes as described in clause 10.</p>
                <p>If you are a Doctor User, in order to be able to track the status of private health insurance claims submitted to MPL in relation to treatment rendered by you or obtain remittance advices in relation to such claims for each practice location from where you practise, you must provide to Medibank:</p>
                <ol type={'a'}>
                        <li><span>your speciality;</span></li>
                        <li><span>your registration number issued by the Australian Health Practitioner Regulation Agency;</span></li>
                        <li><span>your stem number; and</span></li>
                        <li><span>each of your practice locations in relation to which you wish to use the Portal to track the status of private health insurance claims submitted to MPL in relation to treatment rendered by you at that practice location or obtain remittance advices in relation to such claims.</span></li>
                </ol>
                <p>If you are a Practice Manager User, in order to perform tasks on behalf of the Doctor User:</p>
                <ol type={'a'}>
                        <li><span>you must provide to Medibank the Doctor User’s provider number for the practice location in relation to which you wish to perform tasks on behalf of the Doctor User; and</span></li>
                        <li><span>the Doctor User must approve that you are linked to the Doctor User's Portal account.</span></li>
                </ol>
                <p>If you are a Staff Member User, in order to perform tasks on behalf of the Doctor User:</p>
                <ol type={'a'}>
                        <li><span>you must provide to Medibank the Doctor User’s provider number for the practice location in relation to which you wish to perform tasks on behalf of the Doctor User; and</span></li>
                        <li><span>the Doctor User or Practice Manager User must approve that you are linked to the Doctor User's Portal account.</span></li>
                </ol>
                <p>If you are a Doctor User, and a Practice Manager User and/or a Staff Member User ceases to work at your practice or you change practices, you must call Medibank immediately and request Medibank to unlink that Practice Manager User and/or Staff Member User from your Portal account.  Failure to do so will mean that the Practice Manager User and/or Staff Member User will be able to continue to access information relating to your Portal account.  Alternatively, in the case of departing Staff Member Users, the Practice Manager User can call Medibank and request Medibank to unlink the Staff Member User from the Doctor User's account.</p>
                <p>Medibank has the sole right to determine your eligibility for access, the extent of your access and the level of services available via the Portal. The range of services that Medibank makes available to you through the Portal may differ depending on whether you are a Doctor User, Practice Manager User or Staff Member User.</p>

                <br/><h6>2. YOUR SECURITY CREDENTIALS</h6>

                <p>Your security credentials including your username, password and security or verification codes received via SMS (Security Credentials) enable you and Medibank to prevent unauthorised access to your and others' personal details in the Portal. You are entirely responsible for their use and you must keep your Security Credentials confidential at all times. You may not permit anyone else to use your Security Credentials.</p>
                <p>We are entitled to assume (and we will assume) that you are the user whenever your Security Credentials are used to access the Portal. Please notify us immediately if you become aware of any unauthorised access to or use of your Security Credentials.</p>
                <p>To protect the integrity of your Security Credentials, we require you to log out of your secure session before you browse away from the Portal, or before you leave your computer unattended.</p>

                <br/><h6>3. COMMUNICATIONS VIA THE PORTAL</h6>

                <p>We use the Portal to communicate to you the status of private health insurance claims submitted to MPL in relation to treatment the Doctor User rendered and remittance advices with respect to such claims.</p>
                <p>Medibank reserves the right to communicate with you by methods other than through the Portal, or in addition to the Portal, even where you have indicated a preference to receive information via the Portal.</p>

                <br/><h6>4. APPROPRIATE USE OF THE PORTAL</h6>

                <p>If you are a Doctor User, you must not use or attempt to use the Portal to access information related to any person other than the Doctor User's patients.</p>
                <p>If you are a Practice Manager User or a Staff Member User, you must not use or attempt to use the Portal to access information related to any person other than the Doctor User whose Portal account you are linked to and that Doctor User's patients.</p>
                <p>Medibank makes the Portal available to you on the basis that you will act appropriately and responsibly. If you do not, you must bear full responsibility for your actions.</p>
                <p>You acknowledge that we can suspend or terminate your use of the Portal if we reasonably believe that your use of the Portal is interfering with any services provided by us or you permit anyone else to use your Security Credentials.</p>

                <br/><h6>5. DISCLAIMERS</h6>

                <p>The Portal is provided to you on an "as is" and "as available" basis and may, from time to time, contain errors, faults and inaccuracies. Subject to clause 7 and to the fullest extent permitted by law, we make no representation and provide no express or implied warranty or guarantee about the security of the Portal, the safety of any file or software associated with it (such as safety from any virus or defects including those that could damage or interfere with any data, software or hardware with which it might be used) or that access to the Portal will be uninterrupted.</p>
                <p>The content of the Portal is provided for information purposes only. Subject to clause 7 and to the fullest extent permitted by law, Medibank does not give any express or implied warranties and makes no representations in relation to the Portal. In particular, while reasonable care is taken in its preparation, Medibank does not guarantee or warrant the accuracy, reliability, completeness or currency of the information in the Portal (including any errors in, or omissions from, the content of the Portal) or its usefulness in achieving any purpose. Claims information displayed through the Portal may not be accurate, complete or up to date. Information in the Portal, to the extent that it is third party information, should not be used without validating that information from appropriate sources where it is prudent to do so. You should make and rely upon your own assessments and enquires to verify the accuracy of the information provided.</p>
                <p>Medibank does not provide telecommunications services and does not warrant that you will have continuous access to the Portal or that it will be supplied fault free. Medibank will not be liable in the event that the Portal is unavailable to you for any reason including due to computer downtime attributable to malfunctions, upgrades, preventative or remedial maintenance activities or interruption in telecommunications supply.</p>
                <p>Medibank reserves the right to suspend or terminate the availability of the Portal at any time.</p>
                <p>You agree to access and use the Portal, including its content, claims information and services, entirely at your own risk.</p>
                <p>Medibank makes no claims that the Portal is appropriate, legal or may be downloaded outside Australia. If you access the Portal from outside Australia, you do so at your own risk and are responsible for compliance with the laws of your jurisdiction.</p>

                <br/><h6>6. LIMITATION OF LIABILITY</h6>

                <p>Subject to clause 7 and to the fullest extent permitted by law, Medibank and its officers, employees and agents accept no liability for any loss, damage, illness or injury (including any loss of profits or income, business interruption or loss or corruption of data or other information) arising from:</p>
                <ol type={'a'}>
                        <li><span>your access to or use of the Portal or websites linked from the Portal;</span></li>
                        <li><span>the information in the Portal, or on websites linked from the Portal, being incorrect, incomplete or misleading;</span></li>
                        <li><span>any action or indecision by you in reliance of the information contained in the Portal; or</span></li>
                        <li><span>any delay or interruption in access to or use of the Portal or websites linked from the Portal.</span></li>
                </ol>

                <br/><h6>7. CONSUMER PROTECTION LEGISLATION</h6>
                <p>The Portal comes with non-excludable warranties and guarantees under consumer protection legislation, such as that the Portal will be provided with due care and skill and be reasonably fit for its usual purpose. If Medibank breaches any of these non-excludable warranties or guarantees, you are entitled, at your option, to a refund or the re-supply of the services, and to compensation for any other reasonably foreseeable loss.</p>

                <br/><h6>8. INTELLECTUAL PROPERTY</h6>
                <p><b>Copyright</b></p>
                <p>All content on the Portal, including text, graphics, logos, photographs, downloadable documents and data compilations is the property of Medibank or its content suppliers and protected by Australian and international copyright laws. The compilation of all content on the Portal is the exclusive property of Medibank and protected by Australian and international copyright laws.</p>
                <p><b>Trade marks</b></p>
                <p>"Medibank" and all related graphics, photographs, logos, and product and service names are trade marks (both registered and unregistered) of Medibank. These trade marks, logos and other proprietary graphics may not be used in connection with any other product or service without Medibank's express prior written consent.</p>
                <p>You acknowledge and agree that you will not do anything to or which might hinder, impede, invalidate or challenge Medibank's ownership of or entitlement to use or the registration of any part of the intellectual property of the Portal or which might diminish the value of the intellectual property of the Portal.</p>

                <br/><h6>9. LICENCE AND SITE ACCESS</h6>
                <p>Medibank grants you a limited licence to access and make personal use of the Portal and not to download (other than page caching or to access documents designed for downloading) or modify it, or any portion of it, except with Medibank's express prior written consent. This licence does not include any resale or commercial use of the Portal or its contents (including documents designed for downloading); any derivative use of the Portal or its contents; or any use of data mining, robots, or similar data gathering and extraction tools. The Portal or any portion of the Portal (including documents designed for downloading) may not be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial purpose without express prior written consent of Medibank. You may not frame or use framing techniques to enclose any trade mark, logo, or other proprietary information of the Portal without Medibank's express prior written consent. You may not use any metatags or any other hidden text using Medibank's name or trade marks without Medibank's express prior written consent. Any unauthorised use will automatically terminate the licence granted above. </p>

                <br/><h6>10. THIRD PARTY WEBSITES</h6>
                <p>Medibank may, from time to time, include links on the Portal to websites owned by other parties. Medibank is not responsible for the material contained on these websites or the availability of these websites. Medibank does not endorse and is not responsible or liable for any content, including advertising or products offered on these websites, nor is Medibank responsible for any damage, offence or loss caused as the result of visiting these websites. Your use of third party websites is at your own risk and subject to the terms and conditions of use for such sites and the privacy policy applicable to any such sites.</p>
                <p>Medibank recommends that, before you visit any website, you check that your computer is running up-to-date virus checking software.</p>

                <br/><h6>11. PRIVACY</h6>
                <p><b>Handling of your personal information generally</b></p>
                <p>If you are a Doctor User, Medibank will handle all your personal information collected through the verification and account creation process, and personal information which is displayed in the Portal, in accordance with Medibank's Privacy Policy (available at <a href="https://www.medibank.com.au/legal-information/">https://www.medibank.com.au/legal-information/</a>).</p>
                <p>If you are a Doctor User, your personal information, including in relation to treatment you have rendered, may be shared with Practice Manager Users and Staff Member Users.</p>
                <p>If you are a Practice Manager User or a Staff Member User, Medibank will handle all your personal information collected through the verification and account creation process, and any personal information displayed in the Portal, in accordance with our Privacy Policy (available at <a href="https://www.medibank.com.au/legal-information/">https://www.medibank.com.au/legal-information/</a>).</p>

                <p><b>Verification of your Identity</b></p>
                <p>Medibank requires the verification of your identity prior to creating an account to use the Medibank Provider Portal. Equifax Information Services and Solutions Pty Limited (ABN 26 000 602 862) (Equifax) has been engaged by Medibank to provide it with verification services. Equifax will do this in consultation with the Department of Home Affairs, via the Department’s Document Verification Service.</p>
                <p>In order to complete the verification, Medibank requires your name, date of birth and current Australian drivers licence details which will be provided to Equifax. By entering your details into the verification step of Medibank’s Provider Portal, you consent to the provision of this information to Equifax and the Department of Home Affairs, and the verification of your identity.</p>
                <p>If you do not provide the information requested, or your information is not verified by the Document Verification Service, we may not be able to grant you access to Medibank’s Provider Portal.</p>

                <br/><h6>12. INDEMNITY</h6>
                <p>You agree to defend, indemnify and hold harmless Medibank and its officers, directors, employees and agents from and against all claims, demands, actions, suits, loss, damages, liabilities and costs (including solicitors' costs on a solicitor and own client basis) related to or arising directly or indirectly from any and all of the following:</p>
                <ol type={'a'}>
                        <li><span>breaches by you of these Terms of Use;</span></li>
                        <li><span>conduct and activities occurring under your Security Credentials;</span></li>
                        <li><span>claims or demands, whether or not proven, made by any third party, due to or arising from any breach by you of these Terms of Use or any use of the Portal including its content or services by you or any person using your Security Credentials.</span></li>
                </ol>

                <br/><h6>13. COOKIES AND TRACKING</h6>
                <p>When you visit the Portal, our servers collect routine logging information such as the pages visited, the time of your visit, the web browser that you are using and the IP address associated with your request. Such information will be handled in line with our Privacy Policy (available at <a href="https://www.medibank.com.au/legal-information/">https://www.medibank.com.au/legal-information/</a>).</p>
                <p>If you would rather not have this information stored on your computer, you can configure your browser so it does not accept cookies. However, if you disable cookies you may not be able to access the Portal.</p>

                <br/><h6>14. SUSPENSION AND TERMINATION</h6>
                <p>In addition to any other suspension or termination rights specified in these Terms of Use, including, without limitation, Medibank's general right to suspend or terminate the availability of the Portal at any time, Medibank may at any time suspend or terminate your registration to the Portal if in its opinion:</p>
                <ol type={'a'}>
                        <li><span>you breach these Terms of Use;</span></li>
                        <li><span>you commit any dishonest or fraudulent act in relation to the Portal or these Terms of Use;</span></li>
                        <li><span>you behave in a manner which in the reasonable opinion of Medibank might tend to adversely affect the reputation or public image of Medibank; or</span></li>
                        <li><span>you infringe any intellectual property right or privacy, moral or publicity right of Medibank or any third party.</span></li>
                </ol>
                <p>Upon termination of your registration:</p>
                <ol type={'a'}>
                        <li><span>your Security Credentials will be cancelled and you will no longer be able or entitled to access the Portal;</span></li>
                        <li><span>your licence to use the Portal automatically terminates; and</span></li>
                        <li><span>any provisions of these Terms of Use, which by their nature would be expected to survive termination, survive and remain in full force and effect in accordance with their terms. These include without limitation clauses 5, 6, 7, 8, 10 and 11.</span></li>
                </ol>

                <br/><h6>15. CHANGES TO TERMS OF USE</h6>
                <p>These Terms of Use are effective from 30 May 2019.  Each time you log in to the Portal, you will be taken to be agreeing to these Terms of Use. Medibank may vary these Terms of Use at any time by publishing a revised version of the Terms of Use in the Portal.  Please read the Terms of Use whenever you log in.</p>

                <br/><h6>16. GENERAL</h6>
                <p>These Terms of Use contain the entire terms and conditions that apply to your registration to and your use of the Portal. Your rights and obligations under these Terms of Use may not be transferred or assigned directly or indirectly without the prior written consent of Medibank. If the whole or any part of these Terms of Use are invalid, unenforceable, illegal, void or voidable for any reason, these Terms of Use will be construed and be binding as if the invalid, unenforceable, illegal, void or voidable part had been deleted from them or read down to the extent necessary to overcome the difficulty.</p>
                <p>A waiver by Medibank of any breach or a failure to enforce or to insist on the observance of a condition of these Terms of Use will not be a waiver of any other or of any subsequent breach.</p>
                <p>Medibank will not be responsible or liable for failure to perform any obligation under these Terms of Use if such failure is caused by the occurrence of any matter beyond its reasonable control including, without limitation, internet and communications outages, fire, flood, war, or act of God.</p>
                <p>These Terms of Use are governed by the laws in force in the State of Victoria, Australia.  Both you and Medibank irrevocably submit to the non-exclusive jurisdiction of the courts of that State and all courts with jurisdiction to hear appeals from those courts.</p>
        </div>
    );
}