import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SearchResultsItemComponent from './SearchResultsItemComponent';
import {withRouter} from 'react-router-dom';
import Constants from 'utils/Constants';
import SearchCriteriaContext from "context/SearchCriteriaContext";

export class SearchResultsItemContainer extends Component {

    constructor(props) {
        super(props)

        this.handleViewDetails = this.handleViewDetails.bind(this)
    }

    handleViewDetails() {
        const {searchResultsPage, actions} = this.context
        const {claim, sequenceNo} = this.props;

        // The sequenceNo just represents where this results item comes
        // in respect to the current page of results. For tagging, this
        // number needs to be adjusted to cater for it's position
        // in the full list of results for all pages.
        let actualSeqNo = ((searchResultsPage - 1 ) * Constants.CLAIMS_SEARCH_ITEMS_PER_PAGE) + sequenceNo

        actions.tagViewClaimDetails(claim.claimNo, actualSeqNo)

        this.props.history.push(
            {
                pathname: '/claims/' + claim.claimNo,
                search: '?mNo=' + claim.patient.membershipNo +
                    '&pId=' + claim.patient.businessPartnerId
            })
    }

    render() {
        const {claim} = this.props;

        return (
            <SearchResultsItemComponent
                claim={claim}
                handleViewDetails={this.handleViewDetails} />
        )
    }
}

SearchResultsItemContainer.propTypes = {
    claim: PropTypes.instanceOf(Object).isRequired
};

export default withRouter(SearchResultsItemContainer);
SearchResultsItemContainer.contextType = SearchCriteriaContext;