import React, { Component } from 'react';
import styles from './Step3Container.module.scss';
import RegistrationContext from 'context/RegistrationContext';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import PasswordConfirmContainer from 'components/PasswordConfirmContainer';
import Step3UsernameContainer from './Step3UsernameContainer';
import Step3EmailContainer from './Step3EmailContainer';
import MobileNoInputContainer from "components/mobileno/MobileNoInputContainer";
import TagManager from "react-gtm-module";
import {getRegoStepArgs, TAG_LABEL_FORM_COMPLETE} from "utils/GTMHelper"

const TAG_STEP_NAME = 'Create account';
const TAG_FIELD_NAME_LOGIN_PREFERENCE = 'Login preference';
const TAG_FIELD_NAME_NEXT_BUTTON = 'Create Account Button';

class Step3Container extends Component {

    constructor(props) {
        super(props);

        this.handleNext = this.handleNext.bind(this);
        this.handleRadioCheck = this.handleRadioCheck.bind(this)
        this.isEmailAsUsername = this.isEmailAsUsername.bind(this)
        this.setUsername = this.setUsername.bind(this)
        this.tagFieldChange = this.tagFieldChange.bind(this)

        this.state = {
            selectedUsernameOption: 'username'
        }
    }

    handleRadioCheck(event) {
        const {actions} = this.context;
        actions.setUsername('')
        actions.setUserEmail('')

        this.tagFieldChange(TAG_FIELD_NAME_LOGIN_PREFERENCE, event.target.value)

        this.setState({
            selectedUsernameOption: event.target.value
        })
    }

    handleNext() {
        this.tagFieldChange(TAG_FIELD_NAME_NEXT_BUTTON, '')

        const {actions} = this.context;
        actions.completeStep3();
    }

    tagFieldChange(fieldName, fieldValue) {
        const {stepNo, gtmSequenceId} = this.context
        const tagParms = {
            label: TAG_LABEL_FORM_COMPLETE,
            sequenceId: gtmSequenceId,
            stepName: TAG_STEP_NAME,
            stepValue: stepNo,
            fieldName: fieldName,
            fieldValue: fieldValue
        }
        TagManager.dataLayer(getRegoStepArgs(tagParms))
    }

    isEmailAsUsername() {
        return this.state.selectedUsernameOption === 'email'
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    renderLoginPreference() {
        return (
            <>
                <div className='row'>
                    <div className={classnames('col-lg-7', styles.titleWrapper)}>
                        <h3>Your login preference</h3>
                        <hr/>
                    </div>
                    <div className={classnames('col-lg-5', styles.copyWrapper)}>
                    </div>
                </div>
                <div className='row'>
                    <div className={classnames('col-lg-7', styles.radioWrapper)}>
                        <div className="input-group">
                            <label className={classnames("input-radio",styles.radioLabel)}>
                                I'd like to create a username
                                <input type="radio"
                                       name="username"
                                       value='username'
                                       checked={!this.isEmailAsUsername()}
                                       onChange={this.handleRadioCheck}/>
                                <div className="input-indicator needsclick"></div>
                            </label>
                            <label className={classnames("input-radio",styles.radioLabel)}>
                                I'd like to use my email as my username
                                <input type="radio"
                                       name="email"
                                       value='email'
                                       checked={this.isEmailAsUsername()}
                                       onChange={this.handleRadioCheck} />
                                <div className="input-indicator needsclick"></div>
                            </label>
                        </div>
                        <small className={classnames("input-helper feedback", styles.radioHelp)} >
                            <strong>If you'd like to use your email as your username,</strong>
                            <span>
                                &nbsp;please make sure your email account isn't shared with other people, and that it is not a generic practice email address.
                            </span>
                        </small>
                    </div>
                    <div className={classnames('col-lg-5', styles.radioCopyWrapper)}>
                        <div className='small'>
                            Please note, your username can't be changed once the registration process is complete.
                            If you use your email address as your username, you will need to create a new Provider
                            Self Service account if your email address changes.
                        </div>
                    </div>
                </div>
            </>
        )
    }

    renderContentTitle() {
        return (
            <div className='row'>
                <div className={classnames('col-lg-7', styles.createAcctWrapper)}>
                    <h3>Create your account</h3>
                    <hr/>
                </div>
                <div className={classnames('col-lg-5', styles.copyWrapper)}>
                </div>
            </div>
        )
    }

    setUsername(inputValue) {
        const {actions} = this.context;
        actions.setUsername(inputValue)
        if (this.isEmailAsUsername()) {
            actions.setUserEmail(inputValue)
        }
    }

    renderUsernameRow() {
        const {creatingAccount} = this.context;
        return (
            <div className='row align-items-stretch'>
                <div className={classnames('col-lg-7', styles.fieldsWrapper)}>
                    <div className='row'>
                        <div className='col-lg-9'>
                            <Step3UsernameContainer
                                onUsernameInput={this.setUsername}
                                disabled={creatingAccount}
                                emailAsUsername={this.isEmailAsUsername()}
                                key={this.isEmailAsUsername()}
                            />
                        </div>
                    </div>
                </div>
                <div className={classnames('col-lg-5', styles.copyWrapper)}>
                    <div className='small'>Your username will be used to log in to Provider Self Service.</div>
                </div>
            </div>
        )
    }

    renderPasswordRow() {
        const {creatingAccount, actions} = this.context;
        return (
            <div className='row align-items-stretch'>
                <div className={classnames('col-lg-7', styles.fieldsWrapper)}>
                    <div className='row'>
                        <div className='col-lg-9'>
                            <PasswordConfirmContainer
                                setUserPassword={actions.setUserPassword}
                                disabled={creatingAccount}
                                label='Password'
                                confirmLabel='Confirm password'
                            />
                        </div>
                    </div>
                </div>
                <div className={classnames('col-lg-5', styles.copyWrapper)}>
                    <div className='small'>Passwords should be 7-40 characters and contain at least 1 lower case letter and 1 number.</div>
                </div>
            </div>
        )
    }

    renderMobileRow() {
        const {creatingAccount, actions, displayMobileUniquenessError} = this.context;
        return (
            <div className='row align-items-stretch'>
                <div className={classnames('col-lg-7', styles.fieldsWrapper)}>
                    <div className='row'>
                        <div className='col-lg-9'>
                            <MobileNoInputContainer
                                onMobileInput={actions.setUserMobile}
                                disabled={creatingAccount}
                                isNumberAlreadyInUse={displayMobileUniquenessError}
                            />
                        </div>
                    </div>
                </div>
                <div className={classnames('col-lg-5', styles.copyWrapper)}>
                    <div className='small'>
                        Please provide your own Australian mobile number.<br/>
                        A verification code will be sent to the mobile number you provide in
                        order to complete registration, each time you log into your Provider
                        Self Service Account, and to retrieve your username and password in
                        case you forget.
                    </div>
                </div>
            </div>
        )
    }

    renderEmailRow() {
        if (!this.isEmailAsUsername()) {
            const {creatingAccount, actions} = this.context;
            return (
                <div className='row align-items-stretch'>
                    <div className={classnames('col-lg-7', styles.fieldsWrapper)}>
                        <div className='row'>
                            <div className='col-lg-9'>
                                <Step3EmailContainer
                                    onEmailInput={actions.setUserEmail}
                                    disabled={creatingAccount}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={classnames('col-lg-5', styles.copyWrapper)}>
                        <div className='small'>
                            You will receive notifications and updates from Provider Self Service
                            to the email address provided. We will also send you an email
                            with a verification link to get your account up and running.
                        </div>
                    </div>
                </div>
            )
        }
    }

    renderNextButton() {
        let disabled = !this.props.nextEnabled;
        let buttonContents = 'Create account';
        if (this.context.creatingAccount) {
            buttonContents = <div className="spinner-md text-white"></div>;
            disabled = true;
        }

        return (
            <div>
                <div className='input-group'>
                    <button
                        onClick={this.handleNext}
                        className='btn-lg btn btn-primary'
                        disabled={disabled}
                        color="primary">
                        {buttonContents}
                    </button>
                </div>
            </div>
        )
    }

    renderNextButtonRow() {

        return (
            <div className='row align-items-stretch'>
                <div className={classnames('col-lg-7', styles.fieldsWrapper)}>
                    <div className='row'>
                        <div className='col-lg-9'>
                            {this.renderNextButton()}
                        </div>
                    </div>
                </div>
                <div className={classnames('col-lg-5', styles.copyWrapper)}>
                </div>
            </div>
        )
    }

    renderTsAndCsRow() {
        return (
            <div className='row'>
                <div className={classnames('col-lg-7', styles.fieldsWrapper)}>
                    <div className={styles.termsConditions}>
                        <span>By creating an account, I confirm I have read and agree to the Terms of Use and </span>
                        <a
                            className='simple-link-reverse'
                            href="https://www.medibank.com.au/legal-information/"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            Medibank's Privacy Policy
                        </a>
                    </div>
                </div>
                <div className={classnames('col-lg-5', styles.copyWrapper)}>
                </div>
            </div>
        )
    }

    render() {
        return (
            <>
                <div className={classnames('container', styles.contentWrapper)}>
                    {this.renderLoginPreference()}
                    {this.renderContentTitle()}
                    {this.renderUsernameRow()}
                    {this.renderEmailRow()}
                    {this.renderPasswordRow()}
                    {this.renderMobileRow()}
                    {this.renderNextButtonRow()}
                    {this.renderTsAndCsRow()}
                </div>
            </>
        );
    }
}

Step3Container.contextType = RegistrationContext;

Step3Container.propTypes = {
    nextEnabled: PropTypes.bool
};

Step3Container.defaultProps = {
    nextEnabled: false
};

export default Step3Container;