import React, { Component } from 'react';
import styles from './MobileDetailsEditNumberComponent.module.scss';
import classnames from 'classnames';
import PropTypes from 'prop-types'

class MobileDetailsEditNumberComponent extends Component {

    constructor(props) {
        super(props)

        this.handleMobileNoInput = this.handleMobileNoInput.bind(this);
    }

    handleMobileNoInput(event) {
        this.props.handleMobileNoInput(event.target.value)
    }

    render() {
        const {mobileNoValue,
            mobileNoIsValid,
            mobileNoErrorMsg,
            handleSendSMS,
            smsRequested,
            showResendSMS,
            mobileIsInUse} = this.props

        const sendDisabled = (!mobileNoIsValid || smsRequested || mobileIsInUse)
        let sendBtnText = 'Send SMS';
        if (showResendSMS) {
            sendBtnText = 'Resend SMS'
        }

        let inputGroupClasses = 'input-group';
        if (mobileNoErrorMsg.length > 0) {
            inputGroupClasses += ' input-group-alert';
        }

        return (
            <div className={classnames('row', styles.mobileRow)}>
                <div className='col-lg-7'>
                    <div className={inputGroupClasses}>
                        <span className='label'>New mobile number</span>
                        <input onChange={this.handleMobileNoInput}
                               id="newMobile"
                               type={'text'}
                               placeholder={'Please enter mobile number'}
                               value={mobileNoValue}
                               disabled={smsRequested}
                               className='input-search form-control'
                        />
                    </div>
                </div>
                <div className={classnames('col-lg-5',styles.sendBtnWrapper)}>
                    <button
                        className={classnames('btn btn-lg btn-dark-ghost', styles.sendBtn)}
                        disabled={sendDisabled}
                        onClick={handleSendSMS}
                    >
                        {sendBtnText}
                    </button>
                </div>
                {mobileNoErrorMsg.length > 0 &&
                <div className='col-lg-12'>
                    <div data-test-id='error' className={classnames('input-helper form-error small', styles.inputHelper)}>
                        {mobileNoErrorMsg}
                    </div>
                </div>
                }
                {mobileIsInUse &&
                <div className='col-lg-12'>
                    <div data-test-id='error' className={classnames('input-helper form-error small', styles.inputHelper)}>
                        This mobile number is already in use. Please enter a new number.
                    </div>
                </div>
                }
                {smsRequested && mobileNoErrorMsg.length === 0 &&
                <div className='col-lg-12'>
                    <span>Your number has been updated and a verification code has been sent</span>
                </div>
                }
            </div>
        )
    }
}

MobileDetailsEditNumberComponent.propTypes = {
    mobileNoValue: PropTypes.string.isRequired,
    handleMobileNoInput: PropTypes.func.isRequired,
    mobileNoIsValid: PropTypes.bool.isRequired,
    mobileNoErrorMsg: PropTypes.string,
    handleSendSMS: PropTypes.func.isRequired,
    smsRequested: PropTypes.bool.isRequired,
    showResendSMS: PropTypes.bool.isRequired
}

MobileDetailsEditNumberComponent.defaultProps = {
    mobileNoIsValid: false,
    mobileNoErrorMsg: ''
}

export default MobileDetailsEditNumberComponent;