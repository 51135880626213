import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from "classnames";
import styles from "./PasswordInputComponent.module.scss";

class PasswordInputComponent extends Component {

    constructor(props) {
        super(props);

        this.toggleShowHide = this.toggleShowHide.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);

        this.state = {
            showPassword: false,
            inError: false
        }
    }

    toggleShowHide() {
        this.setState({
            showPassword: !this.state.showPassword
        })
    }

    handleOnChange(event) {
        const inputValue = event.target.value;
        this.props.onChange(inputValue);
    }

    render() {
        const {label, fieldId, inputValue, onBlur, inError, errorText, disabled, withExplanation} = this.props;
        const {showPassword} = this.state;
        const btnText = (showPassword) ? 'Hide' : 'Show';
        const inputType = (showPassword) ? 'text' : 'password';

        return (
            <>
                <div className='input-group input-with-icon-right'>
                    <label htmlFor={fieldId}>{label}</label>
                    <button type="button"
                            className="btn btn-inline btn-sm js-toggle-password"
                            disabled={disabled}
                            tabIndex={-1}
                            onClick={this.toggleShowHide}
                    >
                        {btnText}
                    </button>
                    <input id={fieldId}
                           type={inputType}
                           value={inputValue}
                           onChange={this.handleOnChange}
                           onBlur={onBlur}
                           disabled={disabled}
                           className={classnames('input-password', {error: inError} )} />
                </div>
                {inError &&
                <div data-test-id='error' className={classnames('input-helper form-error small', styles.inputHelper)}>
                    {errorText}
                </div>
                }
                {withExplanation &&
                <div className={classnames('', styles.explanation)}>
                    <div className='small'>Passwords should be 7-40 characters and contain at least 1 lower case letter and 1 number.</div>
                </div>
                }
            </>
        )
    }
}

PasswordInputComponent.propTypes = {
    label: PropTypes.string.isRequired,
    fieldId: PropTypes.string.isRequired,
    inputValue: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    inError: PropTypes.bool.isRequired,
    errorText: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    withExplanation: PropTypes.bool
}

PasswordInputComponent.defaultProps = {
    disabled: false,
    withExplanation: false
}

export default PasswordInputComponent;