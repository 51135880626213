import React, { Component } from 'react';
import classnames from 'classnames';
import UserContext from "context/UserContext";
import styles from './MyDetailsContainer.module.scss';
import PersonalDetailsComponent from "components/account/mydetails/PersonalDetailsComponent";
import MobileDetailsContainer from 'components/account/mydetails/MobileDetailsContainer'
import PasswordDetailsContainer from 'components/account/mydetails/PasswordDetailsContainer'
import Constants from 'utils/Constants';
import AccountNavComponent from './AccountNavComponent'
import EmailDetailsContainer from "components/account/mydetails/EmailDetailsContainer";
import {getPageLoadArgs} from "utils/GTMHelper"
import TagManager from "react-gtm-module"


class MyDetailsContainer extends Component {

    componentDidMount() {
        const {cognitoId, role} = this.context.currentUser
        TagManager.dataLayer(getPageLoadArgs('Your Details',this.props.location.pathname, cognitoId, role))
    }

    renderPersonalDetails() {
        const {firstName, lastName, role, username} = this.context.currentUser;
        const niceRole = Constants.USER_ROLE_REVERSE_MAPPING[role]

        return (
            <PersonalDetailsComponent
                firstName={firstName}
                lastName={lastName}
                role={niceRole}
                username={username}
            />
        )
    }

    renderPasswordSection() {
        return (
            <PasswordDetailsContainer />
        )
    }

    renderEmailSection() {
        const {email} = this.context.currentUser;

        return (
            <EmailDetailsContainer email={email} />
        )
    }

    renderMobileSection() {

        return (
            <MobileDetailsContainer />
        )
    }

    render() {
        return (
            <div className={classnames('container', styles.mainWrapper)}>
                <div className='row'>
                    <div className='col-lg-3'>
                        <AccountNavComponent />
                    </div>
                    <div className='col-lg-9'>
                        <h3>Your details</h3>
                        {this.renderPersonalDetails()}
                        {this.renderPasswordSection()}
                        {this.renderEmailSection()}
                        {this.renderMobileSection()}
                    </div>
                </div>

            </div>
        )
    }
}

MyDetailsContainer.contextType = UserContext;

export default MyDetailsContainer;