import React, { Component } from 'react';
import RegistrationContext from 'context/RegistrationContext';
import styles from './Step2FormContainer.module.scss';
import DateInputComponent from 'components/datepickers/DateInputComponent';
import Utils from 'utils/Utils'
import PropTypes from 'prop-types'
import classnames from "classnames";

class Step2FormContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dvsCheckboxChecked: false,
        };

        this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
        this.handleLastNameChange = this.handleLastNameChange.bind(this);
        this.handleDriversLicenceChange = this.handleDriversLicenceChange.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
        this.handleDriversCardNumberChange = this.handleDriversCardNumberChange.bind(this);
        this.handleDOBChange = this.handleDOBChange.bind(this);
        this.onDVSAgreementCheckboxToggled = this.onDVSAgreementCheckboxToggled.bind(this);
    }

    handleFirstNameChange(event) {
        const value = event.target.value
        if (value.length > 40) {
            return
        }
        this.context.actions.setUserFirstName(value);
    }

    handleLastNameChange(event) {
        const value = event.target.value
        if (value.length > 40) {
            return
        }
        this.context.actions.setUserLastName(value);
    }

    handleDOBChange(dateInput) {
        const {actions} = this.context
        actions.setUserDOB(dateInput)
    }

    handleDriversLicenceChange(event) {
        const value = event.target.value
        if (value.length > 0 &&
            (!Utils.containsOnlyLettersAndDigits(value) ||
            value.length > 15)) {
            return
        }
        this.context.actions.setDriversLicence(value);
    }

    handleDriversCardNumberChange(event) {
        const value = event.target.value
        if (value.length > 0 &&
            (!Utils.containsOnlyLettersAndDigits(value) ||
            value.length > 10)) {
            return
        }
        this.context.actions.setDriversCardNumber(value)
    }

    handleStateChange(event) {
        const value = event.target.value
        this.context.actions.setDriversLicenceState(value)
    }

    renderPersonalDetailsSection() {
        const {userFirstName, userLastName} = this.context;
        const {disabled} = this.props

        return (
            <div>
                <h3>Enter your details</h3>
                <hr/>
                <div className='input-group-row'>
                    <div className='input-group input-group-small'>
                        <label htmlFor="firstName">First name</label>
                        <input onChange={this.handleFirstNameChange}
                               onBlur={this.handleFirstNameTagging}
                               placeholder='First name'
                               id='firstName'
                               type='text'
                               value={userFirstName}
                               disabled={disabled}
                               className='input-search form-control' />
                    </div>
                    <div className='input-group input-group-small'>
                        <label htmlFor="lastName">Last name</label>
                        <input onChange={this.handleLastNameChange}
                               onBlur={this.handleLastNameTagging}
                               placeholder='Last name'
                               id='lastName'
                               type='text'
                               value={userLastName}
                               disabled={disabled}
                               className='input-search form-control' />
                    </div>
                </div>
                <div className='input-group-row'>
                    <div className='input-group input-group-small'>
                        <label htmlFor="dateOfBirth">Date of birth</label>
                        <DateInputComponent
                            onDateInput={this.handleDOBChange}
                            dobRestriction={true}
                            earliestDate={'01/01/1900'}
                            disabled={disabled}
                        />
                    </div>
                </div>
            </div>
        )
    }

    renderIdentitySection() {
        const {driversLicenceNo, driversCardNumber} = this.context;
        const {disabled} = this.props

        return (
            <div className={styles.identityWrapper}>
                <h3>Verify your identity</h3>
                <hr/>
                <div className='input-group-row'>
                    <div className={classnames('input-group',styles.inputGroupSmallTwoThirds)}>
                        <label htmlFor="dlNumber">Current Australian Driver's Licence number</label>
                        <input id='dlNumber'
                               type='text'
                               value={driversLicenceNo}
                               onChange={this.handleDriversLicenceChange}
                               onBlur={this.handleDriversLicenceTagging}
                               disabled={disabled}
                               className='input-search form-control' />
                    </div>

                    <div className={classnames('input-group select',styles.inputGroupSmallOneThird)}>
                        <label htmlFor="state">Issued state</label>
                        <select onChange={this.handleStateChange}
                                className='form-control'
                                disabled={disabled}
                                type="select" name="select" id="state">
                            <option value='ACT'>ACT</option>
                            <option value='NSW'>NSW</option>
                            <option value='NT'>NT</option>
                            <option value='QLD'>QLD</option>
                            <option value='SA'>SA</option>
                            <option value='TAS'>TAS</option>
                            <option value='WA'>WA</option>
                            <option value='VIC'>VIC</option>
                        </select>
                        <span className='select-chevron'></span>
                    </div>
                </div>
                <div className='input-group-row'>
                    <div className={classnames('input-group',styles.inputGroupSmallTwoThirds)}>
                        <label htmlFor="dlCardNumber">Current Australian Driver's Licence card number</label>
                        <input id='dlCardNumber'
                               type='text'
                               value={driversCardNumber}
                               onChange={this.handleDriversCardNumberChange}
                               disabled={disabled}
                               className='input-search form-control' />
                    </div>
                </div>
                <div className="input-group-row input-group">
                    <label className='input-checkbox'>
                        <span>I agree to provide my driver's licence details to Equifax to verify my identity via DVS</span>
                        <input
                            defaultChecked={false}
                            onClick={this.onDVSAgreementCheckboxToggled}
                            type='checkbox'
                            value={this.state.dvsAgreement}
                        />
                        <span className={classnames('input-indicator', 'needsclick')} />
                    </label>
                </div>
            </div>
        )
    }

    render() {

        return (
            <>
                {this.renderPersonalDetailsSection()}
                {this.renderIdentitySection()}
            </>
        );
    }

    onDVSAgreementCheckboxToggled() {
        const newCheckboxValue = !this.state.dvsCheckboxChecked;
        this.setState({ dvsCheckboxChecked: newCheckboxValue });
        this.props.onDvsCheckboxToggled(newCheckboxValue);
    }
}

Step2FormContainer.propTypes = {
    disabled: PropTypes.bool,
    onDvsCheckboxToggled: PropTypes.func
}

Step2FormContainer.contextType = RegistrationContext;

export default Step2FormContainer;