import React, { Component } from 'react';
import PropTypes from 'prop-types'
import ConnectedUsersItemComponent from './ConnectedUsersItemComponent'

class ConnectedUsersListComponent extends Component {

    render() {

        const {connectedUsers} = this.props

        if (connectedUsers.length > 0) {
            return connectedUsers.map((connectedUser) => {
                return <ConnectedUsersItemComponent
                    key={connectedUser.username}
                    user={connectedUser}
                />
            });
        } else {
            return (
                <tr>
                    <td colSpan="3">
                        No users found.
                    </td>
                </tr>
            )
        }
    }
}

ConnectedUsersListComponent.propTypes = {
    connectedUsers: PropTypes.array.isRequired
}

export default ConnectedUsersListComponent;