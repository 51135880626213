import React, { Component } from 'react';
import classnames from "classnames";
import UserContext from "../../context/UserContext";
import styles from './AddProviderStep.module.scss';
import CancelButtonComponent from "./CancelButtonComponent";
import AddProviderSearchComponent from "./AddProviderSearchComponent";
import {lookupProvider} from "../../graphql/queries";
import {API, graphqlOperation} from 'aws-amplify';
import SpinnerButtonComponent from "../spinnerButton/SpinnerButtonComponent";
import {getPageLoadArgs, getManageProvidersArgs} from "utils/GTMHelper"
import TagManager from "react-gtm-module";
import {withRouter} from "react-router-dom";
import PropTypes from 'prop-types'

export class AddProviderStep extends Component {

    constructor(props) {
        super(props);

        this.setStemNo = this.setStemNo.bind(this);
        this.setProviderNo = this.setProviderNo.bind(this);
        this.handleNext = this.handleNext.bind(this)
        this.handleProviderNumberSearch = this.handleProviderNumberSearch.bind(this)

        this.state = {
            inError: false,
            error: null,
            searchStemNo: '',
            searchProviderNo: '',
            locations: [],
            actions: {
                setStemNo: this.setStemNo,
                setProviderNo: this.setProviderNo
            }
        }
    }

    setStemNo(stemNo) {
        this.setState({
            searchStemNo: stemNo,
            inError: false
        }, () => {this.props.clearErrors()})
    }

    setProviderNo(providerNo) {
        this.setState({
            searchProviderNo: providerNo,
            inError: false
        }, () => {this.props.clearErrors()})
    }

    async handleNext() {
        this.setState({}, () => {
            this.handleProviderNumberSearch()
        })

    }

    handleProviderNumberSearch() {
        let providerNumber = this.state.searchProviderNo
        let stem = this.state.searchStemNo
        if (stem === '') {
            stem = null
        }
        if (providerNumber === '') {
            providerNumber = null
        }
        this.setState({searching: true}, async () => {
            try {
                let results = await API.graphql(graphqlOperation(lookupProvider, {
                    input: {
                        stem: stem,
                        providerNumber: providerNumber
                    }
                }));

                let locations = results.data.lookupProvider
                this.setState({searching: false}, () => {this.props.actions.handleNextStep(locations)})
            }
            catch (error) {
                this.setState({inError: true, searching: false, errorCode: error.errors[0].errorType, errorMessage: error.errors[0].message})
            }
        })
    }

    /***
     * Determine if search button can be enabled if the mandatory search criteria has been
     * selected
     */
    canSearchBeRun() {
        return (this.state.searchStemNo ||
            this.state.searchProviderNo) ? true : false;
    }

    componentDidMount() {
        const {cognitoId, role} = this.context.currentUser
        TagManager.dataLayer(getPageLoadArgs('Add Another Provider', this.props.location.pathname, cognitoId, role))

        const tagParms = {
            label: 'Start',
            sequenceId: this.props.gtmSequenceId,
            manageType: 'Add Other Provider'
        }

        TagManager.dataLayer(getManageProvidersArgs(tagParms))
    }

    render() {
        const disabled = !this.canSearchBeRun();
        const {searchStemNo, searchProviderNo} = this.state;
        const {setStemNo, setProviderNo} = this.state.actions;
        let {errorMessage, errorCode, inError, clearErrors} = this.props
        let classes = 'input-group'
        let actualErrorMessage = (<span>&nbsp;</span>);
        if (inError) {
            if (errorCode === 'NoDataFound') {
                actualErrorMessage = errorMessage
                classes += ' input-group-alert'
            } else if (errorCode === 'AllProvidersLinked') {
                actualErrorMessage = 'All provider numbers under this STEM are already linked to your account'
                if (this.state.searchProviderNo !== undefined && this.state.searchProviderNo !== '') {
                    actualErrorMessage = 'This provider number is already linked to your account'
                }
                classes += ' input-group-alert'
            } else {
                actualErrorMessage = "There was a technical error verifying your details. Please try again."
                classes += ' input-group-alert'
            }
        }

        return (
            <div className={styles.step1Wrapper}>
                <div className="container">
                    <div className={classnames('row', styles.header)}>
                        <div className={classnames('col-lg-8')}>
                            <h3>Request access to other provider data</h3>
                            <p>Search and select providers that you would like access to</p>
                        </div>
                        <div className={classnames('col-lg-4', styles.buttonCol)}>
                            <CancelButtonComponent />
                        </div>
                    </div>
                    <div className={classnames('row')}>
                        <div className={classnames('col-lg-12')}>
                            <div className={classnames(styles.title)}><h4>Provider details</h4></div>

                        </div>
                        <div className={classnames('col-lg-12')}>
                            <div className={classnames(styles.contentWrapper)} >
                                <AddProviderSearchComponent
                                    stemNo={searchStemNo}
                                    providerNo={searchProviderNo}
                                    setStemNo={setStemNo}
                                    setProviderNo={setProviderNo}
                                    clearErrors={clearErrors}
                                    inputClass={classes}
                                />
                                <div data-test-id='error' className={classnames('input-helper form-error small')}>
                                    {actualErrorMessage}
                                </div>
                                <div className={classnames(styles.content)}>
                                    <SpinnerButtonComponent
                                        handleClick={this.handleNext}
                                        className={styles.button}
                                        text={'Search'}
                                        spinning={this.state.searching}
                                        disabled={disabled}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

AddProviderStep.propTypes = {
    gtmSequenceId: PropTypes.string.isRequired
}

export default withRouter(AddProviderStep);
AddProviderStep.contextType = UserContext;
