import classnames from "classnames"
import styles from "./ResendVerificationCodeLink.module.scss"
import React, {Component} from "react"
import PropTypes from 'prop-types'

const TIMEOUT = 15000 //15 seconds

class ResendVerificationCodeLink extends Component {


    constructor() {
        super()
        this.handleResend = this.handleResend.bind(this)
        this.showResend = this.showResend.bind(this)
        this.showLink = this.showLink.bind(this)

        this.state = {
            resent: false,
            showLink: false
        }
    }

    componentDidMount() {
        if (this.props.sendViaEmail) {
            this.showLink()
        } else {
            this.timer = setTimeout(this.showLink, this.props.timeout);
        }

    }

    componentWillUnmount() {
        clearTimeout(this.timer)
    }

    showLink() {
        this.setState({showLink: true})
    }

    render() {
        const {sendViaEmail, mobileNumberEnding} = this.props

        if (!this.state.showLink) return <></>
        const linkText = (sendViaEmail) ? 'Resend email' : 'Resend code'
        const linkQuestion = (sendViaEmail) ? 'Need a new link?' : 'Need a new code?'
        var resendMessage = (<div className={classnames(styles.messageResend)}>{linkQuestion}&nbsp;<a className='simple-link-reverse' href="true" onClick={this.handleResend}>{linkText}</a></div>);
        if (this.state.resent) {
            if (sendViaEmail) {
                resendMessage = (<div className={classnames(styles.messageResend)}>A new verification email has been sent.</div>)
            } else {
                resendMessage = (<div className={classnames(styles.messageResend)}>A new code has been sent to the number ending in {mobileNumberEnding}</div>)
            }
        }
        return resendMessage
    }

    showResend() {
        this.setState({resent: false});
    }

    handleResend(event) {
        event.preventDefault();
        this.setState({resent: true});
        this.props.continue()
        setTimeout(this.showResend, this.props.timeout);
    }
}

ResendVerificationCodeLink.propTypes = {
    timeout: PropTypes.number,
    sendViaEmail: PropTypes.bool,
    mobileNumberEnding: PropTypes.string
}

ResendVerificationCodeLink.defaultProps = {
    timeout: TIMEOUT,
    sendViaEmail: false
}

export default ResendVerificationCodeLink
